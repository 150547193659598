import { createSelector } from 'reselect';
import { getMetadataMeasuresWithScales } from './rangeDataSelector';
import { emptyObject } from '../../functions/functions';

const getGeojson = ({geomap}) => geomap.geojson;
const getGeoJsonFeatures = ({geomap}) => geomap.geojson.features;
const getGeoMapColorBy = ({ parameters }) => parameters.geoMapColorBy;

export const uniqueCladesSelector = createSelector(getGeoJsonFeatures, features => features ? [...new Set(features.map(el => el.properties.groupId).sort())] : []);

export const geojsonSelector = createSelector(getGeojson, geojson => {
    if (!geojson.features) {
        const _geojson = { ...geojson};
        _geojson.features = [];
        return _geojson
    }
    return geojson;
})
export const correctedColorsGeoJson = createSelector([geojsonSelector, getGeoMapColorBy, getMetadataMeasuresWithScales], (geojson, geoMapColorBy, measures) => {
  
    // console.log('clades', measures.clade);
    if (geojson.features.length === 0) return geojson;
    // console.log('[correctedColorsGeoJson]', geojson, geoMapColorBy, measures)
    const selectedMeasure = measures[geoMapColorBy] || {};


    
    const colorData = selectedMeasure.scale.range?.data; //visibleRange;
    // console.log('[correctedColorsGeoJson], colorData:',);

    // console.log('colorData = ', colorData, geojson.features, selectedMeasure)

    // console.log('[correctedColorsGeoJson] geoMapColorBy = ',geoMapColorBy);
    const _geojson = { ...geojson };

    if (colorData) {
        _geojson.features = (geojson.features||[]).map(f => {
        
         return { ...f, properties: { ...f.properties, color: colorData[f.properties.groupId]?.color}};
         
        });
        // console.log(_geojson.features);
    }
    // console.log(_geojson.features);
    else _geojson.features = [];
    // return geojson;
    return _geojson;
})
