// Action Types
import {
    FETCH_FREQUENCIES_REQUEST,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_FREQUENCIES_ERROR,
    TOGGLE_FREQUENCIES_OPTION,
    RESET_FREQUENCIES,
    RESET_VISIBLE_BINS
} from './actionTypes';

import { request, received } from '../baseActions';


const fetchFrequencies = (payload) => {
    const { strainSubset } = payload;
    const subsetId = strainSubset;
    const _payload = { ...payload, subsetId };
    //console.log(subsetId, regionId, strainSubset);
    return received(FETCH_FREQUENCIES_REQUEST, _payload)
}

const fetchFrequenciesSuccess = (res) => received(FETCH_FREQUENCIES_SUCCESS, res)
const fetchFrequenciesError = (error) => received(FETCH_FREQUENCIES_ERROR, error)

const fetchFrequenciesForAllRegions = (payload) => received(FETCH_FREQUENCIES_REQUEST, {...payload, allRegions: true});

const resetFrequencies = () => request(RESET_FREQUENCIES);

const resetVisibleBins = (strainSubset, selectedBins) => received(RESET_VISIBLE_BINS, { strainSubset, selectedBins });

const toggleFrequenciesOption = (optionId) => received(TOGGLE_FREQUENCIES_OPTION, { optionId });

export { fetchFrequencies, fetchFrequenciesSuccess, fetchFrequenciesError, fetchFrequenciesForAllRegions, resetFrequencies, resetVisibleBins, toggleFrequenciesOption };
