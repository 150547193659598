import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography, Checkbox, FormControl, MenuItem, Tooltip, DialogContentText } from '@mui/material';
import { styles, NewScaleDialog } from './styles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import DraggableList from '../Elements/DraggableList';
import ScaleIdConfigurator from '../Elements/ScaleIdConfigurator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { spliceScaleId } from '../Elements/functions';

const additionalDomainDiscrete = {
    value: Infinity,
    color: 'white'
};

const domainInitState = [
    {
        value: 0,
        quantile: false,
        color: 'white'
    },
    {
        value: 10,
        quantile: false,
        color: 'red'
    }
];

const errorInitState = { scaleType: {status: false, message: ''}, antigenicScaleType: {status: false, message: ''}, id: {status: false, message: ''} };

const reorder = (
    list,
    startIndex,
    endIndex
  )=> {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

const EditScaleDialog = ({ scale, lineages, scalesPalette, openDialog, handleCloseDialog, updateScale }) => {
    const classes = styles();
    const [ updatedScale, setUpdatedScale] = useState({});
    const [ paletteOptions, setPaletteOptions ] = useState(['custom']);
    const [ updatedDomain, setUpdatedDomain ] = useState([]);
    const [ initVals, setInitVals ] = useState({});

    useEffect(() => {
        const options = paletteOptions.concat(Object.keys(scalesPalette));
        setPaletteOptions(options);
    }, [scalesPalette]);

    useEffect(() => {
        const isCustomScale = typeof scale.range !== 'string';
        const isDomain = scale.domain || false;

        const initState = {
            scaleId: scale.scaleId,
            discrete: scale.discrete,
            numeric: true,
            palette: isCustomScale ? 'custom' : scale.range,
            lineage: scale.lineage || 'none',
        };
        setUpdatedScale(initState);

        if (!isDomain)
            return;

        let domainInitState = [];
        if (!scale.discrete){
            const scaleColors = !isCustomScale ? scalesPalette[scale.range] : scale.range;
            const lastIndex = scaleColors.length - 1;
            const middleColors = scaleColors.slice(1, lastIndex).map(el => {return {color: el, value: 0, quantile: false}});
            domainInitState = [{
                value: scale.domain[0].value,
                quantile: scale.domain[0].quantile,
                color: scaleColors[0]
            },
            ...middleColors,
            {
                value: scale.domain[1].value,
                quantile: scale.domain[1].quantile,
                color: scaleColors[lastIndex]
            }];
        } else {
            const scaleColors = !isCustomScale ? scalesPalette[scale.range] : scale.range;
            const lastIndex = scale.domain.length;
            const begin = scale.domain.map((el, index) => {
                return {
                    value: el.value,
                    color: scaleColors[index],
                    quantile: el.quantile
                }
            })
            domainInitState = [ ...begin, { value: Infinity, color: scaleColors[lastIndex]}]

        }
        setUpdatedDomain(domainInitState)

        const initValues = spliceScaleId(scale.scaleId);
        setInitVals(initValues)
    }, []);

    const handleChangeLineage = (e) => setUpdatedScale({ ...updatedScale, lineage: e.target.value });

    const handleCheckboxChange = (e) => {
        const discrete = e.target.checked;
        if (!discrete){
            let newDomain = updatedDomain.slice(0, updatedDomain.length - 1);
            setUpdatedDomain(newDomain);
            setUpdatedScale({ ...updatedScale, discrete });
        }
        else {
            let newDomain = [ ...updatedDomain, additionalDomainDiscrete];
            setUpdatedDomain(newDomain);
            setUpdatedScale({ ...updatedScale, discrete });
        }
    }

    const handleValueChange = (e, index) => {
        const newDomain = [ ...updatedDomain];
        const isQuantile = updatedDomain[index].quantile;
        const newValue = e.target.value;
        newDomain[index].value = isQuantile && (newValue > 1 || newValue < 0) ? 1 : newValue;
        setUpdatedDomain(newDomain);
    }

    const handleColorChange = (value, index) => {
        const newDomain = [ ...updatedDomain];
        newDomain[index].color = `#${value.hex}`;
        setUpdatedDomain(newDomain);
    };

    const handleQuantileChange = (e, index) => {
        const newDomain = [ ...updatedDomain];
        newDomain[index].quantile = e.target.checked;
        if (e.target.checked && (newDomain[index].value > 1 || newDomain[index].value < 0))
            newDomain[index].value = '';
            setUpdatedDomain(newDomain);
    }

    const addNewElement =  () => {
        const newDomain = [ ...updatedDomain];
        newDomain.splice(1, 0, { ...domainInitState[0]});
        setUpdatedDomain(newDomain);
    }

    const removeElement = (index) => {
        const newDomain = [ ...updatedDomain];
        newDomain.splice(index, 1);
        setUpdatedDomain(newDomain);
    }

    const onEndDrag = ({ destination, source }) => {
        if (!destination) return;
        const newDomain = reorder(updatedDomain, source.index, destination.index);
        setUpdatedDomain(newDomain);
    }

    const handleChange = (e) => {
        const palette = e.target.value;
        let newDomain = [];

        if ( palette === 'custom'){
            newDomain = [ ...domainInitState];
        } else {
            const colorsArray = scalesPalette[palette];
            newDomain = colorsArray.map((col, index) => {
                return {
                    value: !index ? 0 : 1,
                    quantile: true,
                    color: col
                };
            });
        }
        setUpdatedScale({ ...updatedScale, palette });
        setUpdatedDomain(newDomain);
    }

    const handleSubmit = () => {
        const doesLineageChanged = updatedScale.lineage === 'none' ?
            scale.lineage !== undefined :
            updatedScale.lineage !== scale.lineage;
        const doesIdChanged = updatedScale.scaleId !== scale.scaleId;

        updateScale(updatedScale, updatedDomain, doesLineageChanged, doesIdChanged, scale.scaleId)
    }

    const handleScaleIdChange = (scaleId) => {
        setUpdatedScale({ ...updatedScale, scaleId });
        // setError(errorInitState);
    }

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={NewScaleDialog}>
            <DialogTitle id="confirm-dialog-title">
                Edit scale: {scale.scaleId}
            </DialogTitle>
            <DialogContent style={{height: '450px', overflow: 'overlay'}}>
                <DialogContentText className={classes.helpText}>
                    ScaleId is a combination of measure and its name,<br/> current scale id: {updatedScale.scaleId}
                </DialogContentText>
                <ScaleIdConfigurator
                    handleScaleIdChange={handleScaleIdChange}
                    newScale={updatedScale}
                    error={errorInitState}
                    edit={true}
                    initVals={initVals}
                />
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'lineage'} label={'Lineage'} />
                    <CustomSelect
                        value={updatedScale.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        { lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}
                        <MenuItem key={'empty'} value={'none'} >{`None (all lineages)`}</MenuItem>

                    </CustomSelect>
                    </FormControl>

                    <FormControlLabel
                        value={updatedScale.discrete}
                        label={<Typography className={classes.formControlLabel}>Discrete</Typography>}
                        control={(
                            <Checkbox
                                icon={<CircleUnchecked />}
                                checked={updatedScale.discrete}
                                checkedIcon={<RadioButtonCheckedIcon style={{color: '#6F6CFF'}}/>}
                                onChange={handleCheckboxChange}
                                className={classes.checkbox}
                            />
                        )}
                    />
                    <div style={{height: '14px'}}/>

                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id={'palette'} label={'Scale palette'} />
                        <CustomSelect
                            value={updatedScale.palette}
                            onChange={handleChange}
                            className={classes.textField}
                            inputProps={{
                                name: 'palette',
                                id: 'palette',
                            }}
                        >
                            {paletteOptions.map((color, index) => {
                                if (color === 'custom' || !scalesPalette[color]?.length)
                                    return ( <MenuItem key={`${color}-${index}`} value={color} > {color[0].toUpperCase() + color.slice(1)}</MenuItem> );

                                let gradientStyle = 'linear-gradient(0.25turn, ';

                                scalesPalette[color].forEach((t, index) => {

                                    if (index === scalesPalette[color].length - 1)
                                        gradientStyle += index === t.length - 1 ? `${t})` :`${t})`;
                                    else
                                        gradientStyle += index === t.length - 1 ? `${t})` :`${t}, `;
                                });

                                const text = <div style={{whiteSpace: 'pre-line'}}>
                                    <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                                </div>



                                return (
                                    <Tooltip followCursor key={`${color}-${index}`} value={color} placement="bottom-start" title={text}  className={classes.tooltip} >
                                        <MenuItem className={classes.tooltip} >{color[0].toUpperCase() + color.slice(1)}</MenuItem>
                                    </Tooltip>
                                )
                            })}

                        </CustomSelect>


                    <DraggableList
                        domain={updatedDomain}
                        newScale={updatedScale}
                        handleValueChange={handleValueChange}
                        handleColorChange={handleColorChange}
                        handleQuantileChange={handleQuantileChange}
                        addNewElement={addNewElement}
                        onEndDrag={onEndDrag}
                        removeElement={removeElement}
                    />

                </FormControl>

            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm}
                    onClick={handleSubmit}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    const { lineages } = state.lineages;

    return {
        lineages
    }
};


export default connect(mapStateToProps)(EditScaleDialog);
