import React from 'react';
import { styles } from './styles';
import { Grid } from '@mui/material';
import ScalesTable from './ScalesTable';


const ScalesPanel = () => {
    const classes = styles();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
                <ScalesTable />
            </Grid>
        </Grid>
    )
}

export default ScalesPanel;
