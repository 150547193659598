import React from 'react';
import { styles } from './styles';
import { Grid } from '@mui/material';
import RegionsTable from './RegionsTable';

const RegionsPanel = () => {
    const classes = styles();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
                <RegionsTable />
            </Grid>
        </Grid>
    )
}

export default RegionsPanel;