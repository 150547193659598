import { createSelector } from 'reselect';
import { stack } from 'd3-shape';
import { group } from 'd3-array';
import { interpolateNumber } from 'd3-interpolate';
import { randomUniform } from 'd3-random';
import { dateToDays, emptyObject, reduxDateFormatToDate } from '../../functions/functions';
import appConfig from '../../config/appConfig';
import { getTextMetrics } from '../../functions/functions';
import { cloneDeep } from 'lodash';

const getFrequencies = state => state.frequenciesData.frequencies;
const getFrequenciesStatus = state => state.frequenciesData.frequenciesStatus;

const getSubsetId = state => state.parameters.strainSubset;
const getRegions = state => state.parameters.regions;
const getSeqCaseCounts = state => state.frequenciesData.seqCaseCounts;
const getSelectedBins = state => state.frequenciesData.selectedBins;
const getDataPoints = ({ frequenciesData }) => frequenciesData.dataPoints;

const getPredictions = state => state.predictionsData.predictions;
const getPredictionsStatus = state => state.predictionsData.predictionsStatus;
const getStrainSubsetOptions = state => state.metadata.strainSubsetOptions;
const getSelectedModels = state => state.parameters.selectedModels;
const getStrainSubset = ({ parameters }) => parameters.strainSubset;
const getPlotType = ({ parameters }) => parameters.plotType;

const getModelPredictions = createSelector([getPredictions, getSelectedModels, getStrainSubset], (predictions, selectedModels, subsetId) => {
    const result = selectedModels.filter(({ invalid, idIncomplete }) => !invalid && !idIncomplete).map((el, index) => {
        // console.log('[getModelPredictions]', index, el.modelId, get(predictions, [subsetId, el.modelRegionId, el.modelType, el.modelId]));
        return { data: predictions?.[subsetId]?.[el.modelRegionId]?.[el.modelType]?.[el.modelId], ...el }
    })
    // console.log(result);
    return result;
});

const getModelPredictionsForRegions = createSelector([getPredictions, getSelectedModels, getRegions], (predictions, selectedModels, regions) => {
    return Object.keys(regions).reduce((acc, subsetId) => {
        const result = selectedModels.map((el) => {
            return { data: predictions?.[subsetId]?.[el.modelRegionId]?.[el.modelType]?.[el.modelId], ...el }
        })
        acc[subsetId] = result;
        return acc;
    }, {});
});

const statusOrder = { 'none': 0, 'loading': 1, 'loaded': 3, 'nodata': 4 };

const getModelPredictionsStatus = createSelector([getPredictionsStatus, getSelectedModels], (predictionsStatus, selectedModels) => {

    // console.log('[getModelPredictionsStatus]',  Object.keys(predictionsStatus));
    const statuses = Object.keys(predictionsStatus || {}).reduce((acc, region) => {
        const pStatus = predictionsStatus[region];
        //console.log(predictionsStatus);
        const s = selectedModels.reduce((accStatus, model, index) => {
            const { modelRegionId, modelType, modelId, invalid, idIncomplete } = model;

            const modelStatus = (invalid || idIncomplete)
                ? 'nodata'
                : ((pStatus?.[modelRegionId]?.[modelType]?.[modelId]) || 'none');

            // console.log(index, '. getModelPredictionsStatus', region, model, modelStatus);
            return (statusOrder[modelStatus] < statusOrder[accStatus]) ? modelStatus : accStatus;
        }, 'nodata');
        // console.log('[getModelPredictionsStatus] region = ', region, 'status = ',s);
        acc[region] = s;
        return acc;
    }, {});

    // console.log('[getModelPredictionsStatus]', statuses);
    return statuses;
});


const frequenciesSelector = createSelector([getFrequencies, getSubsetId], (frequencies, regionId) => (
    frequencies && regionId
        ? frequencies[regionId]
        : null)
);

// const predictionsSelector = createSelector([getPredictions, getFirstModelId, getSubsetId, getFirstModelType], (predictions, modelId, regionId, modelType) => (
//     predictions && regionId && modelId && predictions[regionId] && predictions[regionId][modelType] && predictions[regionId][modelType][modelId]
//         ? predictions[regionId][modelType][modelId] : []
//     )
// );

const seqCaseCountsSelector = createSelector([getSeqCaseCounts, getSubsetId], (seqCaseCounts, regionId) => (seqCaseCounts && regionId
    ? seqCaseCounts[regionId]
    : null)
);

const frequenciesStatusSelector = createSelector([getFrequenciesStatus, getSubsetId], (frequenciesStatus, subsetId) => (frequenciesStatus && subsetId
    ? frequenciesStatus[subsetId] || 'none'
    : 'none'));

const selectedBinsSelector = createSelector([getSelectedBins, getSubsetId], (selectedBins, regionId) => {
    const res = selectedBins && regionId ? selectedBins[regionId] || {} : {};
    return res;
});


const predictionsStatusSelector = state => ((state.predictionsData.predictionsStatus && state.parameters.regionId && state.parameters.modelType && state.parameters.modelId)
    ? state.predictionsData.predictionsStatus[state.parameters.regionId][state.parameters.modelType][state.parameters.modelId]
    : 'none');

const cladesStatusSelector = state => (
    /* state.frequenciesData.frequenciesStatus[state.parameters.regionId] === 'loaded' && */ state.cladeData.cladesStatus === 'loaded'
);



const getTrackingFrom = ({ parameters }) => parameters.trackingFrom;
const getTrackingTo = ({ parameters }) => parameters.trackingTo;

const _getFreqPlots = (frequencies, selectedBins, cladesStatus, seqCaseCounts, points) => {
    // console.log(`[_getFreqPlots]: cladesStatus = ${cladesStatus}, frequencies = ${frequencies}`)
    // console.log({frequencies, seqCaseCounts})
    if (!cladesStatus || !frequencies || frequencies.length === 0) return {};

    const binKeys = Object.keys(selectedBins);

    // fill data with missing bins (values equal to 0) to avoid wrong interpolation

    const timeBins = frequencies.reduce((acc, f) => {
        if (!acc[f.time]) acc[f.time] = new Set();
        acc[f.time].add(f.bin)
        return acc;

    }, {});

    const startEndTimes = frequencies.reduce((acc, f) => {
        const { time, bin } = f;

        if (!acc[bin]) acc[bin] = { start: time, end: time };
        if (time < acc[bin].start) {
            acc[bin].start = time;
            //  console.log(bin, time, 'setting start time');
        }
        if (time > acc[bin].end) {
            acc[bin].end = time;
            //     console.log(f, 'setting end time')
        }
        return acc;
    }, {});


    const missingTimeBins = Object.entries(timeBins).map(([timeKey, bins]) => {
        const time = parseInt(timeKey);
        return {
            time,
            missingBins: binKeys.filter(element => !bins.has(element) && time >= startEndTimes[element].start && time <= startEndTimes[element].end)
        }
    });

    const outputFrequencies = cloneDeep(frequencies);
    missingTimeBins.forEach(element => {
        element.missingBins.forEach(bin => outputFrequencies.push({ time: element.time, bin, x: 0, y: 0 }));
    })
    outputFrequencies.sort((a, b) => a.time - b.time);


    const selectedBinsFrequencies = outputFrequencies.filter(c => (c.bin in selectedBins));
    const otherBinsFrequencies = outputFrequencies.filter(c => !(c.bin in selectedBins));
    const otherBinsMap = otherBinsFrequencies.reduce((tmp, c) => {
        if (!tmp[c.time]) tmp[c.time] = { x: 0, y: 0 };
        tmp[c.time].x += c.x;
        tmp[c.time].y += c.y;
        return tmp;
    }, {});

    if (!emptyObject(otherBinsMap)) {
        Object.keys(otherBinsMap).forEach(time => {
            selectedBinsFrequencies.push({ bin: '0', time: +time, ...otherBinsMap[time] });
        });
        binKeys.push(0);
    }

    //selectedBinsFrequencies.sort((e1, e2) =>e1.time < e2.time || e1.bin < e2.bin);
    //const nestedData =  nest().key(d => d.time).entries(selectedBinsFrequencies);

    //group(selectedBinsFrequencies, d => d.time)
    const nestedData = [...group(selectedBinsFrequencies, d => d.time).entries()];

    //console.log(_nestedData);
    const initKeyVals = binKeys.reduce((tmp, c) => { tmp[c] = 0; return tmp; }, {});

    const stackedPlot = {};
    const getStackedData = (key) => {
        const prepData = nestedData.map(([time, values]) => {
            const binVals = values.reduce((tmp, v) => {
                if (v[key] > 0) tmp[v.bin] = v[key];
                return tmp;
            },
                { ...initKeyVals });
            return { time, ...binVals };
        });

        // console.log(prepData);
        return stack().keys(binKeys)(prepData);
    };

    stackedPlot.frequencies = getStackedData('y');
    stackedPlot.multiplicities = getStackedData('my');

    const maxStackedMultiplicity = stackedPlot.multiplicities.length ? Math.max(...stackedPlot.multiplicities[stackedPlot.multiplicities.length - 1].map(elem => elem[1])) : 0;
    const maxMultiplicity = Math.max(...selectedBinsFrequencies.filter(c => !emptyObject(c.mx)).map(c => c.mx));

    //const nonStackedPlot = nest().key(d => d.bin).entries(outputFrequencies);


    const nonStackedPlot = [...group(outputFrequencies, d => d.bin).entries()];

    const maxSequencesVal = Math.max(
        ...seqCaseCounts.map(({ seqCnt }) => seqCnt),
        ...seqCaseCounts.map(({ rawSeqCnt }) => rawSeqCnt)
    );
    const sequencesPlot = [{ key: 'sequences', values: seqCaseCounts }];

    const maxCasesVal = Math.max(
        ...seqCaseCounts.map(({ caseCnt }) => caseCnt),
        ...seqCaseCounts.map(({ rawCaseCnt }) => rawCaseCnt)
    );
    const casesPlot = [{ key: 'case', values: seqCaseCounts }];
    // console.log(stackedPlot);
    // console.log(points);
    // console.log(stackedPlot.outputFrequencies);

    const getDataPointsPositions = (stackedArray) => {

        const dataPoints = (points || []).map(p => {
            //console.log(`p = ${JSON.stringify(p)}, points = ${stackedPlot.frequencies.filter(sp => sp.key == p.strainCat).length}`);

            const freqCatArray = stackedArray.find(sp => sp.key == p.strainCat); // find freqeuncies of the data point category
            if (!freqCatArray) return null;

            const closestPoints = freqCatArray
                .map(elem => ({ s1: elem[0], s2: elem[1], t: +elem.data.time, dtime: p.time, diff: elem.data.time - p.time }))
                .sort((e1, e2) => Math.abs(e1.diff) - Math.abs(e2.diff))
                .slice(0, 2)
                .sort((e1, e2) => e1.diff - e2.diff);

            // Check whether any of the frequency points has the same time as the data point
            const exactPoint = closestPoints.find(e => e.diff === 0);

            // Two closest frequency points;
            const fp1 = closestPoints[0];
            const fp2 = closestPoints[1];

            const t = (p.time - fp1.t) / (fp2.t - fp1.t);

            // Interpolate if there is no exactPoint

            const y1 = exactPoint === undefined
                ? interpolateNumber(fp1.s1, fp2.s1)(t)
                : exactPoint.s1;
            const y2 = exactPoint === undefined
                ? interpolateNumber(fp1.s2, fp2.s2)(t)
                : exactPoint.s2;

            //Mid point
            //const y_mid = (y2 - y1) / 2 + y1;

            // Normal distribution
            //const m = randomNormal(0, (y2 - y1)*0.5)();
            //const y_norm = (y2 - y1) / 2 + y1 + m;

            //Uniform  distribution
            const y_uni = randomUniform(y1, y2)()

            //select one of: y_uni, y_norm, y_mid
            const _y = y_uni;

            const y = Math.max(Math.min(y2, _y), y1);
            return ({ ...p, y, y1, y2 });
        }).filter(p => p); //filter out empty data points
        return dataPoints;
    }

    const dataPointsFrequencies = getDataPointsPositions(stackedPlot.frequencies);
    const dataPointsMultiplicities = getDataPointsPositions(stackedPlot.multiplicities);

    return {
        nonStackedPlot,
        stackedPlot,
        selectedBins: binKeys,
        maxMultiplicity,
        maxStackedMultiplicity,
        sequencesPlot,
        maxSequencesVal,
        casesPlot,
        maxCasesVal,
        dataPointsFrequencies,
        dataPointsMultiplicities
    };
};

const emptyArray = [];


export const trackingToSelector = createSelector(getTrackingTo, trackingTo => reduxDateFormatToDate(trackingTo));
export const trackingFromSelector = createSelector(getTrackingFrom, trackingFrom => reduxDateFormatToDate(trackingFrom));

const dataPointsSelector = createSelector([getDataPoints, trackingFromSelector, trackingToSelector], (dataPoints, trackingFrom, trackingTo) => {

    const filteredPoints = dataPoints
        ? dataPoints.filter(d => d.time >= dateToDays(trackingFrom) && d.time <= dateToDays(trackingTo))
        : emptyArray
    // console.log(`[dataPointsSelector]: trackingTo = ${trackingTo}, filteredPoints = ${JSON.stringify(filteredPoints.map(d => ({ ...d, date: daysToDate(d.time) })), null, ' ')}`)
    return filteredPoints;
}
)

const getPlotFrequencies = createSelector(
    [frequenciesSelector, selectedBinsSelector, cladesStatusSelector, seqCaseCountsSelector, dataPointsSelector],
    (frequencies, selectedBins, cladesStatus, seqCaseCounts, dataPoints) => _getFreqPlots(frequencies, selectedBins, cladesStatus, seqCaseCounts, dataPoints)
);

const getPlotFrequenciesForRegions = createSelector(
    [getFrequencies, getSelectedBins, cladesStatusSelector, getSeqCaseCounts, dataPointsSelector],
    (frequencies, selectedBins, cladesStatus, seqCaseCounts, dataPoints) => Object.keys(frequencies || {}).reduce((regionFrequencies, regionId) => {
        regionFrequencies[regionId] = _getFreqPlots(frequencies[regionId], selectedBins[regionId], cladesStatus, seqCaseCounts[regionId], dataPoints);
        return regionFrequencies;
    }, {})
);

const _getSeqCaseCounts = seqCaseCounts => {
    const seqCntMap = seqCaseCounts.reduce((acc, scCnt) => {
        appConfig.frequenciesGreyZoneThresholds.forEach(threshold => {
            if (!acc[threshold.x1]) acc[threshold.x1] = { key: threshold.x1, color: threshold.color, values: [] };
            const x1 = (scCnt.seqCnt >= threshold.x0 && scCnt.seqCnt < threshold.x1) ? 1 : 0;
            acc[threshold.x1].values.push({ time: scCnt.time, x0: 0, x1 });
        });
        return acc;
    }, {});
    return Object.values(seqCntMap);
};

const getPlotSeqCaseCounts = createSelector(seqCaseCountsSelector, seqCaseCounts => _getSeqCaseCounts(seqCaseCounts));

const getSeqCaseCountsForRegions = createSelector(
    getSeqCaseCounts, seqCaseCounts => Object.keys(seqCaseCounts || {}).reduce((regionSeqCaseCounts, regionId) => {
        regionSeqCaseCounts[regionId] = _getSeqCaseCounts(seqCaseCounts[regionId]);
        return regionSeqCaseCounts;
    }, {})
);

const _getPredictionPlots = (predictions, selectedBins) => {
    if (!predictions || emptyObject(predictions)) return { plotPredictions: [], stackedPredictions: [] };

    const cladeKeys = [...Object.keys(selectedBins), 0];

    const selectedCladesPredictions = predictions.filter(c => (c.bin in selectedBins));
    const otherCladesPredictions = predictions.filter(c => !(c.bin in selectedBins));
    const otherCladesMap = otherCladesPredictions.reduce((tmp, c) => {
        if (!tmp[c.time]) tmp[c.time] = { x: 0, y: 0 };
        tmp[c.time].x += c.x;
        tmp[c.time].y += c.y;
        return tmp;
    }, {});

    Object.keys(otherCladesMap).forEach(time => {
        selectedCladesPredictions.push({ bin: '0', time: +time, ...otherCladesMap[time] });
    });


    //const nestedData = nest().key(d => d.time).entries(selectedCladesPredictions);
    const nestedData = [...group(selectedCladesPredictions, d => d.time).entries()]
    const initKeyVals = cladeKeys.reduce((tmp, c) => { tmp[c] = 0; return tmp; }, {});
    // const prepData1 = nestedData.map(d => {
    //     const cladeVals = d.values.reduce((tmp, v) => {
    //         if (v.y > 0) tmp[v.bin] = v.y;
    //         return tmp;
    //     },
    //     { ...initKeyVals });
    //     return { time: d.key, ...cladeVals };
    // });

    const prepData = nestedData.map(([time, values]) => {
        const cladeVals = values.reduce((tmp, v) => {
            if (v.y > 0) tmp[v.bin] = v.y;
            return tmp;
        },
            { ...initKeyVals });
        return { time, ...cladeVals };
    });
    const stackedPredictions = stack().keys(cladeKeys)(prepData);
    //const plotPredictions = nest().key(d => d.bin).entries(predictions);
    const plotPredictions = [...group(predictions, d => d.bin).entries()];

    return { plotPredictions, stackedPredictions };
};

// const getPlotPredictions = createSelector(
//     [predictionsSelector, selectedBinsSelector],
//     (predictions, selectedBins) => _getPredictionPlots(predictions, selectedBins)
// );

const getPlotPredictionsForRegions = createSelector([getModelPredictionsForRegions, selectedBinsSelector],
    (predictions, selectedBins) => Object.keys(predictions).reduce((acc, regionId) => {
        const res = predictions[regionId].map((prediction) => ({ ...prediction, data: _getPredictionPlots(prediction.data, selectedBins) }));
        //console.log('[getPlotPredictionsForRegions]', res);
        acc[regionId] = res;
        return acc;
    }, {})
);
const getPlotPredictions = createSelector([getModelPredictions, selectedBinsSelector],
    (predictions, selectedBins) => {
        const res = predictions.map((prediction) => ({ ...prediction, data: _getPredictionPlots(prediction.data, selectedBins) }));
        return res;
    }
);

// const getIntro = (state) => state.parameters.intro;
const getYAxisTextWidth = createSelector(
    [
        getModelPredictions,
        //getIntro,
        frequenciesSelector,
        getSeqCaseCounts,
        getSubsetId,
        getPlotType,
    ],
    (predictions, /*intro,*/ frequencies, seqCaseCounts, subsetId, plotType) => {
        let maxValue = 0;

        if (predictions[0])
            for (const pred of (predictions || [])) {
                if (pred.data)
                    for (const obj of pred.data) {
                        const value = plotType === 'multiplicities' ? obj.mx : obj.x;
                        if (value > maxValue)
                            maxValue = value;
                    }
            }

        if (frequencies)
            for (const obj of (frequencies || [])) {
                const value = plotType === 'multiplicities' ? obj.mx : obj.x;
                if (value > maxValue)
                    maxValue = value;
            }


        const seqCaseCnt = seqCaseCounts[subsetId];
        if (seqCaseCnt)
            for (const obj of (seqCaseCnt || [])) {
                const valueCase = obj.caseCnt;
                const valueSeq = obj.seqCnt;

                if (valueCase > maxValue)
                    maxValue = valueCase;
                if (valueSeq > maxValue)
                    maxValue = valueSeq
            }

        const roundedMaxValue = parseInt(maxValue);
        const metrics = getTextMetrics(roundedMaxValue, "15px 'Inter', 'sans-serif'");
        const width = parseInt(metrics.width)

        return width + 10;
    });

const regionsSelector = createSelector(getRegions, regions => [...Object.keys(regions || {}), 'ALL']);

const getFrequenciesStatusesForRegions = createSelector([regionsSelector, getFrequenciesStatus],
    (regions, frequenciesStatus) => regions.reduce((regionStatuses, regionId) => {
        regionStatuses[regionId] = frequenciesStatus ? frequenciesStatus[regionId] || 'none' : 'none';
        return regionStatuses;
    }, {})
);


const strainSubsetKeysSelector = createSelector(getStrainSubsetOptions, strainSubsetOptions => (strainSubsetOptions || []).map(({ key }) => key));

const getFrequenciesStatusesForStrainSubsets = createSelector([strainSubsetKeysSelector, getFrequenciesStatus],
    (strainSubsetOptions, frequenciesStatus) => strainSubsetOptions.reduce((regionStatuses, regionId) => {
        regionStatuses[regionId] = frequenciesStatus ? frequenciesStatus[regionId] || 'none' : 'none';
        return regionStatuses;
    }, {}));






//const noModelDataSelector = state => !isValidModelId(state.parameters.modelId, state.models.models, state.models.modelTypes) || state.modelData.modelStatus === 'nodata';

const getPredictionsStatusesForStrainSubsets = createSelector([strainSubsetKeysSelector, getModelPredictionsStatus],
    (strainSubsetOptions, predictionsStatus /*, noModelData, modelId*/) => {
        try {
            const statuses = strainSubsetOptions.reduce((regionStatuses, regionId) => {
                regionStatuses[regionId] = predictionsStatus[regionId] || 'nodata'; //'none';
                return regionStatuses;
            }, {})
            return statuses;
        } catch (e) {
            console.log(e);
        }
    });

const getRegionalReportFrequenciesStatus = createSelector([getFrequenciesStatusesForStrainSubsets, getRegions], (statuses, regions) =>
    Object.keys(regions || {}).reduce((accStatus, region) => {
        const rStatus = statuses[region] || 'none';
        return (statusOrder[rStatus] < statusOrder[accStatus]) ? rStatus : accStatus;
    }, 'nodata')
);

const getRegionalReportPredictionsStatus = createSelector([getPredictionsStatusesForStrainSubsets, getRegions], (statuses, regions) =>
    Object.keys(regions || {}).reduce((accStatus, region) => {
        const rStatus = statuses[region] || 'none';
        return (statusOrder[rStatus] < statusOrder[accStatus]) ? rStatus : accStatus;
    }, 'nodata')
);



export {
    getPlotFrequencies,
    frequenciesStatusSelector,
    predictionsStatusSelector,
    getPlotSeqCaseCounts,
    getPlotFrequenciesForRegions,
    getPlotPredictionsForRegions,
    getPlotPredictions,
    getFrequenciesStatusesForRegions,
    getFrequenciesStatusesForStrainSubsets,
    getPredictionsStatusesForStrainSubsets,
    getSeqCaseCountsForRegions,
    selectedBinsSelector,
    getRegionalReportFrequenciesStatus,
    getRegionalReportPredictionsStatus,
    getYAxisTextWidth,
};
