import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axiosRequests';
import {
    FETCH_LINEAGES_REQUEST,
    FETCH_LINEAGE_REQUEST,
} from '../actions/actionTypes';
import {
    fetchLineagesSuccess,
    fetchLineagesError,
    fetchLineageSuccess,
    fetchLineageError,
} from '../actions/lineagesActions';
import config from '../../config/envConfig';
import { prepareUrl } from '../../functions/functions';
import { sessionTimeOut } from './helpers/functions';

export const fetchLineages = action$ => action$.pipe(
    ofType(FETCH_LINEAGES_REQUEST),
    mergeMap(() => {
        const url = prepareUrl(`${config.serverLink}/api/lineages`);
        // console.log(`fetchLineages => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchLineagesSuccess(response.data)
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);

            return fetchLineagesError({ error: data.error, errorWebsiteText: 'An error has occured during downloading lineages.', errorWebsiteType: 'fetch'});
        })
    })
);

export const fetchLineage = action$ => action$.pipe(
    ofType(FETCH_LINEAGE_REQUEST),
    mergeMap(action => {
       
        const { settings, exportMode, ...otherParams } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/lineage`, otherParams, exportMode);

        return fetchAxios(url).then(function (response) {
            const  { parameters } = response.data;
            const _parameters = { ...parameters, ...otherParams };
            //console.log('predictionBaseline = ', parameters?.predictionBaseline, daysToDate(otherParams?.predictionBaseline) );
            return fetchLineageSuccess({ ...response.data, settings, parameters: _parameters })
        // const { settings, selectedModels } = action.payload;
        // console.log(`fetchLineage => url = ${url}`);

        // return fetchAxios(url).then(function (response) {
        //     const parameters = selectedModels ? { ...response.data.parameters, selectedModels } : {...response.data.parameters };
        //     const payload = { ...response.data, settings, parameters };

        //     return fetchLineageSuccess({ ...payload });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchLineageError({ error: data.error, errorWebsiteText: 'An error has occured during downloading lineage data.', errorWebsiteType: 'fetch'});
        })
    })
);
