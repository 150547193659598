import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import AddLineageRegionDialog from '../Dialogs/AddLineageRegion';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AddLineageRegion = ({ currentLineage, setInfo, setInfoDialog, setAction, fetchLineageRegions }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const addNewLineageRegion = async (newRegion) => {
        const url = `${config.serverLink}/api/admin/addLineageRegion`;
        const body = {
            lineage: currentLineage,
            newRegion
        }

        try {
            postAxios(url, body).then((response) => {
                if (response.data.status === 'success'){
                    handleCloseDialog();
                    setAction('Add lineage region');
                    setInfo(`Region ${newRegion.key} for lineage: ${currentLineage} was added sucessfully.`);
                    setInfoDialog(true);
                    fetchLineageRegions();
                } else {
                    handleCloseDialog();
                    setAction('Add lineage region');
                    setInfo(`Region ${newRegion.key} for lineage: ${currentLineage} wasn't added.`);
                    setInfoDialog(true);
                }
            })
          } catch (error) {
            console.error('Error adding subset:', error);
          }
    };

    return (
        <>
            <IconButton className={classes.add} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddLineageRegionDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} addNewLineageRegion={addNewLineageRegion} />
        </>
    );
};

export default AddLineageRegion;
