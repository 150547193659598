export const styles =  (theme) => ({
    cladeLabelBorder: {
        fill: '#ffffff',
        opacity: 0.5,
        stroke: '#4F4F4F',
        strokeWidth: 1,
        cursor: 'pointer',
        rx: 4,
        ry: 4
    },
    cladeLabelText: {
        fontSize: '12px',
        //font-family: "Inter", sans-serif;
        // dominantBaseline: 'text-before-edge',
        cursor: 'pointer',
    },

    cladeLabelAnchorPointCircle: {
        fill: '#ffffff',
        stroke: '#4F4F4F',
        strokeWidth: 1
    },
    cladeLabelLink: {
        stroke: '#000000',
        strokeWidth: 1
    }
});
