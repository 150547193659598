/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus, getStrainTreeErrorStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
import { emptyObject } from '../../../functions/functions';
import { getColorScaleForColorBy } from '../../../redux/selectors/parametersSelector';
import { getTreeNodeAttrs } from '../../../redux/selectors/treeDataSelector';

// import { printWhatChanged } from '../../../functions/utils';

const viewToRender = 'strainTree';
const componentId = 'nodes';

const StrainsLayer = (props) => {
    const _element = useRef();

    const { loading, errorStatus,
        modelId, colorBy, showLeaves, displayOrder, searchStrainMode, showColoredNodesOnly,
        calculatedDomain, antigenicDataType, antigenicTiterType, renderStatus,
        treeAttrs, antigenicStrainSearchStatus, colorScale, cladeType,
        setComponentStatus, submissionDate,
    } = props;
   
   // console.log('[StrainsLayer] showColoredNodesOnly', showColoredNodesOnly);
    const startRender = async () => {
        // console.log('[StrainsLayer.startRender]');
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => {
        // console.log('[StrainsLayer.stopRender]');
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };

    // const prevProps = useRef(props);

    // useEffect(() => {
    //   const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
    //     if (prevProps.current[k] !== v) {
    //       ps[k] = [prevProps.current[k], v]; 
    //     }
    //     return ps;
    //   }, {});
  
    //   console.log('Changed props:', changedProps, loading);
  
    //   // Update previous props after comparing
    //   prevProps.current = props;
    // }, [props]); 


    // console.log('[StrainsLayer] status', renderStatus)
    useEffect(() => {
        // console.log('[StrainsLayer] useEffect renderStatus', renderStatus, 'initialized', props.initialized)
        if (renderStatus !== RENDER_STATUS.NONE) return;
       
        // console.log('[StrainsLayer] useEffect 0 [renderStatus]',renderStatus);
        startRender()
            .then(() => treeD3.drawStrainsLayer(_element.current)) 
            .then(() => stopRender());
    }, [renderStatus])


    useEffect(() => {
        if (loading || errorStatus) return;
        // console.log('[StrainsLayer] useEffect 1');
        //console.log('[StrainsLayer] useEffect 0');
        startRender()
            .then(() => treeD3.changeNodesColor(_element.current))
            .then(() => stopRender());
    }, [modelId, calculatedDomain, antigenicDataType, antigenicTiterType, colorScale, cladeType, antigenicTiterType, antigenicDataType]);


    // those parameters might change the available nodes
    useEffect(() => {
        if (loading || errorStatus || emptyObject(treeAttrs)) return;
        // console.log(`[StrainsLayer] useEffect 2`); 
        startRender()
            .then(() => treeD3.drawStrainsLayer(_element.current)) 
            .then(() => stopRender());
    }, [loading, colorBy, submissionDate, showLeaves, searchStrainMode, showColoredNodesOnly, treeAttrs]);

    useEffect(() => {
        if (loading || errorStatus || antigenicStrainSearchStatus !=='found') return;
        // console.log(`[StrainsLayer] useEffect 3`); 
        startRender()
            .then(() => treeD3.drawStrainsLayer(_element.current)) 
            .then(() => stopRender());
    }, [antigenicStrainSearchStatus]);

    useEffect(() => {
        //console.log('[StrainsLayer] useEffect 3 errorStatus, treeAttrs', (errorStatus || (!loading && emptyObject(treeAttrs))))
        if (errorStatus || (!loading && emptyObject(treeAttrs))){
            treeD3.removeElementsLayer(_element.current);
            stopRender(); 
            return;
        }
    }, [errorStatus, treeAttrs])

    useEffect(() => { 
        if (loading || errorStatus) return; 
        // console.log('[StrainsLayer] useEffect 4');
        treeD3.removeElementsLayer(_element.current).then(() => {
            startRender(); 
            treeD3.drawStrainsLayer(_element.current).then(() => {
                //console.log('[StrainsLayer]: stop'); 
                stopRender();
            });
        }); 
    }, [displayOrder]);

    return <g id="nodes" transform={treeD3.translate(false)} ref={_element} />;
};

StrainsLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
    showLeaves: PropTypes.bool,
    displayOrder: PropTypes.string,
    errorStatus: PropTypes.bool,
    modelId: PropTypes.string,
    colorBy: PropTypes.string,
    antigenicDataType: PropTypes.string,
    antigenicTiterType: PropTypes.string,
    cladeType: PropTypes.string,
    renderStatus: PropTypes.string,
    antigenicStrainSearchStatus: PropTypes.string,
    searchStrainMode: PropTypes.bool,
    showColoredNodesOnly: PropTypes.bool,
    setComponentStatus: PropTypes.func,
    treeAttrs: PropTypes.object,
    // visibleNodes: PropTypes.object,
    colorScale: PropTypes.string,
    calculatedDomain: PropTypes.object

};

const mapStateToProps = (state, ownProps) => {
    //console.log('StrainsLayer.mapStateToProps.1');
    const loading = getStrainTreeStatus(state) || !ownProps.initialized; //|| !ownProps.initialized || getAntigenicDistanceStatus(state);
    const errorStatus = getStrainTreeErrorStatus(state);
    const colorScale = getColorScaleForColorBy(state); //state.parameters.colorScale,
    //console.log('StrainsLayer.mapStateToProps.2');
    //const strainTreeDimsSet = state.render.strainTreeWidth && state.render.strainTreeHeight;
   
    return {
        loading,
        errorStatus,
        //treeLoading: getStrainTreeStatus(state),
        //antigenicLoading: getAntigenicStrainLoading(state),
        antigenicStrainSearchStatus: state.treeData.strainSearchStatuses.antigenic,
        renderStatus:  state.render.viewToRender.components[componentId] || null,
      
        //layout: state.parameters.layout,
        submissionDate: state.parameters.submissionDate,
        colorBy: state.parameters.colorBy,
        cladeType: state.parameters.cladeType,
        showLeaves: state.parameters.showLeaves,
        searchStrainMode: state.parameters.searchStrainMode,
        displayOrder: state.parameters.displayOrder,
        showColoredNodesOnly: state.parameters.showColoredNodesOnly,
        calculatedDomain: state.parameters.calculatedDomain,
        modelId: state.parameters.modelId,
        // refStrain: state.parameters.refStrain,
        colorScale,
        treeAttrs: getTreeNodeAttrs(state), //: state.treeData.treeAttrs,
        antigenicDataType: state.parameters.antigenicDataType,
        antigenicTiterType: state.parameters.antigenicTiterType,
        // treeDataStatus: state.treeData.treeDataStatus,
        // visibleNodes: state.treeData.visibleNodes,

    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(StrainsLayer);
