export const LAYOUT = {
    TREE: {
        label: 'Tree', value: 'tree',
    },
    FAN: {
        label: 'Fan', value: 'fan',
    },
}

const INFLUENZA = 'INFLUENZA';
const SARS_COV_2 = 'SARS_COV_2';

export const PATHOGEN_TYPES = {
    INFLUENZA: { value: INFLUENZA, label: 'Influenza' },
    SARS_COV_2: { value: SARS_COV_2, label: 'SARS-CoV-2' }
}
