import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, CircularProgress, Switch, MenuItem, Tooltip, FormControlLabel, Typography, Checkbox, Grid, FormGroup } from '@mui/material';
import { styles, EditMeasureDialogSX } from './styles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import JSZip from 'jszip';
import config from '../../../../config/envConfig';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

const dialogInitState = {
    key: '',
    label: '',
    lineage: '',
    rule: '',
    highlightAll: false,
    showAll: false,
}

const AddSubsetDialog = ({ openDialog, handleCloseDialog, lineages, addNewSubsetFile, addNewSubsetRule }) => {
    const [newSubset, setNewSubset] = useState(dialogInitState);
    const [isRule, setIsRule] = useState(true);
    const [file, setFile] = useState([]);
    const [dataFileUploaded, setDataFileUploaded] = useState('none');
    const classes = styles();

    const handleChangeSwith = (e) => setIsRule(!isRule);
    const handleChangeKey = (e) => setNewSubset({ ...newSubset, key: e.target.value });
    const handleLabelChange = (e) => setNewSubset({ ...newSubset, label: e.target.value });
    const handleChangeRule = (e) => setNewSubset({ ...newSubset, rule: e.target.value });
    const handleCheckboxChange = (e, type) => setNewSubset({ ...newSubset, [type]: e.target.checked });
    const handleChangeLineage = (e) => {
        const newLineage = e.target.value;
        setNewSubset({ ...newSubset, lineage: newLineage });
    }
    const handleDataFileChange = async (e) => {
        setDataFileUploaded('loading')
        const zip = new JSZip();
        const selectedFile = e.target.files[0];

        const data = await selectedFile.arrayBuffer();
        zip.file(selectedFile.name, data, { binary: true });

        const zippedFile = await zip.generateAsync({ type: 'blob' });
        setDataFileUploaded('done');
        setFile(zippedFile);
    };

    const handleSubmit = async () => {
        if (isRule) {
            await addNewSubsetRule(newSubset)
            setNewSubset({ ...dialogInitState });
        } else {
            await addNewSubsetFile(newSubset, file);
            setNewSubset({ ...dialogInitState });
            setFile([]);
            setDataFileUploaded('none');
            setIsRule(true);
        }
        handleCloseDialog();
    }

    const xs = 12;
    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={EditMeasureDialogSX}>
            <DialogTitle id="confirm-dialog-title">
                Add subset:
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <FormGroup className={classes.switch}>
                    <FormControlLabel control={
                        <Switch color="secondary"
                            classes={{
                                track: classes.switch_track,
                                switchBase: classes.switch_base,
                            }}
                            onChange={handleChangeSwith}
                            checked={isRule}
                        />}
                        label={isRule ? 'Based on rule' : 'Based on file'}
                    />
                </FormGroup>
                {!isRule &&
                    <div style={{ marginTop: '5px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="dataFileInput" className={classes.dataButton}>
                            Upload data file
                        </label>
                        <input
                            id="dataFileInput"
                            type="file"
                            accept=".json"
                            onChange={handleDataFileChange}
                            style={{ display: 'none' }}
                        />
                        {dataFileUploaded === 'loading' &&
                            <div className={classes.icon}>
                                <CircularProgress size={18} disableShrink />
                            </div>
                        }
                        {dataFileUploaded === 'none' &&
                            <div className={classes.icon}>
                                <CloseIcon style={{ color: 'grey' }} />
                            </div>
                        }
                        {dataFileUploaded === 'done' &&
                            <div className={classes.icon}>
                                <DoneIcon style={{ color: 'green' }} />
                            </div>
                        }
                        {dataFileUploaded !== 'loading' && dataFileUploaded !== 'none' && dataFileUploaded !== 'done' &&
                            <div className={classes.icon}>
                                <CloseIcon style={{ color: 'red' }} />
                            </div>
                        }
                    </div>
                }

                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'lineage'} label={'Lineage'} />
                    <CustomSelect
                        value={newSubset.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        <MenuItem key={'empty'} value={'none'} >{'(all)'}</MenuItem>
                        {lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}

                    </CustomSelect>
                </FormControl>
                <StyledTextField
                    id="key"
                    type="text"
                    label="Key"
                    autoComplete='off'
                    className={classes.textField}
                    value={newSubset.key}
                    onChange={handleChangeKey}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    className={classes.textField}
                    value={newSubset.label}
                    onChange={handleLabelChange}
                />
                {isRule &&
                    <StyledTextField
                        id="label"
                        type="text"
                        label="Name regex"
                        autoComplete='off'
                        className={classes.textField}
                        value={newSubset.rule}
                        onChange={handleChangeRule}
                    />
                }
                <Grid container >
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            label={<Typography className={classes.formControlLabel}>Show all</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={newSubset.showAll}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'showAll')}
                                        inputProps={{
                                            name: 'showAll',
                                            id: 'showAll',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    {!isRule &&
                        <Grid item xs={xs} >
                            <FormControlLabel
                                className={classes.boolOption}
                                value="highlightAll"
                                label={<Typography className={classes.formControlLabel}>Highlight all</Typography>}
                                control={
                                    (
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checked={newSubset.highlightAll}
                                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                            onChange={(e) => handleCheckboxChange(e, 'highlightAll')}
                                            inputProps={{
                                                name: 'highlightAll',
                                                id: 'highlightAll',
                                            }}

                                        />
                                    )
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit} disabled={!isRule && dataFileUploaded !== 'done'}>
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default AddSubsetDialog;