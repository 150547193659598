import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditDialog from '../Dialogs/EditDialog';
import EditIcon from '@mui/icons-material/Edit';
import config from '../../../../config/envConfig';
import { postAxios, fetchAxios } from '../../../../functions/axiosRequests';

const EditUser = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const { user, setLoaded, setInfoDialog, setInfo, setAction } = props;

    const handleClick = async () => {
        await fetchRoles();
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchRoles = async () => {
        const url = `${config.serverLink}/api/admin/getAllRoles`;
        await fetchAxios(url)
            .then((response) => {
                setRoles(response.data.roles);
        });
    };

    const updateUser = (upadatedUser) => {
        const url = `${config.serverLink}/api/admin/editUser`;
        const body = { user: upadatedUser };

        postAxios(url, body)
            .then((response) => {
                if (!response.status)
                    setInfo(`An error occured during updating ${user.email}.`);
                else
                    setInfo(`User ${user.email} updated sucesfully.`);

                setAction('Update user');
                setLoaded(false);
                handleCloseDialog();
                setInfoDialog(true);
        });
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <EditIcon />
            </IconButton>
            { roles.length > 0 && <EditDialog user={user} handleCloseDialog={handleCloseDialog} openDialog={openDialog} updateUser={updateUser} roles={roles} /> }
        </>
    );
};

export default EditUser;
