import React from 'react';
import { withStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = () => ({
    dropdown: {
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        maxHeight: '250px',
        maxWidth: '250px',
        backgroundColor: 'white',
        fontSize: '13px',
        "& ul": {
            "& li": {
                padding: '4px',
                cursor: 'pointer',
                fontSize: '13px',
                '&:hover': {
                    background: '#5094f8',
                    color: 'white'
                },
            },
        }
    },
    icon: {
        marginTop: '2px', // Move the icon down by 2 pixels
      },
})

const LineageSelect = (props) => {
    const { classes, children, ...rest } = props;
    return (
        <Select
            {...rest}
            className={classes.root}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                classes: { paper: classes.dropdown },
            }}
            IconComponent={ArrowDropDownIcon}
            id="custom-select"
            sx={{
                '& #custom-select': {
                    backgroundColor: 'white',
                    fontSize: '13px',
                    letterSpacing: '-0.1px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    borderRadius: '5px',
                    paddingLeft: '5px',
                    marginTop: '5px'
                }
            }}
            displayEmpty
            classes={{icon: classes.icon}}
        >
            {children}
        </Select>
    )
}

export default withStyles(styles)(LineageSelect);