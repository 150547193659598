import { makeStyles } from '@mui/styles';
import { isFirefox } from '../../../functions/checkBrowser';

export const styles = makeStyles({
    root: {
        height: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px 10px 15px',
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        margin: '8px 0px'
    },
    colFormControl: {
        // minWidth: 120,
        width: '49%',
        margin: '8px 4px 8px 0px',
        // padding: '1px'
    },
    lastColFormControl: {
        minWidth: 120,
        width: '50%',
        margin: '8px 0px 8px 0px',
        // padding: '1px'
    },
    selectEmpty: {
        marginTop: '15px'
    },
    button: {
        // boxShadow: 'none'
        width: '80px',
        height: '32px',
        marginTop: '5px',
        marginBottom: '10px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    deletednfo: {
        margin: '24px 0 8px'
    },
    hideButton: {
        padding: '0px',
        float: 'right'
    },
    buttonWrapper: {
        width: '100%',
        height: '30px'
    },
    formControlLabel: {
        fontSize: '13px',
        marginBottom: '5px',
        lineHeight: 1
    },
    checkbox: {
        padding: '0 4px 0 0',
        margin: 0,
        display:'table-cell',
        verticalAlign: 'top'
    },
});
