import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import config from '../../../../config/envConfig';
import { postAxios, fetchAxios } from '../../../../functions/axiosRequests';
import { emptyObject } from '../../../../functions/functions';
import EditIcon from '@mui/icons-material/Edit';
import EditRoleDialog from '../Dialogs/EditRoleDialog';

const EditRole = ({ roleName, setInfo, setInfoDialog, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [role, setRole] = useState({});

    const handleClick = async () => {
        await fetchRoleDetails();
        setOpenDialog(true);
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const fetchRoleDetails = async () => {
        const url = `${config.serverLink}/api/admin/getRole?name=${roleName}`;

        await fetchAxios(url)
            .then((response) => {
                setRole(response.data.role);
        });
    };

    const updateRole = (role) => {
        const url = `${config.serverLink}/api/admin/editRole`;
        const body = { role };
        postAxios(url, body)
            .then((response) => {
                const { result } = response.data;
                if (!response.status)
                    setInfo(`An error occured during updating ${result}.`);
                else
                    setInfo(`Role ${result} updated sucesfully.`);

                setAction('Update role');
                handleCloseDialog();
                setInfoDialog(true);
        });
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <EditIcon />
            </IconButton>
            {!emptyObject(role) && <EditRoleDialog role={role} handleCloseDialog={handleCloseDialog} openDialog={openDialog} updateRole={updateRole}/>}
        </>
    );


}

export default EditRole;
