import { useEffect } from 'react';

function useSuppressWarnings(warningsToSuppress) {
  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (warningsToSuppress.some(warning => args[0].includes(warning))) {
        return;
      }
      originalConsoleError(...args);
    };
    return () => {
      console.error = originalConsoleError; // Restore original console.error on cleanup
    };
  }, [warningsToSuppress]);
}

export default useSuppressWarnings;
