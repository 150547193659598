import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import './assets/fonts/Inter-Regular.ttf';
import './assets/fonts/Inter-Light.ttf';
import './assets/fonts/IBMPlexSans-Regular.ttf';
import './assets/fonts/Inter-Bold.ttf';
import './assets/css/main.css';
import './assets/css/alert.css';
import theme from './theme';
import Main from './Main';
import config from './config/envConfig';

if (config.NODE_ENV !== "development" && config.REACT_APP_SHOW_LOGS !== true)
  console.log = () => {};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
            <Main />
      </StyledEngineProvider>
    </ThemeProvider>
);
