import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from '../../Users/styles';
import AddNewRoleDialog from '../Dialogs/AddNewRoleDialog';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { ExpandIcon } from '../../../Alerts/styles';

const AddNewRole = ({ setLoaded, setInfoDialog, setInfo, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true)
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const addNewRole = (role) => {
        const url = `${config.serverLink}/api/admin/addNewRole`;
        const body = { role };
        postAxios(url, body)
            .then((response) => {
                if (!response.status)
                    setInfo('An error occured during adding role.');
                else
                    setInfo('Role added sucesfully.');

                setAction('Add role');
                setLoaded(false);
                handleCloseDialog();
                setInfoDialog(true);
        });
    };

    return (
        <>
            <IconButton className={classes.addUser} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddNewRoleDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} addNewRole={addNewRole}/>
        </>

    );
};

export default AddNewRole;
