import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import ExportContextMenu from './ExportContextMenu';
import { styles } from '../../components/ParametersInfo/styles'

const initialState = {
    menuOn: false,
    menuPos: { x: null, y: null }
};

class ExportableComponent extends Component {
    state = initialState;
    mapDimensions = {};

    handleClick = event => {
        const {setMapParams, filename } = this.props;
        if (filename === 'geomap'){
            this.mapDimensions = setMapParams();

        }

        event.preventDefault();
        const { menuOn } = this.state;
        this.setState({
            menuOn: !menuOn,
            menuPos: {
                x: event.clientX - 2,
                y: event.clientY - 4
            }
        });
    };

    handleClose = () => {
        this.setState(initialState);
    };

    render = () => {
        const { children, filename, classes, compact } = this.props;
        const { menuOn, menuPos } = this.state;
        return (
            <div onContextMenu={this.handleClick} className={compact ? classes.compact : classes.exportableComponent}>
                {children}
                <ExportContextMenu handleClose={this.handleClose} menuOn={menuOn} menuPos={menuPos} filename={filename} mapDimensions={this.mapDimensions}/>
            </div>
        );
    }
}

ExportableComponent.propTypes = {
    children: PropTypes.element,
    filename: PropTypes.string,
    compact: PropTypes.bool,
    classes: PropTypes.shape({ exportableComponent: PropTypes.string, compact: PropTypes.string })
};

export default withStyles(styles)(ExportableComponent);
