export const displayMutVal = (mut) => {
    return Object.entries(mut).map(([key, val]) => `${val[0]}${key}${val[1]}`).join('');
}
export const getAminoMutDisplayVal = (mut) => (mut.length === 3 ? `${mut[1]}:${displayMutVal(mut[2])}` : '');
export const getAminoMutDisplayValShort = (mut) => (mut.length === 3 ? `${displayMutVal(mut[2])}` : '');

export const prepareMutationsInfo = (muts) => {
  
    const _muts = (!muts || !muts.length) ? [] : muts;
    return (_muts).reduce((acc, mut) => {
        acc.nucMut.push(displayMutVal(mut[0]));
        if (mut.length === 3) {
            acc.aminoMut.push(getAminoMutDisplayVal(mut))
        }
        return acc;
    }, { nucMut: [], aminoMut: [] });
}
