import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Hidden } from '@mui/material';
import ExportableComponent from '../Export/ExportableComponent';
import TreeGraph from '../../components/Tree/TreeGraph';
import { Edit } from '../Edit/Edit';
import NodeInfo from '../../components/Tree/NodeInfo';
import TreeGraphOptions from '../../components/Tree/options/TreeGraphOptions';
import CladeSchema from '../../components/CladeSchema/CladeSchema';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import ScalesLegend from '../../components/ColorLegend/Legends/ScalesLegend';
import { withStyles } from '@mui/styles';
import { fetchLineages } from '../../redux/actions/lineagesActions';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { shouldFetch, isColorByModel, isLoadedOrNA } from '../../functions/functions';
import { fetchModels, fetchModel, fetchModelTypes } from '../../redux/actions/modelActions';
import { fetchAntigenicModel, fetchAntigenicObservedData, fetchAntigenicRawModel, setParameters } from '../../redux/actions/antigenicActions';
import { getCustomMeasures, getIgnoreStrainCutOffDateForColorBy, } from '../../redux/selectors/metadataSelector';
import { fetchTCellAntigenicityOptions, fetchTCellAntigenicityScores, fetchCustomTreeAttrs, fetchMutationClasses, fetchVpValues, fetchAntigenicReferenceStrain, fetchBranchNodes, fetchSelectedStrain } from '../../redux/actions/treeDataActions';
import { fetchGenotypeData, fetchMutationGroupValues, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import { getIsMobile, getStrainSearchStatus, shouldFetchModelsSelector } from '../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../config/consts';
import { styles } from './styles';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { fetchHumanPools, fetchHumanSerologyData } from '../../redux/actions/humanSerologyActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import { schemaHeightMultiplierInit, schemaWidth } from '../../components/CladeSchema/helpers';
import TreeLegend from '../../components/ColorLegend/Legends/TreeLegend';
import { isNull } from 'lodash';



const StrainTree = props => {

    const {
        lineage, regionId, modelId, colorBy, antigenicTiterType, antigenicDataType, exportMode, zoomNodeId, strainSubset, exportParams,
        gene, hla, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, humanPool, humanSerologyDataType, strainId, modelRegionId, modelType, antigenicModelId, mutationsGroup,
        showCladeBar, showMutationsGroups, models, refStrain, predictionBaseline, branchNodes,

        shouldFetchModels,

        treeDataStatus, cladesStatus, modelsStatus, lineageStatus, lineagesStatus, antigenicModelStatus, antigenicObservedDataStatus, antigenicRawModelStatus, mutationsPositionsDictStatus, genotypeDataStatus,
        customMeasures, customTreeDataStatus, tcellStatus, vpMethodsStatus, mutationClassesStatus, vpValuesStatus, humanPoolsStatus, humanSerologyDataStatus, strainSearchLoading,
        modelStatus, modelTypesStatus, antigenicModelsStatus, mutationGroupValuesStatus, antigenicReferenceStrainStatus, tcellAntigenicityOptionsStatus, searchStrainStatus,

        fetchModel, fetchLineages, fetchClades, initStrainTree, fetchAntigenicModel, fetchCustomTreeAttrs, fetchTCellAntigenicityScores, fetchVpMethods, fetchMutationClasses, fetchVpValues,
        fetchAntigenicObservedData, fetchGenotypeData, fetchMutationsPositionsDictionary,
        fetchAntigenicRawModel,
        fetchHumanPools, fetchHumanSerologyData, fetchModelTypes, fetchMutationGroupValues, fetchAntigenicReferenceStrain, fetchBranchNodes, fetchSelectedStrain

    } = props;


    const { classes, editMode, menuRight, hiddenMenuMobile, isMobile, renderStatus, hiddenMenu } = props;

    const canRefetchData = treeDataStatus === 'loaded' && !strainSearchLoading && vpValuesStatus === 'loaded';

    useEffect(() => {
        initComponentData();
    })

    const _fetchModel = async () => {

        // console.log(`
        // shouldFetchModels = ${shouldFetchModels},
        // isColorByModel(${colorBy}) = ${isColorByModel(colorBy)}  
        // modelId = ${modelId} 
        // models.includes(modelId) = ${(models||[]).includes(modelId)}
        // modelType = ${modelType} 
        // modelRegionId = ${modelRegionId} 
        // exportMode = ${exportMode}
        // isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        // modelsStatus = ${modelsStatus} 
        // canRefetchData = ${canRefetchData} 
        // shouldFetch(modelStatus) = ${shouldFetch(modelStatus)}
        // modelStatus=${modelStatus}
        // `, models)
        if (isColorByModel(colorBy)
            && modelId
            && (!shouldFetchModels || models.includes(modelId))
            && modelType
            && modelRegionId
            && (!shouldFetchModels || (isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded'))
            && canRefetchData
            && shouldFetch(modelStatus)
        ) return fetchModel({ lineage, colorBy, modelRegionId, modelType, modelId, zoomNodeId, strainSubset });
        return null;
    }

    const _fetchTree = async () => {
        const genotypeParams = colorBy === 'genotype' ? { mutgene, mutposition } : {};
        const humanSerologyParams = colorBy === 'humanSerology' ? { humanPool, humanSerologyDataType } : {};
        if (shouldFetch(treeDataStatus)) {
            // console.log(exportParams)
            return initStrainTree({ lineage, colorBy, zoomNodeId, strainSubset, strainCutOffDate, ignoreStrainCutOffDate, predictionBaseline, vpMethod, ...genotypeParams, strainId, ...humanSerologyParams, /*treeAttrsParams,*/ ...(exportParams || {}) })
        }
        return null;
    }

    const _fetchClades = async () => {
        const cladesNeeded = colorBy === 'clade' || showCladeBar;
        if (shouldFetch(cladesStatus) /*&& treeDataStatus === 'loaded'*/ && cladesNeeded) {
            return fetchClades({ lineage })
        };
        return null;
    }

    const _fetchVpValues = async () => {
        const initStrainTreeNeeded = shouldFetch(treeDataStatus) || shouldFetch(cladesStatus);
        const fetchVpValuesNeeeded = !initStrainTreeNeeded && shouldFetch(vpValuesStatus);
        if (fetchVpValuesNeeeded)
            return fetchVpValues({ lineage, zoomNodeId, strainSubset, strainCutOffDate, vpMethod })
        return null;
    }

    const _fetchMutationGroupValues = async () => {
        if (showMutationsGroups && mutationsGroup && shouldFetch(mutationGroupValuesStatus) && treeDataStatus === 'loaded')
            return fetchMutationGroupValues({ lineage, mutationsGroup });
        return null;
    }

    const _fetchMutationClasses = async () => {
        if (shouldFetch(mutationClassesStatus))
            return fetchMutationClasses({ lineage });
        return null;
    }

    const _fetchCustomTreeAttrs = async () => {
        if (shouldFetch(customTreeDataStatus[colorBy]) && customMeasures[colorBy] && canRefetchData /*&& !exportMode*/)
            return fetchCustomTreeAttrs({ lineage, colorBy, strainSubset, zoomNodeId });
        return null;
    }
    const _fetchBranchNodes = async () => {
        const shouldFetchBranches = branchNodes.some((customAttrId) => shouldFetch(customTreeDataStatus[customAttrId]) && customMeasures[customAttrId]);  
        if (shouldFetchBranches && canRefetchData)
            return fetchBranchNodes({ lineage, branchNodes, strainSubset });
        return null;
    }

    const _fetchSelectedNode = async () => {
        // console.log('[__fetchSelectedNode]', strainId, canRefetchData, shouldFetch(searchStrainStatus));
        if (strainId && canRefetchData && shouldFetch(searchStrainStatus))
            return fetchSelectedStrain({ lineage, strainId, searchId: 'searchStrain', zoomNodeId, strainSubset });
        return null;
    }

    const _fetchAntigenicModel = async () => {
        const shouldFetchColorByAntigenicModel = colorBy === 'antigenic' && modelId && antigenicTiterType && shouldFetch(antigenicModelStatus);
        const shouldFetchColorByAntigenicInferredOrObserved = shouldFetchColorByAntigenicModel && (antigenicDataType === 'inferred' || antigenicDataType === 'observed');
        if (shouldFetchColorByAntigenicInferredOrObserved && (exportMode || antigenicModelsStatus === 'loaded'))
            return fetchAntigenicModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
        return null;
    }

    const _fetchAntigenicObservedData = async () => {
        const shouldFetchColorByAntigenicEpitopeClades = colorBy === 'antigenic' && modelId && antigenicTiterType && antigenicDataType === 'epitope_clades' && shouldFetch(antigenicObservedDataStatus);
        if (shouldFetchColorByAntigenicEpitopeClades)
            return fetchAntigenicObservedData({ lineage, antigenicModelId });
        return null;
    }

    const _fetchAntigenicRawModel = async () => {
        const shouldFetchColorByAntigenicRawStrains = colorBy === 'antigenic' && modelId
            && antigenicTiterType
            && (antigenicDataType === 'raw_strain' || antigenicDataType === 'observed_strain')
            && shouldFetch(antigenicRawModelStatus) && treeDataStatus === 'loaded' && cladesStatus === 'loaded';

        if (shouldFetchColorByAntigenicRawStrains)
            return fetchAntigenicRawModel({ lineage, antigenicModelId, antigenicDataType, antigenicTiterType });
        return null;
    }

    const _fetchAntigenicReferenceStrain = async () => {
        const isNullRefStrain = (refStrain) => `${refStrain || ''}`.length === 0;
        const shouldFetchAntigenicReferenceStrain = colorBy === 'antigenic' && modelId
            && antigenicTiterType
            && antigenicDataType
            && !isNullRefStrain(refStrain)
            && shouldFetch(antigenicReferenceStrainStatus) && antigenicReferenceStrainStatus !== 'not_found' && cladesStatus === 'loaded';

        if (shouldFetchAntigenicReferenceStrain)
            return fetchAntigenicReferenceStrain({ lineage, colorBy, refStrain, antigenicDataType, searchId: 'antigenic', zoomNodeId, ignoreStrainCutOffDate });
        return null;
    }

    const _fetchGenotypeData = async () => {
        if (colorBy === 'genotype' && shouldFetch(genotypeDataStatus) && !isNull(mutgene) && mutgene.length > 0 && !isNull(mutposition))
            return fetchGenotypeData({ lineage, mutgene, mutposition, zoomNodeId });
        return null;
    }

    const _fetchTCellAntigenicityScores = async () => {
        if (colorBy === 'tcellAntigenicity' && gene && hla && shouldFetch(tcellStatus[`${gene}_${hla}`]) && !exportMode)
            return fetchTCellAntigenicityScores({ lineage, colorBy, gene, hla, strainSubset });
        return null;
    }

    const _fetchHumanPools = async () => {
        if (colorBy === 'humanSerology' && shouldFetch(humanPoolsStatus))
            return fetchHumanPools({ lineage });
        return null;
    }

    const _fetchHumanSerologyData = async () => {
        if (colorBy === 'humanSerology' && canRefetchData && shouldFetch(humanSerologyDataStatus) && humanPool && humanSerologyDataType)
            return fetchHumanSerologyData({ lineage, colorBy, humanPool, humanSerologyDataType });
        return null;
    }

    const initComponentData = async () => {
        if (lineageStatus !== 'loaded') return;
        // console.log(`[initComponentData]
        //     colorBy = ${colorBy},
        //     modelId = ${modelId},
        //     modelStatus = ${modelStatus},
        //     gene = ${gene},
        //     hla = ${hla},
        //     tCellStatus = ${tcellStatus[`${gene}_${hla}`]},

        //     treeDataStatus = ${treeDataStatus},
        //     cladesStatus = ${cladesStatus}`);


        // const treeAttrsParams = [].join(',');




        // console.log(`fetchColorByAntigenicRawStrains = ${fetchColorByAntigenicRawStrains}, antigenicRawModelStatus = ${antigenicRawModelStatus}`);
        // console.log('[StrainTree]', initStrainTreeNeeded);
        // console.log('lineagesStatus = ',lineagesStatus, shouldFetch(lineagesStatus) )
        // console.log('treeDataStatus', treeDataStatus, 'modelStatus = ', modelStatus, 'modelId = ', modelId, 'shouldFetch(modelStatus) = ', shouldFetch(modelStatus), 'willFetchModel => ', (modelId && treeDataStatus === 'loaded' && shouldFetch(modelStatus) && isColorByModel(colorBy)))
        //console.log(`vpMethod = ${vpMethod}, treeDataStatus = ${treeDataStatus}, strainSearchLoading = ${strainSearchLoading}, vpValuesStatus = ${vpValuesStatus}, modelStatus = ${modelStatus}, modelId = ${modelId}`);




        //console.log('[StrainTree] colorBy = ',colorBy, 'isColorByModel = ',isColorByModel(colorBy), 'modelTypesStatus = ',modelTypesStatus, 'modelsStatus = ',modelsStatus)
        // console.log(`
        //     isColorByModel(colorBy) = ${isColorByModel(colorBy)}
        //     modelId = ${modelId}
        //     modelType = ${modelType}
        //     modelRegionId = ${modelRegionId}
        //     exportMode = ${exportMode}
        //     isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        //     modelsStatus = ${modelsStatus}
        //     canRefetchData = ${canRefetchData} 
        //     shouldFetch(modelStatus) = ${shouldFetch(modelStatus)}
        // `)



        // console.log(`exportMode = ${exportMode}, lineageStatus = ${lineageStatus}`);
        await Promise.all([
            _fetchModel(),
            _fetchTree(),
            _fetchClades(),
            _fetchVpValues(),
            _fetchMutationClasses(),
            _fetchCustomTreeAttrs(),
            _fetchAntigenicModel(),
            _fetchAntigenicObservedData(),
            _fetchAntigenicRawModel(),
            _fetchAntigenicReferenceStrain(),
            _fetchGenotypeData(),
            _fetchTCellAntigenicityScores(),
            _fetchHumanPools(),
            _fetchHumanSerologyData(),
            _fetchMutationGroupValues(),
            _fetchBranchNodes(),
            _fetchSelectedNode()
            // shouldFetch(tcellAntigenicityOptionsStatus) && !exportMode ? fetchTCellAntigenicityOptions({ lineage }) : null,
        ])
    }

    // console.log('EXPORT DONE:', renderStatus === RENDER_STATUS.DONE )


    return (
        <>
            {!exportMode && (
                <div className={classes.root}>
                    <ErrorAlert />
                    <Grid container className={classes.container}>
                        {
                            isMobile ?
                                <>
                                    <TreeGraph />
                                    {!hiddenMenuMobile &&
                                        <TreeGraphOptions intro={false} />
                                    }
                                    <NodeInfo />
                                </>
                                :
                                <>
                                    {!menuRight &&
                                        <Grid item className={`${classes.treeSidebarLeft} ${hiddenMenu ? classes.hidden : ''}`}>
                                            {!hiddenMenu && <NodeInfo />}
                                            <TreeGraphOptions intro={false} />
                                        </Grid>}
                                    <Grid item xs className={classes.item}>
                                        <ExportableComponent filename="strainTree">
                                            <TreeGraph />
                                        </ExportableComponent>
                                        <CladeSchema cladeSchemaWidth={schemaWidth} />
                                        <MetaInformations />
                                    </Grid>
                                    {menuRight &&
                                        <Grid item className={`${classes.treeSidebarRight} ${hiddenMenu ? classes.hidden : ''}`}>
                                            {!hiddenMenu && <NodeInfo />}
                                            <TreeGraphOptions intro={false} />
                                        </Grid>
                                    }
                                </>

                        }



                    </Grid>

                </div>
            )}
            {exportMode && !editMode && (
                <div className={classes.rootExport}>
                    <div className={classes.containerExport}>
                        <div id='exportComponent' className={classes.itemExport}>
                            <TreeGraph />
                        </div>
                        <div className={classes.legendExport} >
                            <TreeLegend />
                            <ScalesLegend scaleId="strainTree" valueGetter={() => null} ticks={3} precision={3} />
                        </div>
                        <CladeSchema cladeSchemaWidth={schemaWidth} />
                    </div>
                </div>
            )}
            {exportMode && editMode && (
                <Edit />
            )}
            {(renderStatus === RENDER_STATUS.DONE) && (
                <div id="exportDone" />
            )}
        </>
    );

}

StrainTree.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        item: PropTypes.string,
        legend: PropTypes.string,
        rootExport: PropTypes.string,
        containerExport: PropTypes.string,
        itemExport: PropTypes.string
    }),
    //location: PropTypes.shape({ pathname: PropTypes.string }),
    exportMode: PropTypes.bool,
    treeDataStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    modelStatus: PropTypes.string,
    modelsStatus: PropTypes.string,
    lineageStatus: PropTypes.string,
    lineagesStatus: PropTypes.string,
    lineage: PropTypes.string,
    modelId: PropTypes.string,
    regionId: PropTypes.string,
    colorBy: PropTypes.string,
    zoomNodeId: PropTypes.number,
    antigenicTiterType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]),
    refClade: PropTypes.number,
    strainSubset: PropTypes.string,
    renderStatus: PropTypes.string,
    clades: PropTypes.shape({ label: PropTypes.string }),
    exportParams: PropTypes.shape({ lineage: PropTypes.string, modelId: PropTypes.string, regionId: PropTypes.string }),
    tcellAntigenicityOptionsStatus: PropTypes.string,
    initStrainTree: PropTypes.func,
    fetchLineage: PropTypes.func,
    fetchLineages: PropTypes.func,
    fetchAntigenicModel: PropTypes.func,
    fetchGenotypeData: PropTypes.func,
    fetchTCellAntigenicityOptions: PropTypes.func,
    fetchBranchNodes: PropTypes.func
};

const mapStateToProps = (state) => ({
    treeDataStatus: state.treeData.treeDataStatus,
    cladeSchema: state.cladeData.cladeSchema,
    cladesStatus: state.cladeData.cladesStatus,
    modelStatus: state.modelData.modelStatus[state.parameters.colorBy],
    modelsStatus: state.models.modelsStatus[state.parameters.colorBy],
    modelTypesStatus: state.models.modelTypesStatus[state.parameters.colorBy] || 'NA',
    lineagesStatus: state.lineages.lineagesStatus,
    lineageStatus: state.lineages.lineageStatus,
    genotypeDataStatus: state.genotype.genotypeDataStatus,
    mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
    lineage: state.parameters.lineage,
    modelType: state.parameters.modelType,
    modelId: state.parameters.modelId,
    models: state.models.models[state.parameters.colorBy],
    antigenicModelId: state.parameters.antigenicModelId,
    regionId: state.parameters.regionId,
    modelRegionId: state.parameters.modelRegionId,
    colorBy: state.parameters.colorBy,
    zoomNodeId: state.parameters.zoomNodeId,
    showCladeBar: state.parameters.showCladeBar,
    showMutationsGroups: state.parameters.showMutationsGroups,
    antigenicTiterType: state.parameters.antigenicTiterType,
    antigenicDataType: state.parameters.antigenicDataType,
    antigenicModelStatus: state.antigenic.antigenicModelStatus,
    antigenicObservedDataStatus: state.antigenic.antigenicObservedDataStatus,
    antigenicRawModelStatus: state.antigenic.antigenicRawModelStatus,
    strainSubset: state.parameters.strainSubset,
    refClade: state.parameters.refClade,
    gene: state.parameters.gene,
    editMode: state.parameters.editMode,
    mutgene: state.parameters.mutgene,
    mutposition: state.parameters.mutposition,
    mutationGroup: state.parameters.mutationGroup,
    predictionBaseline: state.parameters.predictionBaseline,
    mutationGroupValuesStatus: state.genotype.mutationGroupValuesStatus,
    strainId: state.parameters.strainId,
    refStrain: state.parameters.refStrain,
    hla: state.parameters.hla,
    vpMethod: state.parameters.vpMethod,
    mutationsGroup: state.parameters.mutationsGroup,
    strainCutOffDate: state.parameters.strainCutOffDate,
    branchNodes: state.parameters.branchNodes,
    ignoreStrainCutOffDate: getIgnoreStrainCutOffDateForColorBy(state),
    clades: state.cladeData.clades,
    tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
    vpMethodsStatus: state.metadata.vpMethodsStatus,
    mutationClassesStatus: state.metadata.mutationClassesStatus,
    exportMode: state.parameters.exportMode,
    renderStatus: state.render.renderStatus, //getStrainTreeRenderStatus(state), //

    customTreeDataStatus: state.customTreeData.status,
    tcellStatus: state.customTreeData.tcellStatus,
    vpValuesStatus: state.treeData.vpValuesStatus,
    genotypeDataStatus: state.genotype.genotypeDataStatus,
    customMeasures: getCustomMeasures(state),
    menuRight: state.user.menuRight,
    hiddenMenu: state.render.hiddenMenu,
    hiddenMenuMobile: state.render.hiddenMenuMobile,
    isMobile: getIsMobile(),
    shouldFetchModels: shouldFetchModelsSelector(state),
    strainSearchLoading: getStrainSearchStatus(state),

    humanPoolsStatus: state.humanSerology.humanPoolsStatus,
    humanSerologyDataStatus: state.humanSerology.humanSerologyDataStatus,
    humanPool: state.parameters.humanPool,
    humanSerologyDataType: state.parameters.humanSerologyDataType,
    antigenicModelsStatus: state.models.modelsStatus.antigenic,
    antigenicReferenceStrainStatus: state.treeData.strainSearchStatuses.antigenic,
    searchStrainStatus: state.treeData.strainSearchStatuses.searchStrain


});



const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchModel,
            fetchClades,
            initStrainTree,
            fetchAntigenicModel,
            fetchAntigenicObservedData,
            fetchAntigenicRawModel,
            fetchTCellAntigenicityScores,
            fetchCustomTreeAttrs,
            fetchVpValues,
            fetchGenotypeData,
            fetchMutationClasses,
            fetchHumanPools,
            fetchHumanSerologyData,
            fetchMutationGroupValues,
            fetchAntigenicReferenceStrain,
            fetchBranchNodes,
            fetchSelectedStrain

        },
        dispatch,
    );

const StrainTreeWithStyles = withStyles(styles)(StrainTree);

export default connect(mapStateToProps, mapDispatchToProps)(StrainTreeWithStyles);

