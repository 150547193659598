import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import DeleteMutClassDialog from '../Dialogs/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { styles } from './styles';

const Delete = ({ setInfoDialog, setAction, setInfo, updateTable, name, lineage, mutGroup}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteMutClass = () => {
        const url = `${config.serverLink}/api/admin/deleteMutationClass`;
        const body = { 
            lineage,
            name,
            mutGroup
        };

        postAxios(url, body)
            .then((response) => {
                const status = response.data.status;
                setAction('Delete mutation class');
                if (status){
                    handleCloseDialog();
                    setInfoDialog(true);
                    updateTable();
                    setInfo(`Mutation class ${name} deleted sucesfully.`);

                } else {
                    handleCloseDialog();
                    setInfo(`An error occured during deleting ${name}.`);
                    setInfoDialog(true);
                    updateTable();
                }
            });
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <DeleteMutClassDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteMutClass} name={name} />
        </>

    );
};

export default Delete;