import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, FormControl } from '@mui/material';
import PropTypes from 'prop-types';
import { useStyles, dialog } from './styles';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';

export const PDFDimensions = (props) => {
    const classes = useStyles();
    const { handleCloseDialog, savePDF } = props;
    const [ dimensions, setDimensions ] = useState({ height: 450, width: 800 });

    const handleChange = (e, type) => {
        const regex = /^[0-9]*$/;
        let input = e.target.value;

        if (input[0] === '0' && input[1])
            input = input.substring(1);

        if (input == '')
            input = 0;

        if (regex.test(input) || input == '0'){
            (type === 'height') ?
                setDimensions({ height: input, width: dimensions.width }) :
                setDimensions({ height: dimensions.height, width: input })
        }
    }

    return (
        <Dialog open={true} onClose={handleCloseDialog} sx={dialog}>
            <DialogTitle id="dimnestions-dialog-title">
                PDF dimensions
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Please specify the dimensions of the PDF you wish to download:
                </DialogContentText>

                <FormControl fullWidth>
                    <StyledTextField
                        id="width"
                        type="text"
                        label="Width"
                        className={classes.textField}
                        value={dimensions.width}
                        onChange={(e) => handleChange(e, 'width')}
                    />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                    <StyledTextField
                        id="height"
                        type="text"
                        label="Height"
                        className={classes.textField}
                        value={dimensions.height}
                        onChange={(e) => handleChange(e, 'height')}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm} onClick={() => savePDF(dimensions)}>Download</Button>
            </DialogActions>
        </Dialog>);
};

PDFDimensions.propTypes = {
    openDialog: PropTypes.bool,
    handleCloseDialog: PropTypes.func,
    savePDF: PropTypes.func,
    getExportLink: PropTypes.func
};
