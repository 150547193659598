import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';
import UpdateLineageRegionDialog from '../Dialogs/EditLineageRegions';

const EditLineageRegion = ({ lineageRegion, currentLineage, setInfoDialog, setInfo, setAction, fetchLineageRegions }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const updateLineageRegion = (oldKey, newRegion) => {
        const url = `${config.serverLink}/api/admin/updateLineageRegion`;
        const body = { oldRegionKey: oldKey, newRegion, lineage: currentLineage };

        postAxios(url, body)
            .then((response) => {
                if (response.data.status === 'success') {
                    setOpenDialog(false);
                    setAction('Update lineage region');
                    setInfo(`Region ${newRegion.key} for lineage: ${currentLineage} was updated sucessfully`);
                    setInfoDialog(true);
                    fetchLineageRegions();
                }
                else {
                    setOpenDialog(false);
                    setAction('Lineage region');
                    setInfo(`Region ${newRegion.key} for lineage: ${currentLineage} wasn't updated`);
                    setInfoDialog(true);
                }
            });
    };

    return <>
        <IconButton className={classes.button} onClick={handleClick}>
            <EditIcon />
        </IconButton>
        <UpdateLineageRegionDialog oldLineageRegion={lineageRegion} openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateLineageRegion={updateLineageRegion} />
    </>
};

export default EditLineageRegion;
