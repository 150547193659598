import React, { useState, useEffect } from 'react';
import { InputAdornment, IconButton, FormControl } from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { bindActionCreators } from 'redux';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { setParameters } from '../../redux/actions/parametersActions';
import { MinusIcon, PlusIcon, ValidateIcon } from '../SvgIcons/SequencesInputIcons';
import { predictionBaselineSelector } from '../../redux/selectors/parametersSelector';
// import { fetchGeoData } from '../../redux/actions/geomapActions';

const styles = makeStyles(() =>({
    formControl: {
        margin: '8px 0px 16px 0px'
    },
    text: {
        textTransform: 'capitalize'
    },
    icon: {
        padding: 0,
        marginRight: '3px',
        '&:hover': {
            color: '#000000',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            color: '#000000',
          },
    },
    iconEnd : {
        padding: 0,
        margin: 0,
    }
}));

const GeoFreqSpanSelector = (props) => {
const { caseSpan, setParameters, /*fetchGeoData, colorBy, predictionBaseline, lineage, cladeType*/ } = props;
    const classes = styles();
    const [ caseSpanValue, setCaseSpanValue ] = useState(0);
    const [ canSubmit, setCanSubmit ]  = useState(false);
    const [ initialValue, setInitialValue ] = useState(0);

    useEffect(() => {
        setCaseSpanValue(caseSpan);
        setInitialValue(caseSpan);
    }, [])

    const handleChange = (e) => {
        let input = e.target.value;

        if (input == ''){
            setCaseSpanValue('');
            setCanSubmit(false);
            return;
        }
        else {
            setCaseSpanValue(input);
            setCanSubmit(true);
        }
    }

    const handleClick = (type) => {
        const newValue = type === 'up' ? parseInt(caseSpanValue) + 1 : caseSpanValue - 1;

        setCaseSpanValue(newValue.toString());
        setCanSubmit(true);
    }

    const handleSubmit = (e) => {
        setParameters({ caseSpan: caseSpanValue });
        // fetchGeoData({ lineage, colorBy, predictionBaseline, caseSpan: caseSpanValue, cladeType});
        setCanSubmit(false);
        e.preventDefault();
    }

    return (
        <FormControl className={classes.formControl} fullWidth>
                <StyledTextField
                    id="case-span"
                    label="Time window"
                    type='number'
                    value={caseSpanValue || ''}
                    style={{margin: '0px'}}
                    onChange={handleChange}
                    InputProps={{ endAdornment:
                        <InputAdornment position="end">
                            <IconButton className={classes.icon} onClick={() => handleClick('down')}>
                                <MinusIcon/>
                            </IconButton>
                            <IconButton className={classes.icon} onClick={() => handleClick('up')}>
                                <PlusIcon/>
                            </IconButton>
                            <IconButton type='submit' className={classes.iconEnd} disabled={!canSubmit || caseSpanValue == initialValue} onClick={handleSubmit}>
                                <ValidateIcon color={canSubmit && caseSpanValue != initialValue ? '#0000FF' : '#888888'}/>
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </FormControl>
    )
}

const mapStateToProps = (state) => ({
    caseSpan: state.parameters.caseSpan,
    lineage: state.parameters.lineage,
    predictionBaseline: predictionBaselineSelector(state),
    colorBy: state.parameters.geoMapColorBy,
    cladeType: state.parameters.cladeType
});

const mapDispatchToProps = dispatch => bindActionCreators({
        setParameters,
        // fetchGeoData
    }, dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(GeoFreqSpanSelector);
