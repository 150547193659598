/* eslint-disable no-shadow */
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Grid, Button } from '@mui/material';

import { resetSession } from '../../redux/actions/sessionActions';
import { fetchLineage } from '../../redux/actions/lineagesActions';
import { lineagesSelector } from '../../redux/selectors/metadataSelector';
import LineageSelect from '../../assets/GlobalStyles/LineageStyles/LineageSelectorSelect';
import { treeD3 } from '../Tree/d3/TreeD3';
// import { setGeoLoading } from '../../redux/actions/geomapActions';
import { useLocation, useNavigate } from 'react-router';
import appConfig from '../../config/appConfig';
import LineageSelectorLabel from '../../assets/GlobalStyles/LineageStyles/LineageSelectorLabel';
import { PATHOGEN_TYPES } from '../../config/dictionaries';
import { getIsMobile } from '../../redux/selectors/statusSelector';

const styles = () => ({
    lineageContainer: {
        paddingTop: '5px',
        marginLeft: '5px',
        width: '400px',
        paddingBottom: '10px',
        display: 'flex'
    },
    formControlLineage: {
        width: '150%',
        margin: '0px 24px 0px 10px'
    },
    formControlBuild: {
        width: '40%',
        margin: '0px 0px'
    },

    lineageContainerMobile: {
        // minWidth: '100%',
        paddingLeft: '12px',
        paddingRight: '7px',
        display: 'flex'
    },
    formControlLineageMobile: {
        width: '140%',
        marginRight: '11px'
    },
    formControlBuildMobile: {
        width: '40%'

    },
});


const getPathogenType = (pathogen) => appConfig.pathogenTypes[pathogen]?.value || PATHOGEN_TYPES.INFLUENZA.value;

const LineageSelector = props => {
    const { resetSession, lineage, lineages, classes, lineagesStatus, isMobile } = props;

    const [pathogen, setPatogene] = useState(lineage ? lineage.split('_')[0] : '');
    const [build, setBuild] = useState(lineage ? lineage.split('_').slice(1).join('_') : '');
    const location = useLocation();
    const navigate = useNavigate();


    const dictionary = useMemo(() => {
        if (lineagesStatus !== 'loaded') return {};
        const result = lineages.reduce((acc, item) => {
            const parts = item.id.split('_');
            const key = parts[0];
            const value = parts.slice(1).join('_');
            if (!acc[key]) acc[key] = [];
            acc[key].push(value);
            return acc;
        }, {});
        return result;
    }, [lineages, lineagesStatus]);

    const pathogenOptions = useMemo(() => { return Object.keys(dictionary) }, [dictionary]);
    const buildOptions = useMemo(() => { return dictionary[pathogen] }, [dictionary, pathogen]);


    const resetLineageURLParam = () => {
        const params = new URLSearchParams(location.search);
        params.delete('lineage'); // Remove the 'lineage' parameter
        navigate({
            pathname: location.pathname,
            search: params.toString()
        }, { replace: true }); // The replace option makes it behave like history.replace
    }

    useEffect(() => {
        setPatogene(lineage ? lineage.split('_')[0] : '');
        setBuild(lineage ? lineage.split('_').slice(1).join('_') : '');
    }, [lineage])

    const handlePathogenChange = async event => {
        const value = event.target.value;
        setPatogene(value);

        const builds = dictionary[value];
        const firstBuild = builds.includes('HA_NA')
            ? 'HA_NA'
            : (builds.includes('Unique') ? 'Unique' : builds[0]);
        // setBuildOptions(builds);
        setBuild(firstBuild);
        const newLineage = `${value}${firstBuild ? '_' : ''}${firstBuild}`;
        updateLineage(newLineage);
        resetLineageURLParam();
    };

    const handleBuildChange = async event => {
        const value = event.target.value;
        setBuild(value);
        const newLineage = value.length > 0 ? `${pathogen}_${value}` : pathogen;
        updateLineage(newLineage);
        resetLineageURLParam();

    };

    const updateLineage = async (lineage) => {
        setPatogene(lineage ? lineage.split('_')[0] : '');
        setBuild(lineage ? lineage.split('_').slice(1).join('_') : '');
        // console.log('updateLineage');
        treeD3.resetScales();
        await resetSession({ lineage });
    }


    return <div className={isMobile ? classes.lineageContainerMobile : classes.lineageContainer}>
        {pathogenOptions && pathogenOptions.length > 0 && (

                <FormControl className={isMobile ? classes.formControlLineageMobile : classes.formControlLineage} fullWidth>
                    <LineageSelectorLabel id='pathogen' label='Pathogen' />
                    <LineageSelect
                        value={pathogen}
                        onChange={handlePathogenChange}
                        // data-testid="pathogen-dropdown"
                        aria-labelledby="pathogen-label"
                        inputProps={{
                            name: 'pathogen',
                            id: 'pathogen',
                        }}
                    >
                        {pathogenOptions.map((element) => (
                            <MenuItem
                                key={`pathogen_${element}`}
                                id={`pathogen_${element}`}
                                data-testid={`pathogen-option`}
                                value={element}>{appConfig.pathogensLabels[element] || element}
                            </MenuItem>
                        ))}
                    </LineageSelect>
                </FormControl>
     
        )}
        {buildOptions && buildOptions.length > 0 && (

                <FormControl className={isMobile ? classes.formControlbuildMobile : classes.formControlbuild} >
                    <LineageSelectorLabel id='build' label='Tree' />
                    <LineageSelect
                        value={build}
                        onChange={handleBuildChange}
                        inputProps={{
                            name: 'build',
                            id: 'build',
                        }}
                    >
                        {buildOptions.map((element) => (
                            <MenuItem
                                key={`build_${element}`}
                                id={`build_${element}`}
                                data-testid={`build-option`}
                                value={element}>{appConfig.buildsLabels[element] || element}
                            </MenuItem>
                        ))}
                    </LineageSelect>
                </FormControl>

        )}
    </div>

};

LineageSelector.propTypes = {
    lineage: PropTypes.string,
    lineages: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })),
    lineagesStatus: PropTypes.string,
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    inactiveLineage: PropTypes.bool,
    resetSession: PropTypes.func,
    fetchLineage: PropTypes.func,
    fetchLineages: PropTypes.func,
};


const mapStateToProps = (state) => {
    const lineages = lineagesSelector(state); // ownProps.intro ? getIntroLineages(state) : getUserFilteredLineages(state);
    return ({
        lineages,
        lineagesStatus: state.lineages.lineagesStatus,
        lineage: state.parameters.lineage || '',
        isMobile: getIsMobile()
    })
};

const mapDispatchToProps = dispatch => ({
    fetchLineage: payload => {
        dispatch(setParameters(payload));
        dispatch(fetchLineage(payload));
    },
    // setGeoLoading: payload => dispatch(setGeoLoading(payload)),
    resetSession: payload => dispatch(resetSession(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LineageSelector));
