import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { FormHelperText, Grid } from "@mui/material";

import { styles } from './styles';
import { getAntigenicFitnessModelSegmentsOptions, getAntigenicModelSegmentsOptions, getModelSegmentsOptions } from '../../redux/selectors/modelsSelector';
import { getAntigenicSegments, getAntigenicSegmentsByName } from '../../functions/functions';
import { resetModelData } from '../../redux/actions/modelActions';
import { resetAntigenicModel } from '../../redux/actions/antigenicActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { AntigenicModelInput } from './AntigenicModelInput';
import { resetAntigenicFitnessModel } from '../../redux/actions/fitnessActions';


const AntigenicModelIdSelector = (props) => {
    const { isAntigenicModel, antigenicSegmentsNames, modelContext, models,
        collaboratingCenter, collaboratingCenters,
        assay, assays,
        strainPropagation, strainPropagations,
        refStrainPropagation, refStrainPropagations,
        modelId,
        resetModelData, resetAntigenicModel, resetAntigenicFitnessModel, resetPredictions, setParameters
    } = props;

    // console.log(props);
    const [error, setError] = useState(false);
    // const [errorTxt, setErrorTxt] = useState(null);
    const [errorSource, setErrorSource] = useState(null);

    const classes = styles();

    const inValues = (list, val) => list && list.map(({ id }) => id).includes(val);

    const _collaboratingCenter = inValues(collaboratingCenters, collaboratingCenter) ? collaboratingCenter : null;
    const _assay = inValues(assays, assay) ? assay : null;
    const _strainPropagation = inValues(strainPropagations, strainPropagation) ? strainPropagation : null;
    const _refStrainPropagation = inValues(refStrainPropagations, refStrainPropagation) ? refStrainPropagation : null;


    const nodata = models.length > 0 && !models.includes(modelId);

    const handleModelChange = async (event) => {
        let { name, value } = event.target;
        console.log('[handleModelChange]', name, value);
        const getModelIdFromSegments = (name, value) => {
            const val = {
                collaboratingCenter: name === "collaboratingCenter" ? value : collaboratingCenter,
                assay: name === "assay" ? value : assay,
                strainPropagation: name === "strainPropagation" ? value : strainPropagation,
                refStrainPropagation: name === "refStrainPropagation" ? value : refStrainPropagation
            }
            const _modelId = antigenicSegmentsNames.map(segmentName => val[segmentName]).join('-');
            return _modelId;
        };

        const _modelId = getModelIdFromSegments(name, value);


        const nodata = !models.includes(_modelId);

        console.log('[handleModelChange] _modelId', _modelId, 'nodata', nodata);
        if (nodata) {
            setError(true);
            // setErrorTxt(`No model for ${value}`);
            setErrorSource(name);
            //return;
        } else {
            setError(false);
            // setErrorTxt(null);
            setErrorSource(null);
        }
        const modelIdVar = (modelContext === 'antigenic' || modelContext === 'antigenicFitness') ? 'antigenicModelId' : 'modelId';

        // console.log('[handleModelChange]', modelIdVar, _modelId, { [modelIdVar]: _modelId });
        setParameters({ [modelIdVar]: _modelId });

        if (modelContext === 'strainTree')
            resetModelData({ nodata });
        if (modelContext === 'antigenic')
            resetAntigenicModel({ nodata });
        if (modelContext === 'antigenicFitness')
            resetAntigenicFitnessModel({ nodata });
        if (modelContext === 'frequencies')
            resetPredictions({ nodata });

    };

    useEffect(() => {
        return () => {
            setError(false);
            // setErrorTxt(null);
            setErrorSource(null);
        }
    }, []);

    // console.log(_collaboratingCenter)
    return <>
        {isAntigenicModel && (
            <Grid container spacing={2} style={modelContext === 'strainTree' ? { marginTop: '0px', marginBottom: '8px' } : { marginTop: '8px', marginBottom: '8px' }} >
                {_collaboratingCenter &&
                    <AntigenicModelInput
                        name="collaboratingCenter"
                        label="Collaborating center"
                        value={collaboratingCenter}
                        options={collaboratingCenters}
                        error={error && errorSource === "collaboratingCenter"}
                        // errorTxt={
                        //     error && errorSource === "collaboratingCenter"
                        //         ? errorTxt
                        //         : null
                        // }
                        changeHandler={handleModelChange}
                        classes={classes}
                    />
                }
                {_assay &&
                    // inAssays &&
                    <AntigenicModelInput
                        name="assay"
                        label="Assay"
                        value={assay}
                        options={assays}
                        error={error && errorSource === "assay"}
                        // errorTxt={
                        //     error && errorSource === "assay"
                        //         ? errorTxt
                        //         : null
                        // }
                        changeHandler={handleModelChange}
                        classes={classes}
                    />}
                {_strainPropagation &&
                    <AntigenicModelInput
                        name="strainPropagation"
                        label="Strain Propagation"
                        value={strainPropagation}
                        options={strainPropagations}
                        error={error && errorSource === "strainPropagation"}
                        // errorTxt={
                        //     error && errorSource === "strainPropagation"
                        //         ? errorTxt
                        //         : null
                        // }
                        changeHandler={handleModelChange}
                        classes={classes}
                    />
                }
                {_refStrainPropagation &&
                    <AntigenicModelInput
                        name="refStrainPropagation"
                        label="Ref. strain propagation"
                        disabled={!(modelContext === 'antigenic' || modelContext === 'antigenicFitness')}
                        value={(modelContext === 'antigenic' || modelContext === 'antigenicFitness') ? refStrainPropagation : 'CELL'}
                        options={refStrainPropagations}
                        error={error && errorSource === "refStrainPropagation"}
                        // errorTxt={
                        //     error && errorSource === "refStrainPropagation"
                        //         ? errorTxt
                        //         : null
                        // }
                        changeHandler={handleModelChange}
                        classes={classes}
                    />}
            </Grid>
        )}
        {nodata &&
            <Grid>
                <FormHelperText error={true}>
                    No such model: {modelId}
                </FormHelperText>
            </Grid>
        }
    </>
}

const mapStateToProps = (state, ownProps) => {
    const { modelContext } = ownProps;
    const { colorBy, modelType } = state.parameters;

    const modelVariable = (modelContext === 'antigenic' || modelContext === 'antigenicFitness')
        ? modelContext
        : ((modelContext === 'frequencies') ? 'fitness' : colorBy);

    const modelId = (modelContext === "antigenic")
        ? state.parameters.antigenicModelId
        : (modelContext === "antigenicFitness"
            ? state.parameters.antigenicFitnessModelId
            : state.parameters.modelId
        );

    const models = state.models.models[modelVariable];

    const antigenicModelTypes = state.metadata?.modelsConfig.antigenicModelTypes || [];

    const isAntigenicModel = modelContext === 'antigenic' || modelContext === 'antigenicFitness' || antigenicModelTypes.includes(modelType)

    const antigenicSegmentsNames = state.metadata?.modelsConfig.antigenicModelIdComponents[modelVariable] || [];
    const { strainPropagation, refStrainPropagation, collaboratingCenter, assay } = getAntigenicSegmentsByName(modelId, antigenicSegmentsNames);

    let antigenicModelOptions = {};
    if (modelContext === 'antigenic' ) antigenicModelOptions = getAntigenicModelSegmentsOptions(state);
    else if (modelContext === 'antigenicFitness') antigenicModelOptions = getAntigenicFitnessModelSegmentsOptions(state);
    else antigenicModelOptions = getModelSegmentsOptions(state);

    const { collaboratingCenters, assays, strainPropagations, refStrainPropagations } = antigenicModelOptions;

    // console.log(collaboratingCenters, assays, strainPropagations, refStrainPropagations);
    return {
        isAntigenicModel,
        modelId,
        strainPropagation,
        refStrainPropagation,
        collaboratingCenter,
        assay,
        models,
        antigenicSegmentsNames,
        collaboratingCenters,
        assays,
        strainPropagations,
        refStrainPropagations,
        modelContext
    };
};


const mapDispatchToProps = dispatch => bindActionCreators({
    resetAntigenicModel,
    setParameters,
    resetPredictions,
    resetModelData,
    resetAntigenicFitnessModel
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AntigenicModelIdSelector);
