import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';

import { makeStyles } from '@mui/styles';
import { useEventListener } from 'usehooks-ts';

import { styles } from './styles';
import { treeD3 } from '../../d3/TreeD3';

import { selectNodeData } from '../../../../redux/actions/nodeActions';
import { setLabelMovement } from '../../../../redux/actions/renderActions';
import { merge } from 'lodash';


const useStyles = makeStyles(styles);


export const getX = id => treeD3.x(+id);
export const getY = id => treeD3.y(+id);
export const getYOrder = val => treeD3.yOrder(val);

export const r = 3;
export const rMargin = 3;

const CladeLabelText = props => {
    const w = 16;
    const h = 8;
    const _textRef = useRef();
    const _nomenclatureRef = useRef();
    const _element = useRef();
    const { id, labelText, newNomenclature, classNamePrefix, xPos, x, yPos, y, labelHeight, labelWidth, setDimensions } = props;

    const textHeight = useRef(0);
    useEffect(() => {
        const bBox = _textRef.current.getBBox();
        setDimensions({ labelWidth: bBox.width + w, labelHeight: bBox.height + h });
        textHeight.current = (_nomenclatureRef.current ? bBox.height / 2 : bBox.height) + h;
    }, []);

    const xMove = x - xPos;
    const yMove = y - yPos;

    console.log(id, `(${xMove}, ${-labelHeight +  yMove})`, labelHeight, yMove, labelText);

    return (
        <g id={`${classNamePrefix}_${id}`} className="label" ref={_element}
            transform={`translate(${xMove}, ${-(labelHeight||0) + yMove})`}>
            <rect
                style={{
                    fill: '#ffffff',
                    opacity: 0.5,
                    stroke: '#4F4F4F',
                    strokeWidth: 1,
                    rx: 4,
                    ry: 4
                }}
                rx={4}
                ry={4}
                height={labelHeight}
                width={labelWidth}
            ></rect>
            <text
                style={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    cursor: 'pointer',
                }}
                transform={`translate(8, ${4 + textHeight.current / 2})`}
                ref={_textRef}
            >
                <tspan textAnchor="start">{labelText}</tspan>
                {newNomenclature && (
                    <tspan x="0" y="16" textAnchor="start" ref={_nomenclatureRef}>
                        [{newNomenclature}]
                    </tspan>
                )}
            </text>
            {/* <circle r="2" fill="red" transform={`translate(8, ${4 + textHeight.current/2})`}/> */}
        </g>
    );
};



const CladeLabel = (props) => {
    const { id, classNamePrefix, xMod, yMod, strainTreeWidth, strainTreeHeight, setLabelMovement, minOrder, maxOrder, showCladeBarLabels,
        onElementRendered, rerenderLabels } = props;
    const _element = useRef();

    const position = useRef({});
    const dimensions = useRef({});
    const [positionSet, setPositionSet] = useState(false);
    const [dimensionsSet, setDimensionsSet] = useState(false);

    const handleDrag = (event, data) => {
        const { x, y } = data;
        setLabelMovement({ id, type: classNamePrefix, xMod: x, yMod: y })
    };

    // const [resizeCnt, setResizeCnt] = useState(0);

    const xPos = getX(id);
    const yPos = getY(id);
    const clearSelectedNode = () => {
        if (!treeD3.selectedNode) props.selectNodeData();
    };
    const selectedNode = () => {
        if (!treeD3.selectedNode) props.selectNodeData({ nodeId: +id });
    };

    useEventListener('mouseover', selectedNode, _element);
    useEventListener('mouseleave', clearSelectedNode, _element);

    // useEffect(() => {
    //     if (rerenderLabels) onElementRendered();
    // }, [onElementRendered]);

    useEffect(() => {

        //console.log('[CladeLabel].rerenderLabels', rerenderLabels, 'positionSet', positionSet, 'dimensionsSet', dimensionsSet)
        if (rerenderLabels && positionSet && dimensionsSet) {
            //console.log('[CladeLabel].back')
            const labels = { [id]: merge(dimensions.current, position.current) };
            onElementRendered(labels);
        }
    }, [onElementRendered, positionSet, dimensionsSet]);


    const setPosition = () => {
        const x = getX(id);
        const y = getY(id);

        position.current = {
            x: x + r + rMargin,
            y: y + dimensions.current.labelHeight / 2,
            xAnchor: x,
            yAnchor: y,
            minY: getYOrder(minOrder),
            maxY: getYOrder(maxOrder)
        }

        setPositionSet(true);
    }


    useEffect(() => {
        if (dimensions.current.labelHeight > 0) setPosition()
    }, [strainTreeWidth, strainTreeHeight, dimensionsSet])


    const handleSetDimensions = useCallback((dim) => {

        // if (!rerenderLabels) return;
        dimensions.current = dim;
        setDimensionsSet(true);
    }, [])
    
    console.log('[CladeLabel] dimensions.current', dimensions.current)
  
    return (
        <g transform={`translate(${xPos}, ${yPos})`} ref={_element}>
            <Draggable onDrag={handleDrag} position={{ x: xMod, y: yMod }} >
                <g>
                    <CladeLabelText setDimensions={handleSetDimensions} {...props} {...dimensions.current} />
                </g>
            </Draggable>
        </g>
    );
};

const mapStateToProps = (state, ownProps) => {
    //const treeAttrs = getTreeNodeAttrs(state);
    const labelPos = state.render.labels?.[ownProps.classNamePrefix]?.[ownProps.id] || {};
    const editMode = state.parameters.editMode;
    const xPos = getX(ownProps.id);
    const yPos = getY(ownProps.id);


    return {
        strainTreeWidth: state.render.strainTreeWidth,
        strainTreeHeight: state.render.strainTreeHeight,
        showCladeBarLabels: state.parameters.showCladeBarLabels,
        editMode,
        xPos,
        yPos,
        // x: labelPos?.x || xPos,
        // y: labelPos?.y || yPos,
        xMod: labelPos?.xMod || 0,
        yMod: labelPos?.yMod || 0,
        

    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setLabelMovement
        },
        dispatch,
    );
export default connect(mapStateToProps, mapDispatchToProps)(CladeLabel);

//export { CladeLabelAnchorPoint };
