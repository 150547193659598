import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControl, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import { fetchVpMethods, resetVpValues } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import appConfig from '../../config/appConfig';
import { bindActionCreators } from 'redux';
import { shouldFetch } from '../../functions/functions';

const styles = () => ({
    formControl: {
        textTransform: 'capitalize',
        minWidth: 120,
        margin: '8px 0px'
    },
    text: {
        textTransform: 'capitalize'
    },
    label: {
        textTransform: 'none'
    }
});

const VpMethodSelector = props => {

    const { vpMethod, vpMethods, classes,  lineage, 
        lineageStatus, vpMethodsStatus,
        setParameters, resetVpValues, fetchVpMethods 
    } = props;

    const handleVpMethodChange = async event => {
        const vpMethod = event.target.value;
        setParameters({ vpMethod });
        resetVpValues();
    };

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        if (shouldFetch(vpMethodsStatus)) fetchVpMethods({ lineage });
    }, [lineageStatus]);

    const _vpMethod = vpMethods.includes(vpMethod) ? vpMethod : appConfig.default.vpMethod;

    return (
        vpMethods && vpMethods.length > 0 && (
            <FormControl className={classes.formControl} fullWidth>
                <CustomLabel id={'vpMethod'} label={'Vp method'} />
                <CustomSelect
                    value={_vpMethod} // {this.state.modelId}
                    className={classes.text}
                    onChange={handleVpMethodChange}
                    inputProps={{
                        name: 'vpMethod',
                        id: 'vpMethod',
                    }}
                >
                    {vpMethods.map((id) => (
                        <MenuItem className={classes.text} key={`vp_method_${id}`} value={id}>{id}</MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>
        )
    );

}

VpMethodSelector.propTypes = {
    lineage: PropTypes.string,
    vpMethod: PropTypes.string
};


const mapStateToProps = (state) => ({
    vpMethods: state.metadata.vpMethods,
    vpMethodsStatus: state.metadata.vpMethodsStatus,
    lineage: state.parameters.lineage,
    lineageStatus: state.lineages.lineageStatus,
    regionId: state.parameters.regionId,
    strainSubset: state.parameters.strainSubset,
    vpMethod: state.parameters.vpMethod,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    resetVpValues,
    fetchVpMethods
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VpMethodSelector));
