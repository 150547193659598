import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { FETCH_GEO_DATA_REQUEST, } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import { fetchGeoDataError, fetchGeoDataSuccess } from '../actions/geomapActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import { sessionTimeOut } from './helpers/functions';

export const fetchGeoMapDataEpic = action$ => action$.pipe(
    ofType(FETCH_GEO_DATA_REQUEST),
    mergeMap(action => {
        const url = prepareUrl(`${config.serverLink}/api/geomap`, action.payload);
        // console.log(`fetchGeoData => url = ${url}`);

        return fetchAxios(url).then(function (response) {
            // console.log('[fetchGeoMapDataEpic]', response.data);
            return fetchGeoDataSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchGeoDataError({ error: data.error, errorWebsiteText: 'An error has occured during downloading map data.', errorWebsiteType: 'fetch'});
        })
    })
);
