import React from 'react';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import TableUsers from '../../components/Admin/Users/TableUsers';
import TableRoles from '../../components/Admin/Roles/TableRoles';
import { styles } from './styles';
import { TabPanel, a11yProps } from '../../assets/GlobalStyles/TabElements';

export const AdminPanel = () => {
    const [value, setValue] = React.useState(0);
    const classes = styles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    return (
    <Box sx={{ width: '100%' }} className={classes.root}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} >
                <Tab label="Users" {...a11yProps(0)} />
                <Tab label="Roles" {...a11yProps(1)} />
            </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <TableUsers />
                </Grid>
            </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
        <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <TableRoles />
                </Grid>
            </Grid>
        </TabPanel>
    </Box>
  );
};
