import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Grid } from '@mui/material';
import TreeMenu from './Menu/TreeMenu';
import CladeSchemaMenu from './Menu/CladeSchemaMenu';
import ColorLegendMenu from './Menu/ColorLegendMenu';
import { LogoIconDesktop } from '../../components/SvgIcons/LogoIcon';
import CladeSchema from '../../components/CladeSchema/CladeSchema';
import { schemaWidthInit, schemaHeightMultiplierInit } from '../../components/CladeSchema/helpers';
import TreeLegend from '../../components/ColorLegend/Legends/TreeLegend';
import TreeGraph from '../../components/Tree/TreeGraph';
import { styles } from './styles';

export const Edit = () => {
    const classes = styles();
    const [schemaWidth, setSchemaWidth] = useState(schemaWidthInit);
    const [collSize, setCollSize] = useState(3);
    const [schemaHeightMultiplier, setSchemaHeightMultiplier] = useState(schemaHeightMultiplierInit);

    return (
        <div className={classes.rootAll}>
            <AppBar position="static" className={classes.bar}>
                <Toolbar >
                    <IconButton className={classes.logoButton} color="inherit" aria-label="Menu">
                        <LogoIconDesktop />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={3}>
                        <TreeMenu />
                    </Grid>
                    <Grid item xs={9} className={classes.tree}>
                        <TreeGraph />
                    </Grid>
                </Grid>
                 <Grid container className={classes.overflow}>
                    <Grid item xs={3} className={classes.overflow}>
                        <CladeSchemaMenu
                            width={schemaWidth}
                            setSchemaWidth={setSchemaWidth}
                            height={schemaHeightMultiplier}
                            setSchemaHeightMultiplier={setSchemaHeightMultiplier}
                            />
                    </Grid>
                    <Grid item xs={9} className={classes.overflow}>
                        <CladeSchema cladeSchemaWidth={schemaWidth} cladeSchemaHeightMultiplier={schemaHeightMultiplier}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={3}>
                        <ColorLegendMenu collSize={collSize} setCollSize={setCollSize}/>
                    </Grid>
                    <Grid item xs={9} className={classes.colorLegend}>
                        <TreeLegend collSize={collSize} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
