import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import AddSubsetDialog from '../Dialogs/AddSubsetDialog';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AddSubset = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { lineages, setInfo, setAction, setInfoDialog, fetchCustomTreeSubsets } = props;
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const addNewSubsetRule = async (subset) => {
        const url = `${config.serverLink}/api/admin/addCustomTreeSubsetRule`;
        const body = {
            lineage: subset.lineage,
            newSubset: {
                key: subset.key,
                label: subset.label,
                rule: {
                    name: subset.rule
                },
                showAll: subset.showAll
            }
        }

        try {
            postAxios(url, body).then((response) => {
                if (response.data.status) {
                    handleCloseDialog();
                    setAction('Add custom subset');
                    setInfo(`Subset ${subset.key} was added sucessfully.`);
                    setInfoDialog(true);
                    fetchCustomTreeSubsets();
                } else {
                    handleCloseDialog();
                    setAction('Add custom subset');
                    setInfo(`Subset ${subset.key} wasn't added`);
                    setInfoDialog(true);
                }
            })
        } catch (error) {
            console.error('Error adding subset:', error);
        }
    };

    const addNewSubsetFile = async (subset, file) => {
        const newSubset = {
            lineage: subset.lineage,
            key: subset.key,
            label: subset.label,
            showAll: subset.showAll,
            highlightAll: subset.highlightAll
        }
        const url = `${config.serverLink}/api/admin/addCustomTreeSubsetFile`;
        const formData = new FormData();
        formData.append('file', file, 'data.zip');

        for (const el of Object.keys(newSubset))
            formData.append(el, subset[el]);

        try {
            const res = await fetch(url, {
                method: 'POST',
                body: formData,
            });
            if (res.ok) {
                const data = await res.json();
                handleCloseDialog();
                setAction('Add custom subset');

                if (data.status === 'success') {
                    setInfo(`Subset ${subset.key} was added sucessfully.`);
                    setInfoDialog(true);
                    fetchCustomTreeSubsets();
                } else {
                    setInfo(`Subset ${subset.key} wasn't added`);
                    setInfoDialog(true);
                }
            }
        } catch (error) {
            handleCloseDialog();
            setAction('Add custom subset');
            setInfo(`Subset ${subset.key} wasn't added`);
            setInfoDialog(true);
        }
    };

    return (
        <>
            <IconButton className={classes.add} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddSubsetDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} addNewSubsetRule={addNewSubsetRule} addNewSubsetFile={addNewSubsetFile} lineages={lineages} />
        </>
    );
};

export default AddSubset;
