import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormControl, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { setLayoutParameters } from '../../redux/actions/parametersActions';
import appConfig from '../../config/appConfig';
import { bindActionCreators } from 'redux';

const useStyles = makeStyles({
    formControl: {
        margin: '8px 0px'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});


const CladeBarTypeSelector = props => {

    const { cladeBarType, cladeTypes, setLayoutParameters } = props;
    const classes = useStyles();

    function makeAfterSpaceSmall(inputString) {
        // Split the string into an array of words
        let words = inputString.split(' ');
      
        // Iterate through the words and make the first letter of each word after space lowercase
        for (let i = 1; i < words.length; i++) {
          words[i] = words[i].charAt(0).toLowerCase() + words[i].slice(1);
        }
      
        // Join the modified words back into a string
        let resultString = words.join(' ');
      
        return resultString;
      }

    const handleValueChange = event => {
        const cladeBarType = event.target.value;
        setLayoutParameters({ cladeBarType });
    }

    return <FormControl className={classes.formControl} fullWidth>
                <CustomLabel id={`${cladeBarType}_cladeBarType`} label={'Clade bar type'} />
                <CustomSelect
                    value={cladeBarType}
                    onChange={handleValueChange}
                    inputProps={{ name: `${cladeBarType}`, id: `${cladeBarType}_cladeBarType` }}
                >
                    {cladeTypes.map(c => (
                        <MenuItem key={c.key} value={c.key}>{makeAfterSpaceSmall(c.label)}</MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>;
}



CladeBarTypeSelector.propTypes = {
    cladeBarType: PropTypes.string,
    setParameters: PropTypes.func,
    cladeTypes: PropTypes.arrayOf(PropTypes.shape({key: PropTypes.string, label: PropTypes.string}))
};

const mapStateToProps = (state) => {
    return ({
        //  lineage: state.parameters.lineage,
        cladeBarType: state.parameters.cladeBarType,
        cladeTypes: state.metadata.cladeTypes || appConfig.cladeTypes
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLayoutParameters
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CladeBarTypeSelector);
