import React from 'react';
import { withStyles } from '@mui/styles';
import Select from '@mui/material/Select';

const styles = () => ({
    dropdown: {
        shadow: 'none',
        border: '1px #C4C4C4',
        maxHeight: '250px',
        backgroundColor: 'white',
        "& ul": {
            "& li": {
                padding: '4px 12px',
                cursor: 'pointer',
                fontSize: '14px',
                '&:hover': {
                    background: '#5094f8',
                    color: 'white'
                },
            },
        }
    },
})

const CustomSelect = (props) => {
    const { classes, children, ...rest } = props;
    return (
        <Select {...rest}
            className={classes.root}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center"
                },
                classes: { paper: classes.dropdown },
            }}
            id="custom-select"
            sx={{
                '& #custom-select': {
                    fontSize: '14px',
                    letterSpacing: '-0.1px',
                    paddingBottom: '3px'
                }
            }}

            displayEmpty
        >
            {children}
        </Select>)
}

export default withStyles(styles)(CustomSelect)
