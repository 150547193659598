import { cloneDeep, uniq } from 'lodash';
import deepFreeze from 'deep-freeze';

import {
    FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR,
    FETCH_VACCINE_CANDIDATES_REQUEST,
    FETCH_VACCINE_CANDIDATES_SUCCESS,
    FETCH_VACCINE_CANDIDATES_ERROR,
    FETCH_LINEAGE_SUCCESS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_VP_METHODS_REQUEST,
    FETCH_VP_METHODS_SUCCESS,
    FETCH_VP_METHODS_ERROR,
    FETCH_MUTATION_CLASSES_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_MEASURE_SCALES_DOMAINS_REQUEST,
    FETCH_MEASURE_SCALES_DOMAINS_SUCCESS,
    FETCH_LINEAGE_REQUEST,
    SET_MEASURE_SCALE,
    SET_PARAMETERS,
    RESET_GENOTYPE_STATUS
} from '../actions/actionTypes';
import { getMeasureScaleParamName } from '../../functions/functions';

let metadataInitialState = { };
export const setMetadataInitialState = (state) => {
    metadataInitialState = state;
};

export default function metadataReducer(state = metadataInitialState, action) {
    deepFreeze(state);
    if (action.payload && action.payload.settings) return state;

    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return {
                ...metadataInitialState,
            };
        }

        case FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST: {
            return { ...state, tcellAntigenicityOptionsStatus: 'loading' };
        }
        case FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS: {
            // console.log(action.payload);
            const { antigenicityOptions } = action.payload;
            return {
                ...state,
                tcellAntigenicityOptions: antigenicityOptions,
                tcellAntigenicityOptionsStatus: 'loaded'
            };
        }
        case FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR: {
            return {
                ...state,
                tcellAntigenicityOptionsStatus: 'error'
            };
        }

        case FETCH_VP_METHODS_REQUEST: {
            return { ...state, vpMethodsStatus: 'loading' };
        }
        case FETCH_VP_METHODS_SUCCESS: {
            // console.log(action.payload);
            const { vpMethods } = action.payload;
            return {
                ...state,
                vpMethods: uniq(state.vpMethods.concat(vpMethods)),
                vpMethodsStatus: 'loaded'
            };
        }
        case FETCH_VP_METHODS_ERROR: {
            return {
                ...state,
                vpMethodsStatus: 'error'
            };
        }

        case FETCH_MUTATION_CLASSES_REQUEST: {
            return { ...state, mutationClassesStatus: 'loading' };
        }
        case FETCH_MUTATION_CLASSES_SUCCESS: {
            // console.log(action.payload);
            const { mutationClasses } = action.payload;
            return {
                ...state,
                mutationClasses: { ...state.mutationClasses, ...mutationClasses },
                mutationClassesStatus: 'loaded'
            };
        }
        case FETCH_MUTATION_CLASSES_ERROR: {
            return {
                ...state,
                mutationClassesStatus: 'error'
            };
        }
        case FETCH_VACCINE_CANDIDATES_REQUEST: {
            return {
                ...state,
                vaccineCandidatesStatus: 'loading'
            }
        }
        case FETCH_VACCINE_CANDIDATES_SUCCESS: {
            const { strainsList } = action.payload;
            return {
                ...state,
                vaccineCandidates: strainsList ? strainsList : [],
                vaccineCandidatesStatus: strainsList ? 'loaded' : 'uninitialized'
            }
        }
        case FETCH_VACCINE_CANDIDATES_ERROR: {
            return {
                ...state,
                vaccineCandidates: [],
                vaccineCandidatesStatus: 'error'
            }
        }
        case FETCH_LINEAGE_REQUEST: {
            return {
                ...state,
                measures: metadataInitialState.measures,
                measureScalesDomains: {},
                measureDomainStatuses: {}
            }
        }
        case FETCH_LINEAGE_SUCCESS: {
            const { metadata, customMeasures, processingMetadata, measureDomains, measures, measureBins, scales } = action.payload;
            const customNodeMeasures = Object.keys(customMeasures.node || {}).reduce((acc, k) => { acc[k] = { ...customMeasures.node[k], custom: true }; return acc; }, {});
            const customBranchMeasures = Object.keys(customMeasures.branch || {}).reduce((acc, k) => { acc[k] = { ...customMeasures.branch[k], custom: true, branch: true }; return acc; }, {});
            const newMeasures = { ...measures, ...customNodeMeasures, ...customBranchMeasures };
           
            const _colorByOptions = metadata && metadata.colorByOptions ? metadata.colorByOptions : { ...state.metadata.colorByOptions };
            const colorByOptions = Object.keys(newMeasures)
                .filter(colorBy =>  newMeasures[colorBy] && (_colorByOptions[colorBy]))
                .reduce((colorByOptions, colorBy) => { colorByOptions[colorBy] = true; return colorByOptions; }, {});

            //const mapColorOptions =  metadata && metadata.mapColorOptions ? metadata.mapColorOptions : { ...state.metadata.mapColorOptions };
            
            const loadedMeasureDomains = Object.keys({ ...measureDomains, ...measureBins });
            const measureDomainStatuses = loadedMeasureDomains.reduce((acc, m) => { acc[m] = 'loaded'; return acc;},{});

           
            const disreteScaleTypes = Object.keys(scales).reduce((acc, scaleName) => {
                const scaleType = scaleName.split('.')[0];
                acc[scaleType] = acc[scaleType] || scales[scaleName].discrete || false;
                return acc;

            }, {});
            Object.keys(newMeasures).forEach(m => {
                const scaleType = newMeasures[m].scaleType || m;
                newMeasures[m].discreteScale = disreteScaleTypes[scaleType];
            })
            //console.log('disreteScaleTypes',disreteScaleTypes)
            // const newState = cloneDeep(state);
           const res = {
                ...state,
                ...metadata,
                customMeasures,
                measures: newMeasures,
                measureBins,
                processingMetadata,
                colorByOptions,
                measureScalesDomains: measureDomains,
                scales,
                measureDomainStatuses,
               // mapColorOptions
            };
            // console.log(res);
            return res;
        }
        
        // case INIT_STRAIN_TREE_SUCCESS:
        // case FETCH_FREQUENCIES_SUCCESS: {
        //     const { scaleBins } = action.payload;

          
        //      if (scaleBins) {
        //         const measures = { ...state.measures };
        //         Object.keys(scaleBins).forEach(m => {
        //             console.log(`${m} => assign colors: ${state.measures[m].assignColors}`)
        //             if (state.measures[m].assignColors) {
        //                 const colors = Object.keys(scaleBins[m] || {}).reduce((_colors, cat) => { _colors[cat] = scaleBins[m][cat].color; return _colors }, {});
        //                 measures[m] = { ...measures[m], color: colors };
        //             }
        //             measures[m].bins = scaleBins[m];
        //         })
        //         return { ...state, measures };
        //     }
        //     return state;
        // }
        
        case FETCH_MEASURE_SCALES_DOMAINS_REQUEST: {
            const { colorBy, freqCategory, geoMapColorBy } = action.payload;
            const _colorBy =  colorBy || freqCategory || geoMapColorBy;
            const measureDomainStatuses = { ...state.measureDomainStatuses };
            measureDomainStatuses[_colorBy] = 'loading';
            return {
                ...state,
                measureDomainStatuses
            }

        }
        case FETCH_MEASURE_SCALES_DOMAINS_SUCCESS: {
            const { measure, domains, bins } = action.payload;
            
            const measureScalesDomains = { ...state.measureScalesDomains };
            //console.log(measure, 'domains =',domains, 'bins =',bins);
            measureScalesDomains[measure] = domains;
  
            const measureBins = { ...state.measureBins };
            measureBins[measure] = {...measureBins[measure], ...bins };

            const measureDomainStatuses = { ...state.measureDomainStatuses };
            measureDomainStatuses[measure] = 'loaded';

            return {
                ...state,
                measureBins,
                measureScalesDomains,
                measureDomainStatuses
            }
        }

        case SET_MEASURE_SCALE: {
            const { measure, scaleName, parameters } = action.payload;
            const measures = cloneDeep(state.measures);
            const paramName = getMeasureScaleParamName(measures[measure]); // state.measures[measure].scale?.paramName;
            const paramValue = paramName && parameters[paramName];
            const scaleChanged = paramName && paramValue 
                ? measures[measure].scale[paramName][paramValue] !== scaleName
                : measures[measure].scale !== scaleName;
            if (!scaleChanged) return { ...state };

            if (paramName) measures[measure].scale.paramName = paramName;
            if (paramName && paramValue) {
                console.log(measures[measure].scale[paramName][paramValue])
                measures[measure].scale[paramName][paramValue] = scaleName;
            }
            else measures[measure].scale = scaleName;
            
            return {
                ...state,
                measures
            }
        }
        case SET_PARAMETERS: {
            //console.log('SET_PARAMETERS metadataReducer')
            const { parameters } = action.payload;
            const { colorScale, colorBy } = parameters;

            if (!colorScale || !colorBy || !state.measures[colorBy]) return { ...state };

            const measures = { ...state.measures };
        
            const paramName = getMeasureScaleParamName(measures[colorBy])        
            const paramValue = paramName && parameters?.[paramName];
    
            if (paramName && paramValue )
               measures[colorBy].scale[paramName][paramValue] = colorScale;
            else measures[colorBy].scale = colorScale;
            return {
                ...state,
                measures
            }
        }
        case RESET_GENOTYPE_STATUS: {
            const measureDomainStatuses = { ...state.measureDomainStatuses };
            measureDomainStatuses.genotype = 'none';
            const measureBins = { ...state.measureBins };
            measureBins.genotype = {};
            return {
                ...state,
                measureDomainStatuses,
                measureBins
            }
               
        }
        default: {
            return state || metadataInitialState;
        }
    }
}
