import React from 'react';
import { Grid, Tabs, Tab, Box } from '@mui/material';
import ParametersPanel from './Parameters/ParametersPanel';
import { styles } from './styles';
import { TabPanel, a11yProps } from '../../assets/GlobalStyles/TabElements';
import ScalesPanel from './Scales/ScalesPanel';
import MeasuresPanel from './Measures/MeasuresPanel';
import MutationClassesPanel from './MutationClasses/MutationClassesPanel';
import SubsetsPanel from './Subsets/SubsetsPanel';
import RegionsPanel from './Regions/RegionsPanel';

export const SettingsPanel = () => {
    const [value, setValue] = React.useState(0);
    const classes = styles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    return (
    <Box sx={{ width: '100%' }} className={classes.root}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} >
                <Tab label="Parameters" {...a11yProps(0)} />
                <Tab label="Measures" {...a11yProps(1)} />
                <Tab label="Scales" {...a11yProps(2)} />
                <Tab label="Mutation classes" {...a11yProps(3)} />
                <Tab label="Subsets" {...a11yProps(4)} />
                <Tab label="Regions" {...a11yProps(5)} />
            </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <ParametersPanel />
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <MeasuresPanel />
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <ScalesPanel />
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
        <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <MutationClassesPanel />
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
        <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <SubsetsPanel />
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={5}>
        <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.item}>
                    <RegionsPanel />
                </Grid>
            </Grid>
        </TabPanel>
    </Box>
  );
};
