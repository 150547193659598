import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import {FormControl, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import { fetchSubsetTree } from '../../redux/actions/treeDataActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { resetVisibleBins } from '../../redux/actions/frequenciesActions';
import { emptyObject } from '../../functions/functions';
import { getPredictionsStatus } from '../../redux/selectors/statusSelector';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { predictionBaselineSelector } from '../../redux/selectors/parametersSelector';

const styles = () => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px'
    },
});


class StrainsHighlightSelector extends PureComponent {
    handleChange = async event => {
        const { setParameters, fetchSubsetTree, strainSubset,
            zoomNodeId, lineage
        } = this.props;
        const strainHighlight = event.target.value;
        setParameters({ strainHighlight });
        console.log(`[highlight]: strainSubset = ${strainSubset}, strainHighlight = ${strainHighlight}`)
        fetchSubsetTree({ strainHighlight, zoomNodeId, lineage, strainSubset });
    };


    render = () => {
        const { classes, strainHighlight, strainHighlightOptions } = this.props;
        return (
            <>
                {!emptyObject(strainHighlightOptions) && (
                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id={'strainHighlight'} label={'Highlight strains'} />
                        <CustomSelect
                            value={strainHighlight}
                            onChange={this.handleChange}
                            inputProps={{
                                name: 'strainHighlight',
                                id: 'strainHighlight',
                            }}
                        >
                            {strainHighlightOptions.map(({ key, label }) => (
                                <MenuItem key={key || '_ss'} value={key}>{label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                )}
            </>
        );
    }
}

StrainsHighlightSelector.propTypes = {
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    strainHighlightOptions: PropTypes.arrayOf(PropTypes.shape({})),
    lineage: PropTypes.string,
    strainHighlight: PropTypes.string,
    zoomNodeId: PropTypes.number,
    setParameters: PropTypes.func,
    fetchSubsetTree: PropTypes.func
};


const mapStateToProps = (state) => {
    const { parameters, treeData, frequenciesData, metadata } = state;
    return ({
        lineage: parameters.lineage,
        strainSubset: parameters.strainSubset,
        strainHighlight: parameters.strainHighlight,
        strainHighlightOptions: metadata.strainHighlightOptions,
        zoomNodeId: parameters.zoomNodeId,
        subsetTreeStatus: treeData.subsetTreeStatus,
        frequenciesStatus: frequenciesData.frequenciesStatus,
        freqCategory: parameters.freqCategory,
        predictionBaseline: predictionBaselineSelector(state),
        gene: parameters.gene,
        hla: parameters.hla,
        // binMethod: parameters.binMethod,
        // binCnt: parameters.binCnt,
        predictionsStatus: getPredictionsStatus(state),
        selectedBins: frequenciesData.selectedBins,
    })
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    fetchSubsetTree,
    resetVisibleBins,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StrainsHighlightSelector));
