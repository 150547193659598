import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent, CardContent, Card, Button, FormControl, MenuItem, DialogActions, FormControlLabel, Typography, Checkbox, Grid } from '@mui/material';
import { styles, EditMeasureDialogSX } from './styles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import ColorPickerComponent from '../../Scales/Elements/ColorPicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Positions from '../Elements/Positions';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SymbolComponent, signOptions } from '../Elements/Symbol';

const dialogInitState = {
    name: '',
    label: '',
    lineage: '',
    mutGroup: '',
    color: '#ffffff',
    symbol_sign: '',
    cladeSchema: 'none',
    positions: []
};

const AddMutClassDialog = ({ lineages, openDialog, handleCloseDialog, addMutClass, mutationGenes }) => {
    const [newMutClass, setNewMutClass] = useState(dialogInitState);
    const [positionsState, setPositionsState] = useState({});
    const [expanded, setExpanded] = useState({});
    const classes = styles();

    const handleExpandClick = (key) => { setExpanded({ ...expanded, [key]: !expanded[key] }) };
    const handleNameChange = (e) => { setNewMutClass({ ...newMutClass, name: e.target.value }) };
    const handleMutGroupChange = (e) => { setNewMutClass({ ...newMutClass, mutGroup: e.target.value }) };
    const handleChangeLineage = (e) => setNewMutClass({ ...newMutClass, lineage: e.target.value });
    const handleChangeLabel = (e) => { setNewMutClass({ ...newMutClass, label: e.target.value }) };

    const handleColorChange = (color) => {
        const hexColor = color.hex;
        setNewMutClass({ ...newMutClass, color: hexColor });
    }
    const handleSymbolSignChange = (e) => {
        setNewMutClass({ ...newMutClass, symbol_sign: e.target.value });
    }
    const handleEditKey = (e, index, oldKey) => {
        const newKey = e.target.value;
        const newPositions = [...newMutClass.positions];
        newPositions[index] = newKey;
        setNewMutClass({ ...newMutClass, positions: newPositions });
        const newPositionsObj = { ...positionsState };
        newPositionsObj[newKey] = [...positionsState[oldKey]];
        delete newPositionsObj[oldKey];
        setPositionsState({ ...newPositionsObj });
    }
    const handleAddPositionClick = () => {
        const newPostitionKey = newMutClass.lineage ? mutationGenes[newMutClass.lineage][0].toLowerCase() : 'a';
        const positions = [...newMutClass.positions, newPostitionKey];
        setNewMutClass({ ...newMutClass, positions });
        const positionsStateNew = {
            ...positionsState,
            newPostitionKey: [
                {
                    min: 0,
                    max: 1
                }
            ]
        };
        setPositionsState(positionsStateNew)
    }
    const handleRemovePositionClick = (index, key) => {
        const positions = [...newMutClass.positions];
        positions.splice(index, 1);
        setNewMutClass({ ...newMutClass, positions });
        const positionsStateNew = { ...positionsState };
        delete positionsStateNew[key];
        setPositionsState(positionsStateNew)
    }

    const handleSubmit = async () => {
        const body = {
            ...newMutClass,
            positions: { ...positionsState }
        };

        await addMutClass(body);
    }

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={EditMeasureDialogSX}>
            <DialogTitle id="add-mut-class-dialog">
                Add mutation class
            </DialogTitle>
            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <StyledTextField
                    id="name"
                    type="text"
                    label="Mutation class name"
                    autoComplete='off'
                    className={classes.textField}
                    value={newMutClass.name}
                    onChange={handleNameChange}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    className={classes.textField}
                    value={newMutClass.label}
                    onChange={handleChangeLabel}
                />
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'plotType'} label={'Lineage'} />
                    <CustomSelect
                        value={newMutClass.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        {lineages && lineages.map(lineage =>
                            <MenuItem key={lineage} value={lineage} >{lineage}</MenuItem>
                        )}
                    </CustomSelect>
                </FormControl>
                <StyledTextField
                    id="mutGroup"
                    onChange={handleMutGroupChange}
                    type="text"
                    label="Mutation group"
                    autoComplete='off'
                    className={classes.textField}
                    value={newMutClass.mutGroup}
                />
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'plotType'} label={'Symbol sign'} />
                    <CustomSelect
                        value={newMutClass.symbol_sign}
                        onChange={handleSymbolSignChange}
                        inputProps={{
                            name: 'symbolSign',
                            id: 'symbolSign',
                        }}
                    >
                        {signOptions.map(sign => {
                            return (
                                <MenuItem key={sign} value={sign} >
                                    <SymbolComponent shape={sign} color={newMutClass.color} />
                                </MenuItem>
                            )
                        })}
                    </CustomSelect>
                </FormControl>
                <div>
                    Color:
                    <ColorPickerComponent handleColorChange={handleColorChange} color={newMutClass.color} />
                </div>
                <div className={classes.head}>
                    <span>
                        Positions:
                    </span>

                    <IconButton
                        onClick={handleAddPositionClick}
                    >
                        <AddIcon />
                    </IconButton>
                </div>
                {newMutClass.positions.map((key, index) => {
                    return (
                        <Card key={`${key}-el-${index}`} className={classes.cardFull}>
                            <CardContent className={classes.card}>
                                <div className={classes.line}>
                                    <FormControl fullWidth className={classes.formControlLineage}>
                                        <CustomLabel id={`${key}-head`} label={'Gene'} />
                                        <CustomSelect
                                            value={key}
                                            onChange={(e) => handleEditKey(e, index, key)}
                                            inputProps={{
                                                name: 'gene',
                                                id: 'gene',
                                            }}
                                        >
                                            {mutationGenes[newMutClass.lineage].map(gene => {
                                                return (
                                                    <MenuItem key={gene} value={gene.toLowerCase()} >
                                                        {gene}
                                                    </MenuItem>
                                                )
                                            })}
                                        </CustomSelect>
                                    </FormControl>
                                    <IconButton
                                        onClick={() => handleExpandClick(key)}
                                        aria-expanded={expanded}
                                        className={classes.expandbut}
                                        aria-label="show more"
                                    >
                                        {expanded[key] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    <IconButton className={classes.buttonRemove} onClick={() => { handleRemovePositionClick(index, key) }}>
                                        <RemoveIcon />
                                    </IconButton>
                                </div>
                            </CardContent>
                            {expanded[key] && (
                                <Positions handleRemovePositionClick={handleRemovePositionClick} positionKey={key} positions={positionsState} setPositionsState={setPositionsState} />
                            )}
                        </Card>
                    )
                })}
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm}
                    onClick={handleSubmit}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddMutClassDialog;