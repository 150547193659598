import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { ExpandIcon } from '../../../Alerts/styles';
import AddUserDialog from '../Dialogs/AddUserDialog';
import { styles } from '../styles';
import { postAxios, fetchAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AddUser = (props) => {
    const { setLoaded, setInfoDialog, setInfo, setAction } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const classes = styles();

    const handleClick = async () => {
        await fetchRoles();
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const fetchRoles = async () => {
        const url = `${config.serverLink}/api/admin/getAllRoles`;
        await fetchAxios(url)
            .then((response) => {
                setRoles(response.data.roles);
        });
    };

    const addUser = (user) => {
        const url = `${config.serverLink}/api/admin/addUser`;
        const body = { user };
        postAxios(url, body)
            .then((response) => {
                if (!response.status)
                    setInfo('An error occured during adding.');
                else
                    setInfo('User added sucesfully.');

                setAction('Add user');
                setLoaded(false);
                handleCloseDialog();
                setInfoDialog(true);
        });
    };

    return (
        <>
            <IconButton className={classes.addUser} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            { roles.length > 0 && <AddUserDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} addUser={addUser} roles={roles}/> }
        </>

    );
};

export default AddUser;
