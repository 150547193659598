import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import AddMeasureDialog from '../Dialogs/AddMeasureDialog';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AddMeasure = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const { scales, lineages } = props;
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const addNewMeasure = async (measure, files) => {
        const formData = new FormData();
        formData.append('file', files, 'data.zip'); // Append zipped file
        formData.append('lineage', measure.lineage);

        try {
            await fetch(`${config.serverLink}/api/customAttributes/upload`, {
              method: 'POST',
              body: formData,
            });
            // console.log('Files uploaded successfully');
          } catch (error) {
            console.error('Error uploading files:', error);
          }
    };

    return (
        <>
            <IconButton className={classes.addMeasure} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddMeasureDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} addNewMeasure={addNewMeasure} scales={scales} lineages={lineages}/>
        </>
    );
};

export default AddMeasure;
