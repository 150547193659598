import React from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControl, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import appConfig from '../../config/appConfig';
import { setParameters } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';

const useStyles = makeStyles({
    formControl: {
        margin: '17px 0px 0px 0px',
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box',
    },
});


const DisplayOrderSelector = (props) => {

    const { displayOrder, setParameters } = props;
    const classes = useStyles();

    const handleChange = async event => {
        const displayOrder = event.target.value;
        setParameters({ displayOrder });
    };

    return (
        <FormControl className={classes.formControl} fullWidth>
            <CustomLabel id={'displayOrder'} label={'Display order'}  />
            <CustomSelect
                value={displayOrder}
                onChange={handleChange}
                style={{paddingTop: '1px'}}
                inputProps={{
                    name: 'displayOrder',
                    id: 'displayOrder',
                }}
            >
                {appConfig.displayOrderOptions.map((cB) => (
                    <MenuItem key={cB.id} value={cB.id}>
                        {cB.label}
                    </MenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );
};

DisplayOrderSelector.propTypes = {
    displayOrder: PropTypes.string,
};

const mapStateToProps = ({ parameters }) => ({
    displayOrder: parameters.displayOrder,
});

const mapDispatchToProps = (dispatch) => ({
    setParameters: (payload) => dispatch(setParameters(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayOrderSelector);
