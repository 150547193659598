import { isFirefox } from '../../../functions/checkBrowser';

export const styles = (theme) => ({
    rootWithAlerts: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        maxHeight: 'calc(100% - 20px)',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: '#fff',
        margin: '0px 0px 24px',
    },
    rootWithAlertsMobile: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        maxHeight: 'calc(100% - 45px)',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: '#fff',
        margin: '0px 0px 24px',
        position: 'absolute',
    },
    rootWithoutAlerts: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        maxHeight: 'calc(100% - 20px)',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px 10px 15px',
        backgroundColor: '#fff',
        margin: '0px 0px 24px',
    },
    rootWithoutAlertsMobile: {
        overflowY: isFirefox ? 'auto' : 'overlay',
        maxHeight: 'calc(100% - 25px)',
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '10px 15px 10px 15px',
        backgroundColor: '#fff',
        margin: '0px 0px 24px',
        position: 'absolute',
    },
    formControl: {
        margin: '8px 0px 8px 0px',
        minWidth: 120,
    },
    datePickers: {
        marginTop: '0px'
    },
    datePicker: {
        paddingTop: '30px'
    },
    hideButton: {
        float: 'right',
        padding: '0px'
    },
    buttonWrapper: {
        width: '100%',
        height: '30px'
    },
    expandButton: {
        marginTop: '7px'
    },
    dateIntro: {
        marginTop: '16px',
        marginBottom: '8px'
    }
});
