import {
    FETCH_LINEAGE_SUCCESS,
    FETCH_VP_METHODS_SUCCESS,
    FETCH_MUT_POS_DICT_SUCCESS,
    FETCH_MODELS_SUCCESS,
    FETCH_MODEL_TYPES_SUCCESS,
    FETCH_ANTIGENIC_MODELS_LIST_SUCCESS,
    SET_LINEAGE_SETTINGS_REQUEST,
    SET_LINEAGE_SETTINGS_SUCCESS,
    SET_LINEAGE_SETTINGS_ERROR,
    FETCH_ALL_SCALES_SUCCESS,
    FETCH_MODEL_TYPES_REQUEST,
    FETCH_VP_METHODS_REQUEST,
    SET_SETTINGS_PARAMETERS,
    FETCH_LINEAGE_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    FETCH_MODELS_REQUEST,
    SET_SETTINGS_METADATA,
    FETCH_CUSTOM_TREE_SUBSETS_REQUEST,
    FETCH_CUSTOM_TREE_SUBSETS_SUCCESS,
    FETCH_CUSTOM_TREE_SUBSETS_ERROR,
    FETCH_LINEAGE_REGIONS_REQUEST,
    FETCH_LINEAGE_REGIONS_SUCCESS,
    FETCH_LINEAGE_REGIONS_ERROR
} from "../actions/actionTypes";
import { prepareParameters } from '../../functions/functions';
import appConfig from '../../config/appConfig';
import { cloneDeep } from 'lodash';
import deepFreeze from 'deep-freeze';

let settingsInitialState = { };
export const setSettingsInitialState = (state) => {
    settingsInitialState = state;
};

const GENES_LABELS = {
    genome: 'Nucleotide'
};

const GENES_ORDER = {
    genome: 1,
    default: 2
};

export const settingsReducer = (state = settingsInitialState, action) => {

    deepFreeze(state);
    if (action.payload && !action.payload.settings 
        && action.type !== SET_SETTINGS_PARAMETERS
        && action.type !== SET_SETTINGS_METADATA
        && action.type !== FETCH_ALL_SCALES_SUCCESS
        && action.type !== FETCH_LINEAGE_REQUEST
        && action.type !== FETCH_CUSTOM_TREE_SUBSETS_SUCCESS
        && action.type !== FETCH_LINEAGE_REGIONS_SUCCESS
        && action.type !== FETCH_CUSTOM_TREE_SUBSETS_REQUEST
        && action.type !== FETCH_CUSTOM_TREE_SUBSETS_ERROR
        && action.type !== FETCH_LINEAGE_SUCCESS
    ) return state;
        
    switch (action.type) {
        case FETCH_LINEAGE_REQUEST: {
            const { lineage } = action.payload
            return {
                ...settingsInitialState,
                parameters: {
                    ...state.parameters,
                    lineage,
                },
                lineages: {
                    lineageStatus: 'loading'
                }
            };
        }
        case FETCH_LINEAGE_SUCCESS: {
            const { metadata, customMeasures, processingMetadata, parameters, scales, measureDomains, measureBins } = action.payload;
            const _parameters = prepareParameters(parameters);
            if (!_parameters.zoomNodeId)
                _parameters.zoomNodeId = null;
            
            const customNodeMeasures = Object.keys(customMeasures.node || {}).reduce((acc, k) => { acc[k] = { ...customMeasures.node[k], custom: true }; return acc; }, {});
            const customBranchMeasures = Object.keys(customMeasures.branch || {}).reduce((acc, k) => { acc[k] = { ...customMeasures.branch[k], custom: true, branch: true }; return acc; }, {});
            const standardMeasures = cloneDeep(state.metadata.measures);
            const measures =
                {  
                    ...standardMeasures, 
                    ...customNodeMeasures, 
                    ...customBranchMeasures
                };
                
            const _colorByOptions = metadata && metadata.colorByOptions ? metadata.colorByOptions : appConfig.default.colorByOptions;
            const colorByOptions = Object.keys(measures)
                .filter(colorBy =>  measures[colorBy] && (_colorByOptions[colorBy]))
                .reduce((colorByOptions, colorBy) => { colorByOptions[colorBy] = true; return colorByOptions; }, {});
            
            const measureDomainStatutes = Object.keys(measureDomains).reduce((acc, m) => { acc[m] = 'loaded'; return acc;},{});

            const disreteScaleTypes = Object.keys(scales).reduce((acc, scaleName) => {
                const scaleType = scaleName.split('.')[0];
                acc[scaleType] = acc[scaleType] || scales[scaleName].discrete || false;
                return acc;

            }, {});
            Object.keys(measures).forEach(m => {
                const scaleType = measures[m].scaleType || m;
                measures[m].discreteScale = disreteScaleTypes[scaleType];
            })

            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    ...metadata,
                    customMeasures,
                    measures,
                    measureBins,
                    processingMetadata,
                    colorByOptions,
                    measureScalesDomains: measureDomains,
                    scales,
                    measureDomainStatutes
                },
                parameters: {
                    ...state.parameters,
                    ..._parameters
                },
                lineages: {
                    ...state.lineages,
                    lineageStatus: 'loaded'
                }
            };
        }
        case SET_LINEAGE_SETTINGS_SUCCESS: {
            const { parameters } = action.payload;
            return {
                ...state,
                parameters:
                {
                    ...state.parameters,
                    ...parameters
                },
                setSettingsStatus: 'success'
            }
        }

        case FETCH_CUSTOM_TREE_SUBSETS_REQUEST: {
            return {
                ...state,
                customSubsets: {
                    ...state.customSubsets,
                    status: 'loading',
                }
            }
        }

        case FETCH_CUSTOM_TREE_SUBSETS_SUCCESS: {
            const { customSubsets } = action.payload;
            return {
                ...state,
                customSubsets: {
                    status: 'loaded',
                    data: customSubsets
                }
            }
        }

        case FETCH_CUSTOM_TREE_SUBSETS_ERROR: {
            return {
                ...state,
                customSubsets: {
                    status: 'error',
                }
            }
        }

        case FETCH_LINEAGE_REGIONS_SUCCESS: {
            const { lineagesRegions } = action.payload;
            return {
                ...state,
                lineageRegions: {
                    status: 'loaded',
                    data: { ...lineagesRegions }
                }
            }
        }

        case FETCH_LINEAGE_REGIONS_REQUEST: {
            return {
                ...state,
                lineageRegions: {
                    ...state.lineagesRegions,
                    status: 'loading',
                }
            }
        }

        case FETCH_LINEAGE_REGIONS_ERROR: {
            return {
                ...state,
                lineageRegions: {
                    status: 'error',
                }
            }
        }

        case FETCH_VP_METHODS_REQUEST: {
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    vpMethodsStatus: 'loading'
                }
            }
        }
        case FETCH_VP_METHODS_SUCCESS: {
            const { vpMethods } = action.payload;

            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    vpMethods,
                    vpMethodsStatus: 'loaded'
                },
            };
        }
        case FETCH_MUT_POS_DICT_SUCCESS: {
            const { mutPositions } = action.payload;
            const genesList = Object.keys(mutPositions);
            const genotypeFilterGenesList = genesList
                .map(e => ({ label: GENES_LABELS[e] || e, val: e, order: GENES_ORDER[e] || GENES_ORDER.default }))
                .sort((a, b) => {
                    if (a.order === b.order) {
                        return a.label.localeCompare(b.label);
                    }
                    return a.order > b.order;
                });

            return {
                ...state,
                genotype: {
                    genotypeFilterGenesList,
                    mutPositions,
                },

            };
        }

        case FETCH_MODEL_TYPES_REQUEST: {
            const { colorBy } = action.payload;
            const _colorBy = colorBy || 'fitness';
            const _modelTypesStatus = { ...state.models.modelTypesStatus };
            _modelTypesStatus[_colorBy] = 'loading';
            const _modelTypes = { ...state.models.modelTypes };
            _modelTypes[_colorBy] = []; 


            return {
                ...state,
                models: {
                    ...state.models,
                    modelTypes: _modelTypes,
                    modelTypesStatus: _modelTypesStatus
                }
            }
        }

        case FETCH_MODEL_TYPES_SUCCESS: {
            const { modelTypes, colorBy } = action.payload;
            const _colorBy = colorBy || 'fitness';
            const _modelTypes = { ...state.models.modelTypes };
            _modelTypes[_colorBy] = modelTypes; 
            const modelType = modelTypes.includes(state.parameters.modelType) || modelTypes.length === 0 ?
                state.parameters.modelType
                : modelTypes[0];

            const _modelTypesStatus = { ...state.models.modelTypesStatus };
            _modelTypesStatus[_colorBy] = 'loaded';
            //console.log(modelType, state.parameters.modelType)
            return {
                ...state,
                models: {
                    ...state.models,
                    modelTypes: _modelTypes,
                    modelTypesStatus: _modelTypesStatus
                },
                parameters: {
                    ...state.parameters,
                    modelType
                }
            }
        }

        case FETCH_MODELS_REQUEST: {
            const { colorBy } = action.payload;

            const models = { ...state.models.models };
            models[colorBy] = [];

            const modelsStatus = { ...state.models.modelsStatus };
            modelsStatus[colorBy] = 'loading';

            

            return {
                ...state,
                models: {
                        ...state.models,
                        models,
                        modelsStatus
                    },
              
                    
            };
        }

        case FETCH_MODELS_SUCCESS: {
            const { models, colorBy } = action.payload;
            const _modelId = colorBy === 'antigenic' ? state.parameters.antigenicModelId : state.parameters.modelId;
            const modelIdVar = colorBy === 'antigenic' ? 'antigenicModelId' : 'modelId';
            const modelId = (_modelId && models.includes(_modelId)) || (models.length === 0)? _modelId : models[0];

            const _models = { ...state.models.models };
            _models[colorBy] = models;

            const _modelsStatus = { ...state.models.modelsStatus };
            _modelsStatus[colorBy] = 'loaded';

            return {
                ...state,
                models: {
                        ...state.models,
                        models: _models,
                        modelsStatus: _modelsStatus
                    },
                parameters: {
                    ...state.parameters,
                    [modelIdVar]: modelId
                }
                    
            };
        }
        // case FETCH_ANTIGENIC_MODELS_LIST_SUCCESS: {
        //     const { antigenicModels } = action.payload;
        //     const antigenicModelId = state.parameters.antigenicModelId && antigenicModels.includes(state.parameters.antigenicModelId) ? state.parameters.antigenicModelId : antigenicModels[0];
        //     return {
        //         ...state,
        //         models: {
        //             ...state.models,
        //             antigenicModels,
        //             antigenicModelsStatus: 'loaded'
        //         },
        //         parameters: {
        //             ...state.parameters,
        //             antigenicModelId
        //         }
        //     };
        // }
        case SET_LINEAGE_SETTINGS_REQUEST: {
            return {
                ...state,
                setSettingsStatus: 'processing'
            }
        }
      
        case SET_LINEAGE_SETTINGS_ERROR: {
            return {
                ...state,
                setSettingsStatus: 'error'
            }
        }
        case FETCH_ALL_SCALES_SUCCESS: {
            const { colorScales, scalesPalette } = action.payload;
            return {
                ...state,
                colorScales,
                scalesPalette
            }
        }

        case FETCH_MUTATION_CLASSES_SUCCESS: {
            // console.log(action.payload);
            const { mutationClasses } = action.payload;
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    mutationClasses: { ...state.metadata.mutationClasses, ...mutationClasses },
                    mutationClassesStatus: 'loaded'
                }
            };
        }

        case SET_SETTINGS_PARAMETERS: {
            const parameters = action.payload;
            //const _parameters = prepareParameters(parameters);
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...parameters
                }
            }
        }
        
        case SET_SETTINGS_METADATA: {
            const metadata = action.payload;
   
            //const _parameters = prepareParameters(parameters);
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    ...metadata
                }
            }
        }


        default:
            return state || settingsInitialState;
    }
}
