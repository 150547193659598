import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus, getIsMobile } from '../../../redux/selectors/statusSelector';
import { setActiveLegendOption } from '../../../redux/actions/nodeActions';
import { setParameters } from '../../../redux/actions/parametersActions';
import { treeD3 } from '../d3/TreeD3';
import { getCladeBar, getNodeClade } from '../../../redux/selectors/treeDataSelector';
import { LAYOUT } from '../../../config/dictionaries';

const viewToRender = 'strainTree';
const componentId = 'cladeBar';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const CladeBarLayer = (props) => {
    const _element = useRef();
    const { loading, renderStatus, lineage, cladeBar, showCladeBar, showCladeBarLabels, treeScaleTypeX, treeScaleTypeY, colorBy, layout, isMobile,
        setParameters, setComponentStatus, setActiveLegendOption, activeLegendOption, nodeCladeId
    } = props;
    const prevActive = usePrevious(activeLegendOption);
    const prevNodeCladeId = usePrevious(nodeCladeId);

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    }

    const stopRender = () => {
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    }

    const highlightLegend = (clade, type) => {
        if (type) {
            setActiveLegendOption({ value: clade, option: colorBy });
            const ele = document.getElementById(clade);
            const parent = document.getElementById("legendWrapper");
            if (parent === null)
                return;
            else {
                const offset = ele ? ele.getBoundingClientRect().top - parent.getBoundingClientRect().top : 0;
                parent.scrollTop += offset;
            }
        } else
            setActiveLegendOption({ value: '', option: colorBy });
    }

    useEffect(() => {
        isMobile && setParameters({ showCladeBarLabels: false });
    }, []);


    useEffect(() => {
        if (activeLegendOption.value)
            treeD3.highlightCladeBar(_element.current, activeLegendOption.value, true);

        if (prevActive?.value || (prevActive?.value && activeLegendOption.value !== prevActive.value))
            treeD3.highlightCladeBar(_element.current, prevActive.value, false);
    }, [activeLegendOption]);

    useEffect(() => {
        if (nodeCladeId)
            treeD3.highlightCladeBar(_element.current, nodeCladeId, true);

        if (!nodeCladeId || (nodeCladeId && nodeCladeId !== prevNodeCladeId))
            treeD3.highlightCladeBar(_element.current, prevNodeCladeId, false);

    }, [nodeCladeId]);

    useEffect(() => {
        treeD3.removeElementsLayer(_element.current);
    }, [lineage])

    
    useEffect(() => {
        // console.log('CladeBarLayer.useEffect 1', loading)
        const _renderStatus = renderStatus || RENDER_STATUS.NONE
        if (loading || _renderStatus !== RENDER_STATUS.NONE) return;

        // if (!showCladeBar){
        //     treeD3.translateGraphElements();
        // } else {
        startRender()
            .then(() => treeD3.removeElementsLayer(_element.current))
            .then(() => treeD3.drawCladeBarLayer(_element.current, showCladeBarLabels, highlightLegend))
            .then(() => stopRender());

        // }
    }, [renderStatus, loading]); //[treeScaleTypeX, treeScaleTypeY, cladeBar, showCladeBar, showCladeBarLabels, layout, loading]);

    // useEffect(() => {
    //     if (renderStatus !== RENDER_STATUS.NONE) return;
    //     startRender()
    //         .then(() => treeD3.translateCladeBarLayer())
    //         .then(() => stopRender());
    // }, [renderStatus]);

    // useEffect(() => {
    //     if (loading) return;

    //     if (!showCladeBar)
    //         treeD3.translateGraphElements();

    // }, [showCladeBar]);

    const xtrans = treeD3.getTreeWidth();
    return <>{showCladeBar && layout !== LAYOUT.FAN.value && <g id="cladeBar" transform={`translate(${xtrans}, 0)`} ref={_element} />}</>;
};


CladeBarLayer.propTypes = {
    loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    const cladeBar = getCladeBar(state);
    const nodeCladeId = getNodeClade(state);

    return {
        loading,
        lineage: state.parameters.lineage,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        treeScaleTypeX: state.parameters.treeScaleTypeX,
        treeScaleTypeY: state.parameters.treeScaleTypeY,
        colorBy: state.parameters.colorBy,
        showCladeBar: state.parameters.showCladeBar,
        showCladeBarLabels: state.parameters.showCladeBarLabels,
        modelId: state.parameters.modelId,
        cladeBar,
        layout: state.parameters.layout,
        cladeBarType: state.parameters.cladeBarType,
        isMobile: getIsMobile(),
        activeLegendOption: state.nodeData.activeLegendOption,
        nodeCladeId: nodeCladeId,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
            setActiveLegendOption,
            setParameters
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CladeBarLayer);
