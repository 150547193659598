import { combineEpics } from 'redux-observable';
import * as antigenic from './antigenicEpic';
import * as clade from './cladeEpic';
import * as lineage from './lineageEpic';
import * as treeData from './treeDataEpic';
import * as user from './userEpic';
import * as zoom from './zoomEpic';
import * as model from './modelEpic';
import * as session from './sessionEpic';
import * as frequencies from './frequenciesEpic';
import * as prediction from './predictionEpic';
import * as geomap from './geoMapEpic'
import * as mutations from './mutationsEpic';
import * as settings from './settingsEpic';
import * as humanSerology from './humanSerologyEpic';
import * as fitness from './fitnessEpic';

const epics = [
    ...Object.values(antigenic),
    ...Object.values(clade),
    ...Object.values(lineage),
    ...Object.values(treeData),
    ...Object.values(fitness),
    ...Object.values(user),
    ...Object.values(zoom),
    ...Object.values(model),
    ...Object.values(session),
    ...Object.values(frequencies),
    ...Object.values(prediction),
    ...Object.values(geomap),
    ...Object.values(mutations),
    ...Object.values(settings),
    ...Object.values(humanSerology)
];

export const rootEpic = combineEpics(
    ...epics
);
