import { 
    FETCH_FITNESS_MODEL_REQUEST,
    FETCH_FITNESS_MODEL_SUCCESS,
    FETCH_FITNESS_MODEL_ERROR,
    RESET_SESSION,
    RESET_ANTIGENIC_FITNESS_MODEL,
    SIGNOUT_REQUEST
} from '../actions/actionTypes';
import deepFreeze from 'deep-freeze';

let fitnessInitialState = { };
export const setFitnessInitialState = (state) => {
    fitnessInitialState = state;
};

const fitness = (state = fitnessInitialState, action) => {
    deepFreeze(state);
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return { ...fitnessInitialState };
        }
        case FETCH_FITNESS_MODEL_REQUEST: {
            return {
                ...state,
                fitnessModelStatus: 'loading',
            };
        }
        case FETCH_FITNESS_MODEL_SUCCESS: {
            const { fitnessModel } = action.payload;
            //console.log('epic reducer success', action)
            return {
                ...state,
                fitnessModel,
                fitnessModelStatus: fitnessModel === null ? 'nodata' : 'loaded',
            };
        }
        case FETCH_FITNESS_MODEL_ERROR: {
            return {
                ...state,
                fitnessModelStatus: 'error',
            };
        }
        case RESET_ANTIGENIC_FITNESS_MODEL: {
            return { ...fitnessInitialState };
        }
        default:
            return state || fitnessInitialState;
    }
}

export default fitness;
