import { symbol, symbols } from 'd3-shape';
import { select } from 'd3-selection';
import { color } from 'd3-color';
export const getSymbol = (shape, legend, size) => {
    let ssize = 60;
    if (legend)
        ssize = 120;
    else {
        if (size === 'small')
            ssize = 30;
        if (size === 'medium')
            ssize = 60;
        if (size === 'big')
            ssize = 90;
    }
    
    switch (shape) {
        case 'circle': return symbol().type(symbols[0]).size(ssize);
        case 'cross': return symbol().type(symbols[1]).size(ssize);
        case 'diamond': return symbol().type(symbols[2]).size(ssize);
        case 'square': return symbol().type(symbols[3]).size(ssize);
        case 'star': return symbol().type(symbols[4]).size(ssize);
        case 'triangle': return symbol().type(symbols[5]).size(ssize);
        default: {
            return symbol().type(symbols[0]).size(ssize);
        }
    }
}

export const getSymbolPathForNode = (shape, legend, size) => node => {
    return node.append('path').attr('d', getSymbol(shape, legend, size));
};


export const drawSymbolElement = (properties) => selection => {
    const branchNodes = selection.nodes();
    branchNodes.forEach(bnode => {
        const snode = select(bnode);
        const d = snode.datum();
        // snode.append('path')
        //     .attr('d', getSymbol(properties.symbol_sign, true))
        //     .attr('transform', 'translate(10, 12)')
        //     .attr('class', 'legendElem')
        //     .attr('fill', properties.color)
        //     .attr('stroke', '#B8B8B8')
        //     .attr('stroke-opacity', 0.5);
        snode.append('path')
            .attr('d', getSymbol(properties.symbol_sign, true))
            .attr('transform', 'translate(14, 14)')
            .attr('class', 'legendElem')
            .attr('fill', properties.color)
            .attr('stroke', color(properties.color).darker())

    });
}
