import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axiosRequests';
import config from '../../config/envConfig';
import { fetchModelsError, fetchModelsSuccess, fetchModelError, fetchModelSuccess, fetchModelTypesError, fetchModelTypesSuccess } from '../actions/modelActions';
import {
    FETCH_FITNESS_DATA_REQUEST,
    FETCH_MODELS_REQUEST, FETCH_MODEL_DATA_REQUEST, FETCH_MODEL_TYPES_REQUEST
} from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import { sessionTimeOut } from './helpers/functions';

export const fetchModelTypesEpic = action$ => action$.pipe(
    ofType(FETCH_MODEL_TYPES_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { lineage, modelRegionId, colorBy, settings } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/models/types`, { lineage, modelRegionId, colorBy });
        console.log(`fetchModel, url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchModelTypesSuccess({ ...response.data, colorBy, settings });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);

            return fetchModelTypesError({ error: data.error, errorWebsiteText: 'An error has occured on download of model types.', errorWebsiteType: 'fetch', colorBy});
        });
    })
);

export const fetchModelsEpic = action$ => action$.pipe(
    ofType(FETCH_MODELS_REQUEST),
    mergeMap(action => {
        const { lineage, modelRegionId, modelType, colorBy, settings } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/models`, { lineage, modelRegionId, colorBy, modelType });

        return fetchAxios(url).then(function (response) {
            return fetchModelsSuccess({ ...response.data, colorBy, settings });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchModelsError({ error: data.error, errorWebsiteText: 'An error has occured during downloading models.', errorWebsiteType: 'fetch', colorBy});
        });
    })
);

export const fetchModelEpic = action$ => action$.pipe(
    ofType(FETCH_MODEL_DATA_REQUEST),
    mergeMap(action => {

        const { lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/models/model`, { lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy });
        //console.log(`fetchModel, url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchModelSuccess({...response.data, colorBy });
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);

            return fetchModelError({ error: data.error, errorWebsiteText: 'An error has occured during downloading model data.', errorWebsiteType: 'fetch', colorBy});
        });
    })
);


