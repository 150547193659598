
import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, IconButton, InputAdornment, TableSortLabel,
    MenuItem, FormControl, Button, Autocomplete
} from '@mui/material';
import { styles, StyledTableCell } from './styles';
import CustomLabel from '../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../../components/Alerts/styles';
import Info from '../Info';
import { getBiggerForSortTable } from '../../../functions/functions';
import { fetchLineageRegions } from '../../../redux/actions/settingsActions';
import { shouldFetch } from '../../../functions/functions';
import AddLineageRegion from './Actions/Add';
import DeleteLineageRegion from './Actions/Delete';
import EditLineageRegion from './Actions/Edit';
import { SymbolComponent } from '../MutationClasses/Elements/Symbol';

function createData(lineageRegion) {
    const { key, label, color } = lineageRegion;
    return { key, label, color };
}

const orderInitState = {
    key: 'none',
    label: 'none',
    color: 'none',
};

const searchState = {
    key: '',
    label: '',
}

const searchOptionsState = {
    key: [],
    label: [],
}

const RegionsTable = (props) => {
    const { lineageRegions, fetchLineageRegions, lineageRegionsLength, status, lineages } = props;
    const classes = styles();
    const [orderBy, setOrderBy] = useState({ ...orderInitState, key: 'asc' });
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searched, setSearched] = useState({ ...searchState });
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');
    const [currentLineage, setCurrentLineage] = useState('');
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);

    useEffect(() => {
        if (shouldFetch(status))
            fetchLineageRegions();
    }, []);

    useEffect(() => {
        filterafterSearchClicked();
    }, [tableData]);

    useEffect(() => {
        if (currentLineage === '' || status !== 'loaded') return;

        setTableRegions();
    }, [currentLineage, lineageRegions]);

    const setTableRegions = () => {
        const rows = lineageRegions[currentLineage].map((lineageRegion) => {
            return createData(lineageRegion);
        });

        const newOptions = {
            key: ["", ...new Set(lineageRegions[currentLineage].map(el => el.key))],
            label: ["", ...new Set(lineageRegions[currentLineage].map(el => el.label))],
        }
        setSearchOptions({ ...newOptions });
        setTableData(rows);
        const sorted = sortData([...rows], 'key', 'asc');
        setDisplayedTableData(sorted);
    }

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    }

    const handleCurrentLineageChange = (e) => { setCurrentLineage(e.target.value); };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e, type) => {
        setSearched({ ...searched, [type]: e });
    };

    const clearField = (type) => {
        setSearched({ ...searched, [type]: '' });
    }

    const filterafterSearchClicked = () => {
        let filteredRows = [...tableData];

        if (searched.key !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.key.toLowerCase().includes(searched.key.toLowerCase())
            });
        }

        if (searched.label !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.label.includes(searched.label)
            });
        }
        
        setDisplayedTableData(filteredRows);
        setPage(0);
    }

    const cancelSearch = () => {
        setSearched({ ...searchState });
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc'

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted)
    }

    return (
        <>
            <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                {lineageRegionsLength > 0 &&
                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id={'currentLineage'} label={'Lineage'} />
                        <CustomSelect
                            value={currentLineage}
                            defaultValue={''}
                            onChange={handleCurrentLineageChange}
                            className={classes.searchSelect}
                            inputProps={{
                                name: 'currentLineage',
                                id: 'currentLineage',
                            }}
                        >
                            <MenuItem key={''} value={''}>none</MenuItem>
                            {lineages.map((t) => (
                                <MenuItem key={t} value={t}>{t}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                }
                { currentLineage && <>
                    <div className={classes.searchcont}>
                        <Autocomplete
                            id="key"
                            options={searchOptions.key}
                            getOptionLabel={(option) => option}
                            value={searched.key}
                            className={classes.searchAuto}
                            onChange={(event, newValue) => requestSearch(newValue, 'key')}
                            inputValue={searched.key}
                            onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'key')}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    className={classes.search}
                                    label="Region key"
                                    InputProps={{
                                        style: { paddingRight: 0 },
                                        ...params.InputProps,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => clearField('key')}>
                                                    <HideIcon />
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            id="label"
                            options={searchOptions.label}
                            getOptionLabel={(option) => option}
                            value={searched.label}
                            className={classes.searchAuto}
                            onChange={(event, newValue) => requestSearch(newValue, 'label')}
                            inputValue={searched.label}
                            onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'label')}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    label="Label"
                                    className={classes.search}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { paddingRight: 0 },
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => clearField('label')}>
                                                    <HideIcon />
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                            )}
                        />
                    </div>
                    <Button className={classes.saveButton} onClick={filterafterSearchClicked}>
                        Search
                    </Button>
                    <Button className={classes.cancelButton} onClick={cancelSearch}>
                        Cancel
                    </Button>
                    <TableContainer >
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={orderBy.key !== 'none'}
                                            direction={orderBy.key !== 'none' ? orderBy.key : 'asc'}
                                            onClick={(e) => handleSort('key')}
                                        >
                                            Key
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={orderBy.label !== 'none'}
                                            direction={orderBy.label !== 'none' ? orderBy.label : 'asc'}
                                            onClick={(e) => handleSort('label')}
                                        >
                                            Label
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                            active={orderBy.color !== 'none'}
                                            direction={orderBy.color !== 'none' ? orderBy.color : 'asc'}
                                            onClick={(e) => handleSort('color')}
                                        >
                                            Color
                                        </TableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TableSortLabel
                                        >
                                            Actions
                                        </TableSortLabel>
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    (rowsPerPage > 0
                                        ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : displayedTableData
                                    ).map((row, index) => (
                                        <TableRow key={`${row.key}_${index}`} >
                                            <TableCell component="th" scope="row">
                                                {row.key}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.label}
                                            </TableCell>
                                            <TableCell align="left">
                                                <SymbolComponent color={row.color} shape={'square'} />
                                            </TableCell>
                                            <TableCell align="left">
                                                <DeleteLineageRegion
                                                    lineageRegion={row}
                                                    currentLineage={currentLineage}
                                                    setInfo={setInfo}
                                                    setInfoDialog={setInfoDialog}
                                                    setAction={setAction}
                                                    fetchLineageRegions={fetchLineageRegions}
                                                />
                                                <EditLineageRegion
                                                    lineageRegion={row}
                                                    currentLineage={currentLineage}
                                                    setInfo={setInfo}
                                                    setInfoDialog={setInfoDialog}
                                                    setAction={setAction}
                                                    fetchLineageRegions={fetchLineageRegions}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                <TableRow
                                    key='last'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <td>
                                        <AddLineageRegion
                                            currentLineage={currentLineage}
                                            setInfo={setInfo}
                                            setInfoDialog={setInfoDialog}
                                            setAction={setAction}
                                            fetchLineageRegions={fetchLineageRegions}
                                        />
                                    </td>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={displayedTableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            </Paper>
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </>
    );
};

const mapStateToProps = (state) => {
    const { lineageRegions } = state.settings;
    const { lineages } = state.lineages;

    return {
        status: lineageRegions.status,
        lineageRegions: lineageRegions.data,
        lineageRegionsLength: Object.keys(lineageRegions).length,
        lineages
    }
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchLineageRegions,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(RegionsTable);
