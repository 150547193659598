import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { styles, dialog } from '../styles';

const Alert = ({ handleCloseAlert, openAlert }) => {
    const classes = styles();
    const { open, type } = openAlert;
    return (
        <Dialog open={open} onClose={handleCloseAlert} sx={dialog}>
            <DialogTitle id="alert-dialog-title">
                Update settings
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    { type ?
                        'Lineage settings updated successfully.' :
                        'Updating settings failed.'
                    }
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className={`${classes.button} ${classes.buttonDialog}`}
                    onClick={handleCloseAlert}>Close</Button>
            </DialogActions>
        </Dialog>);
};

export default Alert;
