import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        position: 'absolute',
        width: '100%',
        zIndex: 10,
        overflow: 'auto'
    },
    welcome: {
        height: '100vh'
    }
});
