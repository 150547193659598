import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { fetchAxios } from '../../functions/axiosRequests';
import config from '../../config/envConfig';
import {
    FETCH_HUMAN_POOLS_REQUEST, FETCH_HUMAN_SEROLOGY_REQUEST,
} from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import { sessionTimeOut } from './helpers/functions';
import { fetchHumanPoolsError, fetchHumanPoolsSuccess, fetchHumanSerologyDataError, fetchHumanSerologyDataSuccess } from '../actions/humanSerologyActions';

export const fetchHumanPoolsEpic = action$ => action$.pipe(
    ofType(FETCH_HUMAN_POOLS_REQUEST),
    mergeMap(action => {
        const { lineage } = action.payload.lineage;
        const url = prepareUrl(`${config.serverLink}/api/humanSerology/humanPools`, { lineage });
        //`fetchHumanPools => url = ${url}`);
        return fetchAxios(url).then(function (response) {
            return fetchHumanPoolsSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408)
                return sessionTimeOut(error);

            return fetchHumanPoolsError({ error: data.error, errorWebsiteText: 'An error has occured when fecthing human pools.', errorWebsiteType: 'fetch'});
        });
    })
);

export const fetchHumanSerologyDataEpic = action$ => action$.pipe(
    ofType(FETCH_HUMAN_SEROLOGY_REQUEST),
    mergeMap(action => {
        //console.log(action.payload);
        const { lineage, humanPool, humanSerologyDataType, zoomNodeId, colorBy } = action.payload;

        const url = prepareUrl(`${config.serverLink}/api/tree/humanSerologyData`, { lineage, humanPool, humanSerologyDataType, zoomNodeId, colorBy });
       // console.log(`fetchHumanSerologyData, url = ${url}`);

        return fetchAxios(url).then(function (response) {
            return fetchHumanSerologyDataSuccess(response.data);
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error);

            return fetchHumanSerologyDataError({ error: data.error, errorWebsiteText: 'An error has occured when fetching human serology data.', errorWebsiteType: 'fetch'});
        });
    })
);
