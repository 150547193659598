import { cloneDeep, get, isNumber, uniq } from 'lodash';
import { createSelector, } from 'reselect';

import { stratify } from '../../functions/stratify';
import { emptyObject, treePostOrder, treePreOrder, isNull, minNumber, maxNumber, getTextMetrics } from '../../functions/functions';
import { getAminoMutDisplayVal, getAminoMutDisplayValShort } from '../../components/Tree/d3/displayValue';
import { getScaledValue } from '../../functions/scales';


// const logSelectorOutputChanges = (selector, name) => {
//     let lastOutput = null;
//     return (...args) => {
//         const result = selector(...args);
//         if (lastOutput !== result) {
//             console.log(`Output of ${name} has changed!`, {
//                 oldOutput: lastOutput,
//                 newOutput: result
//             }, isObject(result) ? `${lastOutput === null ? null : Object.keys(lastOutput || {}).length} / ${Object.keys(result).length}` : '',
//                 'deepEqual = ', isEqual(result, lastOutput));
//             // if (name === 'getMutationClassesData' && isObject(lastOutput)) console.log('diff', Object.keys(result)
//             //     .filter(id => !isEqual(result[id], lastOutput[id]))
//             //     .map(id => ({ id, now: result[id], before: lastOutput[id]}))
//             // );
//             lastOutput = result;
//         }
//         return result;
//     };
// };


const getClades = ({ cladeData }) => cladeData.clades;
const getActiveClades = ({ cladeData }) => cladeData.activeClades;
const getCladeBarType = ({ parameters }) => parameters.cladeBarType;

const getTreeAttrs = ({ treeData }) => treeData.treeAttrs;
const getAdditionalNodes = ({ treeData }) => treeData.additionalNodes;
const getStrainsLists = ({ treeData }) => treeData.strainsLists;
const getHumanSerologyData = ({ humanSerology }) => humanSerology.humanSerologyData;
const getCladeType = ({ parameters }) => parameters.cladeType;
const getNodeId = ({ nodeData }) => nodeData.nodeId;

export const getShowCladeLabels = ({ parameters }) => parameters.showCladeLabels;

const getMutationsGroup = ({ parameters }) => parameters.mutationsGroup;
const getMutationClassesVisibility = ({ parameters }) => parameters.visibleMutationClasses;
const getShowMutationsGroups = ({ parameters }) => parameters.showMutationsGroups;
const getMutationsThreshold = ({ parameters }) => parameters.mutationsThreshold;
const getMutationGroupValues = ({ genotype }) => genotype.mutationGroupValues;
const getReferenceStrainNodes = ({ treeData }) => treeData.referenceStrainNodes;

const getModelsIdMapping = ({ metadata }) => metadata.modelsConfig.idMapping;

//const treeAttrsSelector = ({ treeData }) => treeData.treeAttrs;

const getMergedAdditionalNodes = createSelector(getAdditionalNodes, additionalNodes => {
    const res = Object.keys(additionalNodes).reduce((acc, key) => ({ ...acc, ...additionalNodes[key] }), {});
    // console.log('[getMergedAdditionalNodes]', Object.keys(res).length);
    return res;
});

const treeAttrsSelector = createSelector([getTreeAttrs, getMergedAdditionalNodes, getReferenceStrainNodes], (treeAttrs, additionalNodes, referenceStrainNodes) => {
    // console.log('[treeAttrsSelector], additionalNodes: ', Object.keys(additionalNodes).length);
    if (!treeAttrs) return treeAttrs;
    return ({ ...treeAttrs, ...additionalNodes, ...referenceStrainNodes });
});

const getNodeClade = createSelector([getNodeId, getTreeAttrs, getCladeType, getClades], (nodeId, treeAttrs, cladeType, clades) => {
    const clade = treeAttrs[nodeId]?.clade || null;
    if (!clade) return clade;

    if (!clade)
        return clade;

    const cladeId = getCladeIdByType(clade, cladeType, clades)
    return cladeId;
})


// const getTreeOrderDict = ({ treeData }) => treeData.treeOrderDict;
const _getVisibleNodes = ({ treeData }) => treeData.visibleNodes;

export const getVisibleNodes = createSelector([_getVisibleNodes, getMergedAdditionalNodes, getReferenceStrainNodes], (visibleNodes, additionalNodes, referenceStrainNodes) => {
    const _additionalNodes = Object.keys(additionalNodes || {}).reduce((acc, id) => { acc[id] = 1; return acc }, {});
   
    const _referenceStrainNodes = Object.keys(referenceStrainNodes || {}).reduce((acc, id) => { acc[id] = 1; return acc }, {});
    const res = { ...visibleNodes, ..._additionalNodes, ..._referenceStrainNodes };
    // console.log('[getVisibleNodes]', Object.keys(_additionalNodes).length, Object.keys(res).length);
    return res;
});

const getTreeFreqs = ({ treeData }) => treeData.treeFreqs;
const getZoomNodeId = ({ parameters }) => parameters.zoomNodeId;
const _getGenotypeData = ({ genotype }) => genotype.genotypeData;
const getGenotypeDataStatus = ({ genotype }) => genotype.genotypeDataStatus;

const geneMutationSelector = createSelector([_getGenotypeData, getGenotypeDataStatus],
    (genotypeData, genotypeDataStatus) => genotypeDataStatus === 'loaded' ? genotypeData : null);;

const getColorBy = ({ parameters }) => parameters.colorBy;
const getMutationClasses = ({ metadata }) => metadata.mutationClasses;


const getTreeArr = createSelector([treeAttrsSelector, getVisibleNodes], (treeAttrs, visibleNodes) => {
    if (emptyObject(treeAttrs)) return [];
    // console.log('[getTreeArr]: visibleNodes: ', Object.keys(visibleNodes||{}).length, 'treeAttrs:',Object.keys(treeAttrs || {}).length);
    try {
        const treeArr = Object.keys(visibleNodes || {}).map((id) => {
            if (!treeAttrs[id]) console.log('[getTreeArr] ERROR', id);
            return { id: +id, p: treeAttrs[id].p };
        });
        // console.log('[getTreeArr]:', treeArr);
        return treeArr;
    } catch (error) {
        return [];
    }
});

const getAllTreeArr = createSelector([treeAttrsSelector], (treeAttrs) => {
    if (emptyObject(treeAttrs)) return [];
    try {
        const treeArr = Object.keys(treeAttrs || {}).map((id) => {
            // if (!treeAttrs[id]) console.log('[getTreeArr] ERROR', id);
            return { id: +id, p: treeAttrs[id].p };
        });
        return treeArr;
    } catch (error) {
        return [];
    }
});

const getTreeFromTreeArray = createSelector([getTreeArr, getZoomNodeId], (treeArr, zoomNodeId) => {
    try {
        const tree = stratify(treeArr, zoomNodeId);
        // cs
        return tree;
    } catch (error) {
        console.log('err', error);
        return {};
    }
});

const getCladeByType = (cladeId, cladeType, clades) => {
    let clade = clades[cladeId];
    if (cladeType !== 'clade') {
        clade = clades[cladeId]?.cladeMapping?.[cladeType];
    }
    return clade;
}

const getCladeIdByType = (id, cladeType, clades) => {
    const clade = getCladeByType(id, cladeType, clades);
    return clade?.alpha || clade?.id;
}

const getTreeDataStatus = ({ treeData }) => treeData.treeDataStatus;
const getCladesStatus = ({ cladeData }) => cladeData.cladesStatus;

const getVisibleTreeClades = createSelector(
    [getClades, treeAttrsSelector, getVisibleNodes, getTreeDataStatus, getCladeType, getCladesStatus],
    (clades, treeAttrs, visibleNodes, treeDataStatus, cladeType, cladesStatus) => {
        if (treeDataStatus !== 'loaded' || cladesStatus !== 'loaded') {
            return null;
        }

        const treeClades = Object.keys(visibleNodes).reduce((_treeClades, id) => {
            const clade = getCladeIdByType(treeAttrs[id].clade, cladeType, clades);
            if (clade && !_treeClades[clade]) {
                _treeClades[clade] = clades[clade];
            }
            return _treeClades;
        }, {});
        return treeClades;
    },
);

const getCladesOfType = createSelector([getClades, getCladeType, getCladesStatus],
    (clades, cladeType, cladesStatus) => {
        if (cladesStatus !== 'loaded') {
            return null;
        }

        const allClades = Object.keys(clades).reduce((_allClades, id) => {
            const clade = getCladeIdByType(id, cladeType, clades);
            console.log(id, clade, cladeType);
            if (clade && !_allClades[clade]) {
                _allClades[clade] = clades[clade];
            }
            return _allClades;
        }, {});
        console.log('[getCladesOfType] allClades = ', Object.keys(allClades).length, cladeType)
        return allClades;
    },
);

const getGene = ({ parameters }) => parameters.gene;
const getHla = ({ parameters }) => parameters.hla;
const getTcellAntigenicityScores = ({ customTreeData }) => customTreeData.tcellAntigenicityScores;
const getTcellAntigenicityScoresBins = ({ customTreeData }) => customTreeData.tcellAntigenicityScoresBins;

const tcellAntigenicityScoresSelector = createSelector([getGene, getHla, getTcellAntigenicityScores], (gene, hla, tcellAntigenicityScores) => {
    const data = get(tcellAntigenicityScores, `${gene}_${hla}`) || null;
    return data;
});

const tcellAntigenicityScoresBinsSelector = createSelector([getGene, getHla, getTcellAntigenicityScoresBins], (gene, hla, tcellAntigenicityScoresBins) => {
    return tcellAntigenicityScoresBins
        ? tcellAntigenicityScoresBins[`${gene}_${hla}`]
        : {};
});

const getCustomTreeAttrs = ({ customTreeData }) => customTreeData.customTreeAttrs;

const getTcellAntigenicityStatus = ({ parameters, customTreeData }) => {
    const { gene, hla } = parameters;
    return customTreeData.tcellStatus ? customTreeData.tcellStatus[`${gene}_${hla}`] : null;
};

const getModelData = ({ modelData }) => modelData.model;

const getCustomTreeAttrsById = createSelector(getCustomTreeAttrs, (customTreeAttrs) => {
    if (emptyObject(customTreeAttrs)) return null;
    return Object.keys(customTreeAttrs).reduce((acc, attr) => {
        //console.log(`[getCustomTreeAttrsById], attr = ${attr}`, customTreeAttrs[attr]);
        Object.keys(customTreeAttrs[attr]).forEach((id) => {
            if (!acc[id]) acc[id] = {};
            acc[id][attr] = customTreeAttrs[attr][id];
        });
        return acc;
    }, {});
});


const isEmptyModel = (model) => Object.keys(model || {}).every(attr => emptyObject(model[attr]));

const getModelDataById = createSelector([getModelData, getModelsIdMapping, treeAttrsSelector, getClades], (model, idMapping, treeAttrs, clades) => {
    if (isEmptyModel(model)) return null;
    // console.log(`MMMMMM [getModelDataById], 
    // clades = ${Object.keys(clades || {}).length} 
    // treeAttrs = ${Object.keys(treeAttrs || {}).length}
    // model = ${Object.keys(model || {}).length}
    // idMapping = ${Object.keys(idMapping || {}).length}`);
    try {
        const res = Object.keys(model).reduce((acc, attr) => {
            const attrVar = idMapping[attr].attr;
            const cladeType = (attrVar === 'clade') ? idMapping[attr].cladeType : null;

            Object.keys(treeAttrs).forEach((id) => {
                let valueId = attrVar === 'id' ? id : treeAttrs[id][attrVar];
                if (attrVar === 'clade')
                    valueId = getCladeIdByType(valueId, cladeType, clades)
                if (!acc[id]) acc[id] = {};
                acc[id][attr] = model[attr][valueId];
            });
            return acc;
        }, {});
        return res;
    }
    catch (e) {
        console.log(e);
    }
});

// const getRootNodeId = ({ treeData }) =>
//     (treeData.zoomNodeStack || []).length > 0 ? treeData.zoomNodeStack[0] : undefined;

export const getGenotypeData = createSelector(
    [getAllTreeArr, geneMutationSelector],
    (treeArr, genotypeData) => {
        if (!genotypeData) return null;

        const tree = stratify(treeArr); //, rootNodeId);
        if (!tree) return null;
        const geneMutationValues = {};
        // console.log(tree);
        treePreOrder(tree, (node, parent) => {
            geneMutationValues[node.id] = !parent
                ? genotypeData[-1]
                : genotypeData[node.id] || (parent && geneMutationValues[parent.id]);
        });
        return geneMutationValues;
    },
);


const treeOrderDictMinMaxSelector = createSelector(
    [getTreeFromTreeArray, treeAttrsSelector, getTreeFreqs],
    (tree, treeAttrs, treeFreqs) => {
        let order = 1;
        const treeOrderDict = {};
        const minMaxDict = {};
        if (!tree) return { treeOrderDict, minMaxDict };

        const sortTreeByFrequency = (treeFreqs, tree, treeAttrs) => {
            const freqTree = treePostOrder(tree, (node) => {

                if (node.children) {
                    const getNodeMaxTime = (n) => (n.maxTime || treeAttrs[n.id] ? treeAttrs[n.id].maxTime : null);
                    node.children.sort(
                        (c1, c2) =>
                            Math.round(((treeFreqs[c1.id] || 0) - (treeFreqs[c2.id] || 0)) * 1000) / 1000 ||
                            getNodeMaxTime(c1) - getNodeMaxTime(c2) ||
                            c1.id - c2.id,
                    );
                }

            });
            return freqTree;
        };

        const _tree = cloneDeep(tree);
        sortTreeByFrequency(treeFreqs, _tree, treeAttrs);


        const getTreeOrder = (_tree /*, falseLeafFilter*/) => {
            treePostOrder(_tree, (node) => {
                if (!node.children || node.children.length === 0) {
                    treeOrderDict[node.id] = order;
                    minMaxDict[node.id] = { minOrder: order, maxOrder: order };
                    order++;
                } else {
                    const nodeMinMaxOrder = (node.children || []).filter(node => !node.falseLeaf)
                        .reduce(
                            ({ minOrder, maxOrder, minChild, maxChild }, child) => ({
                                minOrder: minNumber(minMaxDict[child.id].minOrder, minOrder),
                                maxOrder: maxNumber(minMaxDict[child.id].maxOrder, maxOrder),
                                minChild: minNumber(treeOrderDict[child.id], minChild),
                                maxChild: maxNumber(treeOrderDict[child.id], maxChild)
                            }),
                            {
                                minOrder: null,
                                maxOrder: null,
                                minChild: null,
                                maxChild: null,
                            },
                        );

                    treeOrderDict[node.id] = (nodeMinMaxOrder.minChild + nodeMinMaxOrder.maxChild) / 2;

                    minMaxDict[node.id] = {
                        minOrder: nodeMinMaxOrder.minOrder,
                        maxOrder: nodeMinMaxOrder.maxOrder,
                    };
                }
            });

            // remove false leafs
            return Object.keys(treeOrderDict).filter(k => k > 0).reduce((acc, id) => { acc[id] = treeOrderDict[id]; return acc; }, {});
        };
        getTreeOrder(_tree);
        return { treeOrderDict, minMaxDict };
    }
);

const treeOrderDictSelector = createSelector(treeOrderDictMinMaxSelector, (treeOrderDictMinMax) => {
    return treeOrderDictMinMax.treeOrderDict
});


const getCladeBarData = createSelector([treeOrderDictSelector, treeAttrsSelector, getClades, getCladeBarType],
    (treeOrderDict, treeAttrs, clades, cladeBarType) => {
        //console.log('[getCladeBarData]', clades, cladeBarType);
        // console.log({treeOrderDict})
        try {
            if (emptyObject(treeAttrs) || emptyObject(treeOrderDict) || emptyObject(clades)) return;

            const sortedDict = Object.keys(treeOrderDict)
                .filter((id) => treeAttrs[id].name)
                .sort(function (a, b) {
                    return treeOrderDict[a] - treeOrderDict[b];
                });
            // console.log('[getCladeBarData] sortedDict', sortedDict);
            const { minOrder, maxOrder } = Object.values(treeOrderDict).reduce((
                { minOrder, maxOrder }, v) => ({
                    minOrder: Math.min(v, minOrder || v),
                    maxOrder: Math.max(v, maxOrder || v)
                }), {});
            // console.log(`[getCladeBarData] minOrder = ${minOrder}, maxOrder = ${maxOrder}`)

            let cladeBarData = [];
            let prevId = 0;

            const getNodeClade = (id) => {
                const cladeId = treeAttrs[id].clade;
                const clade = getCladeIdByType(cladeId, cladeBarType, clades);
                //const clade = (cladeBarType === 'clade' || !clades[cladeId]?.cladeMapping)
                //  ? cladeId
                //: clades[cladeId]?.cladeMapping[cladeBarType].alpha;
                return clade;
            }
            let currentCladeData = {
                startOrder: treeOrderDict[sortedDict[0]],
                clade: getNodeClade(sortedDict[0]),
                endOrder: 0,
            };
            // console.log('[getCladeBarData] currentCladeData', currentCladeData);
            sortedDict.forEach((id) => {
                if (currentCladeData.clade !== getNodeClade(id)) {
                    currentCladeData.endOrder = treeOrderDict[prevId];
                    cladeBarData.push({ ...currentCladeData });

                    currentCladeData.startOrder = treeOrderDict[id];
                    currentCladeData.clade = getNodeClade(id);
                }
                if (id === sortedDict[sortedDict.length - 1]) {
                    currentCladeData.endOrder = treeOrderDict[id];
                    cladeBarData.push({ ...currentCladeData });
                }

                prevId = id;
            });
            //console.log(`[getCladeBarData] cladeBarData = `, cladeBarData);
            const cladesLengths = {};
            for (const elem of cladeBarData) {
                const len = elem.endOrder - elem.startOrder;

                if (!cladesLengths[elem.clade]) {
                    cladesLengths[elem.clade] = { len, aliged: false };
                } else if (cladesLengths[elem.clade].len < len) {
                    cladesLengths[elem.clade].len = len;
                }
            }

            cladeBarData.forEach(elem => {
                const len = elem.endOrder - elem.startOrder;
                const maxLength = cladesLengths[elem.clade];

                if (len === maxLength.len && !maxLength.alinged)
                    elem.showLabel = true;
                elem.label = clades[elem.clade]?.label || '';
                const { width, height } = getTextMetrics(elem.label, `12px 'Inter', 'Verdana'`);
                elem.width = width;
                elem.height = height;
                //console.log(elem, clades[elem.clade].label, getTextMetrics(clades[elem.clade].label,  `12px 'Inter', 'Verdana'`));
            });

            //adjust end dates - after giving space for clade labels
            cladeBarData.forEach((v, i, arr) => {
                if (i === 0) cladeBarData[i].startOrder = minOrder;
                if (i === arr.length - 1) cladeBarData[i].endOrder = maxOrder;
                if (i < arr.length - 1) cladeBarData[i].endOrder = arr[i + 1].startOrder - 1;
            })
            //console.log('[getCladeBarData] cladeBarData', cladeBarData);


            return cladeBarData;
        } catch (e) {
            console.error(e);
            return [];
        }
    });


const getMutationsClasses = createSelector([getMutationsGroup, getMutationClasses],
    (mutationsGroup, mutationClasses) => {
        if (!mutationsGroup || !mutationClasses[mutationsGroup]) return null;
        return mutationClasses[mutationsGroup];
    });



// const loggedGetTreeFromTreeArray = logSelectorOutputChanges(getTreeFromTreeArray, "getTreeFromTreeArray");
// const loggedGetMutationsClasses = logSelectorOutputChanges(getMutationsClasses, "getMutationsClasses");

// const loggedTreeAttrsSelector = logSelectorOutputChanges(treeAttrsSelector, "treeAttrsSelector");
// const loggedTreeOrderDictSelector = logSelectorOutputChanges(treeOrderDictSelector, "treeOrderDictSelector");
// const loggedGetTreeFreqs = logSelectorOutputChanges(getTreeFreqs, "getTreeFreqs");
// const loggedTcellAntigenicityScoresSelector = logSelectorOutputChanges(tcellAntigenicityScoresSelector, "tcellAntigenicityScoresSelector");
// const loggedGetCustomTreeAttrsById = logSelectorOutputChanges(getCustomTreeAttrsById, "getCustomTreeAttrsById");
// const loggedGetModelDataById = logSelectorOutputChanges(getModelDataById, "getModelDataById");
// const loggedGetGenotypeData = logSelectorOutputChanges(getGenotypeData, "getGenotypeData");
// const loggedGetColorBy = logSelectorOutputChanges(getColorBy, "getColorBy");
// const loggedGetHumanSerologyData = logSelectorOutputChanges(getHumanSerologyData, "getHumanSerologyData");

// const loggedGetClades = logSelectorOutputChanges(getClades, "getClades");
// const loggedGetCladeType = logSelectorOutputChanges(getCladeType, "getCladeType");
// const loggedGetActiveClades = logSelectorOutputChanges(getActiveClades, "getActiveClades");
// const loggedGetCladesStatus = logSelectorOutputChanges(getCladesStatus, "getCladesStatus");

// const loggedGetMutationsGroup = logSelectorOutputChanges(getMutationsGroup, "getMutationsGroup");

// const loggedGetMutationClassesVisibility = logSelectorOutputChanges(getMutationClassesVisibility, "getMutationClassesVisibility");
// const loggedGetMutationsThreshold = logSelectorOutputChanges(getMutationsThreshold, "getMutationsThreshold");
// const loggedGetMutationGroupValues = logSelectorOutputChanges(getMutationGroupValues, "getMutationGroupValues");

const getMutationClassesData = createSelector(
    [
        getMutationsClasses,
        getMutationsGroup,
        getTreeFromTreeArray,
        treeAttrsSelector,
        getMutationClassesVisibility,
        getMutationsThreshold,
        getMutationGroupValues,
        getClades,
        getCladeType,
    ],
    (
        mutClasses,
        mutationsGroup,
        tree,
        treeAttrs,
        visibleMutationClasses,
        mutationsThreshold,
        mutationGroupValues,
        //   clades,
        //  cladeType,
    ) => {
        if (emptyObject(mutClasses) || emptyObject(mutationsGroup) || emptyObject(mutationGroupValues) || !treeAttrs || !tree || emptyObject(tree) /*|| emptyObject(clades)*/) return null;
        const mutationsGroups = {};
        //console.log('[getMutationClassesData] mutClasses:', mutClasses);
        //if (!mutClasses || !mutationGroupValues /*|| !showMutationsGroups || !mutationsGroup || !mutationClasses ||*/ || !treeAttrs || !tree || emptyObject(tree))
        //  return mutationsGroups;

        //const mutClasses = mutationClasses[mutationsGroup];
        //console.log(mutClasses);

        treePreOrder(tree, (node) => {
            const mutNotExist = !mutationGroupValues[node.id];
            if (mutNotExist || ((treeAttrs[node.id].tn || 1) < mutationsThreshold)) return;
            const mutations = mutationGroupValues[node.id];
            const aminoacidMutations = mutations.filter((m) => m.length === 3);
            if (aminoacidMutations && aminoacidMutations.length > 0) {
                const aminoacidMutations = mutations.filter((m) => m.length === 3);
                const nodeMutClasses = Object.keys(mutClasses).reduce((accMc, mc) => {
                    // find matching mutations for mutation class mc.
                    const matchingAminoacidMuts = aminoacidMutations.reduce((acc, m) => {
                        const gene = m[1];
                        const aMut = m[2]; //in fact it's and one element array...
                        const pos = Object.keys(aMut)[0];
                        if (mutClasses[mc].positions[gene] && mutClasses[mc].positions[gene][pos])
                            acc.push(getAminoMutDisplayVal(m));
                        return acc;
                    }, []);
                    if (matchingAminoacidMuts.length > 0) accMc[mc] = uniq(matchingAminoacidMuts); //.join(',');
                    return accMc;
                }, {});
                //console.log('dict', node.id, nodeMutClasses);

                if (Object.keys(nodeMutClasses).length > 0)
                    mutationsGroups[node.id] = Object.entries(nodeMutClasses)
                        .filter(([mutClass]) => {
                            return visibleMutationClasses?.[mutationsGroup]?.[mutClass];
                        })
                        .map(([mutClass, mutations]) => {
                            const cladeId = treeAttrs[node.id].clade;
                            //const cladeSchemaStyle = schemaStyles.has(mutClasses[mutClass].cladeSchema) ? mutClasses[mutClass].cladeSchema : 'none';
                            //const cladeSchema = cladeSchemaStyle && cladeSchemaStyle !== 'none' && clades[cladeId];
                            // console.log(cladeId, clades[cladeId], clades)
                            //const clade = cladeSchema
                            //  ? (cladeType === 'clade' ? cladeId : clades[cladeId].cladeMapping[cladeType].alpha)
                            // : undefined;
                            //console.log(mutClass, mutClasses[mutClass].cladeSchema )
                            return { mutClass, mutations, cladeId, /*clade,*/ cladeSchema: mutClasses[mutClass].cladeSchema };
                        });

            }
        });

        return mutationsGroups;
    },
);



// const loggedGetMutationClassesData = logSelectorOutputChanges(getMutationClassesData, "getMutationClassesData");

export const getMutationClassesDataWithClades = createSelector([getMutationClassesData, getClades, getCladeType], (mutationsGroups, clades, cladeType) => {
    if (emptyObject(mutationsGroups) || !clades || !cladeType) return null;
    //console.log('[getMutationClassesDataWithClades]', mutationsGroups);
    const schemaStyles = new Set(['normal', 'bold', 'italic', 'none']);
    const res = Object.keys(mutationsGroups).reduce((acc, id) => {
        const mutations = mutationsGroups[id].map(mg => {
            const { cladeId, cladeSchema } = mg;
            const cladeSchemaStyle = schemaStyles.has(cladeSchema) ? cladeSchema : 'none';
            const inCladeSchema = cladeSchemaStyle && cladeSchemaStyle !== 'none' && clades[cladeId];
            // console.log(cladeId, clades[cladeId], clades)
            const clade = inCladeSchema
                ? (cladeType === 'clade' ? cladeId : clades[cladeId].cladeMapping[cladeType].alpha)
                : undefined;
            return { ...mg, clade };
        });
        acc[id] = mutations;
        return acc;
    }, {});
    return res;
})




const getDivergence = createSelector(
    [getTreeFromTreeArray, treeAttrsSelector, getMutationsClasses, getMutationClassesData],

    (tree, treeAttrs, mutationsClasses, mutationsGroups) => {

        //console.log('[getDivergence] mutClasses =',mutationsClasses, 'mutationsGroups',mutationsGroups);
        // if (emptyObject(mutationsClasses) || emptyObject(mutationsGroups)) return null;
        const mutClasses = Object.keys(mutationsClasses || {});
        // console.log('[mutationsGroups]', mutationsGroups);
        const mutDivergenceRoot = mutClasses.reduce((acc, m) => {
            acc[`${m}_divergence`] = 0;
            return acc;
        }, {});
        const divergenceDict = {};
        if (!tree) return divergenceDict;
        treePreOrder(tree, (node, parent) => {
            // if (!node) if (!treeAttrs[node.id]) console.log(node, parent, treeAttrs[node.id]);
            if (!parent) {
                divergenceDict[node.id] = {
                    AADivergence: treeAttrs[node.id]?.NS || 0,
                    NLDivergence: treeAttrs[node.id]?.NCL || 0,
                    ...mutDivergenceRoot
                    //divergence: (treeAttrs[node.id].NS || 0) + (treeAttrs[node.id].S || 0)
                };
            } else {
                const mutNode = mutationsGroups ? (mutationsGroups[node.id] || []).reduce((acc, m) => { acc[m.mutClass] = m.mutations.length; return acc }, {}) : {};
                const mutationsDivergences = mutClasses.reduce((acc, m) => {
                    acc[`${m}_divergence`] = (mutNode[m] || 0) + divergenceDict[parent.id]?.[`${m}_divergence`];
                    return acc;
                }, {})
                divergenceDict[node.id] = {
                    AADivergence: (treeAttrs[node.id]?.NS || 0) + divergenceDict[parent.id]?.AADivergence,
                    NLDivergence: (treeAttrs[node.id]?.NCL || 0) + divergenceDict[parent.id]?.NLDivergence,
                    ...mutationsDivergences
                    //divergence: (treeAttrs[node.id].NS || 0) + (treeAttrs[node.id].S || 0) + divergenceDict[parent.id].divergence
                };
            }
        });
        // console.log(divergenceDict);
        return divergenceDict;
    });





// const loggedGetDivergence = logSelectorOutputChanges(getDivergence, "getDivergence");



/**
 * Returns a dictionary of all properties merged from different dictionaries for displayed tree nodes
 * @param {Object} treeAttrs dictionary with standard measure values
 * @param {Object} treeOrderDict - dictionary with node order values
 * @param {Object} treeFreqs - dictionary with node frequencies
 * @param {Object} tcellAntigenicityScores - dictionary with tcellAntigenicityScores
 * @param {Object} customTreeAttrs - dictionary with custom measure values
 * @param {Object} model - dictionary with model related data values
 * @param {Object} genotypeData - dictionary with genotypeData
 * @returns {Object} merged dictionary will all values above, structure { [id]: { pmeasure]: value } }
 */
const getTreeNodeAttrs = createSelector(
    // [
    //     loggedTreeAttrsSelector,
    //     loggedTreeOrderDictSelector,
    //     loggedGetTreeFreqs,
    //     loggedTcellAntigenicityScoresSelector,
    //     loggedGetCustomTreeAttrsById,
    //     loggedGetModelDataById,
    //     loggedGetGenotypeData,
    //     loggedGetColorBy,
    //     loggedGetDivergence,
    //     loggedGetHumanSerologyData
    // ],
    [
        treeAttrsSelector,
        treeOrderDictSelector,
        getTreeFreqs,
        tcellAntigenicityScoresSelector,
        getCustomTreeAttrsById,
        getModelDataById,
        getGenotypeData,
        getColorBy,
        getDivergence,
        getHumanSerologyData
    ],
    (
        treeAttrs,
        treeOrderDict,
        treeFreqs,
        tcellAntigenicity,
        _customTreeAttrs,
        _model,
        genotypeData,
        colorBy,
        _divergence,
        humanSerologyData
    ) => {
        //create treeAttrs dict only for displayed nodes
        try {
            const model = _model || {};
            const customTreeAttrs = _customTreeAttrs || {};
            const divergence = _divergence || {};
            // console.log(divergence)
            const treeNodeAttrs = Object.keys(treeOrderDict).reduce((acc, id) => {
                acc[id] = {
                    ...treeAttrs[id],
                    ...customTreeAttrs[id],
                    ...model[id],
                    order: treeOrderDict[id],
                    ...divergence[id],
                    humanSerology: humanSerologyData && humanSerologyData[id]
                };
                if (tcellAntigenicity && tcellAntigenicity[id]) acc[id].tcellAntigenicity = tcellAntigenicity[id];
                if (treeFreqs[id]) acc[id].frequency = treeFreqs[id];
                if (colorBy === 'genotype' && genotypeData && genotypeData[id]) acc[id].genotype = genotypeData[id];
                return acc;
            }, {});
            // console.log(`treeNodeAttrs, ${Object.keys(treeNodeAttrs).length}`);
            return treeNodeAttrs;
        }
        catch (e) {
            console.log(e); return null;
        }
    },
);


export const getDisplayedStrainNames = createSelector([getTreeNodeAttrs, getZoomNodeId], (treeAttrs, zoomNodeId) => {
    if (emptyObject(treeAttrs) || !zoomNodeId) return null;
    const { maxTime } = treeAttrs[zoomNodeId];
    const minTime = treeAttrs[zoomNodeId].time;
    const strains = Object.entries(treeAttrs).filter(([_, { name }]) => name);

    if (strains.length > 40) return null;
    const labels = strains
        .map(([id, { name, time }]) => ({
            id: +id,
            name: name.split('_')[0],
            minTime,
            time,
            maxTime,
            p: (time - minTime) / (maxTime - minTime),
            l: getTextMetrics(name.split('_')[0], `10px 'Inter', 'Verdana'`).width
        }));

    // console.log(labels);
    return labels;
});

const getActiveLegendOption = ({ nodeData }) => nodeData.activeLegendOption;

export const getLegendSelectedNodes = createSelector(
    [getTreeNodeAttrs, getColorBy, getActiveLegendOption, getCladeType, getClades],
    (treeAttrs, colorBy, activeLegendOption, cladeType, clades) => {
        if (
            !treeAttrs ||
            !activeLegendOption ||
            isNull(activeLegendOption.value) ||
            activeLegendOption.value.length === 0
        )
            return null;
        const scaleName = `${colorBy}ValueScale`;

        const value = Number.isNaN(+activeLegendOption.value) ? activeLegendOption.value : +activeLegendOption.value;


        const selectedNodes = Object.entries(treeAttrs)
            .filter(([, val]) => {
                const res = val.name
                    && (
                        (colorBy === 'clade')
                            ? getCladeIdByType(val[colorBy], cladeType, clades) == value
                            : (value === getScaledValue(scaleName, val[colorBy])
                            )
                    );
                return res;
            })
            .map(([id]) => id);
        // console.log(cladeType, colorBy, clades, selectedNodes);

        return selectedNodes;
    },
);



//   const loggedGetModelData = logSelectorOutputChanges(getModelData, "getModelData");
//   const loggedGetModelsIdMapping = logSelectorOutputChanges(getModelsIdMapping, "getModelsIdMapping");
//   const loggedTreeAttrsSelector = logSelectorOutputChanges(treeAttrsSelector, "treeAttrsSelector");
//   const loggedGetClades = logSelectorOutputChanges(getClades, "getClades");

// const loggedGetTreeFromTreeArray = logSelectorOutputChanges(getTreeFromTreeArray, "getTreeFromTreeArray");
// const loggedGetTreeNodeAttrs = logSelectorOutputChanges(getTreeNodeAttrs, "getTreeNodeAttrs");
// const loggedGetClades = logSelectorOutputChanges(getClades, "getClades");
// const loggedGetCladeType = logSelectorOutputChanges(getCladeType, "getCladeType");
// const loggedGetActiveClades = logSelectorOutputChanges(getActiveClades, "getActiveClades");
// const loggedGetCladesStatus = logSelectorOutputChanges(getCladesStatus, "getCladesStatus");

const getCladeSchema = createSelector(
    [getTreeFromTreeArray, getTreeNodeAttrs, getClades, getCladeType, getActiveClades, getCladesStatus, getZoomNodeId],
    (tree, treeAttrs, clades, cladeType, activeClades, cladesStatus, zoomNodeId) => {
        try {
            if (!tree || !treeAttrs || !clades || cladesStatus !== 'loaded') return null;
            const cladeTreeDict = {};
            const cladeRootNodes = {};
            const getNodeCladeByType = (node) => {
                if (!node) return null;
                const nodeId = isNumber(node) ? node : node.id;
                const cladeId = node && treeAttrs[nodeId].clade;
                return cladeId && getCladeIdByType(cladeId, cladeType, clades);
            };
            const getOrder = (node) => node && treeAttrs[node.id].order;
            const getTime = (node) => node && treeAttrs[node.id].time;

            if (emptyObject(tree) || cladesStatus !== 'loaded') return {};

            // console.log(`
            // tree = ${tree?.id}, 
            // treeAttrs = ${Object.keys(treeAttrs ||{}).length}, 
            // clades = ${Object.keys(clades ||{}).length}, 
            // cladeType = ${cladeType}, 
            // activeClades = ${Object.keys(activeClades ||{}).length},
            // cladesStatus = ${cladesStatus}`);

            const endCladeTimes = {};

            // Initialize clades dict  [cladeId][rootNodeId] - a new entry is added when parent node clade differs from node clade.
            treePreOrder(tree, (node, parent) => {

                try {

                    const clade = getNodeCladeByType(node);
                    const parentClade = getNodeCladeByType(parent);
                    // console.log(node?.id, clade);
                    const label = clades[clade]?.label;
                    if (parentClade !== clade) {
                        cladeRootNodes[node.id] = node.id;
                        if (!cladeTreeDict[clade]) cladeTreeDict[clade] = {};
                        cladeTreeDict[clade][node.id] = {
                            id: clade,
                            label,
                            p: getNodeCladeByType(parent),
                            rootOrder: getOrder(node),
                            leaves: node.children && node.children.length > 0 ? 0 : 1,
                            frequency: treeAttrs[node.id].frequency || 0,
                            closed: !activeClades[node.id],
                            nodeId: node.id
                        };
                        //console.log(treeAttrs[node.id]);
                        if (!endCladeTimes[clade]) endCladeTimes[clade] = getTime(node);
                        if (parentClade) endCladeTimes[parentClade] = Math.max(endCladeTimes[parentClade], getTime(node));
                    } else {
                        cladeRootNodes[node.id] = cladeRootNodes[parent.id];
                        const rootId = cladeRootNodes[node.id];
                        // cladeTreeDict[clade][rootId].endTime = Math.max(getTime(node), cladeTreeDict[clade][rootId].endTime);
                        if (!node.children || node.children.length === 0) {
                            cladeTreeDict[clade][rootId].leaves += 1;
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                }
            });

            // Select clade representative - a section with largest number of leaves
            const cladeDict = Object.keys(cladeTreeDict).reduce((acc, clade) => {
                acc[clade] = Object.values(cladeTreeDict[clade]).reduce((accClade, c) => {
                    const res = !accClade || c.leaves > accClade.leaves ? c : accClade;
                    res.closed = (accClade ? accClade.closed : true) && c.closed;
                    res.frequency = c.frequency + (accClade?.frequency || 0);
                    return res;
                }, null);
                return acc;
            }, {});

            // add rootPaths to tree Nodes

            Object.keys(cladeDict).forEach(cladeId => {
                const clade = cladeDict[cladeId];
                let parentTreeNodePath = [clade.nodeId];
                let parentId = treeAttrs[clade.nodeId]?.p;

                //let parentId = getCladeIdByType(_parentId, cladeType, clades);
                while (parentId && clade.p && parentId !== cladeDict[clade.p].nodeId) {
                    parentTreeNodePath.push(parentId);
                    parentId = treeAttrs[parentId]?.p;

                }
                clade.parentTreeNodePath = parentTreeNodePath;
            })
            const cladeTree = stratify(Object.values(cladeDict), getNodeCladeByType(zoomNodeId));
            let maxTime = 0;

            treePostOrder(cladeTree, (node, parent) => {

                const cladeStartTime = (n) => {//n.startTime || 
                    //console.log('cladeStartTime', n.id, clades[n.id],  clades[n.id].ss);
                    return clades[n.id].ss;
                }

                if (node.children && node.children.length === 0) delete node.children;
                const maxChildStartTime = node.children
                    ? node.children.reduce((a, b) => Math.max(a, cladeStartTime(b)), 0)
                    : 0;
                const minChildStartTime = node.children
                    ? node.children.reduce((a, b) => Math.min(a, cladeStartTime(b)), Number.MAX_VALUE)
                    : Number.MAX_VALUE;

                node.branchNodesCount = node.children ? node.children.reduce((a, b) => a + b.branchNodesCount, 0) + 1 : 1;

                node.startTime = Math.min(minChildStartTime, clades[node.id]?.ss);
                node.correctedStartTime = Math.min(minChildStartTime, clades[node.id]?.ss);
                node.endTime = Math.max(maxChildStartTime, clades[node.id]?.se);

                node.maxTreeEndTime = node.children
                    ? node.children.reduce((a, b) => Math.max(a, b.endTime), 0)
                    : node.endTime;

                //console.log('clade =', node.id, 'parentId = ',  parent?.id, clades[node.id].ss, clades[node.id], minChildStartTime, maxChildStartTime, '<',node.startTime, node.endTime, node.maxTreeEndTime,'>');
                //node.label = clades[node.id].label;
                const parentLabel = parent && parent.id && clades[parent.id] ? clades[parent.id].label : '';
                // eslint-disable-next-line no-useless-escape
                node.relativeLabel = clades[node.id]?.label.replace(parentLabel, '').replace(/^[\.,\/]/, '');
                if (node.endTime > maxTime) maxTime = node.endTime;
                // if (node.children) {
                //     node.children.sort(
                //         (c1, c2) => c1.startTime - c2.startTime || c1.maxTreeEndTime - c2.maxTreeEndTime || c1.id - c2.id,
                //     );
                // }
                node.exclFrequency = node.frequency - (node.children || []).reduce((acc, c) => acc + c.frequency, 0);
                node.closed = node.exclFrequency === 0;
            });

            const orderTree = (node, actOrder) => {
                const childrenAbove = (node.children || [])
                    .filter((child) => child.rootOrder < node.rootOrder)
                    .sort(
                        (c1, c2) => c1.correctedStartTime - c2.correctedStartTime || c1.maxTreeEndTime - c2.maxTreeEndTime || c1.id - c2.id,
                    );
                const childrenBelow = (node.children || [])
                    .filter((child) => child.rootOrder >= node.rootOrder)
                    .sort(
                        (c1, c2) => c2.correctedStartTime - c1.correctedStartTime || c1.maxTreeEndTime - c2.maxTreeEndTime || c1.id - c2.id,
                    );
                let _actOrder = actOrder;
                childrenAbove.forEach((child) => {
                    orderTree(child, _actOrder);
                    _actOrder += child.branchNodesCount;
                });
                node.order = _actOrder;
                _actOrder += 1;
                childrenBelow.forEach((child) => {
                    orderTree(child, _actOrder);
                    _actOrder += child.branchNodesCount;
                });
            };


            orderTree(cladeTree, 1);
            // console.log('sortedCladeTree', cladeTree);

            if (cladeTree.children) {
                const childLength = cladeTree.children.length;
                const maxTimeDiff = parseInt((maxTime - cladeTree.correctedStartTime) * 0.1);
                const rootDiff = cladeTree.children[childLength - 1].correctedStartTime - cladeTree.correctedStartTime;
                if (rootDiff > maxTimeDiff) cladeTree.correctedStartTime = cladeTree.correctedStartTime + rootDiff - maxTimeDiff;
                //console.log('cladeId', cladeTree.id, 'maxTimeDiff =',maxTimeDiff, 'rootDiff =',rootDiff)
            }
            //console.log(cladeTree);

            return cladeTree;
        } catch (e) {
            console.log(e);
            return null
        }
    }

);


export const getMutationClassesForCladeSchema = createSelector([getShowMutationsGroups, getMutationClassesDataWithClades, getCladeSchema],
    (showMutationGroups, mutationsGroups, cladeSchema) => {
        if (!showMutationGroups || !mutationsGroups || emptyObject(cladeSchema)) return null;
        //console.log('[getMutationClassesForCladeSchema] mutationsGroups', mutationsGroups);

        const cladeMutations = {};
        const cladeSchemaMutations = Object.keys(mutationsGroups).reduce((acc, nodeId) => {
            const groups = mutationsGroups[nodeId].filter(({ clade }) => clade);
            if (groups.length) acc[nodeId] = groups;
            return acc;
        }, {});
        //console.log('[getMutationClassesForCladeSchema] cladeSchemaMutations', cladeSchemaMutations);

        treePreOrder(cladeSchema, node => {
            //__cladeMutations[node.id] 
            // console.log(node);
            const parentsWithMutations = node.parentTreeNodePath.filter(nodeId => cladeSchemaMutations[nodeId]);
            const cladeMuts = parentsWithMutations.reduce((acc, nodeId) => {
                // console.log(node.id, nodeId, ' => ', cladeSchemaMutations[nodeId]);
                cladeSchemaMutations[nodeId].forEach(m => {
                    const { mutClass, cladeSchemaStyle, mutations } = m;
                    if (!acc[mutClass]) acc[mutClass] = { style: cladeSchemaStyle, mutations: [] };
                    acc[mutClass].mutations = [...acc[mutClass].mutations, ...mutations.map(m => m.split(":")[1])];
                });
                return acc;
            }, {});

            cladeMutations[node.id] = Object.entries(cladeMuts).map(([mutClass, value]) => {
                const mutations = value.mutations.sort((m1, m2) => {
                    const pos1 = +m1.slice(1, m1.length - 1);
                    const pos2 = +m2.slice(1, m2.length - 1);
                    //console.log(m1, m2, pos1, pos2);
                    return pos1 - pos2;
                }).join(',');
                return { mutClass, mutations, style: value.style || 'normal' };
            });
        });
        //const mutations = Object.entries(__cladeMutations).map
        //console.log('[getMutationClassesForCladeSchema]', cladeMutations);
        return cladeMutations;
    });

const getShowVaccines = ({ parameters }) => parameters.showVaccines;
const getShowReassortments = ({ parameters }) => parameters.showReassortments;

const emptyVaccinesAndReassortments =  { vaccines: {}, reassortments: {} };

export const getVaccinesAndReassortmentsForCladeSchema = createSelector(
    [treeAttrsSelector, getClades, getCladeType, getShowVaccines, getShowReassortments,  getTreeDataStatus, getCladesStatus],
    (treeAttrs, clades, cladeType, showVaccines, showReassortments, treeDataStatus, cladesStatus) => {
        try {
        if (treeDataStatus !== 'loaded' || cladesStatus !== 'loaded' || (!showVaccines && !showReassortments))
            return emptyVaccinesAndReassortments;

        const res = Object.entries(treeAttrs).reduce((acc, entry) => {
            const [id, node] = entry;
            const cladeId = node.clade;
            if (!clades[cladeId]) console.log(cladeId, id, node);
            const clade = (cladeType === 'clade' ? cladeId : clades[cladeId].cladeMapping[cladeType].alpha);
            if (node.vaccine && showVaccines)
                acc.vaccines[clade] = node.time
            if (node.R && showReassortments)
                acc.reassortments[clade] = node.time;
            return acc;
        }, { vaccines: {}, reassortments: {} });
        return res;
    }
    catch (e) {
        console.error(e);
        return emptyVaccinesAndReassortments;

    }
    })



const getVisibleMutationClassesLabels = ({ parameters }) => parameters.visibleMutationClassesLabels;




const getMutationClassesDataLabels = createSelector(
    [
        getShowMutationsGroups,
        getMutationsGroup,
        getMutationClasses,
        getTreeFromTreeArray,
        treeAttrsSelector,
        getMutationClassesVisibility,
        getMutationsThreshold,
        getVisibleMutationClassesLabels,
        treeOrderDictMinMaxSelector,
        getMutationGroupValues,
    ],
    (
        showMutationsGroups,
        mutationsGroup,
        mutationClasses,
        tree,
        treeAttrs,
        visibleMutationClasses,
        mutationsThreshold,
        visibleMutationClassesLabels,
        { minMaxDict },
        mutationGroupValues,
        //dataStore
    ) => {

        //console.log('[getMutationClassesDataLabels] selector');
        if (!showMutationsGroups || !mutationsGroup || !mutationClasses || !treeAttrs || !tree || emptyObject(tree))
            return [];

        const mutationsGroups = {};
        const mutClasses = mutationClasses[mutationsGroup];

        treePreOrder(tree, (node) => {
            const mutNotExist = !mutationGroupValues[node.id];
            if (mutNotExist || treeAttrs[node.id].tn < mutationsThreshold) return;

            const mutations = mutationGroupValues[node.id];

            // to be changed and removed
            // if (dataStore === 'mongo' && mutations.length > mutationsThreshold) return;

            const aminoacidMutations = mutations.filter((m) => m.length === 3);
            if (aminoacidMutations && aminoacidMutations.length > 0) {
                const aminoacidMutations = mutations.filter((m) => m.length === 3);
                // console.log(`${node.id} => `, mutations, aminoacidMutations);

                const nodeMutClasses = Object.keys(mutClasses || {})
                    //.filter(mc => visibleMutationClassesLabels[mc])
                    .reduce((accMc, mc) => {
                        // find matching mutations for mutation class mc.

                        const matchingAminoacidMuts = aminoacidMutations.reduce((acc, m) => {
                            const gene = m[1];
                            const aMut = m[2]; //in fact it's and one element array...
                            const pos = Object.keys(aMut)[0];
                            if (mutClasses[mc].positions[gene] && mutClasses[mc].positions[gene][pos]) {
                                if (!acc[gene]) acc[gene] = [];
                                acc[gene].push(getAminoMutDisplayValShort(m));
                                //acc.push(getAminoMutDisplayVal(m));
                            }
                            return acc;
                        }, {});

                        if (Object.keys(matchingAminoacidMuts).length > 0) accMc[mc] = matchingAminoacidMuts; //uniq(matchingAminoacidMuts);

                        return accMc;
                    }, {});
                let startPos = 0;
                if (Object.keys(nodeMutClasses).length > 0)
                    mutationsGroups[node.id] = Object.entries(nodeMutClasses)
                        .filter(([mutClass]) => {
                            return visibleMutationClasses?.[mutationsGroup]?.[mutClass];
                        })
                        .map(([mutClass, mutations]) => {
                            const number = Object.values(mutations).reduce((acc, muts) => acc + muts.length, 0);
                            startPos += number;
                            return {
                                mutClass,
                                mutations: Object.entries(mutations).map(([gene, muts]) => `${gene}: ${muts.join(',')}`),
                                number,
                                startPos: startPos - number,
                                showLabel: visibleMutationClassesLabels[mutClass] || false
                            };
                        });
            }
        });

        const res = Object.entries(mutationsGroups).map((entry) => {
            const [id, muts] = entry;
            const symbolsNumber = muts.reduce((acc, m) => acc + m.number, 0);
            const showLabel = muts.reduce((acc, m) => acc || m.showLabel, false);
            return ({ id, muts, symbolsNumber, showLabel, ...minMaxDict[id] });
        }).filter(({ symbolsNumber }) => symbolsNumber > 0)
        //.filter((_,index) => index < 5);
        // console.log(res);
        return res;
    }
);


// const getMutationsGroup = ({parameters}) => parameters.mutationsGroup;
const getSelectedMutationClasses = createSelector([getMutationClasses, getMutationsGroup], (mutationClasses, mutationsGroup) =>
    !emptyObject(mutationClasses) && mutationsGroup && mutationClasses[mutationsGroup]
        ? mutationClasses[mutationsGroup]
        : null)

//const getGenotypeLegend = ({ genotype }) => genotype.genotypeLegend;


const getDisplayedSequencesNumber = createSelector([treeAttrsSelector, getVisibleNodes], (treeAttrs, visibleNodes) => Object.keys(visibleNodes || {}).reduce((acc, id) => {
    acc += treeAttrs && treeAttrs[id] && treeAttrs[id].name ? 1 : 0;
    return acc;
}, 0));





const getSortedVisibleClades = createSelector(getCladeSchema, (cladeTree) => {
    //console.log('[getSortedVisibleClades]', cladeTree)
    if (emptyObject(cladeTree)) return null;
    const cladeArr = [];
    treePreOrder(cladeTree, ({ startTime, endTime, label, order, id, p }) => {
        cladeArr.push({ startTime, endTime, label, order, id, p });
    });
    return cladeArr.sort((a, b) => a.startTime - b.startTime || a.endTime - b.endTime);
});

const emptyArray = [];
const cladeLabelsSelector = createSelector([treeAttrsSelector, treeOrderDictMinMaxSelector, getShowCladeLabels, getClades, getCladeBarData],
    (treeAttrs, { treeOrderDict, minMaxDict }, showCladeLabels, clades, cladeBarData) => {
        const cladeBarDict = (cladeBarData || []).reduce((acc, clade) => { acc[clade.clade] = true; return acc }, {});
        const cladeLabelNodes = Object.keys(treeAttrs || {})
            .filter((id) => showCladeLabels && treeAttrs[id].fixedLabels && treeOrderDict[id] && !cladeBarDict[treeAttrs[id].clade]);
        if (cladeLabelNodes.length === 0) return emptyArray;
        const res = cladeLabelNodes.map((id) => ({ id, labelText: treeAttrs[id].fixedLabels, newNomenclature: clades[treeAttrs[id].clade].newNomenclature, ...minMaxDict[id] }));
        console.log('[cladeLabelsSelector]', res);
        return res;
    }
);


const getCladeBar = createSelector([getCladeBarData, cladeLabelsSelector, treeOrderDictSelector],
    (cladeBarData, cladeLabels, treeOrderDict) => {
        const _cladeLabels = cladeLabels.map(c => {
            return ({ order: treeOrderDict[c.id], ...c });
        })
        //console.log('[showCladeBar] cladeLabels = ', cladeLabels);
        _cladeLabels.sort((c1, c2) => c1.order - c2.order);
        let i = 0;

        const cladesWithLabels = (cladeBarData || []).map((clade, index) => {
            const labels = [];
            // if (_cladeLabels[])
            while (i < _cladeLabels.length
                && _cladeLabels[i].order >= clade.startOrder
                && (index === cladeBarData.length - 1
                    || _cladeLabels[i].order < cladeBarData[index + 1].startOrder)) {
                labels.push(_cladeLabels[i])
                i++;
            }

            return { ...clade, labels, minHeight: labels.length > 0 /*&& clade.startOrder === clade.endOrder*/ ? Math.sqrt(labels.length) * 23.5 : 8 };
        }
        );
        //console.log(cladesWithLabels);
        return cladesWithLabels;
    });

const getLongestCladeLabel = createSelector([getCladeBar /*, getExportMode*/], (cladeBarData /*, exportMode*/) => {
    let treeWidth = 10000;
    const width = cladeBarData.reduce((acc, { width }) => Math.max(acc, width), 0);
    const formattedWidth = Math.ceil(width) + 5;
    const svgElement = document.getElementById('tree_svg');

    if (svgElement) {
        const { width } = svgElement.getBoundingClientRect();
        // treeWidth = exportMode ? width : width * 0.25;
        treeWidth = width * 0.25;
    }
    return Math.min(formattedWidth, treeWidth);
});



const getRenderedMutationClassLabels = state => state.render.labels.mutationLabel;
//const getMutationsClassesLabelsRenderStatus = state => state.render.viewToRender && state.render.viewToRender.components.mutationsClasses;

// const prepositionedMutationClassLabels = createSelector([getRenderedMutationClassLabels, getMutationsClassesLabelsRenderStatus], (renderedLabels, mutationsClassesLabelsRenderStatus) =>
//     (mutationsClassesLabelsRenderStatus === RENDER_STATUS.START )
//         ? renderedLabels
//         : renderedLabels
// );

const getMutationsLabelsRenderStatus = ({ render }) => render.viewToRender?.components?.mutationsClasses;

const renderedMutationClassesLabelsSelector = createSelector([getMutationClassesDataLabels, getRenderedMutationClassLabels, getMutationsLabelsRenderStatus],
    (mutationClassesData, renderedLabels, mutationsClassesLabelsRenderStatus) => {
        // console.log('[renderedMutationClassesLabelsSelector]: renderedLabels', renderedLabels, mutationClassesData);
        const res = mutationClassesData.reduce((acc, { id, muts }) => {
            //if (!emptyObject(x) && !emptyObject(y))
            muts
                // .filter(({mutClass})=> {
                //     const renderedLabel = renderedLabels?.[`${id}_${mutClass}`];
                //     return renderedLabel && !emptyObject(renderedLabel.x) && !emptyObject(renderedLabel.y);
                // })
                .forEach(({ mutClass, showLabel }) => acc.push({ key: `${id}_${mutClass}`, id, mutClass, showLabel, ...renderedLabels?.[`${id}_${mutClass}`] }));
            return acc;
        }, []);
        return { mutationsClassesLabels: res, mutationsClassesLabelsRenderStatus };
    });

const getMutationClassesLabelsInitialized = createSelector(renderedMutationClassesLabelsSelector, labels => {
    return labels.filter(({ showLabel }) => showLabel).reduce((acc, label) => acc && (label.initialized || false), true);
});

const getRenderedCladeLabels = state => state.render.labels.cladeLabel;

const renderedCladeLabelsSelector = createSelector([cladeLabelsSelector, getRenderedCladeLabels], (cladeLabels, renderedCladeLabels) => {
    //console.log('[renderedCladeLabelsSelector]');
    return cladeLabels.map(label => ({ ...label, ...renderedCladeLabels?.[label.id] }))
}
);

const getCladeLabelsInitialized = createSelector(renderedCladeLabelsSelector, labels => labels.reduce((acc, label) => acc && (label.initialized || false), true));

export {
    // getTreeOrderDict,
    getVisibleTreeClades,
    getCladesOfType,
    tcellAntigenicityScoresSelector,
    tcellAntigenicityScoresBinsSelector,
    getTcellAntigenicityStatus,
    getTreeNodeAttrs,
    getTreeArr,
    //getVisiblePredefinedClades,
    getTreeFromTreeArray,
    treeAttrsSelector,
    getStrainsLists,
    //getPredefinedClades,
    getCladeBarData,
    getCladeBar,
    //getCladeLabelsSections,
    getMutationClassesData,
    getDisplayedSequencesNumber,
    getCladeSchema,
    getSortedVisibleClades,
    treeOrderDictSelector,
    cladeLabelsSelector,
    getMutationClassesDataLabels,
    getSelectedMutationClasses,
    renderedMutationClassesLabelsSelector,
    getMutationClassesLabelsInitialized,
    renderedCladeLabelsSelector,
    getCladeLabelsInitialized,
    getLongestCladeLabel,
    getNodeClade
    //labelDimensionsSelector
};
