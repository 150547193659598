import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { styles } from './styles';

const DeleteScale = ({scale, setInfoDialog, setAction, setInfo, fetchAllScales}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteScale = () => {
        const url = `${config.serverLink}/api/admin/deleteScale`;
        const body = { scale };

        postAxios(url, body)
            .then((response) => {
                const status = response.data.status;
                setAction('Delete scale');
                if (status === 'default scale'){
                    handleCloseDialog();
                    setInfo(`Scale: ${scale.scaleId} is an application default measure that cannot be deleted.`);
                    setInfoDialog(true);
                } else if (status === 'done'){
                    handleCloseDialog();
                    setInfo(`Scale ${scale.scaleId} deleted sucesfully.`);
                    setInfoDialog(true);
                    fetchAllScales();
                } else {
                    handleCloseDialog();
                    setInfo(`An error occured during deleting ${scale.scaleId}.`);
                    setInfoDialog(true);
                }
            });
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteScale} name={scale.scaleId} scale={true} />
        </>

    );
};

export default DeleteScale;
