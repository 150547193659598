import React from 'react';
import { CardContent, IconButton } from '@mui/material';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { styles } from './styles';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const Positions = ({ positions, positionKey, setPositionsState }) => {
    const classes = styles();
    
    const addElement = (index) => {
        const updatedArray = [...positions[positionKey]];
        updatedArray.splice(index + 1, 0, {min: '', max: ''})
        const newPositions = {
            ...positions,
            [positionKey]: updatedArray
        }
        setPositionsState(newPositions)
    }

    const removeElement = (index) => {
        const updatedArray = [...positions[positionKey]];
        updatedArray.splice(index, 1)
        const newPositions = {
            ...positions,
            [positionKey]: updatedArray
        }
        setPositionsState(newPositions)
    }

    const handleChangeMin = (e, index) => {
        const updatedArray = [...positions[positionKey]];
        updatedArray[index].min = parseInt(e.target.value);
        const newPositions = {
            ...positions,
            [positionKey]: updatedArray
        }
        setPositionsState(newPositions)
    }

    const handleChangeMax = (e, index) => {
        const updatedArray = [...positions[positionKey]];
        updatedArray[index].max = parseInt(e.target.value);
        const newPositions = {
            ...positions,
            [positionKey]: updatedArray
        }
        setPositionsState(newPositions)
    }

    return (
        <CardContent className={classes.card} key={positionKey}>
            {positions[positionKey] && positions[positionKey].map((postition, index) => {
                return (
                    <div key={`el-${index}`}>
                        <StyledTextField
                            id={`min-${index}`}
                            type='number'
                            label="Min"
                            className={classes.textField}
                            value={postition.min}
                            onChange={(e) => { handleChangeMin(e, index) }}
                        />
                        <StyledTextField
                            id={`max-${index}`}
                            type='number'
                            label="Max"
                            className={classes.textField}
                            value={postition.max}
                            onChange={(e) => { handleChangeMax(e, index) }}
                        />
                        <IconButton className={classes.button} onClick={() => {addElement(index)}}>
                            <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                        <IconButton className={classes.button} onClick={() => {removeElement(index)}}>
                            <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                    </div>


                )
            })}

        </CardContent>
    );
};

export default Positions;
