import { received } from '../baseActions';
import {
    FETCH_GEO_DATA_REQUEST,
    FETCH_GEO_DATA_SUCCESS,
    FETCH_GEO_DATA_ERROR,
    FETCH_STRAINS_GEO_REQUEST,
    FETCH_STRAINS_GEO_SUCCESS,
    FETCH_STRAINS_GEO_ERROR,
    SET_COLORING,
    // SET_GEO_LOADING
} from './actionTypes';

const fetchGeoData = (payload) => received(FETCH_GEO_DATA_REQUEST, payload);

const fetchGeoDataSuccess = (payload) => received(FETCH_GEO_DATA_SUCCESS, payload);
const fetchGeoDataError = (payload) => received(FETCH_GEO_DATA_ERROR, payload);

const fetchStrainsGeo = (payload) => received(FETCH_STRAINS_GEO_REQUEST, payload);
const fetchStrainsGeoSuccess = (payload) => received(FETCH_STRAINS_GEO_SUCCESS, payload);
const fetchStrainsGeoError = (payload) => received(FETCH_STRAINS_GEO_ERROR, payload);

const setColoring = (payload) => received(SET_COLORING, payload);
// const setGeoLoading = (payload) => received(SET_GEO_LOADING, payload);

export {
    fetchStrainsGeo,
    fetchStrainsGeoSuccess,
    fetchStrainsGeoError,
    fetchGeoData,
    fetchGeoDataSuccess,
    fetchGeoDataError,
    setColoring,
    // setGeoLoading
};
