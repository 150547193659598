/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
//import { usePrevious } from '../../../helpers/customHooks';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';

import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { getCladeLabelsInitialized, renderedCladeLabelsSelector } from '../../../redux/selectors/treeDataSelector';
import { treeD3 } from '../d3/TreeD3';
import CladeLabel from '../labels/CladeLabel/CladeLabel';
import CladeLabelAnchorPoint from '../labels/CladeLabel/CladeLabelAnchorPoint';
import CladeLabelLink from '../labels/CladeLabel/CladeLabelLink';
import { RENDER_STATUS } from '../../../config/consts'
import { LAYOUT } from '../../../config/dictionaries';
import { merge } from 'lodash';
const viewToRender = 'strainTree';
const componentId = 'cladeLabels';

const CladeLabelsLayer = (props) => {

    // console.log('[CladeLabelsLayer] renderStatus:',props.renderStatus);
    const _element = useRef();
    const labels = useRef({})

    const [symbolRenderCount, setSymbolRenderCount] = useState(0);
    const [linkRenderCount, setLinkRenderCount] = useState(0);
    const [labelRenderCount, setLabelRenderCount] = useState(0);
    const [isRendering, setIsRendering] = useState(false);

    const {
        loading,
        cladeLabels,
        setComponentStatus,
        layout,
        renderStatus,
        initialized
    } = props;


    const labelsCnt = useMemo(() => cladeLabels.length);

    const cladeLabelsRendered = (symbolRenderCount === labelsCnt && linkRenderCount === labelsCnt && labelRenderCount === labelsCnt) || labelsCnt === 0;

    const rerenderLabels = !cladeLabelsRendered && (renderStatus === RENDER_STATUS.START || renderStatus === RENDER_STATUS.NONE) && isRendering;

    // console.log('CladeLabels', cladeLabels)
    // console.log('[CladeLabelsLayer] renderStatus = ', renderStatus);
//     console.log(`======> [CladeLabelsLayer] 
//      cladeLabelsRendered = ${cladeLabelsRendered}, 
//      symbolRenderCount = ${symbolRenderCount}, 
//      linkRenderCount = ${linkRenderCount}, 
//      labelRenderCount = ${labelRenderCount}
//   `); 
     // labels = ${labelRenderCount}/${labelsCnt},
    // symbols = ${symbolRenderCount}/${labelsCnt},
    // links = ${linkRenderCount}/${labelsCnt}
    // renderStatus = ${renderStatus}
    // loading = ${loading}
    // rerenderLabels = ${rerenderLabels}
    // isRendering = ${isRendering}
    // initialized = ${initialized}`
    // );

   // const prevProps = useRef(props); 


    // useEffect(() => {
    //    // const _props = { ...props, symbolRenderCount, linkRenderCount, labelRenderCount, isRendering };
    //     const changedProps = Object.entries(props).reduce((acc, [key, value]) => {
    //         if (prevProps.current[key] !== value) {
    //             acc.push({ name: key, prevValue: prevProps.current[key], nextValue: value });
    //         }
    //         return acc;
    //     }, []);

    //     if (changedProps.length > 0) {
    //         console.log('[CladeLabelsLayer] Changed props:', changedProps);
    //     }

    //     prevProps.current = props;
    // });

    const startRender = () => {
       
        setTimeout(() => setComponentStatus(viewToRender, componentId, RENDER_STATUS.START));
    }

    const stopPrepositioning = () => {
        // console.log('[stopPrepositioning]', labels.current);
        setIsRendering(false);
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE_PREPOSITIONING, { labels: labels.current, type: "cladeLabel" });
    }


    useEffect(() => {
        if (loading || renderStatus === RENDER_STATUS.DONE || renderStatus === RENDER_STATUS.DONE_PREPOSITIONING) return;
       // console.log('[CladeLabelsLayer] renderStatus = ', renderStatus, 'cladeLabelsRendered = ', cladeLabelsRendered, 'isRendering = ', isRendering)
        if (renderStatus === RENDER_STATUS.NONE && !cladeLabelsRendered) { // && initialized && !isRendering) {
            //console.log('[CladeLabelsLayer] RESETING, renderStatus = ', renderStatus)
            setIsRendering(true);
            setSymbolRenderCount(0);
            setLinkRenderCount(0);
            setLabelRenderCount(0);

            if (labelsCnt > 0) startRender();
            else stopPrepositioning();
            return;
        }

        if ((renderStatus === RENDER_STATUS.START || renderStatus === RENDER_STATUS.NONE) && cladeLabelsRendered) {
           // console.log('stopPrepositioning');
            stopPrepositioning();
            return
        }
        // console.log('[CladeLabelsLayer] SKIPPED');
    }, [cladeLabelsRendered, renderStatus, loading]);

    const handleSymbolRendered = useCallback(() => {
       // console.log('handleSymbolRendered', renderStatus, rerenderLabels)
        setSymbolRenderCount(c => c + 1);
    }, [rerenderLabels]);

    const handleLinkRendered = useCallback(() => {
        setLinkRenderCount(c => c + 1);
    }, [rerenderLabels])

    const handleLabelRendered = useCallback((labelData) => {
        labels.current = merge(labels.current, labelData);
        setLabelRenderCount(c => c + 1);
    }, [rerenderLabels]);

    return (
        <g id="cladeLabels" transform={treeD3.translate(false)} ref={_element}>
            {!loading && layout !== LAYOUT.FAN.value && (
                <>
                    <g id="cladeLabelLinks">
                        {cladeLabels.map(({ id }) => (
                            <CladeLabelLink
                                key={`${id}_cladeLabelLink`}
                                id={id}
                                classNamePrefix="cladeLabel"
                                onElementRendered={handleLinkRendered}
                                rerenderLabels={rerenderLabels}
                            />
                        ))}
                    </g>
                    <g id="cladeLabelAnchorPoints">
                        {cladeLabels.map(({ id }) => (
                            <CladeLabelAnchorPoint
                                key={`${id}_cladeLabelAnchorPoint`}
                                id={id}
                                classNamePrefix="cladeLabel"
                                onElementRendered={handleSymbolRendered}
                                rerenderLabels={rerenderLabels}
                            />
                        ))}
                    </g>
                    <g id="cladeLabels">
                        {cladeLabels.map(({ id, ...props }) => (
                            <CladeLabel
                                key={`${id}_cladeLabel`}
                                id={id}
                                classNamePrefix="cladeLabel"
                                {...props}
                                onElementRendered={handleLabelRendered}
                                rerenderLabels={rerenderLabels}
                            />
                        ))}
                    </g>
                </>)
            }
        </g >
    );
};

CladeLabelsLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
    //showCladeLabels: PropTypes.bool,
};

// let mi = 0
const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    const cladeLabels = renderedCladeLabelsSelector(state);
    // const renderedLabels = state.render.labels.cladeLabel;
    // const labelsPositioned = getCladeLabelsInitialized(state); //cladeLabels.reduce((acc, { id }) => acc && renderedLabels?.[id].initialized, true);
    return {
        loading,
        //showCladeLabels: state.parameters.showCladeLabels,
        layout: state.parameters.layout,
        cladeLabels,
        renderStatus:state.render.viewToRender.components[componentId] || RENDER_STATUS.NONE
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CladeLabelsLayer);
