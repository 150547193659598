import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, CircularProgress, MenuItem, Tooltip, FormControlLabel, Box, Typography, Checkbox, Grid, Divider } from '@mui/material';
import { styles, EditMeasureDialogSX } from './styles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import JSZip from 'jszip';
import config from '../../../../config/envConfig';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

const dialogInitState = {
    name: '',
    lineage: '',
    scaleType: '',
    scale: '',
    size: '',
    shape: '',
    label: '',
    discrete: false,
    numeric: false,
    colorBy: false,
    frequenciesChart: false,
    geoColorBy: false,
    xScale: false,
    yScale: false,
    ignoreStrainCutOffDate: false,
    element: 'node'
};

const notValidIcon = ['none', 'loading', 'done'];

const AddMeasureDialog = ({ scales, openDialog, handleCloseDialog, lineages, addNewMeasure }) => {
    const [newMeasure, setNewMeasure] = useState(dialogInitState);
    const [scalesOptions, setScalesOptions] = useState([]);
    const [scaleTypes, setScaleTypes] = useState([]);
    const [file, setFile] = useState([]);
    const [dataFileUploaded, setDataFileUploaded] = useState('none');
    const [param, setParam] = useState();
    const [canUploadData, setCanUploadData] = useState(false);
    const classes = styles();

    const handleClose = () => {
        setNewMeasure(dialogInitState);
        handleCloseDialog();
    };

    const getScalesOptions = (lineage, scaleType) => {
        const scalesOpts = scales.filter(scale => {
            const good = (!scale.lineage || (scale.lineage && scale.lineage === lineage)) && scale.id.split('.')[0] === scaleType;
            return good;
        }).map(scale => scale.id);
        return [...new Set(scalesOpts)];
    };

    const getScalesTypesOptions = () => {
        const opts = scales.map(el => el.id.split('.')[0]);
        return [...new Set(opts)];
    };

    useEffect(() => {
        const newScalesOptions = getScalesOptions(newMeasure.lineage, newMeasure.scaleType);
        setScalesOptions(newScalesOptions);

        const { name, scaleType, scale } = newMeasure;
        const uploadAvailable = !!name && !!scaleType && !!scale;
        setCanUploadData(uploadAvailable);
    }, [newMeasure]);

    useEffect(() => {
        const defaultOptions = getScalesTypesOptions();

        setScaleTypes(defaultOptions);
    }, [scales]);

    useEffect(() => {
        console.log(dataFileUploaded)
    }, [dataFileUploaded]);

    const handleFileChange = () => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            try {
                const jsonData = JSON.parse(e.target.result);
                const measureName = Object.keys(jsonData)[0];
                const metaData = jsonData[measureName];

                if (!metaData.scaleType && metaData.scale)
                    metaData.scaleType = metaData.scale.substring(0, metaData.scale.indexOf('.'));

                setNewMeasure({ ...newMeasure, name: measureName, ...metaData });
            } catch (error) {
                console.error('Error parsing JSON file:', error);
            }
        };

        reader.readAsText(file);
        const defaultOptions = getScalesTypesOptions();
        setScaleTypes(defaultOptions);
    };

    const handleChangeLineage = (e) => {
        const newLineage = e.target.value;
        setNewMeasure({ ...newMeasure, lineage: newLineage });
    };

    const checkFile = async (file) => {
        let valid;
        const zip = new JSZip();
        const data = await file.arrayBuffer();
        zip.file(file.name, data, { binary: true });
        const zippedFile = await zip.generateAsync({ type: 'blob' });

        const formData = new FormData();
        formData.append('file', zippedFile, 'data.zip'); // Append zipped file
        formData.append('lineage', newMeasure.lineage);
        formData.append('name', newMeasure.name);

        await fetch(`${config.serverLink}/api/customAttributes/check`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(responseData => {
                valid = responseData.valid
                if (!valid) {
                    setDataFileUploaded(responseData.msg);
                } else {
                    setDataFileUploaded('done');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setDataFileUploaded('An error occurred');
            });

        return valid;
    };

    const handleChangeName = (e) => setNewMeasure({ ...newMeasure, name: e.target.value });
    const handleLabelChange = (e) => setNewMeasure({ ...newMeasure, label: e.target.value });
    const handleChangeElement = (e) => setNewMeasure({ ...newMeasure, element: e.target.value });
    const handleSizeChange = (e) => setNewMeasure({ ...newMeasure, size: e.target.value });
    const handleShapeChange = (e) => setNewMeasure({ ...newMeasure, shape: e.target.value });
    const handleScaleChange = (e) => setNewMeasure({ ...newMeasure, scale: e.target.value });
    const handleScaleChangeParametrized = (e, type) => setNewMeasure({ ...newMeasure, scale: { [param.paramName]: { ...newMeasure.scale[param.paramName], [type]: e.target.value } } });
    const handleChangeScaleType = (e) => setNewMeasure({ ...newMeasure, scaleType: e.target.value });
    const handleCheckboxChange = (e, type) => setNewMeasure({ ...newMeasure, [type]: e.target.checked });

    const handleDataFileChange = async (e) => {
        setDataFileUploaded('loading');
        const zip = new JSZip();
        const selectedFile = e.target.files[0];
        const valid = await checkFile(selectedFile);

        if (!valid)
            return;

        const data = await selectedFile.arrayBuffer();
        zip.file(selectedFile.name, data, { binary: true });

        const dialogStateContent = JSON.stringify({ [newMeasure.name]: newMeasure });
        zip.file(`meta_${newMeasure.name}.json`, dialogStateContent);

        const zippedFile = await zip.generateAsync({ type: 'blob' });
        setDataFileUploaded('done');
        setFile(zippedFile);
    };

    const handleSubmit = async () => {
        await addNewMeasure(newMeasure, file);
        setParam('');
        setNewMeasure({ ...dialogInitState });
        handleClose();
    };

    const _scale = typeof newMeasure.scale == 'string' ? newMeasure.scale : '';
    const xs = 12;
    return (
        <Dialog open={openDialog} onClose={handleClose} sx={EditMeasureDialogSX}>
            <DialogTitle id="confirm-dialog-title">
                Add measure
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'plotType'} label={'Lineage'} />
                    <CustomSelect
                        value={newMeasure.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        {lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}

                    </CustomSelect>
                </FormControl>

                {newMeasure.lineage !== '' && <>
                    <div style={{ marginTop: '5px', marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <label htmlFor="fileInput" className={classes.metaButton}>
                            Upload meta file
                        </label>
                        <input
                            id="fileInput"
                            type="file"
                            accept=".json"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <StyledTextField
                        id="name"
                        type="text"
                        label="Measure name"
                        autoComplete='off'
                        className={classes.textField}
                        value={newMeasure.name}
                        onChange={handleChangeName}
                    />

                    <StyledTextField
                        id="label"
                        type="text"
                        label="Label"
                        autoComplete='off'
                        className={classes.textField}
                        value={newMeasure.label}
                        onChange={handleLabelChange}
                    />

                    {param ?
                        <>
                            {
                                scalesOptions.length > 0 && param.paramValues.map((elem) => {
                                    const filteredOptions = scalesOptions.filter(opt => opt.includes(elem));

                                    return (
                                        <div key={elem}>
                                            <FormControl fullWidth className={classes.formControlLineage}>
                                                <CustomLabel id={'scale'} label={`Scale for ${param.paramName}: ${elem}`} />
                                                <CustomSelect
                                                    value={newMeasure.scale[param.paramName][elem]}
                                                    onChange={(e) => handleScaleChangeParametrized(e, elem)}
                                                    inputProps={{
                                                        name: 'scale',
                                                        id: 'scale',
                                                    }}
                                                >
                                                    {filteredOptions.length > 0 && filteredOptions.map((option, index) => {
                                                        const { colors } = scales.find(el => el.id === option);

                                                        if (!Array.isArray(colors))
                                                            return (<MenuItem key={`${option}-${index}`} value={option} >{option}</MenuItem>);

                                                        let gradientStyle = 'linear-gradient(0.25turn, ';

                                                        colors.forEach((t, index) => {
                                                            if (index === colors.length - 1)
                                                                gradientStyle += index === t.length - 1 ? `${t})` : `${t})`;
                                                            else
                                                                gradientStyle += index === t.length - 1 ? `${t})` : `${t}, `;
                                                        });

                                                        const text = <div style={{ whiteSpace: 'pre-line' }}>
                                                            <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                                                        </div>;

                                                        return (
                                                            <Tooltip followCursor key={`${option}-${index}`} value={option} placement="bottom-start" title={text} className={classes.tooltip} >
                                                                <MenuItem className={classes.tooltip} >{option}</MenuItem>
                                                            </Tooltip>
                                                        );
                                                    })}

                                                </CustomSelect>
                                            </FormControl>
                                        </div>

                                    );
                                })
                            }
                        </>
                        :
                        <>
                            <FormControl fullWidth className={classes.formControlLineage}>
                                <CustomLabel id={'scaleType'} label={'Scale type'} />
                                <CustomSelect
                                    value={newMeasure.scaleType}
                                    onChange={handleChangeScaleType}
                                    inputProps={{
                                        name: 'scaleType',
                                        id: 'scaleType',
                                    }}
                                >
                                    {scaleTypes.length > 0 && scaleTypes.map(option =>
                                        (<MenuItem key={option} value={option} >{option}</MenuItem>)
                                    )}
                                </CustomSelect>
                            </FormControl>

                            <FormControl fullWidth className={classes.formControlLineage}>
                                <CustomLabel id={'scale'} label={'Scale'} />
                                <CustomSelect
                                    value={_scale}
                                    onChange={handleScaleChange}
                                    inputProps={{
                                        name: 'scale',
                                        id: 'scale',
                                    }}
                                >
                                    {scalesOptions.length > 0 && scalesOptions.map((option, index) => {
                                        const { colors } = scales.find(el => el.id === option);

                                        if (!Array.isArray(colors))
                                            return (<MenuItem key={`${option}-${index}`} value={option} >{option}</MenuItem>);

                                        let gradientStyle = 'linear-gradient(0.25turn, ';

                                        colors.forEach((t, index) => {
                                            if (index === colors.length - 1)
                                                gradientStyle += index === t.length - 1 ? `${t})` : `${t})`;
                                            else
                                                gradientStyle += index === t.length - 1 ? `${t})` : `${t}, `;
                                        });

                                        const text = <div style={{ whiteSpace: 'pre-line' }}>
                                            <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                                        </div>;

                                        return (
                                            <Tooltip followCursor key={`${option}-${index}`} value={option} placement="bottom-start" title={text} className={classes.tooltip} >
                                                <MenuItem className={classes.tooltip} >{option}</MenuItem>
                                            </Tooltip>
                                        );
                                    })}

                                </CustomSelect>
                            </FormControl>
                        </>
                    }
                    <FormControl fullWidth className={classes.formControlLineage}>
                        <CustomLabel id={'element'} label={'Element'} />
                        <CustomSelect
                            value={newMeasure.element}
                            onChange={handleChangeElement}
                            inputProps={{
                                name: 'element',
                                id: 'element',
                            }}
                        >
                            <MenuItem key={'node'} value={'node'} >Node</MenuItem>
                            <MenuItem key={'branch'} value={'branch'} >Branch</MenuItem>
                        </CustomSelect>
                    </FormControl>

                    {newMeasure.element === 'branch' &&
                        <>
                            <FormControl fullWidth className={classes.formControlLineage}>
                                <CustomLabel id={'size'} label={'Size'} />
                                <CustomSelect
                                    value={newMeasure.size}
                                    onChange={handleSizeChange}
                                    inputProps={{
                                        name: 'size',
                                        id: 'size',
                                    }}
                                >

                                    <MenuItem key='small' value='small' >Small</MenuItem>
                                    <MenuItem key='medium' value='medium' >Medium</MenuItem>
                                    <MenuItem key='big' value='big' >Big</MenuItem>
                                </CustomSelect>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControlLineage}>
                                <CustomLabel id={'shape'} label={'Shape'} />
                                <CustomSelect
                                    value={newMeasure.shape}
                                    onChange={handleShapeChange}
                                    inputProps={{
                                        name: 'shape',
                                        id: 'shape',
                                    }}
                                >
                                    <MenuItem key='circle' value='circle' >Circle</MenuItem>
                                    <MenuItem key='cross' value='cross' >Cross</MenuItem>
                                    <MenuItem key='diamond' value='diamond' >Diamond</MenuItem>
                                    <MenuItem key='square' value='square' >Square</MenuItem>
                                    <MenuItem key='star' value='star' >Star</MenuItem>
                                    <MenuItem key='triangle' value='triangle' >Triangle</MenuItem>
                                </CustomSelect>
                            </FormControl>
                        </>

                    }

                    <Grid container >
                        <Typography className={classes.title}>General</Typography>
                        <Divider className={classes.divider} />
                        <Grid item xs={xs} >
                            <FormControlLabel
                                className={classes.boolOption}
                                value="discrete"
                                label={<Typography className={classes.formControlLabel}>Discrete</Typography>}
                                control={
                                    (
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checked={newMeasure.discrete}
                                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                            onChange={(e) => handleCheckboxChange(e, 'discrete')}
                                            inputProps={{
                                                name: 'discrete',
                                                id: 'discrete',
                                            }}

                                        />
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={xs} >
                            <FormControlLabel
                                className={classes.boolOption}
                                value="numeric"
                                label={<Typography className={classes.formControlLabel}>Numeric</Typography>}
                                control={
                                    (
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checked={newMeasure.numeric}
                                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                            onChange={(e) => handleCheckboxChange(e, 'numeric')}
                                            inputProps={{
                                                name: 'numeric',
                                                id: 'numeric',
                                            }}

                                        />
                                    )
                                }
                            />
                        </Grid>
                        {newMeasure.element === 'node' && <>
                            <Typography className={classes.title}>Strain tree</Typography>
                            <Divider className={classes.divider} />
                            <Grid item xs={xs} >
                                <FormControlLabel
                                    className={classes.boolOption}
                                    value="colorBy"
                                    label={<Typography className={classes.formControlLabel}>Color by</Typography>}
                                    control={
                                        (
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={newMeasure.colorBy}
                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                onChange={(e) => handleCheckboxChange(e, 'colorBy')}
                                                inputProps={{
                                                    name: 'colorBy',
                                                    id: 'colorBy',
                                                }}

                                            />
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={xs} >
                                <FormControlLabel
                                    className={classes.boolOption}
                                    value="xScale"
                                    label={<Typography className={classes.formControlLabel}>X scale</Typography>}
                                    control={
                                        (
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={newMeasure.xScale}
                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                onChange={(e) => handleCheckboxChange(e, 'xScale')}
                                                inputProps={{
                                                    name: 'xScale',
                                                    id: 'xScale',
                                                }}

                                            />
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={xs} >
                                <FormControlLabel
                                    className={classes.boolOption}
                                    value="yScale"
                                    label={<Typography className={classes.formControlLabel}>Y scale</Typography>}
                                    control={
                                        (
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={newMeasure.yScale}
                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                onChange={(e) => handleCheckboxChange(e, 'yScale')}
                                                inputProps={{
                                                    name: 'yScale',
                                                    id: 'yScale',
                                                }}

                                            />
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={xs} >
                                <FormControlLabel
                                    className={classes.boolOption}
                                    value="ignoreStrainCutOffDate"
                                    label={<Typography className={classes.formControlLabel}>Ignore strainCutOffDate</Typography>}
                                    control={
                                        (
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={newMeasure.ignoreStrainCutOffDate}
                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                onChange={(e) => handleCheckboxChange(e, 'ignoreStrainCutOffDate')}
                                                inputProps={{
                                                    name: 'ignoreStrainCutOffDate',
                                                    id: 'ignoreStrainCutOffDate',
                                                }}

                                            />
                                        )
                                    }
                                />
                            </Grid>
                            <Typography className={classes.title}>Freqeuncies</Typography>
                            <Divider className={classes.divider} />
                            <Grid item xs={xs} >
                                <FormControlLabel
                                    className={classes.boolOption}
                                    value="frequenciesChart"
                                    label={<Typography className={classes.formControlLabel}>Frequencies chart</Typography>}
                                    control={
                                        (
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={newMeasure.frequenciesChart}
                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                onChange={(e) => handleCheckboxChange(e, 'frequenciesChart')}
                                                inputProps={{
                                                    name: 'frequenciesChart',
                                                    id: 'frequenciesChart',
                                                }}

                                            />
                                        )
                                    }
                                />
                            </Grid>
                            <Typography className={classes.title}>Geo map</Typography>
                            <Divider className={classes.divider} />
                            <Grid item xs={xs} >
                                <FormControlLabel
                                    className={classes.boolOption}
                                    value="geoColorBy"
                                    label={<Typography className={classes.formControlLabel}>Geo map color by</Typography>}
                                    control={
                                        (
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={newMeasure.geoColorBy}
                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                onChange={(e) => handleCheckboxChange(e, 'geoColorBy')}
                                                inputProps={{
                                                    name: 'geoColorBy',
                                                    id: 'geoColorBy',
                                                }}

                                            />
                                        )
                                    }
                                />
                            </Grid>
                        </>}
                    </Grid>
                </>}
            </DialogContent>
            <DialogActions>
                <Box flexGrow={1}>
                    {canUploadData &&
                        <div style={{ margin: '5px 0px 20px 15px', display: 'flex', alignItems: 'center', left: 0 }}>
                            <label htmlFor="dataFileInput" className={classes.dataButton}>
                                Upload data file
                            </label>
                            <input
                                id="dataFileInput"
                                type="file"
                                accept=".json"
                                onChange={handleDataFileChange}
                                style={{ display: 'none' }}
                            />
                            {dataFileUploaded === 'loading' &&
                                <div className={classes.icon}>
                                    <CircularProgress size={18} disableShrink />
                                </div>
                            }
                            {dataFileUploaded === 'none' &&
                                <div className={classes.icon}>
                                    <CloseIcon style={{ color: 'grey' }} />
                                </div>
                            }
                            {dataFileUploaded === 'done' &&
                                <div className={classes.icon}>
                                    <DoneIcon style={{ color: 'green' }} />
                                </div>
                            }
                            {!notValidIcon.includes(dataFileUploaded) && (
                                <Tooltip
                                    title={dataFileUploaded}
                                    placement="top-start"
                                    arrow>
                                    <div className={classes.icon}>
                                        <CloseIcon style={{ color: 'red' }} />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    }
                </Box>
                <Button className={classes.cancel} onClick={handleClose}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit} disabled={dataFileUploaded !== 'done'}>
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default AddMeasureDialog;