import React from 'react';

const HamburgerIcon = (props) => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="1.88892" x2="15" y2="1.88892" stroke="#747474" strokeWidth="2" strokeLinecap="round"/>
        <line x1="1" y1="7.94397" x2="15" y2="7.94397" stroke="#747474" strokeWidth="2" strokeLinecap="round"/>
        <line x1="1" y1="13.8889" x2="15" y2="13.8889" stroke="#747474" strokeWidth="2" strokeLinecap="round"/>
    </svg>
);

export default HamburgerIcon;
