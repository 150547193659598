import {
    FETCH_ANTIGENIC_MODEL_REQUEST,
    FETCH_ANTIGENIC_MODEL_SUCCESS,
    FETCH_ANTIGENIC_MODEL_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_REQUEST,
    FETCH_ANTIGENIC_RAW_MODEL_SUCCESS,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST,
    FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    SELECT_ANTIGENIC_VALUES,
    RESET_ANTIGENIC_MODEL,
    RESET_ANTIGENIC_CLADES,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_CLADES_SUCCESS,
} from '../actions/actionTypes';
import deepFreeze from 'deep-freeze';

let antigenicDataInitalState = { };
export const setAntigenicDataInitalState = (state) => {
    antigenicDataInitalState = state;
};

const antigenicModelData = (state = antigenicDataInitalState, action) => {
    deepFreeze(state);
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return antigenicDataInitalState;
        }

        case FETCH_CLADES_SUCCESS: {
            const { antigenicClades } = action.payload;
            return {
                ...state,
                antigenicClades,
                antigenicCladesStatus: 'loaded',
            };
        }
     
        case FETCH_ANTIGENIC_MODEL_REQUEST: {
            // console.log(`FETCH_ANTIGENIC_MODEL_REQUEST`);
            return {
                ...state,
                // antigenicModel: {},
                antigenicModelStatus: 'loading',
            };
        }
        case FETCH_ANTIGENIC_MODEL_SUCCESS: {
            const { antigenicModel } = action.payload;
            //console.log('epic reducer success', action)
            return {
                ...state,
                antigenicModel,
                antigenicModelStatus: antigenicModel === null ? 'nodata' : 'loaded',
            };
        }
        case FETCH_ANTIGENIC_MODEL_ERROR: {
            // console.log(`FETCH_MODEL_DATA_ERROR: action.payload = ${JSON.stringify(action.payload)}`);
            return {
                ...state,
                antigenicModelStatus: 'error',
            };
        }

        case FETCH_ANTIGENIC_OBSERVED_DATA_REQUEST: {
            // console.log(`FETCH_ANTIGENIC_MODEL_REQUEST`);
            return {
                ...state,
                // antigenicModel: {},
                antigenicObservedDataStatus: 'loading',
            };
        }
        case FETCH_ANTIGENIC_OBSERVED_DATA_SUCCESS: {
            const { antigenicObservedData, antigenicObservedRefStrains } = action.payload;
            //console.log('epic reducer success', action)
            return {
                ...state,
                antigenicObservedData,
                antigenicObservedDataStatus: antigenicObservedData === null ? 'nodata' : 'loaded',
                antigenicObservedRefStrains
            };
        }
        case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR: {
            // console.log(`FETCH_MODEL_DATA_ERROR: action.payload = ${JSON.stringify(action.payload)}`);
            return {
                ...state,
                antigenicObservedDataStatus: 'error',
            };
        }

        case FETCH_ANTIGENIC_RAW_MODEL_REQUEST: {
            return {
                ...state,
                rawAntigenicCladeStrains: {},
                rawStrains: {},
                rawAntigenicModel: {},
                antigenicRawModelStatus: 'loading'
            };
        }
        case FETCH_ANTIGENIC_RAW_MODEL_SUCCESS: {
            const { rawAntigenicCladeStrains, rawAntigenicModel, rawStrains } = action.payload;
            return {
                ...state,
                rawAntigenicModel,
                rawStrains,
                rawAntigenicCladeStrains,
                antigenicRawModelStatus: rawAntigenicModel === null ? 'nodata' : 'loaded',
            };
        }
        case FETCH_ANTIGENIC_RAW_MODEL_ERROR: {
            return {
                ...state,
                antigenicRawModelStatus: 'error',
            };
        }

        case RESET_ANTIGENIC_MODEL: {
            const nodata = action.payload?.nodata;
            return {
                ...state,
                antigenicModel: {},
                rawAntigenicCladeStrains: {},
                rawAntigenicModel: {},
                rawStrains: {},
                antigenicModelStatus: nodata ? 'nodata' : 'none',
                antigenicRawModelStatus: nodata ? 'nodata': 'none',
                antigenicObservedDataStatus: nodata ? 'nodata': 'none'
            };
        }
        case RESET_ANTIGENIC_CLADES: {
            return {
                ...state,
                antigenicClades: {},
                antigenicCladesStatus: 'none',
            };
        }
    

        case SELECT_ANTIGENIC_VALUES: {
            const { alpha, rho } = action.payload;
            return {
                ...state,
                alpha,
                rho,
            };
        }
        default:
            return state || antigenicDataInitalState;
    }
};

export { antigenicModelData };
