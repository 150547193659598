/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { getStrainTreeStatus, getStrainTreeErrorStatus } from '../../../redux/selectors/statusSelector';
import { treeD3 } from '../d3/TreeD3';
import { getColorScaleForColorBy } from '../../../redux/selectors/parametersSelector';
import { getTreeNodeAttrs } from '../../../redux/selectors/treeDataSelector';


// import { printWhatChanged } from '../../../functions/utils';

const viewToRender = 'strainTree';
const componentId = 'links';

const LinksLayer = (props) => {
    const _element = useRef();
    // const elementRendered = useRef(false);

    // const prevProps = {
    //     loading: usePrevious(props.loading),
    //     layout: usePrevious(props.layout),
    //     colorBy: usePrevious(props.colorBy),
    // };

    const {
        id,
        showLinks,
        modelId,
        loading,
        errorStatus,
        colorBy,
        displayOrder,
        searchStrainMode,
        calculatedDomain,
        antigenicDataType,
        antigenicTiterType,
        renderStatus,
        setComponentStatus,
        antigenicStrainSearchStatus,
        colorScale,
        cladeType,
        submissionDate,
        treeAttrs
    } = props;

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    }

    const stopRender = () => {
        // console.log('[LINKS]: stop render loading, renderStatus = ', componentId, renderStatus);
        //if (renderStatus !== RENDER_STATUS.DONE)
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    }

    useEffect(() => {
        // console.log('[LinkLayer] useEffect errorStatus', errorStatus)
        if (errorStatus) treeD3.removeElementsLayer(_element.current);
    }, [errorStatus]);


    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        // console.log('[LinksLayer] useEffect 0 [renderStatus] renderStatus =',renderStatus);
        // console.log('[LinksLayer] useEffect 0 in');
        startRender()
            .then(() => treeD3.drawLinksLayer(_element.current)) //draw links instead of translate links to remove dashed lines
            .then(() => stopRender());
    }, [renderStatus])

    useEffect(() => {
        if (loading || errorStatus) return;
        // console.log('[LinksLayer] useEffect 1');
        startRender()
            .then(() => treeD3.changeLinksColor(_element.current))
            .then(() => stopRender());
    }, [modelId, colorBy, calculatedDomain, submissionDate, antigenicTiterType, antigenicDataType, colorScale, cladeType]);

    useEffect(() => {
        if (loading || errorStatus) return;
        // console.log(`[LinksLayer] useEffect 2, loading = ${loading}, showLinks = ${showLinks}`);
        startRender()
            .then(() => treeD3.drawLinksLayer(_element.current))
            .then(() => stopRender());
    }, [loading, showLinks, searchStrainMode, treeAttrs]);

    useEffect(() => {
        if (loading || errorStatus) return;
        // console.log(`[LinksLayer] useEffect 3 displayOrder=${displayOrder}, antigenicStrainSearchStatus=${antigenicStrainSearchStatus}`);
        treeD3.removeElementsLayer(_element.current)
            .then(() => startRender())
            .then(() => treeD3.drawLinksLayer(_element.current))
            .then(() => stopRender());
    }, [displayOrder]); //, antigenicStrainSearchStatus]);

    useEffect(() => {
        if (loading || errorStatus || antigenicStrainSearchStatus !== 'found') return;
        //console.log(`[LinksLayer] useEffect 4 antigenicStrainSearchStatus=${antigenicStrainSearchStatus}, searchStrainMode = ${searchStrainMode}`);     
        startRender()
            .then(() => treeD3.drawLinksLayer(_element.current))
            .then(() => stopRender());
    }, [antigenicStrainSearchStatus]);

    return <>{showLinks && <g id="links" transform={treeD3.translate(false)} ref={_element} />}</>;
};

LinksLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    const errorStatus = getStrainTreeErrorStatus(state);

    return {
        loading,
        errorStatus,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        antigenicStrainSearchStatus: state.treeData.strainSearchStatuses.antigenic,
        layout: state.parameters.layout,
        colorBy: state.parameters.colorBy,
        cladeType: state.parameters.cladeType,
        searchStrainMode: state.parameters.searchStrainMode,
        calculatedDomain: state.parameters.calculatedDomain,
        displayOrder: state.parameters.displayOrder,
        showLinks: state.parameters.showLinks,
        modelId: state.parameters.modelId,
        submissionDate: state.parameters.submissionDate,
        antigenicDataType: state.parameters.antigenicDataType,
        antigenicTiterType: state.parameters.antigenicTiterType,
        colorScale: getColorScaleForColorBy(state),
        // treeAttrs: state.treeData.treeAttrs,
        treeAttrs: getTreeNodeAttrs(state)
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(LinksLayer);
