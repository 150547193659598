import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, IconButton, InputAdornment } from '@mui/material';
import { styles, StyledTableCell } from './styles';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../Alerts/styles';
import AddNewRole from './Actions/AddNewRole';
import EditRole from './Actions/EditRole';
import DeleteRole from './Actions/DeleteRole';
import Info from '../Info';
import { fetchAxios } from '../../../functions/axiosRequests';
import config from '../../../config/envConfig';
import { getBiggerForSortTable } from '../../../functions/functions';

function createData(role) {
    return { role };
}

const TableRoles = () => {
    const classes = styles();
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loaded, setLoaded] = useState(false);
    const [searched, setSearched] = useState('');
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');

    useEffect(() => {
        if (loaded) return;
        const url = `${config.serverLink}/api/admin/getAllRoles`;

        fetchAxios(url).then(function (response) {
            const rows = response.data.roles.map((role) => {
                return createData(role.name)
            })
            const sorted = rows.sort((a, b) => getBiggerForSortTable(a, b, 'role', 'asc' ));
            setTableData(rows);
            setDisplayedTableData(sorted);
        }).catch(function (error) {
            console.log(error)
        });
        
        setLoaded(true);
    }, [loaded]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e) => {
        const search = e.target.value;
        setSearched(search)
        const filteredRows = tableData.filter((row) => {
          return row.role.toLowerCase().includes(search.toLowerCase())
        });
        setDisplayedTableData(filteredRows);
        setPage(0);
      };

    const cancelSearch = () => {
        setSearched("");
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    return (
        <Paper sx={{ marginRight: '15px', marginLeft: '15px'}}>
            <StyledTextField
                id="search"
                label="Search"
                className={classes.search}
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                InputProps={{ endAdornment:
                    <InputAdornment position="end">
                        <IconButton onClick={cancelSearch}>
                            <HideIcon/>
                        </IconButton>
                    </InputAdornment>
                }}
            />
            <TableContainer >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                        <StyledTableCell>Role</StyledTableCell>
                        <StyledTableCell align="center">Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                        (rowsPerPage > 0
                            ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : displayedTableData
                        ).map((row) => (
                            <TableRow
                                key={row.role}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                {row.role}
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteRole roleName={row.role} setLoaded={setLoaded}
                                        setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction}/>
                                    <EditRole roleName={row.role} setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction} />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow
                            key='last'
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <td>
                                <AddNewRole setLoaded={setLoaded} setInfoDialog={setInfoDialog} setInfo={setInfo} setAction={setAction} />
                            </td>

                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={displayedTableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
      </Paper>
    );
};


export default TableRoles;
