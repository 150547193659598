import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import config from '../config/envConfig';
import { getIsMobile } from '../redux/selectors/statusSelector';

const button = {
    marginLeft: 20,
    marginRight: 0,
    minWidth: '34px',
    hight: 32,
    display: 'flex',
    padding: '2px 12px',
    maxHeight: 32,
    minHeight: 32,
    color: '#FFFFFF',
    boxShadow: 'none',
}

const styles = {
    root: {
        boxShadow: 'none'
    },
    menuButton: {
        ...button,
        borderColor: '#b5b3b3',
        boxShadow: 'none',
        color: '#EEEEEE',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    selected: {
        ...button,
        boxShadow: 'none',
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        color: '#FFFFFF',
        '&:hover': {
            boxShadow: 'none',
            borderColor: 'white',
            textDecorationThickness: '3px',
            textDecoration: 'underline',
            color: '#EEEEEE',
        },
    },
    menuButtonHover: {
        ...button,
        width: 'auto',
        borderColor: '#b5b3b3',
        color: '#EEEEEE',
        '&:hover': {
            boxShadow: 'none',
            borderColor: 'white',
            textDecorationThickness: '3px',
            textDecoration: 'underline',
            color: '#EEEEEE',
        }
    },

    noBorderStyle: {
        paddingLeft: 12,
        paddingRight: 0,
        border: 'none',
        '&:hover': {
            border: 'none',
        }
    },
    menuLink: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '12px !important',
        fontStyle: 'normal',
        letterSpacing: '0.22px',
        textTransform: 'uppercase'
    },
    menuTitle: {
        fontSize: '14px',
        textTransform: 'none',
    }

};



const MenuButton = (props) => {
    const { routeName, label, defaultOption, classes, variant, onClick, internalLink, externalLink, bottom } = props;
    const location = useLocation();
    const isMobile = getIsMobile();
    const routeLink = internalLink || externalLink || `${config.frontendPrefix}/${routeName}`;

    const RouterLink = React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={routeLink} {...linkProps} />
    ));
    // const ExternalRouterLink = (props) => <a href={routeLink} target="_blank" rel="noopener noreferrer" {...props} />; 
    const ExternalRouterLink = React.forwardRef((props, ref) => (
        <a ref={ref} href={props.href} target="_blank" rel="noopener noreferrer" {...props} />
    ));

    const InternalRouterLink = React.forwardRef((props, ref) => (
        <a ref={ref} href={props.href} {...props} />
    ));

    const selectedItem = (routeLink === location.pathname) || (location.pathname === '/' && defaultOption);
    const buttonVariant = variant || (selectedItem ? 'contained' : 'outlined');
    return (
        <Button
            id={routeName}
            variant={buttonVariant}
            component={internalLink ? InternalRouterLink : (externalLink ? ExternalRouterLink : RouterLink)}
            href={routeLink}
            className={selectedItem ? classes.selected : classes.menuButtonHover}
            style={((routeName === 'intro' || routeName === '')) ? isMobile ? { marginLeft: '2px' } : { marginLeft: '10px' } : isMobile ? { marginLeft: '5px' } : {}}
            classes={{ root: classes.noBorderStyle }}
            onClick={onClick}
        >
            <Typography className={bottom ? classes.menuLink : classes.menuTitle} noWrap>
                {label}
            </Typography>
        </Button>
    );
};

MenuButton.propTypes = {
    routeName: PropTypes.string,
    label: PropTypes.string,
    defaultOption: PropTypes.bool,
    location: PropTypes.shape({ pathname: PropTypes.string }),
    classes: PropTypes.shape({ menuTitle: PropTypes.string, menuButton: PropTypes.string }),
    onClick: PropTypes.func,
};

export default withStyles(styles)(MenuButton);
