import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import Header from './layout/Header';
import Content from './layout/Content';
import { getSignedUser } from './redux/actions/userActions';
import { setParameters } from './redux/actions/parametersActions';
import { shouldFetch } from './functions/functions';
import { fetchLineage, fetchLineages } from './redux/actions/lineagesActions';
import LoadProgress from './components/LoadProgress/LoadProgress';
import './functions/consoleFilter';
import { trasformUrlToParams } from './functions/functions';
import config from './config/envConfig';
import auth from './functions/auth-helper';
import CookiePopup from './layout/CookiePopup/CookiePopup';

const App = (props) => {
    const {
        children,
        isAuthenticated, userStatus, defaultLineage, reduxExportMode, lineages,
        lineagesStatus, exportMode,
        fetchLineages, fetchLineage, getSignedUser, setParameters,
    } = props;
   
    const [queryParams, setQueryParams] = useState({});
    const location = useLocation();
    const { search, pathname, key } = location;
    const settings = pathname === `${config.frontendPrefix}/settings`;
    const isExportMode = pathname.startsWith(`${config.frontendPrefix}/export`)
    const _queryParams = queryParams || {};
    const lineage = settings ? props.settingsLineage : props.lineage;
    const lineageStatus = settings ? props.settingsLineageStatus : props.lineageStatus;

    const introPaths = [`${config.frontendPrefix}/signin`, `${config.frontendPrefix}/newPassword`, `${config.frontendPrefix}/intro`, `${config.frontendPrefix}/intro-freq`, `${config.frontendPrefix}/intro-map`];
    const isIntro = introPaths.includes(pathname);

    useEffect(() => {
        const qParams = {};
        const queryParams = new URLSearchParams(search);
        if (isExportMode) {
            const queryEntries = queryParams.entries();
            for (const [key, value] of queryEntries) {
                qParams[key] = trasformUrlToParams[key] ? trasformUrlToParams[key](value) : value;
            }
        } else {
            const lineage = queryParams.get('lineage');
            qParams.lineage = lineage;
        }
        setQueryParams(qParams);
    }, []);

    useEffect(() => {
        //console.log({ ...props, isIntro, pathname});
        if (isExportMode) {
            const lineage = _queryParams.lineage;
           
            if (shouldFetch(lineageStatus) && (lineage)) {
                // console.log({predictionBaseline: queryParams.predictionBaseline})
                fetchLineage({ lineage, ...queryParams, exportMode: isExportMode });
                return;
            }
            if (lineageStatus === 'loaded' && reduxExportMode !== isExportMode) {
                setParameters({ ..._queryParams, exportMode: true });
                return;
            }
        } else {

            const intro = (!isAuthenticated && !isExportMode);
            // console.log(`App 
            // isAuthenticated = ${isAuthenticated}, 
            // auth.isAuthenticated() = ${auth.isAuthenticated()},
            // isIntro = ${isIntro}
            // intro = ${intro}
            // userStatus = ${userStatus}`);
            if (userStatus === 'loading') return;
            if (!isAuthenticated && auth.isAuthenticated()) {
                getSignedUser();
            }
           

            // console.log('auth', isAuthenticated, auth.isAuthenticated());
            // //console.log('isIntro = ', isIntro, 'intro = ', intro,'userStatus = ',userStatus, 'lineagesStatus = ',lineagesStatus)
            // if ((!userStatus || userStatus === 'none') && !isIntro) {
            //     if (pathname === undefined || key === 'default') {
            //         console.log('signout')
            //         auth.signout();
            //         return;
            //     }
            //     getSignedUser();
            // }
            else if (shouldFetch(lineagesStatus) && isIntro && userStatus === 'none') {
                fetchLineages();
                return;
            } else if (lineagesStatus === 'loaded' && userStatus !== 'loading') {//if (isAuthenticated || exportMode) {

                const queryParams = new URLSearchParams(search);
                //console.log(queryParams.get('lineage'));
                const qlineage = queryParams.get('lineage');

                //console.log('lineage = ', lineage, qlineage, 'shouldFetch(lineageStatus) = ', shouldFetch(lineageStatus));
                let _lineage = (qlineage && lineages.includes(qlineage)) ? qlineage : (lineage || defaultLineage);

                if (lineages.length > 0) {
                    if (!lineages.includes(_lineage)) {
                        _lineage = lineages[0];
                        setParameters({ lineage: _lineage })
                    }
                    if (shouldFetch(lineageStatus) && (_lineage)) {
                        fetchLineage({ lineage: _lineage, /*isExportMode,*/ intro, settings });
                    }
                }
            }
        }
    });

    return (
        <>
            <Header title="Previr.app" />
            <LoadProgress />
            <Content>{children}</Content>
            {/* Your other components and content */}
            {!exportMode && <CookiePopup />}
        </>
    );
};

App.propTypes = {
    children: PropTypes.element,
    getSignedUser: PropTypes.func,
    setParameters: PropTypes.func,
    fetchLineage: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    userStatus: PropTypes.string,
    lineage: PropTypes.string,
    lineageStatus: PropTypes.string,
    defaultLineage: PropTypes.string,
    queryParams: PropTypes.shape({}),
    exportMode: PropTypes.bool,
    reduxExportMode: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
    getSignedUser: () => dispatch(getSignedUser()),
    setParameters: (payload) => dispatch(setParameters(payload)),
    fetchLineage: (payload) => {
        dispatch(fetchLineage(payload));
        //dispatch(setParameters(payload));
    },
    fetchLineages: () => dispatch(fetchLineages())
});

const mapStateToProps = (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    userStatus: state.user.status,
    defaultLineage: state.user.defaultLineage,
    lineageStatus: state.lineages.lineageStatus,
    lineagesStatus: state.lineages.lineagesStatus,
    lineage: state.parameters.lineage,
    reduxExportMode: state.parameters.exportMode,
    lineages: state.lineages.lineages,
    reloadLineage: state.lineages.reloadLineage,
    settingsLineage: state.settings.parameters.lineage,
    exportMode: state.parameters.exportMode,
    settingsLineageStatus: state.settings.lineages?.lineageStatus
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
