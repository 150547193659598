import { axisBottom, axisLeft } from 'd3-axis';
import { timeMonths } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { select } from 'd3-selection';
import { uniqBy } from 'lodash';
import { getScale } from '../../../functions/scales';
import { yearFirstDate, dateToDays } from '../../../functions/functions';

const DATE_FORMAT = {
    standard: '%m/%Y',
    medium: '%m/%Y',
    small: '%m/%y'
}

const dateTickLength = { standard: 40, medium: 35, small: 20 };


const getTickValue = (startDate, endDate, d, size='standard', scaleName, index, dArr, multiexport) => {
    //const oneDay = 1000 * 60 * 60 * 24;
    //const step = ((endDate.getTime() - startDate.getTime()) / oneDay) > 2 * 365 ? 3 : 1;
    //console.log(`step = ${step}`)
    //if (step === 3) return (d.getMonth() === 0 && d.getFullYear() !== endDate.getFullYear()) ? d.getFullYear() : '';
    // (d.getMonth() === 0 ? d.getFullYear() : '');

    const endPos = scaleName(endDate);
    const dPos = scaleName(d);

    const format = DATE_FORMAT[size];
    const nextD = select(dArr[index+1]).data()[0];
    const nextPos = (index < dArr.length-1) ? scaleName(nextD) : endPos;

    const space = nextPos - dPos;
    // console.log(`${index}. ${d.toLocaleDateString()}. ${space}, ${endPos}, index = ${index}/${dArr.length}`);
    const tickSpace = multiexport ? (dateTickLength[size] / 2) : dateTickLength[size];
    const res = (space > tickSpace && (!multiexport || index > 0)) ? timeFormat(format)(d) : '';
    return res;
};

const getTicks = (startDate, ticksEndDate, endDate, scaleName, size='standard', multiexport) => {

    const w = scaleName(ticksEndDate) - scaleName(startDate);
    // console.log(`[getTicks] step = ${step}, startDate = ${startDate}, ticksEndDate = ${ticksEndDate}`);
    let avgWidth = 0
    let months = new Set();

    const steps = [1, 2, 3, 4, 6, 12, 24];
    let s = 0;

    // console.log(dateTickLength[size]);
    while (avgWidth < dateTickLength[size] && s < steps.length) {
        months = new Set([startDate,
            ...timeMonths(yearFirstDate(dateToDays(startDate)), ticksEndDate, steps[s])
            .filter(d => (d <= endDate && d >= startDate))
        ]);
        avgWidth = w / months.size;
        //console.log(`avgWidth = ${avgWidth}, step = ${steps[s]}, months.size  = ${months.size}, ${dateTickLength[size]}, size = ${size}`);
       s +=1;
    }

    const ticks = uniqBy([...months], d => d.getTime()).sort((a, b) => a - b);
    // console.log(ticks.map(d => d.toLocaleDateString()))
    // console.log(`endDate = ${endDate.toLocaleDateString()}, ticksEndDate = ${ticksEndDate.toLocaleDateString()}`);
    //console.log(`[getTicks]: ${chartName}: ${w}, ticks = ${ticks.length}, tickWidth = ${w / ticks.length}, avgWidth = ${avgWidth}, step = ${steps[s-1]}`);
    return ticks;
}

const drawXAxis = (svg, chartName, xScaleName, width, height, size, padding, multiexport) => {

    const timeScale = getScale(xScaleName);
   
    if (!timeScale) return;

    // const getFirstAndLast = (arr) => {
    //     if (arr.length === 0) {
    //         return null;
    //     } else if (arr.length === 1) {
    //         return arr[0];
    //     } else {
    //         return [arr[0], arr[arr.length - 1]];
    //     }
    // };
    
    // if (chartName.includes('NA'))
    //     console.log(chartName, xScaleName, getFirstAndLast(timeScale.range()));
    const timeScaleDomain = timeScale.domain();
    // console.log(chartName, xScaleName, timeScale.domain());
    const startDate = new Date(timeScaleDomain[0]);
    const endDate = new Date(timeScaleDomain[timeScaleDomain.length-1]);

    const ticksEndDate = new Date(endDate.getTime());
    //if (chartName === 'seqNAchart')

    // ticksEndDate.setMonth(ticksEndDate.getMonth() + 1);

    // const paddingLeft = padding ? padding.left : 32; // margin ? margin.left : 10;
    const paddingBottom = padding ? padding.bottom : 0;
    const paddingTop = padding ? padding.top : 0;
    const paddingLeft = padding ? padding.left : 32;
    const titleHeight = padding && padding.title ? padding.title : 0;

    // console.log(`marginLeft =${paddingLeft}`);

    const xAxis = axisBottom(timeScale)
        // .ticks(6)
        .tickSize(height+paddingTop)
        .tickPadding(4) //paddingBottom)
        .tickFormat((d, i, dArr) => getTickValue(startDate, endDate, d, size, timeScale, i, dArr, multiexport)) // formatYear(d))
        // .tickFormat(d => ((d.getMonth() === 0 /* && d.getFullYear() !== endDate.getFullYear() */) ? d.getFullYear() : '')) // formatYear(d))
        .tickValues(getTicks(startDate, ticksEndDate, endDate, timeScale, size, multiexport));

    // console.log({chartName, xScaleName}, xAxis.tickValues());
    const customXAxis = (g) => {
        g.call(xAxis);
        const domainPath = `M0,${height + paddingBottom}H${width}M${-paddingLeft},0H${width}${paddingTop ? `M${-paddingLeft},${-paddingTop-titleHeight}H${width}`:''}`;
        g.selectAll('.domain')
            .attr('d', domainPath)
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3);

        g.selectAll('line')
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3)
            .attr('y1', `${paddingTop}`)
            .attr('y2', `${height + paddingTop}`);
        g.selectAll('line')
            .filter(d => (
                (
                    d.getMonth() === 0)
                    || (d.getTime() === startDate.getTime())
                    || (d.getTime() === endDate.getTime())
                )
            )
            .attr('stroke-opacity', 1)
            .attr('y2', `${height + paddingBottom + paddingTop}`);
        g.selectAll('.tick text')
            .attr('transform', `translate(${size === 'small' ? 0 : 5}, 8)`)
            .attr('fill', '#000000')
            .style('font-size', () => size === 'small'? '8px' : '10px');
        g.attr('text-anchor', `${size === 'small' ? 'middle' : 'start'}`);

    };

    const customXAxisMultiexport = (g) => {
        g.call(xAxis);
        const domainPath = `M0,${paddingTop+titleHeight}H${width}`;
        g.selectAll('.domain')
            .attr('d', domainPath)
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3);

        g.selectAll('line')
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3)
            .attr('y1', paddingTop)
            .attr('y2', height+paddingTop);

        g.selectAll('line')
            .filter(d => (
                (
                    d.getTime() === startDate.getTime())
                || (d.getTime() === endDate.getTime())
                )
            )
            .attr('stroke-opacity', 1)
            // .attr('y1', paddingTop)
            // .attr('y2', height+paddingTop);
        g.selectAll('.tick text')
           // .attr('transform', `translate(0, -6)`)
            .attr('fill', '#000000')
            .style('font-size', '6px');
        g.attr('text-anchor', 'middle');

    };
    if (multiexport) svg.select(`#${chartName}TimeAxis`).call(customXAxisMultiexport);
    else svg.select(`#${chartName}TimeAxis`).call(customXAxis);

};

const drawYAxis = (svg, chartName, yScaleName, width, height, size, padding, multiexport) => {

    const yScale = getScale(yScaleName);
    if (!yScale) return;

    console.log({chartName, multiexport})
    const paddingLeft = padding && padding.left ? padding.left : 0;

    // console.log('drawYAxis',paddingLeft);
    // const marginTop = margin ? margin.top : 0;
    // console.log(`paddingLeft = ${paddingLeft}`)
    const paddingBottom = padding ? padding.bottom : 0;
    const paddingTop = padding ? padding.top : 0;
    const titleHeight = padding && padding.title ? padding.title : 0;
    const maxScaleVal = yScale.domain()[1];
    const yAxis = axisLeft(yScale)
        .tickSize(0)
        .tickPadding(multiexport ? 2 : paddingLeft)
        .tickFormat(d => (multiexport || (yScale(d) - yScale(maxScaleVal) > 12)) ? d : '')
        .ticks(size==='small' ? 5 : 10);


    // console.log('[drawYAxis]', {chartName, yScaleName, multiexport});
    // if (chartName === 'seqNAChart') console.log(`[drawYAxis]: ${chartName} height = ${height}`);
    const customYAxis = (g) => {
        g.call(yAxis);
        g.selectAll('.domain')
            .attr('d', `M${-paddingLeft},${height + paddingTop}V${-paddingTop}M${width},${-paddingTop}V${height + paddingBottom + paddingTop}`)
            .attr('stroke', '#000000');
        
        // console.log( g.selectAll('.domain'));
        // .attr('stroke-width', 0.5)
        // .attr('stroke-opacity', 1);

        g.selectAll('line')
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3);

        g.selectAll('line')
            .attr('stroke-opacity', 1)
            .attr('x1', -paddingLeft)
            .attr('x2', width);
        g.selectAll('.tick text')
            .attr('transform', 'translate(5, -8)')
            .attr('fill', '#000000')
            .style('font-size', '14px');
        g.selectAll('.tick line').attr('opacity', 0.5);
        g.attr('text-anchor', 'start');

    };

    const customYAxisMultiexport = (g) => {
        g.call(yAxis);
        g.selectAll('.domain')
            .attr('d', `M${width},${paddingTop + titleHeight}V${height + paddingTop + titleHeight}`)
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 1);

        g.selectAll('line')
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3);

        g.selectAll('line')
            .attr('stroke-opacity', 1)
            .attr('x1', 0)
            .attr('x2', width);
        g.selectAll('.tick text')
            // .attr('transform', 'translate(5, -8)')
            .attr('fill', '#000000')
            .style('font-size', '6px');
        g.selectAll('.tick line').attr('opacity', 0.5);
        g.attr('text-anchor', 'end');

    };
    if (multiexport) svg.select(`#${chartName}YAxis`).call(customYAxisMultiexport);
    else svg.select(`#${chartName}YAxis`).call(customYAxis);
};

const drawChartAxes = (svg, chartName, xScaleName, yScaleName, width, height, sizeX, sizeY, margin, padding, multiexport) => {
    // if (chartName === 'caseAFChart') 
    // console.log(`[drawChartAxes]:`,{chartName, xScaleName, width, height, sizeX, margin, padding, multiexport});
    
    drawXAxis(svg, chartName, xScaleName, width, height, sizeX, padding, multiexport);
    drawYAxis(svg, chartName, yScaleName, width, height, sizeY, padding, multiexport);
};


export { drawChartAxes };
