
import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, IconButton, InputAdornment, TableSortLabel,
    MenuItem, FormControl, Button, Autocomplete
} from '@mui/material';
// import CustomLabel from '../../../assets/GlobalStyles/CustomLabel';
import { styles, StyledTableCell } from './styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../../components/Alerts/styles';
import Info from '../Info';
import AddSubset from './Actions/Add';
import { getBiggerForSortTable } from '../../../functions/functions';
// import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
import { fetchCustomTreeSubsets } from '../../../redux/actions/settingsActions';
import DeleteSubset from './Actions/Delete';
import EditSubset from './Actions/Edit';
import { shouldFetch } from '../../../functions/functions';

function createData(subset) {
    const { key, label, showAll, lineage } = subset;
    const ruleString = subset.rule ? subset.rule.name : 'file based';

    return { key, label, lineage, rule: ruleString, showAll: showAll.toString() };
}

const orderInitState = {
    key: 'none',
    label: 'none',
    lineage: 'none',
    rule: 'none',
    showAll: 'none',
};

const searchState = {
    key: '',
    label: '',
    lineage: '',
}

const searchOptionsState = {
    key: [],
    label: [],
    lineage: [],
}

const SubsetsTable = (props) => {
    const { customSubsets, fetchCustomTreeSubsets, customSubsetsLength, status, lineages } = props;
    const classes = styles();
    const [orderBy, setOrderBy] = useState({ ...orderInitState, key: 'asc' });
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searched, setSearched] = useState({ ...searchState });
    const [infoDialog, setInfoDialog] = useState(false);    
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);

    useEffect(() => {
        if (shouldFetch(status))
            fetchCustomTreeSubsets();
    }, []);

    useEffect(() => {
        filterafterSearchClicked();
    }, [tableData]);

    useEffect(() => {
        if (customSubsetsLength === 0) return;

        setTableSubsets();
    }, [customSubsets]);

    const setTableSubsets = () => {
        const rows = customSubsets.map((customSubset) => {
            return createData(customSubset);
        });

        const newOptions = {
            key: ["", ...new Set(customSubsets.map(el => el.key))],
            label: ["", ...new Set(customSubsets.map(el => el.label))],
            lineage: ["", 'all', ...new Set(customSubsets.map(el => el.lineage))],
        }
        setSearchOptions({ ...newOptions });
        setTableData(rows);
        const sorted = sortData([...rows], 'key', 'asc');
        setDisplayedTableData(sorted);
    }

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e, type) => {
        setSearched({ ...searched, [type]: e });
    };

    const clearField = (type) => {
        setSearched({ ...searched, [type]: '' });
    }

    const filterafterSearchClicked = () => {
        let filteredRows = [...tableData];

        if (searched.key !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.key.toLowerCase().includes(searched.key.toLowerCase())
            });
        }

        if (searched.label !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.label.includes(searched.label)
            });
        }

        if (searched.lineage !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.lineage.toLowerCase().includes(searched.lineage.toLowerCase())
            });
        }

        setDisplayedTableData(filteredRows);
        setPage(0);
    }

    const cancelSearch = () => {
        setSearched({ ...searchState });
        setDisplayedTableData(tableData);
    };

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc'

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted)
    }

    return (
        <>
            <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                <div className={classes.searchcont}>
                    <Autocomplete
                        id="key"
                        options={searchOptions.key}
                        getOptionLabel={(option) => option}
                        value={searched.key}
                        className={classes.searchAuto}
                        onChange={(event, newValue) => requestSearch(newValue, 'key')}
                        inputValue={searched.key}
                        onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'key')}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                className={classes.search}
                                label="Subset key"
                                InputProps={{
                                    style: { paddingRight: 0 },
                                    ...params.InputProps,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearField('key')}>
                                                <HideIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="label"
                        options={searchOptions.label}
                        getOptionLabel={(option) => option}
                        value={searched.label}
                        className={classes.searchAuto}
                        onChange={(event, newValue) => requestSearch(newValue, 'label')}
                        inputValue={searched.label}
                        onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'label')}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                label="Label"
                                className={classes.search}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { paddingRight: 0 },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearField('label')}>
                                                <HideIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="lineage"
                        options={searchOptions.lineage}
                        getOptionLabel={(option) => option}
                        value={searched.lineage}
                        className={classes.searchAuto}
                        onChange={(event, newValue) => requestSearch(newValue, 'lineage')}
                        inputValue={searched.lineage}
                        onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'lineage')}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                label="Lineage"
                                className={classes.search}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { paddingRight: 0 },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearField('lineage')}>
                                                <HideIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        )}
                    />
                </div>
                <Button className={classes.saveButton} onClick={filterafterSearchClicked}>
                    Search
                </Button>
                <Button className={classes.cancelButton} onClick={cancelSearch}>
                    Cancel
                </Button>
                <TableContainer >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.key !== 'none'}
                                        direction={orderBy.key !== 'none' ? orderBy.key : 'asc'}
                                        onClick={(e) => handleSort('key')}
                                    >
                                        Key
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.label !== 'none'}
                                        direction={orderBy.label !== 'none' ? orderBy.label : 'asc'}
                                        onClick={(e) => handleSort('label')}
                                    >
                                        Label
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.lineage !== 'none'}
                                        direction={orderBy.lineage !== 'none' ? orderBy.lineage : 'asc'}
                                        onClick={(e) => handleSort('lineage')}
                                    >
                                        Lineage
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.rule !== 'none'}
                                        direction={orderBy.rule !== 'none' ? orderBy.rule : 'asc'}
                                        onClick={(e) => handleSort('rule')}
                                    >
                                        Regex
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                    >
                                        Actions
                                    </TableSortLabel>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rowsPerPage > 0
                                    ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : displayedTableData
                                ).map((row, index) => (
                                    <TableRow key={`${row.key}_${index}`} >
                                        <TableCell component="th" scope="row">
                                            {row.key}
                                        </TableCell>

                                        <TableCell align="left">
                                            {row.label}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.lineage}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.rule}
                                        </TableCell>

                                        <TableCell align="left">
                                             <DeleteSubset
                                                subset={row}
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                                fetchCustomTreeSubsets={fetchCustomTreeSubsets}
                                            />
                                            <EditSubset
                                                subset={row}
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                                fetchCustomTreeSubsets={fetchCustomTreeSubsets}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            <TableRow
                                key='last'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <td>
                                    <AddSubset
                                        lineages={lineages}
                                        setInfo={setInfo}
                                        setInfoDialog={setInfoDialog}
                                        setAction={setAction}
                                        fetchCustomTreeSubsets={fetchCustomTreeSubsets}
                                    />
                                </td>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={displayedTableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </>
    );
};

const mapStateToProps = (state) => {
    const { customSubsets } = state.settings;
    const { lineages } = state.lineages;

    return {
        status: customSubsets.status,
        customSubsets: customSubsets.data,
        customSubsetsLength: customSubsets.data.length,
        lineages
    }
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCustomTreeSubsets,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubsetsTable);
