import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
    root: {
        // flexGrow: 1,
        height: 'calc(100vh - 131px)',
        maxHeight: 'calc(100vh - 131px)'
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    card: {
        maxWidth: 600,
        margin: 'auto',
    },
    media: {
        minHeight: 330,
    },
    legend: {
        marginTop: '5px',
        maxWidth: '300px',
        float: 'left',
    },
    dataLegend: {
        margin: '0px 15px'
    },
    item: {
        position: 'relative',
        height: '100%',
        maxHeight: '100%',
        // overflowY: isFirefox ? 'auto' : 'overlay',
        overflow: 'none'
    },
    sidebar: {
        // overflowY: isFirefox ? 'auto' : 'overlay',
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden'
    },
    fitnessSidebarLeft: {
        right: 0,
        height: '100%',
        maxHeight: '100%',
        // height: 'calc(100% - 59px)',
        // maxHeight: 'calc(100% - 85px)',
        // padding: '0px 10px 0px 20px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        width: '360px',
        maxWidth: '360px',
        overflow: 'hidden'
    },
    expandButton: {
        marginTop: '7px'
    },
    fitnessSidebarRight: {
        position: 'absolute',
        right: 0,
        height: 'calc(100% - 132px)',
        maxHeight: 'calc(100% - 132px)',
        // padding: '0px 20px 0px 10px',
        marginTop: '1px',
        boxSizing: 'border-box',
        width: '360px',
        maxWidth: '360px',
        backgroundColor: 'white',
        overflow: 'hidden'
    },
    button: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        textTransform: 'none',
        marginBottom: '15px',
        marginRight: '5px',
        "&:hover": {
            backgroundColor: '#FFFFFF'
        }
    },
    space: {
        marginBottom: '15px',
        height: 'calc(100vh - 132px)',
        maxHeight: 'calc(100vh - 132px)'
    }
});
