import {
    FETCH_GEO_DATA_REQUEST,
    FETCH_GEO_DATA_SUCCESS,
    FETCH_GEO_DATA_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_COLORING,
    SET_PARAMETERS,
    SET_LAYOUT_PARAMETERS,
    // SET_GEO_LOADING
} from "../actions/actionTypes";
import deepFreeze from 'deep-freeze';
let geomapInitialState = { };
export const setGeomapInitialState = (state) => {
    geomapInitialState = state;
};

export const geoMapReducer = (state = geomapInitialState, action) => {
    deepFreeze(state);

    switch (action.type) {
        case FETCH_GEO_DATA_REQUEST:
            return {
                ...state,
                // geoLoading: true,
                geoMapStatus: 'loading'
            }
        case FETCH_GEO_DATA_SUCCESS:
            return {
                ...state,
                // geoLoading: false,
                geoMapStatus: 'loaded',
                geojson: action.payload.geojson
            }
        case FETCH_GEO_DATA_ERROR:
            return {
                ...state,
                // geoLoading: false,
                geoMapStatus: 'error',
                error: action.payload
            }
        case SET_COLORING: {
            const { coloring } = action.payload;
            return {
                ...state,
                coloring
            }
        }
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return geomapInitialState
        }

        case SET_PARAMETERS: 
        case SET_LAYOUT_PARAMETERS: {
            // console.log(action.payload);
            const a = action.payload?.parameters?.mapParams;
            // const b = action.payload?.parameters?.geoMapColorBy;
            const c = action.payload?.parameters?.mutgene;
            const d = action.payload?.parameters?.mutpostition;
        
            const e = action.payload?.parameters?.caseSpan;
            const f = action.payload?.parameters?.geoFreqCategory;
            const geoMapStatus = a || c || d || f ? state.geoMapStatus : 'none';

            // console.log(a,c, d, e, a || c || d, '=>',  geoMapStatus);
            return {
                ...state,
                geoMapStatus
            }
        }
        // case SET_GEO_LOADING: {
        //     const { geoLoading } = action.payload;
        //     return {
        //         ...state,
        //         geoLoading
        //     }
        // }
        default:
            return state || geomapInitialState;
    }
}
