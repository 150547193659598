const styles = theme => ({
    legendCircleWrapper: {
        height: '26px',
        display: 'flex',
        alignItems: 'center'
    },
    legendText: {
        color: 'black',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        lineHeight: '26px',
    },

    legendCircle: {
        borderRadius: '50%',
        width: 15,
        height: 15,
        marginRight: 9,
        flexShrink: 0,
    },
    valuesTableTdLastCol: {
        textAlign: 'right'
    },
    maxInput: {
        textAlign: 'right',
        border: 'none',
        fontFamily: "Inter",
        fontSize: 12,
        '&:focus': {
            'outline': 'none'
        }
    },
    minInput: {
        border: 'none',
        fontSize: 12,
        fontFamily: "Inter",
        '&:focus': {
            'outline': 'none'
        }
    },
    typography: {
        fontSize: '12px',
    },

    exportableComponent: {
        height: '100%',
        maxHeight: '100%'
    },
    compact: {
        height: '300px',
        maxHeight: '50%'
    },

    tableRoot: {
        height: '100%'
    },
    table: {
        borderSpacing: '0px 5px',
        borderCollapse: 'separate',
        padding: '12px'
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.dark,
        },
        fontSize: 15,
        height: 'auto',
        minHeight: 30,
        maxHeight: 25
    },
    cell: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 12,
        paddingRight: 12,
        borderBottom: 0,
        height: 20,
        width: 110,
        maxWidth: 110,
        minWidth: 110,
        verticalAlign: 'top',
    },
    cellValue: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 12,
        paddingRight: 12,
        borderBottom: 0,
        height: 20,
        width: '100%',
        maxWidth: '100%',
        verticalAlign: 'top',
    }
});

export {
    styles
}
