// Action Types
import {
    FETCH_PREDICTIONS_REQUEST,
    FETCH_PREDICTIONS_SUCCESS,
    FETCH_PREDICTIONS_ERROR,
    RESET_PREDICTIONS,
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';

const fetchPredictions = (payload) => {
    const { strainSubset } = payload;
    const subsetId = strainSubset;
    const _payload = { ...payload, subsetId };
    return received(FETCH_PREDICTIONS_REQUEST, _payload)
}

const fetchPredictionsSuccess = res => received(FETCH_PREDICTIONS_SUCCESS, res)
const fetchPredictionsError = err => received(FETCH_PREDICTIONS_ERROR, err)

const resetPredictions = (payload) => received(RESET_PREDICTIONS, payload);


export { fetchPredictions, fetchPredictionsSuccess, fetchPredictionsError, resetPredictions };
