import deepFreeze from 'deep-freeze';
import {
    SET_COLOR_BY,
    SET_MODEL_TYPE,
    SET_REFERENCE_CLADE,
    SET_REFERENCE_STRAIN,
    SET_ANTIGENIC_HIDDEN_ALPHA_CLADE,
    SET_ANTIGENIC_HIDDEN_RHO_CLADE,
    SET_FITNESS_HIDDEN_ALPHA_CLADE,
    SET_FITNESS_HIDDEN_RHO_CLADE,
    RESET_ANTIGENIC_HIDDEN_CLADES,
    FETCH_LINEAGE_SUCCESS,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS,
    SET_PARAMETERS,
    INIT_STRAIN_TREE_SUCCESS,
    ZOOM_TREE_SUCCESS,
    FETCH_TREE_FREQS_SUCCESS,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    TOGGLE_FREQUENCIES_OPTION,
    TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY,
    RESET_VISIBLE_BINS,
    FETCH_FREQUENCIES_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    SET_MUTATION_CLASSES,
    SET_MEASURE_SCALE,
    SET_MEASURE_DOMAIN,
    GET_SIGNED_USER_SUCCESS,
    FETCH_SELECTED_STRAIN_REQUEST,
    SET_SEARCH_STRAIN_MODE,
    UNHIDE_ALPHA_CLADE,
    UNHIDE_RHO_CLADE,
    SELECT_NODE_DATA,
    UNHIDE_RHO_CLADE_FITNESS,
    RESET_FITNESS_HIDDEN_CLADES,
    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_MODEL_TYPES_SUCCESS,
    FETCH_MODELS_SUCCESS,
    SET_MODEL_REGION_ID,
    FETCH_ANTIGENIC_MODELS_LIST_SUCCESS,
    SET_SELECTED_MODELS,
    SET_SHOW_MUTATIONS_GROUPS,
    SET_LAYOUT_PARAMETERS,
    SET_SHOW_CLADE_LABELS,
    SET_SHOW_REFERENCE_STRAINS,
    SIGNIN_SUCCESS,
    UNHIDE_ALPHA_CLADE_FITNESS,
    SET_BRANCH_NODES,
    SET_SELECTED_STRAIN,
} from '../actions/actionTypes';

import appConfig from '../../config/appConfig';
import { prepareParameters, emptyObject } from '../../functions/functions';
import { cloneDeep } from 'lodash';

let parametersInitialState = {};
export const setParametersInitialState = (state) => {
    parametersInitialState = state;
};

export default function parametersReducer(state = parametersInitialState, action) {
    deepFreeze(state);
    if (action.payload && action.payload.settings) return state;

    switch (action.type) {
        case RESET_SESSION: {
            const { lineage } = action.payload;
            
            return {
                ...parametersInitialState,
                lineage
            };
        }
        case SIGNOUT_REQUEST: {
            return {
                ...parametersInitialState,
            };
        }
        case SIGNIN_SUCCESS: {
            const { defaultLineage } = action.payload;

            return {
                ...state,
                lineage: defaultLineage
            }
        }
        case FETCH_SELECTED_STRAIN_REQUEST: {
            const { strainId } = action.payload;

            return {
                ...state,
                strainId
            }
        }

        case SET_SELECTED_STRAIN: {
            const { strainId } = action.payload;
            return {
                ...state,
                strainId,
                searchStrainMode: false
            }
        }
        case SET_SEARCH_STRAIN_MODE: {
            const { searchStrainMode } = action.payload;
            return {
                ...state,
                searchStrainMode,
                // strainId: !searchStrainMode ? null : state.strainId
            }
        }

        case SET_COLOR_BY: {
            const { colorBy } = action.payload;
            return { ...state, colorBy };
        }


        case SET_BRANCH_NODES: {
            const { branchNodes } = action.payload;
            return { ...state, branchNodes };
        }

        case SET_MODEL_REGION_ID: {
            const { modelRegionId } = action.payload;
            return { ...state, modelRegionId };
        }
        case SET_MODEL_TYPE: {
            const { modelType } = action.payload;
            return { ...state, modelType };
        }

        case SET_REFERENCE_CLADE: {
            const { refClade } = action.payload;
            return { ...state, refClade };
        }
        case SET_REFERENCE_STRAIN: {
            const { refStrain } = action.payload;
            return {
                ...state,
                refStrain
            };
        }

        case SET_ANTIGENIC_HIDDEN_ALPHA_CLADE: {
            const { alpha } = action.payload;
            const _hiddenClades = { ...state.hiddenAlphaClades };
            _hiddenClades[alpha] = true;

            return {
                ...state,
                hiddenAlphaClades: _hiddenClades,
            };
        }

        case SET_ANTIGENIC_HIDDEN_RHO_CLADE: {
            const { rho } = action.payload;
            const _hiddenClades = { ...state.hiddenRhoClades };
            _hiddenClades[rho] = true;
            return {
                ...state,
                hiddenRhoClades: _hiddenClades,
            };
        }

        case UNHIDE_ALPHA_CLADE: {
            const { alpha } = action.payload;
            const _hiddenClades = { ...state.hiddenAlphaClades };
            delete _hiddenClades[alpha];

            return {
                ...state,
                hiddenAlphaClades: _hiddenClades,
            };
        }

        case UNHIDE_RHO_CLADE: {
            const { rho } = action.payload;
            const _hiddenClades = { ...state.hiddenRhoClades };
            delete _hiddenClades[rho];

            return {
                ...state,
                hiddenRhoClades: _hiddenClades,
            };
        }

        case RESET_ANTIGENIC_HIDDEN_CLADES: {
            return {
                ...state,
                hiddenAlphaClades: {},
                hiddenRhoClades: {},
            };
        }

        // case SET_FITNESS_HIDDEN_ALPHA_CLADE: {
        //     const { alpha } = action.payload;
        //     const _hiddenClades = { ...state.hiddenAlphaCladesFitness };
        //     _hiddenClades[alpha] = true;

        //     return {
        //         ...state,
        //         hiddenAlphaCladesFitness: _hiddenClades,
        //     };
        // }

        // case SET_FITNESS_HIDDEN_RHO_CLADE: {
        //     const { rho } = action.payload;
        //     const _hiddenClades = { ...state.hiddenAlphaCladesFitness };
        //     _hiddenClades[rho] = true;
        //     return {
        //         ...state,
        //         hiddenAlphaCladesFitness: _hiddenClades,
        //     };
        // }

        // case UNHIDE_ALPHA_CLADE_FITNESS: {
        //     const { alpha } = action.payload;
        //     const _hiddenClades = { ...state.hiddenAlphaClades };
        //     delete _hiddenClades[alpha];

        //     return {
        //         ...state,
        //         hiddenAlphaClades: _hiddenClades,
        //     };
        // }

        // case UNHIDE_RHO_CLADE_FITNESS: {
        //     const { rho } = action.payload;
        //     const _hiddenClades = { ...state.hiddenRhoCladesFitness };
        //     delete _hiddenClades[rho];

        //     return {
        //         ...state,
        //         hiddenRhoCladesFitness: _hiddenClades,
        //     };
        // }

        // case RESET_ANTIGENIC_HIDDEN_CLADES: {
        //     return {
        //         ...state,
        //         hiddenAlphaCladesFitness: {},
        //         hiddenRhoCladesFitness: {},
        //     };
        // }
        

        case FETCH_FREQUENCIES_SUCCESS: {
            const { frequencies, selectedBins, parameters } = action.payload;
            const { intro } = state ;
            const visibleBins = { ...state.visibleBins };
            
            const frequenciesStatus = { ...state.frequenciesStatus };

            let _parameters = prepareParameters(parameters);

            Object.keys(frequencies).forEach(regId => {
                frequenciesStatus[regId] = 'loaded';
            });

            if (intro) {
                _parameters.trackingTo = _parameters.freqsTo;
                _parameters.trackingFrom = _parameters.freqsFrom;
                _parameters.showPrediction = false;
            }


            // const selectedBinsAll = Object.keys(selectedBins).reduce((acc, regId) => {
            //     Object.keys(selectedBins[regId]).forEach(bin => { acc[bin] = true; });
            //     return acc;
            // }, {});

         

            if (emptyObject(visibleBins)) {
                Object.keys(selectedBins).forEach(regId => {
                    Object.keys(selectedBins[regId]).forEach(bin => { visibleBins[bin] = true; });
                    
                });
            }
            return {
                ...state,
                visibleBins,
                ..._parameters
            };
        }

        case TOGGLE_FREQUENCIES_OPTION: {
            const vC = { ...state.visibleBins };
            const { optionId } = action.payload;
            vC[optionId] = !vC[optionId];
            return {
                ...state,
                visibleBins: { ...vC }
            };
        }

        case TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY: {
            const visibleMutationClassesLabels = { ...state.visibleMutationClassesLabels };
            const { id } = action.payload;
            visibleMutationClassesLabels[id] = !(visibleMutationClassesLabels[id] || false);
            return {
                ...state,
                visibleMutationClassesLabels
            };
        }

        case RESET_VISIBLE_BINS: {
            const { selectedBins, strainSubset } = action.payload;
            console.log('RESET_VISIBLE_BINS', strainSubset, selectedBins)
            const visibleBins = {};
            console.log('')
            Object.keys(selectedBins[strainSubset]).forEach(bin => { visibleBins[bin] = true; });
            return {
                ...state,
                visibleBins
            };
        }

        case FETCH_FREQUENCIES_REQUEST: {
            const { resetVisibleBins } = action.payload;
            return {
                ...state,
                visibleBins: resetVisibleBins ? {} : state.visibleBins,
            }
        }


        case SET_SELECTED_MODELS: {
            const { selectedModels } = action.payload;

            return {
                ...state,
                selectedModels
            }
        }

        case FETCH_LINEAGE_SUCCESS:
        case ZOOM_TREE_SUCCESS:
        case INIT_STRAIN_TREE_SUCCESS:
        case FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS:
        case FETCH_TREE_FREQS_SUCCESS:
        case SET_LAYOUT_PARAMETERS:
        case SET_PARAMETERS: {
            // if (action.type === FETCH_LINEAGE_SUCCESS) console.log(FETCH_LINEAGE_SUCCESS);
            //console.log('SET_PARAMETERS parametersReducer', action.payload?.parameters.showMutationsGroups);
            //if (action.type === FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS) console.log('FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS. parameters 1')
            const { parameters } = action.payload;
            
            const _parameters = prepareParameters(parameters);
          
            // const { showMutationsGroups, visibleMutationClasses } = (parameters || {});
            // if (!emptyObject(showMutationsGroups)) console.log('reducer, showMutationsGroups',showMutationsGroups)
            // const _visibleMutationClasses = visibleMutationClasses
            //     ? visibleMutationClasses
            //     : ((!emptyObject(showMutationsGroups) && showMutationsGroups === false) ? {} : state.visibleMutationClasses);

            // console.log(_visibleMutationClasses);
            if (action.type === FETCH_LINEAGE_SUCCESS && window.innerWidth < 786)
                _parameters.showCladeBarLabels = false;

            //let newSelectedModels = selectedModels ? selectedModels : prepareSelectedModels(state.selectedModels, _parameters);

            return {
                ...state,
                ..._parameters,
                //    selectedModels: newSelectedModels,
                // visibleMutationClasses: _visibleMutationClasses
            };
        }

        case SET_SHOW_MUTATIONS_GROUPS: {
            const { showMutationsGroups } = action.payload;
            return {
                ...state,
                showMutationsGroups
            }
        }

        case SET_SHOW_REFERENCE_STRAINS: {
            const { showReferenceStrains } = action.payload;
            return {
                ...state,
                showReferenceStrains
            }
        }

        case SET_SHOW_CLADE_LABELS: {
            const { showCladeLabels } = action.payload;
            return {
                ...state,
                showCladeLabels
            }
        }

        case SET_MEASURE_SCALE: {
            const { measure, scaleName } = action.payload;
            const colorScales = { ...state.colorScales };

            if (scaleName === colorScales[measure]) return { ...state };
            colorScales[measure] = scaleName;


            const calculatedDomain = { ...state.calculatedDomain };
            delete calculatedDomain[measure]
            return {
                ...state,
                colorScales,
                calculatedDomain
            }
        }

        case SET_MEASURE_DOMAIN: {
            const { measureName, min, max, paramForMeasure } = action.payload;
            const calculatedDomain = cloneDeep(state.calculatedDomain);

            if (!calculatedDomain[measureName]) calculatedDomain[measureName] = {};

            if (paramForMeasure && state[paramForMeasure]) {
                const value = state[paramForMeasure];
                if (!calculatedDomain[measureName][paramForMeasure]) calculatedDomain[measureName][paramForMeasure] = {};
                calculatedDomain[measureName][paramForMeasure][value] = {};
                calculatedDomain[measureName][paramForMeasure][value].min = min;
                calculatedDomain[measureName][paramForMeasure][value].max = max;
                return { ...state, calculatedDomain };
            }

            calculatedDomain[measureName].max = max;
            calculatedDomain[measureName].min = min;
            return { ...state, calculatedDomain };
        }

        case FETCH_MUTATION_CLASSES_SUCCESS: {
            const { mutationClasses } = action.payload;
            const visibleMutationClasses = {};
            const { exportMode } = { ...state }

            if (exportMode) return { ...state }

            Object.keys(mutationClasses).forEach(mutClass => {
                const mutation = {};
                Object.keys(mutationClasses[mutClass]).forEach(mutName => {
                    mutation[mutName] = true;

                });
                visibleMutationClasses[mutClass] = mutation;
            })
            return { ...state, visibleMutationClasses };
        }

        case SET_MUTATION_CLASSES: {
            const { mutType, mutClass } = action.payload;
            let visibleMutationClasses = cloneDeep(state.visibleMutationClasses);

            visibleMutationClasses[mutType][mutClass] = !visibleMutationClasses[mutType][mutClass];

            return { ...state, visibleMutationClasses };
        }

        case GET_SIGNED_USER_SUCCESS: {
            let defaultLineageSettled = action.payload.defaultLineage || state.lineage || appConfig.default.lineage;
            const { lineages } = action.payload;
            if (lineages && lineages.length > 0 && !lineages.includes(defaultLineageSettled))
                defaultLineageSettled = lineages[0]

            return {
                ...state,
                lineage: defaultLineageSettled
            }
        }

        case SELECT_NODE_DATA: {
            const nodeId = action.payload?.nodeId;
            const onlyHighlight = action.payload?.onlyHighlight || false;
            return {
                ...state,
                searchStrainMode: nodeId && !onlyHighlight ? state.searchStrainMode : false
            };

        }

        case FETCH_HUMAN_POOLS_SUCCESS: {
            const { humanPools } = action.payload;
            let humanPool = state.humanPool;
            if (humanPools && humanPools.length > 0 && (!humanPool || !humanPools.includes(humanPool)))
                humanPool = humanPools[0];
            return {
                ...state,
                humanPool
            };
        }


        case FETCH_MODEL_TYPES_SUCCESS: {
            const { modelTypes } = action.payload;

            const modelType = modelTypes.includes(state.modelType) && modelTypes.length > 0 ?
                state.modelType
                : modelTypes[0]
            //console.log('modelType = ',modelType);
            return {
                ...state,
                modelType
            };
        }

        case FETCH_MODELS_SUCCESS: {
            const { models, colorBy } = action.payload;

            // console.log(FETCH_MODELS_SUCCESS, colorBy);
            const modelId = models && models.length > 0 && models.includes(state.modelId)
                ? state.modelId
                : models[0];
            const modelVar = (colorBy === 'antigenic') ? 'antigenicModelId' : 'modelId';
            //console.log('modelId = ',modelId);
            return {
                ...state,
                [modelVar]: modelId
            };
        }

        // case FETCH_ANTIGENIC_MODELS_LIST_SUCCESS: {
        //     const { antigenicModels } = action.payload;


        //     const antigenicModelId = antigenicModels.includes(state.antigenicModelId) && antigenicModels.length > 0
        //         ? state.antigenicModelId
        //         : antigenicModels[0]
        //     //console.log('modelId = ',modelId);
        //     return {
        //         ...state,
        //         antigenicModelId
        //     };
        // }

        default: {
            return state || parametersInitialState;
        }
    }
}
