import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import FrequenciesChart from '../../components/Charts/FrequenciesChart';
import SequencesChart from '../../components/Charts/SequencesChart';
import ChartOptions from '../../components/Charts/options/ChartOptions';
import { fetchFrequencies } from '../../redux/actions/frequenciesActions';
import { fetchPredictions } from '../../redux/actions/predictionsActions';

import { fetchTCellAntigenicityOptions } from '../../redux/actions/treeDataActions';
import { frequenciesStatusSelector } from '../../redux/selectors/frequenciesSelector';
import { getFrequencyCategories } from '../../redux/selectors/metadataSelector';
import { getPredictionsStatus } from '../../redux/selectors/statusSelector';
import { trackingToSelector, trackingFromSelector, predictionBaselineSelector, getScaleNameForFreqCategory } from '../../redux/selectors/parametersSelector';
import appConfig from '../../config/appConfig';
import { shouldFetch, dateToDays } from '../../functions/functions';
import { VIEWS_NAMES } from '../../config/consts';
import { fetchGenotypeData, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import { fetchLineage, fetchLineages } from '../../redux/actions/lineagesActions';
import { getIsMobile } from '../../redux/selectors/statusSelector';
import { styles } from './freqStyles';
import { fetchClades } from '../../redux/actions/cladeActions';
import { RENDER_STATUS } from '../../config/consts';
import ExportableComponent from '../Export/ExportableComponent';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import { bindActionCreators } from 'redux';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

// const propToString = (val) => {
//     if (val instanceof Object) return `Object, keys: ${Object.keys(val).length}`;
//     return val;
// };

// const whatChanged = (props, prevProps) => {
//     const merged = { ...(prevProps || {}), ...(props || {}) };
//     return Object.keys(merged)
//         .filter((p) => props[p] !== prevProps[p])
//         .map((key) => `${key}: ${propToString(prevProps[key])} => ${propToString(props[key])}`);
// };

const IntroFreq = React.memo((props) => {
    // console.log(props);
    const { lineageStatus } = props;
    const {
        classes, lineage, predictionBaseline, trackingFrom, cladeType,
        freqCategory, modelId, regionId, trackingTo,
        strainSubset,
        cladesStatus,
        mutgene, renderStatus,
        mutposition,
        sigmaAg,
        tau,
        frequenciesStatus,
        fetchFrequencies,
        showPrediction,
        gene,
        hla,
        freqMeasure,
        visibleBins,
        hiddenMenu,
        hiddenMenuMobile,
        isMobile,
        scaleName,
        fetchClades
    } = props;

    const initComponentData = async () => {
        await Promise.all([
            shouldFetch(cladesStatus) ? fetchClades({ lineage }) : null,
            // shouldFetch(tcellAntigenicityOptionsStatus)
            //     ? fetchTCellAntigenicityOptions({ lineage })
            //     : null,
        ]);
    };

    const initFrequenciesData = async () => {
        if (shouldFetch(frequenciesStatus) && (freqCategory !== 'genotype' || (mutgene && mutposition))) {
            fetchFrequencies({
                lineage,
                freqCategory,
                regionId,
                trackingFrom,
                predictionBaseline: trackingTo, //today,
                trackingTo: trackingTo, //today,
                gene,
                hla,
                // binMethod: _binMethod,
                // binCnt,
                resetVisibleBins: true,
                strainSubset,
                visibleBins,
                mutgene,
                mutposition,
                modelId,
                sigmaAg,
                tau,
                showPrediction,
                scaleName,
                cladeType
            }); // : null,
        }
    };

    // const initPredictionsData = () => {
    //     const {
    //         frequenciesStatus,
    //         predictionsStatus,
    //         fetchPredictions,
    //         lineage,
    //         freqCategory,
    //         modelId,
    //         modelStatus,
    //         regionId,
    //         predictionBaseline,
    //         sigmaAg,
    //         tau,
    //         trackingTo,
    //         gene,
    //         hla,
    //         binMethod,
    //         binCnt,
    //         strainSubset,
    //         showPrediction,

    //     } = props;

    //     if (shouldFetch(predictionsStatus) && frequenciesStatus === 'loaded' && showPrediction) {
    //         fetchPredictions({
    //             lineage,
    //             freqCategory,
    //             modelId,
    //             regionId,
    //             predictionBaseline,
    //             trackingTo,
    //             sigmaAg,
    //             tau,
    //             gene,
    //             hla,
    //             binMethod: binMethod || 'discrete',
    //             binCnt,
    //             strainSubset,
    //         });
    //     }
    // };


    useEffect(() => {
        //shouldFetch(lineagesStatus) && fetchLineages();
        if (lineageStatus !== 'loaded') return;
        initComponentData();
    }, [lineageStatus]);

    useEffect(() => {
        if (lineageStatus !== 'loaded') return;
        // if (freqCategory !== 'genotype') return;
        // if (shouldFetch(mutationsPositionsDictStatus))
        //     fetchMutationsPositionsDictionary({ lineage })
        // else 
        // if (mutationsPositionsDictStatus === 'loaded' && shouldFetch(frequenciesStatus)) 
        if (shouldFetch(frequenciesStatus)) initFrequenciesData();
    }, [freqCategory, mutgene, mutposition, trackingFrom, trackingTo, strainSubset, predictionBaseline, strainSubset, cladeType, frequenciesStatus])

    // useEffect(() => {
    //     if (lineageStatus !== 'loaded' /*|| cladesStatus !== 'loaded'*/ || frequenciesStatus === 'loading' || freqCategory === 'genotype') return;
    //     initFrequenciesData()

    // }, [freqCategory, /*cladesStatus,*/ trackingFrom, trackingTo, predictionBaseline, strainSubset, cladeType]);

    // useEffect(() => {
    //     if (lineageStatus !== 'loaded' || cladesStatus !== 'loaded' || frequenciesStatus === 'loading') return;
    //     initPredictionsData();
    // }, [sigmaAg, tau, trackingTo, cladesStatus]);

    return (
        <div className={classes.root}>
            <ErrorAlert />
            <Grid container className={classes.container}>
                {
                    isMobile ?
                        !hiddenMenuMobile ?
                            <Grid item xs={12} className={classes.cladeSidebar}>
                                <ChartOptions intro={true} />
                            </Grid>
                            :
                            <Grid item xs={12} className={classes.item}>
                                <FrequenciesChart id='freq-chart' viewName={VIEWS_NAMES.CLADE} />
                                <SequencesChart
                                    viewName={VIEWS_NAMES.CLADE}
                                    title="Curated sequence counts (per week, smoothened)"
                                    type="seq"
                                />
                                <SequencesChart
                                    viewName={VIEWS_NAMES.CLADE}
                                    title="Reported case counts (per day, smoothened)"
                                    type="case"
                                />
                            </Grid>
                        :
                        <>
                            <Grid item xs className={classes.item}>
                                <ExportableComponent filename="frequencies">
                                    <>
                                        <FrequenciesChart id='freq-chart' viewName={VIEWS_NAMES.CLADE} />
                                        <SequencesChart
                                            viewName={VIEWS_NAMES.CLADE}
                                            title="Curated sequence counts (per week, smoothened)"
                                            type="seq"
                                        />

                                        <SequencesChart
                                            viewName={VIEWS_NAMES.CLADE}
                                            title="Reported case counts (per day, smoothened)"
                                            type="case"
                                        />
                                        <div style={{ margin: '0 20px', paddingBottom: '15px' }}>
                                            <MetaInformations />
                                        </div>
                                    </>
                                </ExportableComponent>

                            </Grid>
                            <Grid item className={`${classes.cladeSidebar} ${hiddenMenu ? classes.hidden : ''}`}>
                                <ChartOptions intro={true} />
                            </Grid>
                        </>
                }
            </Grid>
            {
                (renderStatus === RENDER_STATUS.DONE) && (
                    <div id="exportDone" />
                )
            }
        </div >
    );
});

IntroFreq.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        item: PropTypes.string,
        itemExport: PropTypes.string,
        legend: PropTypes.string,
        rootExport: PropTypes.string,
        containerExport: PropTypes.string,
    }),
    // modelsStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    lineage: PropTypes.string,
    freqCategory: PropTypes.string,
    lineageStatus: PropTypes.string,
    modelId: PropTypes.string,
    regionId: PropTypes.string,
    trackingFrom: PropTypes.instanceOf(Date),
    predictionBaseline: PropTypes.instanceOf(Date),
    trackingTo: PropTypes.instanceOf(Date),
    gene: PropTypes.string,
    hla: PropTypes.string,
    binMethod: PropTypes.string,
    binCnt: PropTypes.number,
    renderStatus: PropTypes.string,
    exportParams: PropTypes.shape({}),
    fetchModels: PropTypes.func,
    // fetchCladeFrequenciesRegions: PropTypes.func,
    frequenciesStatus: PropTypes.string,
    fetchLineage: PropTypes.func,
    fetchFrequencies: PropTypes.func,
    predictionsStatus: PropTypes.string,
    colorBy: PropTypes.string,
    zoomNodeId: PropTypes.number,
    strainSubset: PropTypes.string,
    tcellAntigenicityOptionsStatus: PropTypes.string,
    fetchTCellAntigenicityOptions: PropTypes.func,
    fetchPredictions: PropTypes.func,
    initStrainTree: PropTypes.func,
};

const mapStateToProps = (state) => {
    const frequenciesStatus = frequenciesStatusSelector(state);
    const modelId = state.parameters.modelId;
    const predictionsStatus = getPredictionsStatus(state);

    const { freqCategoriesMap } = getFrequencyCategories(state);
    const freqMeasure = freqCategoriesMap[state.parameters.freqCategory];

    return {
        // modelsStatus: state.models.modelsStatus,
        modelStatus: state.modelData.modelStatus,
        frequenciesStatus,
        modelType: state.parameters.modelType,
        lineage: state.parameters.lineage,
        lineageStatus: state.lineages.lineageStatus,
        freqCategory: state.parameters.freqCategory,
        freqMeasure,
        regionId: state.parameters.regionId || appConfig.default.regionId,
        modelRegionId: state.parameters.modelRegionId || appConfig.default.modelRegionId,
        modelId,
        trackingFrom: trackingFromSelector(state),
        predictionBaseline: predictionBaselineSelector(state),
        showPrediction: state.parameters.showPrediction,
        trackingTo: trackingToSelector(state), //state.parameters.trackingTo,
        sigmaAg: state.parameters.sigmaAg,
        tau: state.parameters.tau,
        gene: state.parameters.gene,
        cladeType: state.parameters.cladeType,
        lineagesStatus: state.lineages.lineagesStatus,
        hla: state.parameters.hla,
        binMethod: state.parameters.binMethod,
        binCnt: state.parameters.binCnt,
        colorBy: state.parameters.colorBy,
        zoomNodeId: state.parameters.zoomNodeId,
        strainSubset: state.parameters.strainSubset,
        predictionsStatus,
        cladesStatus: state.cladeData.cladesStatus,
        renderStatus: state.render.renderStatus,
        tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
        visibleBins: state.parameters.visibleBins,
        genotypeDataStatus: state.genotype.genotypeDataStatus,
        mutgene: state.parameters.mutgene,
        mutposition: state.parameters.mutposition,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        hiddenMenu: state.render.hiddenMenu,
        hiddenMenuMobile: state.render.hiddenMenuMobile,
        isMobile: getIsMobile(),
        scaleName: getScaleNameForFreqCategory(state)
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFrequencies,
    fetchPredictions,
    fetchLineages,
    fetchGenotypeData,
    fetchTCellAntigenicityOptions,
    fetchClades
}, dispatch);

const IntroFreqWithStyles = withStyles(styles)(IntroFreq);

export default connect(mapStateToProps, mapDispatchToProps)(IntroFreqWithStyles);
// export { IntroFreqExport };
