import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { styles } from './styles';

const DeleteSubset = ({subset, setInfoDialog, setAction, setInfo, fetchCustomTreeSubsets}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteSubset = () => {
        const url = `${config.serverLink}/api/admin/deleteCustomTreeSubset`;
        const body = { key: subset.key, lineage: subset.lineage };

        postAxios(url, body)
            .then((response) => {
                const status = response.data.status;
                setAction('Delete subset');

                if (status === 'success'){
                    handleCloseDialog();
                    setInfo(`Subset ${subset.key} deleted sucesfully.`);
                    setInfoDialog(true);
                    fetchCustomTreeSubsets();
                } else {
                    handleCloseDialog();
                    setInfo(`An error occured during deleting subset: ${subset.key}.`);
                    setInfoDialog(true);
                }
            });
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteSubset} name={subset.key} scale={false} />
        </>

    );
};

export default DeleteSubset;
