import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, Grid, Divider, Tabs, Tab, Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Typography, Checkbox } from '@mui/material';
import { styles, NewRoleDialog } from '../../Users/styles';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import defaults from '../defaults';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const mergePermissions = (role) => {
    const mergeDefaults = (key) => ({ ...defaults[key], ...role[key] });
    role.antigenicPermissions = mergeDefaults('antigenicPermissions');
    role.frequenciesPermissions = mergeDefaults('frequenciesPermissions');
    role.mapPermissions = mergeDefaults('mapPermissions');
    role.strainTreePermissions = mergeDefaults('strainTreePermissions');
    role.fitnessPermissions = mergeDefaults('fitnessPermissions');
    return role;

}
const EditRoleDialog = ({ handleCloseDialog, openDialog, updateRole, role }) => {
    const [permissions, setPermissions] = useState(mergePermissions(role));

    const [value, setValue] = useState(0);
    const classes = styles();

    const handleTabsChange = (e, tab) => {
        const checked = e.target.checked;
        setPermissions({
            ...permissions,
            tabs: {
                ...permissions.tabs,
                [tab]: checked,
            }
        });

        if (!checked && (tab === 'antigenic' || tab === 'map' || tab === 'fitness'))
            setValue(0);
    };

    const handleAdminRightsChange = (e) => {
        setPermissions({
            ...permissions,
            adminRights: e.target.checked
        });
    };

    const handleDataAdminRightsChange = (e) => {
        setPermissions({
            ...permissions,
            dataAdminRights: e.target.checked
        });
    };

    const handleNameChange = (e) => {
        setPermissions({
            ...permissions,
            name: e.target.value
        });
    };

    useEffect(() => {
        console.log(permissions)
    })
    const handlePermissionChange = (e, permission, type) => {
        switch (type) {
            case 'strainTree': {
                setPermissions({
                    ...permissions,
                    strainTreePermissions: {
                        ...permissions.strainTreePermissions,
                        [permission]: e.target.checked
                    }
                });
                return;
            }
            case 'frequencies': {
                setPermissions({
                    ...permissions,
                    frequenciesPermissions: {
                        ...permissions.frequenciesPermissions,
                        [permission]: e.target.checked
                    }
                });
                return;
            }
            case 'antigenic': {
                setPermissions({
                    ...permissions,
                    antigenicPermissions: {
                        ...permissions.antigenicPermissions,
                        [permission]: e.target.checked
                    }
                });
                return;
            }
            case 'fitness': {
                setPermissions({
                    ...permissions,
                    fitnessPermissions: {
                        ...permissions.fitnessPermissions,
                        [permission]: e.target.checked
                    }
                });
                return;
            }
            case 'map': {
                setPermissions({
                    ...permissions,
                    mapPermissions: {
                        ...permissions.mapPermissions,
                        [permission]: e.target.checked
                    }
                });
                return;
            }
        }

    };

    const handleSubmit = () => {
        console.log(permissions)
        updateRole(permissions)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={NewRoleDialog}>
            <DialogTitle id="confirm-dialog-title">
                Add new role
            </DialogTitle>

            <DialogContent style={{ height: '450px', }}>
                <StyledTextField
                    id="name"
                    type="text"
                    label="Role name"
                    required
                    disabled
                    autoComplete='off'
                    className={classes.textField}
                    value={permissions.name}
                    onChange={handleNameChange}
                />

                <FormControlLabel
                    className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                    value={permissions.adminRights}
                    label={<Typography className={classes.formControlLabel}>Admin rights</Typography>}
                    control={(
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={permissions.adminRights}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                            onChange={handleAdminRightsChange}
                            className={classes.checkbox}
                        />
                    )}
                />

                <FormControlLabel
                    className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                    value={permissions.dataAdminRights}
                    label={<Typography className={classes.formControlLabel}>Data admin rights</Typography>}
                    control={(
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={permissions.dataAdminRights}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                            onChange={handleDataAdminRightsChange}
                            className={classes.checkbox}
                        />
                    )}
                />
                <Typography>Available tabs:</Typography>
                <Grid container className={classes.newRoleGrid}>
                    {
                        Object.keys(permissions.tabs).map(tab => {
                            const disabled = tab === 'strainTree' || tab === 'frequencies';
                            return (
                                <Grid item xs={3} key={tab}>
                                    <FormControlLabel
                                        className={classes.formControlAdd}
                                        value={tab}
                                        disabled={disabled}
                                        label={<Typography className={classes.formControlLabel}>{defaults.tabsLabels[tab]}</Typography>}
                                        control={(
                                            <Checkbox
                                                icon={<CircleUnchecked />}
                                                checked={permissions.tabs[tab]}
                                                disabled={tab === 'strainTree' || tab === 'frequencies'}
                                                checkedIcon={<RadioButtonCheckedIcon
                                                    style={{
                                                        color: disabled ? 'rgba(0, 0, 0, 0.6)' : '#6F6CFF'
                                                    }} />
                                                }
                                                onChange={(e) => handleTabsChange(e, tab)}
                                                className={classes.checkbox}
                                            />
                                        )}
                                    />
                                </Grid>
                            )

                        })
                    }
                </Grid>


                <Box sx={{ width: '100%' }} className={classes.root}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} >
                            <Tab label="Strain tree" {...a11yProps(0)} />
                            <Tab label="Frequencies" {...a11yProps(1)} />
                            {permissions.tabs.antigenic && <Tab label="Antigenic" {...a11yProps(2)} />}
                            {permissions.tabs.fitness && <Tab label="Fitness" {...a11yProps(3)} />}
                            {permissions.tabs.map && <Tab label="Map" {...a11yProps(4)} />}
                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                        <Grid container className={classes.newRoleGrid}>
                            {
                                Object.keys(permissions.strainTreePermissions).map(permission => {
                                    return (
                                        <Grid item xs={4} key={permission}>
                                            <FormControlLabel
                                                className={classes.formControlAdd}
                                                value={permission}
                                                label={<Typography className={classes.formControlLabel}>{defaults.strainTreePermissionsLabels[permission]}</Typography>}
                                                control={(
                                                    <Checkbox
                                                        icon={<CircleUnchecked />}
                                                        checked={permissions.strainTreePermissions[permission]}
                                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                        onChange={(e) => handlePermissionChange(e, permission, 'strainTree')}
                                                        className={classes.checkbox}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Grid container className={classes.newRoleGrid}>
                            {
                                Object.keys(permissions.frequenciesPermissions).map(permission => {
                                    return (
                                        <Grid item xs={4} key={permission}>
                                            <FormControlLabel
                                                className={classes.formControlAdd}
                                                value={permission}
                                                label={<Typography className={classes.formControlLabel}>{defaults.frequenciesPermissionsLabels[permission]}</Typography>}
                                                control={(
                                                    <Checkbox
                                                        icon={<CircleUnchecked />}
                                                        checked={permissions.frequenciesPermissions[permission]}
                                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                        onChange={(e) => handlePermissionChange(e, permission, 'frequencies')}
                                                        className={classes.checkbox}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        {permissions.tabs.antigenic &&
                            Object.keys(permissions.antigenicPermissions).map(permission => {
                                return (
                                    <Grid item xs={4} key={permission}>
                                        <FormControlLabel
                                            className={classes.formControlAdd}
                                            value={permission}
                                            label={<Typography className={classes.formControlLabel}>{defaults.antigenicPermissionsLabels[permission]}</Typography>}
                                            control={(
                                                <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checked={permissions.antigenicPermissions[permission]}
                                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                    onChange={(e) => handlePermissionChange(e, permission, 'antigenic')}
                                                    className={classes.checkbox}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        {permissions.tabs.fitness &&
                            Object.keys(permissions.fitnessPermissions).map(permission => {
                                return (
                                    <Grid item xs={4} key={permission}>
                                        <FormControlLabel
                                            className={classes.formControlAdd}
                                            value={permission}
                                            label={<Typography className={classes.formControlLabel}>{defaults.frequenciesPermissionsLabels[permission]}</Typography>}
                                            control={(
                                                <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checked={permissions.fitnessPermissions[permission]}
                                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                    onChange={(e) => handlePermissionChange(e, permission, 'fitness')}
                                                    className={classes.checkbox}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </TabPanel>

                    <TabPanel value={value} index={4}>
                        {permissions.tabs.map &&
                            Object.keys(permissions.mapPermissions).map(permission => {
                                return (
                                    <Grid item xs={4} key={permission}>
                                        <FormControlLabel
                                            className={classes.formControlAdd}
                                            value={permission}
                                            label={<Typography className={classes.formControlLabel}>{defaults.mapPermissionsLabels[permission]}</Typography>}
                                            control={(
                                                <Checkbox
                                                    icon={<CircleUnchecked />}
                                                    checked={permissions.mapPermissions[permission]}
                                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                    onChange={(e) => handlePermissionChange(e, permission, 'map')}
                                                    className={classes.checkbox}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </TabPanel>
                </Box>

            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm}
                    onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditRoleDialog;
