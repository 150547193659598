import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() => ({
    formControl: {
        minWidth: 100,
        margin: "8px 0px 0px 0px",
    },
    label: {
        whiteSpace: 'nowrap'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    },
    tooltip: {
        margin: 0,
        padding: 0
    },
    button: {
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    add: {
        paddingLeft: '0',
        width: '30px'
    },
    tabPanel: {
        padding: '10px 0 10px 0'
    }
}));
