import { Tab, Box } from '@mui/material';
import { TabPanel,TabContext, TabList } from '@mui/lab';
import { IconButton, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setParameters, setSelectedModels } from '../../redux/actions/parametersActions';
import { ExpandIcon, HideIcon } from '../Alerts/styles';
import SingleModelSelector from './SingleModelSelector';
import { styles } from './styles';
import { cloneDeep } from 'lodash';

const emptyModel = {
    modelRegionId: 'ALL',
    modelType: '',
    modelId: ''
};

const ButtonInTabs = ({ className, onClick, children }) => {
    return <Button className={className} onClick={onClick} children={children} />;
  };
  
const MutlipleModelsSelector = ({ selectedModels, setSelectedModels, chartLayout, strainSubset }) => {

    // console.log('[MultipleModelsSelector], selectedModels = ', selectedModels);
    const classes = styles();
    const [modelTabs, setModelTabs] = useState([]);
    const [value, setValue] = useState('1');

    useEffect( () => {
        setModelTabs(cloneDeep(selectedModels))
    }, []);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const handleClick = () => { 
        const newTabs = [ ...modelTabs, emptyModel];
        setModelTabs(newTabs);
        setValue(newTabs.length.toString());
    };

    const handleClose = (e, index) => { 
        e.stopPropagation();
        let newTabs = cloneDeep(modelTabs);
        newTabs.splice(index, 1);
        setValue('1');
        setModelTabs(newTabs);
        const selectedModels = newTabs.filter(({modelRegionId, modelType, modelId}) => modelRegionId.length > 0 && modelType.length > 0 && modelId.length > 0)
        setSelectedModels({ selectedModels, strainSubset });
    };

    const updateModelState = (index, model) => {
        let newTabs = cloneDeep(modelTabs);
        newTabs[index] = model;
        //console.log(newTabs)
        setModelTabs(newTabs);
    };

    const updateSelectedModels = (index, model, invalid, idIncomplete) => {
        let _selectedModels = cloneDeep(selectedModels);

        // console.log('[updateSelectedModels]', index, model, invalid, _selectedModels);
        //if (!valid) _selectedModels.splice(index, 1);
        //else 
    
        const _model = { ...model, invalid, idIncomplete };
        let newTabs = cloneDeep(modelTabs);
        newTabs[index] = _model;
        //console.log(newTabs)
        setModelTabs(newTabs);
        if ( !selectedModels[index])
            _selectedModels.push(_model);
        else
            _selectedModels[index] = _model;
        // console.log('[updateSelectedModels] selectedModels = ', _selectedModels);
        setSelectedModels({ selectedModels: _selectedModels, strainSubset });
    };

    // console.log('[MultipleModelSelector], modelTabs = ',modelTabs);
    return ( 
        <>
        {
            chartLayout === 'nonstacked' ? 
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} variant='fullWidth'>
                                { modelTabs.length > 0 && modelTabs.map((el, index) => (
                                    <Tab key={`tab-${index}`} style={{fontSize: '12px', padding: 0}} value={( index + 1 ).toString()} 
                                        label={ index !== 0 ?
                                            <span >
                                                {`Model ${index + 1}`}
                                                <IconButton component="div" onClick={(e) => handleClose(e, index)} >
                                                    <HideIcon />
                                                </IconButton>
                                            </span>
                                            :
                                            `Model ${index + 1}`
                                        }
                                    />
                                ))}

                                { modelTabs.length < 3 && modelTabs.length > 0 &&
                                    <ButtonInTabs onClick={handleClick} className={classes.add} >
                                        <ExpandIcon />
                                    </ButtonInTabs>
                                }
                            </TabList>
                        </Box>

                        { modelTabs.length > 0 && modelTabs.map((el, index) => (
                                <TabPanel key={`tab-${index}`} value={( index + 1 ).toString()} className={classes.tabPanel}>
                                    <SingleModelSelector 
                                        index={index} 
                                        updateSelectedModels={updateSelectedModels}
                                        {...modelTabs[index]}
                                        updateModelState={updateModelState}
                                    />
                                </TabPanel>
                            )
                        )}
                    </TabContext>
                </Box>
            :
            <div style={{paddingBottom: '8px'}}>
                { modelTabs.length > 0 &&
                    <SingleModelSelector 
                        index={0} 
                        updateSelectedModels={updateSelectedModels}
                        {...modelTabs[0]}
                        updateModelState={updateModelState}
                    />
                }
            </div>
            
        }
        </>
        
    );
}

const mapStateToProps = (state) => {
    const { selectedModels, chartLayout, strainSubset } = state.parameters
    return ({
        selectedModels,
        chartLayout,
        strainSubset
    });
};

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload)),
    setSelectedModels: payload => dispatch(setSelectedModels(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MutlipleModelsSelector);
