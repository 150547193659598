import React, { PureComponent } from 'react';
import { Grid } from '@mui/material';
import PredictionBaselinePicker from '../DatePickers/PredictionBaselinePicker';
import TrackingFromPicker from '../DatePickers/TrackingFromPicker';
import TrackingToPicker from '../DatePickers/TrackingToPicker';

export default class DatesSelector extends PureComponent {
    render = () => {
        return (
            <Grid container spacing={2} style={{ paddingTop: '13px' }}>
                <Grid item sm={12} md={6} style={{ paddingTop: '0px', marginTop: 0  }}>
                    <TrackingFromPicker />
                </Grid>
                <Grid item sm={12} md={6} style={{ paddingTop: '0px', marginTop: 0 }}>
                    <TrackingToPicker />
                </Grid>
                
                {!this.props.intro &&
                    <Grid item xs={12} style={{ padding: '0px 0px 0px 16px' }}>
                        <PredictionBaselinePicker type={'frequencies'} />
                    </Grid>
                }

            </Grid>
        );
    }
}
