import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { FormControl, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { setLayoutParameters } from '../../redux/actions/parametersActions';
import { bindActionCreators } from 'redux';
import { LAYOUT } from '../../config/dictionaries';

const useStyles = makeStyles({
    formControl: {
        margin: '8px 0px 8px 0px',
        minWidth: 120,
    },
});


const LayoutSelector = props => {

    const { layout, setLayoutParameters } = props;
    const classes = useStyles();

    const handleLayoutChange = event => {
        const layout = event.target.value;
        setLayoutParameters({ layout });
    };


    return <FormControl className={classes.formControl} fullWidth>
        <CustomLabel id={'layout'} label={'Layout'} />
        <CustomSelect
            value={layout}
            onChange={handleLayoutChange}
            inputProps={{
                name: 'layout',
                id: 'layout',
            }}
        >
            {Object.values(LAYOUT).map((l) => (
                <MenuItem key={l.value} value={l.value}>{l.label}</MenuItem>
            ))}
        </CustomSelect>
    </FormControl>
}

LayoutSelector.propTypes = {
    layout: PropTypes.string,
    setLayoutParameters: PropTypes.func,
};

const mapStateToProps = (state) => {
    return ({
        layout: state.parameters.layout
    });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setLayoutParameters
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(LayoutSelector);
