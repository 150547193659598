import React from 'react';

const Error404 = () => (
    <div className="container">
        <div className="row">
            <div className="col col-12">
                <div className="Error404">
                    <h1>Error404</h1>
                </div>
            </div>
        </div>
    </div>
);

export default Error404;
