import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    textField: {
        width: '100%',
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        },
        marginBottom: '18px'
    },
    textFieldSmall: {
        width: '150px',
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '150px',
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        },
        marginBottom: '18px'
    },
    line: {
        display: 'flex',
        alignItems: 'center'
    },
    metaButton: {
        backgroundColor: '#6F6CFF',
        // marginTop: '15px',
        padding: '4px', /* Adjusted padding values */
        color: 'white',
        textTransform: 'none',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
    },
    icon: {
        marginLeft: '7px',
        display: 'flex',
        alignItems: 'center'
    },
    emsg: {
        color: 'red',
        fontSize: '12px'
    },
    dataButton: {
        backgroundColor: '#6F6CFF',
        marginLeft: '20px',
        padding: '4px', /* Adjusted padding values */
        color: 'white',
        textTransform: 'none',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        padding: '5px 12px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        padding: '5px 12px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    formControlAdd: {
        marginRight: 0,
        display: 'table',
        verticalAlign: 'top'
    },
    newRoleGrid: {
        paddingLeft: '5px',
        marginTop: '8px',
        marginBottom: '5px',
    },
    checkbox: {
        padding: '0 4px',
        display:'table-cell',
        verticalAlign: 'top'
    },
    boolOption: {
        padding: '0 10px 0 0 ',
    },
    formControlLabel: {
        fontSize: '13px',
        marginTop: '2px'
    },
    tooltip: {
        margin: 0,
        padding: 0
    },
    info: {
        marginTop: '15px',
    },
    colorsGridLinear: {
        padding: '5px'
    },
    colorsGrid: {
        marginLeft: '5px',
        paddingTop: '25px'
    },
    domain: {
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    draggingListItem: {
        background: 'rgb(235,235,235)'
    },
    remove: {
        marginLeft: '15px',
        marginTop: '0px'
    },
    empty: {
    },
    domainError: {
        color: 'red'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listContainer: {
        display: 'flex',
        fontSize: '18px',
        backgroundColor: '#eee',
        flexDirection: 'column',
    },
    itemContainer: {
        backgroundColor: '#fff',
        border: '1px solid black',
        padding: '25px 70px',
        margin: '15px 50px',
    },
    measurePicker: {
        marginTop: '15px',
        width: 170
    },
    formControlLineage: {
        marginBottom: '5px'
    },
    helpText: {
        maxWidth: '360px'
    },
    cardFull: {
        margin: '0 0 10px 0',
    },
    card: {
        margin: 0,
        paddingBottom: 0
    },
    head: {
        marginBottom: '15px',
        marginTop: '15px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    expandbut: {
        marginTop: '7px'
    },
    buttonRemove: {
        marginTop: '5px',
        marginLeft: '140px'
    }
}));

export const EditMeasureDialogSX = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
        borderRadius: '12px',
        },
    }
};
