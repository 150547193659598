import { connect } from 'react-redux';
import { emptyObject } from '../../functions/functions';
import { StyledRegionalFrequenciesReport } from './StyledRegionalFrequenciesReport';
// import { fetchModels } from '../../redux/actions/modelActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import { fetchFrequencies, fetchFrequenciesForAllRegions } from '../../redux/actions/frequenciesActions';
import { fetchPredictions } from '../../redux/actions/predictionsActions';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { setRenderStatus } from '../../redux/actions/renderActions';
import { fetchTCellAntigenicityOptions } from '../../redux/actions/treeDataActions';
import { getRegionalReportFrequenciesStatus, getRegionalReportPredictionsStatus, trackingFromSelector, trackingToSelector } from '../../redux/selectors/frequenciesSelector';

import appConfig from '../../config/appConfig';
import { getScaleNameForFreqCategory, predictionBaselineSelector } from '../../redux/selectors/parametersSelector';
import { getSelectedModelsToFetch } from '../../redux/selectors/statusSelector';

const mapStateToProps = (state) => {
    const frequenciesStatus = getRegionalReportFrequenciesStatus(state); //frequenciesStatusSelector(state);
    const modelId = emptyObject(state.modelData.model) ? null : state.parameters.modelId;
    const predictionsStatus = getRegionalReportPredictionsStatus(state); //getPredictionsStatus(state);

    const scaleName = getScaleNameForFreqCategory(state);
    //const frequenciesStatusesRegions = getFrequenciesStatusesForStrainSubsets(state);
    // console.log('[FrequenciesChart] frequenciesStatusesRegions = ',frequenciesStatusesRegions)

    //const predictionsStatusesRegions = getPredictionsStatusesForStrainSubsets(state);

    return ({
        frequenciesStatus,
        predictionsStatus,
        lineage: state.parameters.lineage,
        lineageStatus: state.lineages.lineageStatus,
       // treeDataStatus: state.treeData.treeDataStatus,
        freqCategory: state.parameters.freqCategory,
        regionId: state.parameters.regionId || appConfig.default.regionId,
        modelRegionId: state.parameters.modelRegionId || appConfig.default.modelRegionId,
        modelId,
        trackingFrom: trackingFromSelector(state),
        predictionBaseline: predictionBaselineSelector(state),
        trackingTo: trackingToSelector(state),
        cladeType: state.parameters.cladeType,
        sigmaAg: state.parameters.sigmaAg,
        tau: state.parameters.tau,
       // gene: state.parameters.gene,
       // hla: state.parameters.hla,
       // binMethod: state.parameters.binMethod,
       // binCnt: state.parameters.binCnt,
        //colorBy: state.parameters.colorBy,
        //zoomNodeId: state.parameters.zoomNodeId,
        strainSubset: state.parameters.strainSubset,
        predictionsStatus,
        cladesStatus: state.cladeData.cladesStatus,
        exportMode: state.parameters.exportMode,
        renderStatus: state.render.renderStatus,
        tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
        regions: state.parameters.regions, // appConfig.regions,
        vpMethod: state.parameters.vpMethod,
        selectedModels: getSelectedModelsToFetch(state), // state.parameters.selectedModels,
        showPrediction: state.parameters.showPrediction,
        scaleName
    });
};

const mapDispatchToProps = dispatch => ({
    fetchFrequenciesForAllRegions: payload => dispatch(fetchFrequenciesForAllRegions(payload)),
    fetchFrequencies: payload => dispatch(fetchFrequencies(payload)),
    // fetchModels: payload => dispatch(fetchModels(payload)),
    fetchPredictions: payload => {
        dispatch(fetchPredictions(payload))
    },
    fetchClades: payload => dispatch(fetchClades(payload)),
    initStrainTree: payload => dispatch(initStrainTree(payload)),
    fetchTCellAntigenicityOptions: payload => dispatch(fetchTCellAntigenicityOptions(payload)),
    setRenderStatus: payload => dispatch(setRenderStatus(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledRegionalFrequenciesReport);
