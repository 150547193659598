import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import MenuItem from '@mui/material/MenuItem';
import appConfig from '../../config/appConfig';
import { setParameters } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';

const plotTypeOptions = [
    { key: 'frequencies', label: 'Frequencies', introOption: true },
    { key: 'multiplicities', label: 'Multiplicities', introOption: false },
];

const styles = theme => ({

    formControl: {
        margin: '0px 0px'
    },
});

const PlotTypeSelector = props => {

    const { plotType, intro, classes, setParameters } = props;

    const handlePlotTypeChange = event => {
        const plotType = event.target.value;
        setParameters({ plotType });
    };


    const plotTypes = useMemo(() => { return plotTypeOptions.filter(({introOption}) => !intro || introOption)}, [intro]);
    return (
        <FormControl className={classes.formControl} fullWidth>
            <CustomLabel id={'plotType'} label={'Plot'} />
            <CustomSelect
                value={plotType}
                onChange={handlePlotTypeChange}
                inputProps={{
                    name: 'plotType',
                    id: 'plotType',
                }}
            >
                {plotTypes.map((t) => (
                    // <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                    <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );

}

PlotTypeSelector.propTypes = {
    plotType: PropTypes.string,
    intro: PropTypes.bool,
    setParameters: PropTypes.func,
    classes: PropTypes.shape({ formControl: PropTypes.string })
};


const mapStateToProps = ({ parameters }) => {
    return ({
        plotType: parameters.plotType || appConfig.default.plotType,
        intro: parameters.intro
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlotTypeSelector));
