import React from 'react';
import { styles } from './styles';
import { Grid } from '@mui/material';
import SubsetsTable from './SubsetsTable';

const SubsetsPanel = () => {
    const classes = styles();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
                <SubsetsTable />
            </Grid>
        </Grid>
    )
}

export default SubsetsPanel;