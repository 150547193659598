import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';
import EditScaleDialog from '../Dialogs/EditScaleDialog';

const EditScale = ({ scale, scalesPalette, fetchAllScales, setInfoDialog, setInfo, setAction  }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const updateScale = (scale, domain, doesLineageChanged, doesIdChanged, oldScaleId) => {
        const url = `${config.serverLink}/api/admin/updateScale`;
        const body = { scale, domain, doesLineageChanged, doesIdChanged, oldScaleId };

        postAxios(url, body)
            .then((response) => {
                if (response.data.status === 'done'){
                    setOpenDialog(false);
                    setAction('Update scale');
                    fetchAllScales();
                    setInfo(`Scale ${scale.scaleId} was updated sucessfully`);
                    setInfoDialog(true);
                }
                else {
                    setOpenDialog(false);
                    setAction('Update scale');
                    setInfo(`Scale ${scale.scaleId} wasn't updated`);
                    fetchAllScales();
                    setInfoDialog(true);
                }
        });
    };

    const cantEdit = scale && (scale.assignColors === true || scale.range === undefined) || (!scale.numeric || scale.numeric === undefined) || !scale.domain;

    return cantEdit ?
        <></>
        :
        <>
            <IconButton className={classes.button} onClick={handleClick}>
                <EditIcon />
            </IconButton>
            < EditScaleDialog scale={scale} scalesPalette={scalesPalette} openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateScale={updateScale}/>
        </>





};

export default EditScale;
