// CookiePopup.js
import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    maxWidth: 800,
    textAlign: 'center',
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#6F6CFF',
    '&:hover': {
      backgroundColor: '#6F6CFF', // Slightly darker for the hover state, adjust as desired
      color: 'white',
    }
  },
});

const CookiePopup = ({ classes }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('cookieConsentGiven')) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsentGiven', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <p className={classes.content}>
        We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
      </p>
      <Button variant="contained" color="primary" onClick={handleAccept} className={classes.button}>
        Accept
      </Button>
    </div>
  );
};

export default withStyles(styles)(CookiePopup);
