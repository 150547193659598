/* eslint-disable react/state-in-constructor */
/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography, MenuItem, Checkbox, Grid } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

import { setLayoutParameters, setParameters, setShowMutationsGroups, setShowReferenceStrains, setShowCladeLabels } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { fetchMutationGroupValues } from '../../redux/actions/genotypeActions';
import MutationClassesColorLegend from '../Tree/MutationClassesColorLegend'
import { getMutationsGroupsOptions, getMutationClasses } from '../../redux/selectors/metadataSelector';
import { radioButtonsStyles } from '../../assets/GlobalStyles/radioButtons';
import { LAYOUT } from '../../config/dictionaries';
import { dateToDays } from '../../functions/functions';

const mutationsTypes = [
    { id: 'NS', label: 'Non-synonymous' },
    { id: 'S', label: 'Synonymous' },
    { id: 'ALL', label: 'All' },
];

const NodeVisibilitySelector = props => {

    const { setParameters, setShowReferenceStrains, fetchMutationGroupValues, lineage, showLinks, showInternalNodes, showLeaves, showColoredNodesOnly, showMutations, layout,
        showVaccines, showReassortments, showMutationsGroups, mutationsType, mutationsGroups, mutationsGroup,
        showCladeLabels, classes, showCladeBar, showCladeBarLabels, intro, windowWidth, showReferenceStrains,
        treeScaleTypeY, setShowMutationsGroups, setShowCladeLabels, setLayoutParameters
    } = props;


    const handleChange = name => event => {
        const value = event.target.checked === undefined ? event.target.value : event.target.checked;
        //console.log('handleChange', name, value);
        const _value = value === '' ? null : value;
        setParameters({ [name]: _value })


        // if (name === 'mutationsGroup') {
        //     if (_value) fetchMutationGroupValues({ lineage, mutationsGroup: _value })

        //     setParameters({ visibleMutationClassesLabels: {} });
        // }        
    }

    const handleShowCladeBarChange = event => {

        const value = event.target.checked === undefined ? event.target.value : event.target.checked;
        // console.log('handleShowCladeBarChange', value)
        const showCladeBarLabels = !value ? { showCladeBarLabels: false } : {};
        setLayoutParameters({ showCladeBar: value, ...showCladeBarLabels });
    }

    const handleLayoutParameterChange = name => event => {
        const value = event.target.checked === undefined ? event.target.value : event.target.checked;
        const _value = value === '' ? null : value;
        setLayoutParameters({ [name]: _value })
    }

    const handleChangeShowMutationsGroups = event => {
        const value = event.target.checked === undefined ? event.target.value : event.target.checked;
        //console.log('handleChangeShowMutationsGroups', value);
        const _value = value === '' ? null : value;
        setShowMutationsGroups(_value)
    }

    const handleChangeShowCladeLabels = event => {
        const value = event.target.checked === undefined ? event.target.value : event.target.checked;
        //console.log('handleChangeShowCladeLabels', value);
        const _value = value === '' ? null : value;
        setShowCladeLabels(_value);
    }

    const handleChangeShowReferenceStrains = event => {
        const value = event.target.checked === undefined ? event.target.value : event.target.checked;
        //console.log('handleChangeShowCladeLabels', value);
        const _value = value === '' ? null : value;
        setShowReferenceStrains(_value);
    }



    const leftXS = windowWidth > 1100 ? !showCladeBar || layout === LAYOUT.FAN.value ? 5.9 : 6.1 : 12;
    const leftClassName = showCladeBar && layout !== LAYOUT.FAN.value ? `${classes.formControl} ${classes.optionRight}` : classes.formControl;

    const rightXS = windowWidth > 1100 ? layout === LAYOUT.FAN.value ? 6.1 : 5.9 : 12;
    const rightClassName = !showCladeBar || layout === LAYOUT.FAN.value ? `${classes.formControl} ${classes.optionRight}` : classes.formControl;

    // console.log('mutationsGroups = ',mutationsGroups)
    return (
        <>
            <Grid container style={intro ? {marginTop: '15px'} : {}} className={classes.rootRadio} >
                {
                    layout !== LAYOUT.FAN.value && treeScaleTypeY === 'order' &&
                    <Grid item xs={windowWidth > 1100 ? 5.9 : 12} className={classes.option} >
                        <FormControlLabel
                            className={classes.formControl}
                            value="showCladeBar"
                            label={<Typography className={classes.formControlLabel}>Clade bar</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={showCladeBar}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleShowCladeBarChange}
                                        className={classes.checkbox}
                                        id='showCladeBar'
                                    />
                                )
                            }
                        />
                    </Grid>
                }


                {layout !== LAYOUT.FAN.value && showCladeBar &&
                    <Grid item xs={windowWidth > 1100 ? 6.1 : 12} >
                        <FormControlLabel
                            className={`${classes.formControl} ${classes.optionRight}`}
                            value="showCladeBarLabels"
                            label={<Typography className={classes.formControlLabel}>Clade labels</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={showCladeBarLabels}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleLayoutParameterChange('showCladeBarLabels')}
                                        className={classes.checkbox}
                                        id={'showCladeBarLabels'}
                                    />
                                )
                            }
                        />
                    </Grid>
                }
                {layout !== LAYOUT.FAN.value && !intro &&
                    <Grid item xs={windowWidth > 1100 ? 5.9 : 12} className={classes.option} >
                        <FormControlLabel
                            className={!showCladeBar ? `${classes.formControl} ${classes.optionRight}` : classes.formControl}
                            value="showCladeLabels"
                            label={<Typography className={classes.formControlLabel}>Tree labels</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={showCladeLabels}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChangeShowCladeLabels}
                                        className={classes.checkbox}
                                        id='showCladeLabels'
                                    />
                                )
                            }
                        />
                    </Grid>
                }
                {!intro &&
                    <Grid item xs={leftXS} className={classes.option}>
                        <FormControlLabel
                            className={leftClassName}
                            value="showInternalNodes"
                            size='small'
                            label={<Typography className={classes.formControlLabel}>Internal nodes</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={showInternalNodes}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChange('showInternalNodes')}
                                        className={classes.checkbox}
                                        id='showInternalNodes'
                                    />
                                )
                            }
                        />
                    </Grid>}
                <Grid item xs={rightXS} className={classes.option} >
                    <FormControlLabel
                        className={rightClassName}
                        value="showLeaves"
                        label={<Typography className={classes.formControlLabel}>Leaves</Typography>}
                        control={
                            (
                                <Checkbox
                                    icon={<CircleUnchecked />}
                                    checked={showLeaves}
                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                    onChange={handleChange('showLeaves')}
                                    className={classes.checkbox}
                                    id='showLeaves'
                                />
                            )
                        }
                    />
                </Grid>
                <Grid item xs={leftXS} className={classes.option} >
                    <FormControlLabel
                        className={leftClassName}
                        value="showLinks"
                        label={<Typography className={classes.formControlLabel}>Links</Typography>}
                        control={
                            (
                                <Checkbox
                                    icon={<CircleUnchecked size="small" />}
                                    checked={showLinks}
                                    checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                    onChange={handleChange('showLinks')}
                                    className={classes.checkbox}
                                    id='showLinks'
                                />
                            )
                        }
                    />
                </Grid>
                {!intro && <>
                    <Grid item xs={rightXS} className={classes.option} >
                        <FormControlLabel
                            className={rightClassName}
                            value="showMutations"
                            label={<Typography className={classes.formControlLabel}>Mutation counts</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked size="small" />}
                                        checked={showMutations}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChange('showMutations')}
                                        className={classes.checkbox}
                                        id='showMutations'
                                    />
                                )
                            }
                        />
                    </Grid>
                    {!intro &&
                        <Grid item xs={leftXS} className={classes.option} >
                            <FormControlLabel
                                className={leftClassName}
                                value="showColoredNodesOnly"
                                label={<Typography className={classes.formControlLabel}>Colored nodes only</Typography>}
                                control={
                                    (
                                        <Checkbox
                                            icon={<CircleUnchecked size="small" />}
                                            checked={showColoredNodesOnly}
                                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                            onChange={handleChange('showColoredNodesOnly')}
                                            className={classes.checkbox}
                                            id='showColoredNodesOnly'
                                        />
                                    )
                                }
                            />
                        </Grid>}
                </>
                }
                    {!intro &&
                    <Grid item xs={rightXS} className={classes.option} >
                        <FormControlLabel
                            className={rightClassName}
                            value="showVaccines"
                            label={<Typography className={classes.formControlLabel}>Vaccines</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked size="small" />}
                                        checked={showVaccines}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChange('showVaccines')}
                                        className={classes.checkbox}
                                        inputProps={{
                                            name: 'showVaccines',
                                            id: 'showVaccines',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>}
                    {!intro &&
                    <Grid item xs={leftXS} className={classes.option} >
                        <FormControlLabel
                            className={leftClassName}
                            value="showReassortments"
                            label={<Typography className={classes.formControlLabel}>Reassortments</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked size="small" />}
                                        checked={showReassortments}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChange('showReassortments')}
                                        className={classes.checkbox}
                                        inputProps={{
                                            name: 'showReassortments',
                                            id: 'showReassortments',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>}
                {mutationsGroups.length > 1 && (
                    <Grid item xs={rightXS} className={classes.option} >
                        <FormControlLabel
                            className={rightClassName}
                            value="showMutationsGroups"
                            label={<Typography className={classes.formControlLabel}>Mutations</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={showMutationsGroups}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChangeShowMutationsGroups}
                                        className={classes.checkbox}
                                        inputProps={{
                                            name: 'showMutationsGroups',
                                            id: 'showMutationsGroups',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                )}

                {!intro &&
                    <Grid item xs={leftXS} className={classes.option} >
                        <FormControlLabel
                            className={leftClassName}
                            value="showReferenceStrains"
                            label={<Typography className={classes.formControlLabel}>Reference sera</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={showReferenceStrains}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={handleChangeShowReferenceStrains}
                                        className={classes.checkbox}
                                        inputProps={{
                                            name: 'showReferenceStrains',
                                            id: 'showReferenceStrains',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>}
            </Grid>
            {showMutations && (
                <Grid item xs={12} >
                    <FormControl style={{ width: '100%' }} className={classes.formControl} fullWidth>
                        <CustomLabel id={'mutationsType'} label={'Mutations type'} />
                        <CustomSelect
                            style={{ width: '100%' }}
                            value={mutationsType}
                            onChange={handleChange('mutationsType')}
                            inputProps={{
                                name: 'mutationsType',
                                id: 'mutationsType',
                            }}
                        >
                            {mutationsTypes.map((t) => (
                                <MenuItem key={t.id} value={t.id}>{t.label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </Grid>
            )}

            {showMutationsGroups && mutationsGroups.length > 1 && (
                <Grid item xs={12} >
                    <FormControl style={{ width: '100%', marginTop: '8px' }} className={classes.formControl} fullWidth>
                        <CustomLabel id={'mutationsGroup'} label={'Mutations group'} />
                        <CustomSelect
                            style={{ width: '100%' }}
                            value={mutationsGroup}
                            onChange={handleChange('mutationsGroup')}
                            inputProps={{
                                name: 'mutationsGroup',
                                id: 'mutationsGroup',
                            }}
                        >
                            {mutationsGroups.map((t) => (
                                <MenuItem key={t} value={t}>{t}</MenuItem>

                                // <MenuItem key={t.id} value={t.id}>{t.label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                    <MutationClassesColorLegend />
                </Grid>
            )}
        </>
    );

}

NodeVisibilitySelector.propTypes = {
    intro: PropTypes.bool,
    lineage: PropTypes.string,
    layout: PropTypes.string,
    mutationsType: PropTypes.string,
    mutationsGroup: PropTypes.string,
    treeScaleTypeY: PropTypes.string,
    showLinks: PropTypes.bool,
    showMutations: PropTypes.bool,
    showMutationsGroups: PropTypes.bool,
    showInternalNodes: PropTypes.bool,
    showLeaves: PropTypes.bool,
    showColoredNodesOnly: PropTypes.bool,
    showVaccines: PropTypes.bool,
    showReassortments: PropTypes.bool,
    showCladeLabels: PropTypes.bool,
    showCladeBar: PropTypes.bool,
    showCladeBarLabels: PropTypes.bool,
    windowWidth: PropTypes.number,
    mutationsGroups: PropTypes.arrayOf(PropTypes.string),
    setParameters: PropTypes.func,
    fetchMutationGroupValues: PropTypes.func,
    setShowMutationsGroups: PropTypes.func,
    setLayoutParameters: PropTypes.func,
    setShowCladeLabels: PropTypes.func,

    classes: PropTypes.object

};


const mapStateToProps = (state) => ({
    lineage: state.parameters.lineage,
    layout: state.parameters.layout,
    showInternalNodes: state.parameters.showInternalNodes,
    showLeaves: state.parameters.showLeaves,
    showColoredNodesOnly: state.parameters.showColoredNodesOnly,
    showMutations: state.parameters.showMutations,
    showMutationsGroups: state.parameters.showMutationsGroups,
    showVaccines: state.parameters.showVaccines, 
    showReassortments: state.parameters.showReassortments,
    showLinks: state.parameters.showLinks,
    showCladeBar: state.parameters.showCladeBar,
    showCladeLabels: state.parameters.showCladeLabels,
    showCladeBarLabels: state.parameters.showCladeBarLabels,
    showReferenceStrains: state.parameters.showReferenceStrains,
    mutationsGroups: getMutationsGroupsOptions(state),
    mutationsGroup: state.parameters.mutationsGroup || '',
    mutationClasses: getMutationClasses(state),
    mutationsType: state.parameters.mutationsType,
    windowWidth: window.innerWidth,
    treeScaleTypeY: state.parameters.treeScaleTypeY,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    setLayoutParameters,
    fetchMutationGroupValues,
    setShowMutationsGroups,
    setShowCladeLabels,
    setShowReferenceStrains
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(radioButtonsStyles)(NodeVisibilitySelector));
