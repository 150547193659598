import { axisBottom, axisRight } from 'd3-axis';
import { timeMonths } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { select } from 'd3-selection';
import { LAYOUT } from '../../../config/dictionaries';
import { getScale, xMargin } from '../../../functions/scales';
import { arcAxisBottom } from './arcAxis';

const getTickValue = (endDate, d, scaleName, index, dArr, ticks) => {
    if (!ticks[d.getTime()].txt) return '';
    const endPos = scaleName(endDate);
    const dPos = scaleName(d);

    const nextD = index < dArr.length - 1 ? select(dArr[index+1]).data()[0] : endDate;
    const nextPos = (index < dArr.length-1) ? scaleName(nextD) : endPos;

    const space = nextPos - dPos;
    const monthsBetween = timeMonths(d, nextD).length;
    // console.log(`${index}. ${d.toLocaleDateString()}. ${space}, ${endPos}, index = ${index}/${dArr.length}, monthsBetween = ${monthsBetween}`);
    // console.log(monthsBetween);
    const tickSpace = 40;

    const format = (monthsBetween >= 12 || space < tickSpace) ? '%Y' : (space < 50 ? '%m/%y' : '%m/%Y'); // DATE_FORMAT[size];

    const res = ((space > tickSpace || d.getMonth() === 0)) ? timeFormat(format)(d) : '';
    // console.log(`${index}. ${d.toLocaleDateString()} / ${endDate.toLocaleDateString()} ${space}, ${endPos}, index = ${index}/${dArr.length}, monthsBetween = ${monthsBetween}, format = ${format}, res = ${res}, nextD = ${nextD}`);
    return res;
};


const drawRadialXAxis = (svg, width, height, scaleType) => {
    // console.log('[drawRadialXAxis]');
    const xScale = /* scaleType === 'time' ? getScale('radialTimeScale') :*/ getScale(`x${scaleType}RadialScale`);
    if (!xScale) return;

    const xScaleDomain = xScale.domain();
    // console.log('[drawRadialXAxis]', xScaleDomain, xScale.range());
    const startVal = xScaleDomain[0];
    const endVal = xScaleDomain[xScaleDomain.length - 1];


    const axisRadius =  //xScale.range().at(-1);//
        Math.min(width / 2, height);
   
    // console.log(`[drawRadialXAxis] ${Math.min(width / 2, height)} => ${xScale.range().at(-1)}, ${xScale.range().at(0)}` )
    const xAxis = arcAxisBottom(xScale)
        .tickSize(axisRadius)
        .tickPadding(10)
        

    let ticks = {};
    let step;

    if (xScale.time) {
        ticks = xScale.timeTicks; 
        step = xScale.timeStep 
        
        // console.log(`[drawRadialXAxis]: 
        // startVal = ${startVal.toLocaleDateString()}, 
        // endVal = ${endVal.toLocaleDateString()},
        // startTime = ${startTime.toLocaleDateString()},
        // endTime = ${endTime.toLocaleDateString()}`, 
        // Object.values(ticks).map(({d}) => d.toLocaleDateString()))
        xAxis
            .tickFormat((d, i, dArr) => getTickValue(endVal, d, xScale, i, dArr, ticks))
            .tickValues(Object.values(ticks).map(({ d }) => d));
    }


    const bigTick = step <= 12 ? 1 : (step / 12);

    const customXAxis = (g, treeScaleTypeX) => {
        g.call(xAxis);
        g.select('.domain').remove();
        g.selectAll('path').attr('stroke', '#EAEAEA').attr('stroke-width', 1);

        if (treeScaleTypeX === 'time') {
            g.selectAll('path')
                .filter((d, i) => {
                    let yearFirstMonthIndex = null;
                    if (d.getMonth() === 0 && yearFirstMonthIndex === null)
                        yearFirstMonthIndex = i;

                    return (yearFirstMonthIndex !== null
                        && (d.getMonth()) === 0
                        && (scaleType !== 'time' || yearFirstMonthIndex % bigTick === 0 ));
                })
                .attr('stroke-width', 3);

            g.selectAll('.tick text')
                .filter((d, i) => {
                    let yearFirstMonthIndex = null;
                    if (d.getMonth() === 0 && yearFirstMonthIndex === null)
                        yearFirstMonthIndex = i;

                    return (yearFirstMonthIndex !== null
                        && (d.getMonth()) === 0
                        && (scaleType !== 'time' || yearFirstMonthIndex % bigTick === 0 ));
                })
                .attr('fill', '#000000');
            g.attr('text-anchor', 'middle');
            return;
        }

        g.selectAll('.tick text').attr('fill', '#000000');
        g.attr('text-anchor', 'middle');
       
    };

    svg.select('#fanAxis_background')
        .attr('width', axisRadius * 2)
        .attr('height', axisRadius)
        .attr('fill', '#FFFFFF')
        //.attr('transform', `translate(${width / 2}, 0)`);
        //.attr('transform', getTransformString(scaleType, width, height, true));


    svg.select('#fanAxis_ticks').attr('transform', `translate(${scaleType === 'time' ? xMargin : xMargin*2}, 0)`)
        .call(g => customXAxis(g, scaleType));
};

const drawTreeXAxis = (svg, height, scaleType, padding, exportMode) => {
    // const formatYear = d => d.getFullYear();

    // var maxYear = formatYear(Math.max(maxTime, prediction_baseline));
    const xScale = 
       // scaleType === 'time' ? getScale('timeScale') :
        getScale(`x${scaleType}Scale`);

    if (!xScale) return;

    const xScaleDomain = xScale.domain();

    // const startVal = xScaleDomain[0];
    const endVal = xScaleDomain[xScaleDomain.length - 1];

    const width =  xScale.range().at(-1);

    //console.log('[drawTreeXAxis]: endVal = ', endVal);
    const paddingBottom = padding  ? padding.bottom : 32;

    const xAxis = axisBottom(xScale)
        .tickSize(height)
        .tickPadding(exportMode ? 6 : 10);
    let ticks = {};
    let step;
    let firstDate;


    if (xScale.time) {
   // if (scaleType === 'time') {
        const _ticks = xScale.timeTicks; //getTicks(startVal, endVal, xScale, steps);
        ticks = _ticks || ticks;
        const keys = Object.keys(ticks);
        const firstKey = keys.shift();
        firstDate = ticks[firstKey].d;

        step = xScale.timeStep //_ticks.step;
        xAxis
            .tickFormat((d, i, dArr) => getTickValue(endVal, d, xScale, i, dArr, ticks))
            .tickValues(Object.values(ticks).map(({ d }) => d)); //timeMonths(startVal, endVal, getStep(startVal, endVal)));
    };
    const bigTick = step <= 12 ? 1 : (step / 12);

    const customXAxis = (g, treeScaleTypeX) => {
        g.call(xAxis);
        g.selectAll('.domain')
            //   .remove();
            .attr('d', `M${0},${height}H${width}`)
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', 0.3);

        g.selectAll('line')
            .attr('stroke', '#000000')
            .attr('stroke-width', 0.5)
            .attr('stroke-opacity', (d) => {
                const isFrist = d == firstDate;
                return isFrist ? 0 : 0.3;
            }); // .attr('stroke-dasharray', '2,2');

        if (treeScaleTypeX === 'time') {
            let yearFirstMonthIndex = null;
            g.selectAll('line')
                .filter((d, i) => {
                    if (d.getMonth() === 0 && yearFirstMonthIndex === null && ticks[d.getTime()].txt) yearFirstMonthIndex = i;
                    //console.log('yearFirstMonthIndex', yearFirstMonthIndex, d.getMonth(), ticks[d.getTime()], 'bigTick', (i - yearFirstMonthIndex)%bigTick === 0 )
                    return (yearFirstMonthIndex !== null
                        && (d.getMonth()) === 0
                        && (scaleType !== 'time' || (i - yearFirstMonthIndex)%bigTick === 0 ));// ticks[d.getTime()].txt));
                })
                .attr('stroke-opacity', (d) => {
                    const isFrist = d == firstDate;
                    return isFrist ? 0 : 1;
                })
                .attr('y2', `${height + paddingBottom}`);
        }
        g.selectAll('.tick text')
            .attr('transform', 'translate(5, 0)')
            .attr('fill', '#000000')
            .style('font-size', exportMode ? '8px' : '12px');;
        g.attr('text-anchor', 'start');
        //  .attr('font-family', "'Inter', 'sans-serif'");
    };
    //console.log('width = ',width, ' => ', xScale.range().at(-1)); 
    svg.select('#treeAxis_background')
        .attr('width', width) //width)
        .attr('height', height);


    svg.select('#treeAxis').call(g => customXAxis(g, scaleType));
};

const drawTreeYAxis = (svg, width, height, scaleType, padding) => {
    const yScale = getScale(`y${scaleType}Scale`);
    if (scaleType === 'order' || !yScale) {
        svg.select('#treeYAxis').selectAll('g').remove();
        svg.select('#treeYAxis').selectAll('path').remove();
        return;
    };

    const yScaleDomain = yScale.domain();

    // console.log(`[drawTreeXAxis] startVal = ${startVal}, endVal = ${endVal}`);
    const yAxis = axisRight(yScale)
        .tickSize(10)
        .tickPadding(5);


    const customYAxis = (g) => {
        g.call(yAxis);
        g.selectAll('line')
        .attr('stroke', '#000000')
        .attr('stroke-width', 0.5)
        .attr('stroke-opacity', 0.3);
        // g.selectAll('.domain')
        //     //   .remove();
        //     .attr('d', `M${0},${height}V${height+paddingBottom}H${width}V${height}`)
        //     .attr('stroke', '#000000')
        //     .attr('stroke-width', 0.5)
        //     .attr('stroke-opacity', 0.3);

        // g.selectAll('line')
        //     .attr('stroke', '#000000')
        //     .attr('stroke-width', 0.5)
        //     .attr('stroke-opacity', 0.3); // .attr('stroke-dasharray', '2,2');
        // if (treeScaleTypeX === 'time') {
        //     g.selectAll('line')
        //         .filter(d => (d.getMonth() === 0))
        //         .attr('stroke-opacity', 1)
        //         .attr('y2', `${height + paddingBottom}`);
        // }
        // g.selectAll('.tick text').attr('transform', 'translate(5, 0)').attr('fill', '#000000');
        // g.attr('text-anchor', 'start');
        //  .attr('font-family', "'Inter', 'sans-serif'");
    };
    // svg.select('#treeAxis_background')
    //     .attr('width', width)
    //     .attr('height', height);

    svg.select('#treeYAxis').call(g => customYAxis(g));
};

export const drawDateAxis = (svg, props, width, height, padding) => {
    const { layout, treeScaleTypeX, treeScaleTypeY, exportMode } = props;
    //console.log('[drawDateAxis] layout = ',layout);
    if (layout === LAYOUT.TREE.value) {
        svg.select('#fanAxis').style('display', 'none');
        svg.select('#treeAxis').style('display', 'block');
        drawTreeXAxis(svg, height, treeScaleTypeX, padding, exportMode);
        drawTreeYAxis(svg, width, height, treeScaleTypeY);
    } else {
        svg.select('#treeAxis').style('display', 'none');
        svg.select('#fanAxis').style('display', 'block');//.attr('transform', getTransformString(treeScaleTypeX, width, height, false));
        drawRadialXAxis(svg, width, height, treeScaleTypeX);
    };

};

export const hideAxesLayer = (svg, props) => {
    if (!props) return;
    const { layout } = props;
    if (layout === LAYOUT.TREE.value) {
        svg.select('#fanAxis').style('display', 'none');
        svg.select('#treeAxis').style('display', 'none');
    } else {
        svg.select('#treeAxis').style('display', 'none');
        svg.select('#fanAxis').style('display', 'none');//.attr('transform', getTransformString(treeScaleTypeX, width, height, false));
    };
}
