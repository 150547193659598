import React from "react";
import { Grid, FormControl, FormHelperText, MenuItem } from "@mui/material";
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import PropTypes from 'prop-types';
import CustomLabel from "../../assets/GlobalStyles/CustomLabel";

export const AntigenicModelInput = ({ label, value, name, options, changeHandler, error, errorTxt, classes, disabled }) => (
    <Grid item sm={12} md={6} style={{ paddingTop: '8px' }}>
        <FormControl
            className={classes.formControl}
            fullWidth
        >
            <CustomLabel id={'name'} error={error} label={label} />
            <CustomSelect
                value={value}
                disabled={disabled}
                onChange={changeHandler}
                error={error}
                inputProps={{ name: `${name}`, id: `${name}` }}
            >
                {options.map(({ id }) => (
                    <MenuItem key={id || "_"} value={id}>
                        {id === 'ALL' ? 'All' : id}
                    </MenuItem>
                ))}
            </CustomSelect>
            {error && (
                <FormHelperText id={`${name}-error-text`} error>
                    {errorTxt}
                </FormHelperText>
            )}
        </FormControl>
    </Grid>
);

AntigenicModelInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    errorTxt: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
    classes: PropTypes.shape({ formControl: PropTypes.string, label: PropTypes.string }),
    changeHandler: PropTypes.func,
};
