import auth from '../../functions/auth-helper';

// Action Types
import {
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_ERROR,
    SIGNOUT_REQUEST,
    SIGNOUT_SUCCESS,
    SIGNOUT_ERROR,
    SET_USERNAME_SUCCESS,
    SET_USERNAME_ERROR,
    GET_SIGNED_USER_REQUEST,
    GET_SIGNED_USER_SUCCESS,
    GET_SIGNED_USER_ERROR,
    SET_USER_PREFERENCES
} from './actionTypes';

// Base Actions
import { received, error, request } from '../baseActions';

const signIn = (user) => received(SIGNIN_REQUEST, user);
const signInSuccess = (res) => received(SIGNIN_SUCCESS, res);
const signInError = (error) => received(SIGNIN_ERROR, error);

const signOut = (payload) => received(SIGNOUT_REQUEST, payload);
const signOutSuccess = (res) => received(SIGNOUT_SUCCESS, res);
const signOutError = (error) => received(SIGNOUT_ERROR, error);

const getSignedUser = () => request(GET_SIGNED_USER_REQUEST);
const getSignedUserSuccess = (res) => received(GET_SIGNED_USER_SUCCESS, res);
const getSignedUserError = (error) => received(GET_SIGNED_USER_ERROR, error);

const setUsername = () => auth.isAuthenticated
    ? received(SET_USERNAME_SUCCESS, { userName: auth.username() })
    : error(SET_USERNAME_ERROR, { userName: null });

const setUserPreferences = (payload) => received(SET_USER_PREFERENCES, payload);

export {
    signIn,
    signInSuccess,
    signInError,
    signOut,
    signOutSuccess,
    signOutError,
    setUsername,
    getSignedUser,
    getSignedUserSuccess,
    getSignedUserError,
    setUserPreferences
};
