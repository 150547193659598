import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import AddMutClassDialog from '../Dialogs/Add';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AddNewScale = ({ lineages, setInfo, setInfoDialog, setAction, updateTable, mutationGenes }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        console.log(mutationGenes)
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const addMutClass = (body,) => {
        const keys = Object.keys(body.positions);
        let result = {};

        keys.forEach(key => {
            body.positions[key].forEach(range => {
                for (let i = range.min; i <= range.max; i++) {
                    result[i] = true;
                }
            })
            body.positions[key] = result;
            result = {};
        });

        const url = `${config.serverLink}/api/admin/addMutationClass`;
        postAxios(url, { mutClass: { ...body }}).then((response) => {
            if (response.data.status){
                handleCloseDialog();
                setInfoDialog(true);
                setAction('Update mutation class');
                updateTable();
                setInfo(`Mutation class ${body.name} was added sucessfully`);
            } else {
                handleCloseDialog();
                setInfoDialog(true);
                setAction('Update mutation class');
                updateTable();
                setInfo(`Mutation class ${body.name} wasn't added`);
            }
    })};

    return (
        <>
            <IconButton className={classes.addMutation} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddMutClassDialog mutationGenes={mutationGenes} lineages={lineages} addMutClass={addMutClass} handleCloseDialog={handleCloseDialog} openDialog={openDialog} />
        </>
    );
};

export default AddNewScale;
