const vaccineIcon = {
    path: {
        fill: 'white',
        stroke: "#565656",
        'stroke-width': '1px',
        d: 'M14.92,12L8,0L1.08,12C0.4,13.18,0,14.54,0,16c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8C16,14.54,15.6,13.18,14.92,12z',
    },
    symbol: {
        fill: '#565656',
        d: 'M7.18,20l-2.45-7.85h1.48l1.1,3.95c0.13,0.45,0.24,0.87,0.34,1.27c0.1,0.4,0.22,0.83,0.36,1.28h0.05c0.14-0.46,0.25-0.88,0.35-1.28c0.1-0.4,0.22-0.82,0.34-1.27l1.09-3.95h1.43L8.83,20H7.18z',
    },
};

const reassortmentIcon = {
    path: {
        fill: 'white',
        stroke: "#565656",
        'stroke-width': '1px',
        d: 'M0,0 8,0 8,50 0,50',
    },
    symbol: {
        fill: '#565656',
        d: 'M0.445219 8V0.151999H3.09722C3.50522 0.151999 3.88522 0.191999 4.23722 0.271999C4.58922 0.352 4.89322 0.484 5.14922 0.668C5.40522 0.844 5.60522 1.08 5.74922 1.376C5.90122 1.672 5.97722 2.04 5.97722 2.48C5.97722 3.08 5.83322 3.564 5.54522 3.932C5.26522 4.3 4.88922 4.564 4.41722 4.724L6.28922 8H4.72922L3.03722 4.928H1.83722V8H0.445219ZM1.83722 3.824H2.95322C3.48922 3.824 3.89722 3.712 4.17722 3.488C4.46522 3.264 4.60922 2.928 4.60922 2.48C4.60922 2.024 4.46522 1.708 4.17722 1.532C3.89722 1.356 3.48922 1.268 2.95322 1.268H1.83722V3.824Z',
    },
};

const referenceStrainIcon = {
    path: {
        fill: '#6F6CFF',
        // fill: "#565656",
        stroke: "#565656",
       'stroke-width': '1px',
        d: 'M14.92,12L8,0L1.08,12C0.4,13.18,0,14.54,0,16c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8C16,14.54,15.6,13.18,14.92,12z',
    },
    symbol: {
        fill: '#FFFFFF',
        d: 'M5.2,19L6,18c0.6,0.5,1.3,0.9,2,0.9c0.9,0,1.4-0.4,1.4-1c0-0.6-0.5-0.9-1.2-1.2l-1.1-0.5c-0.8-0.3-1.6-0.9-1.6-2.1C5.5,13,6.6,12,8.2,12c0.9,0,1.8,0.4,2.4,1l-0.7,0.9c-0.5-0.4-1-0.7-1.7-0.7c-0.7,0-1.2,0.3-1.2,0.9c0,0.6,0.6,0.8,1.2,1.1l1.1,0.4c0.9,0.4,1.6,1,1.6,2.1c0,1.3-1.1,2.3-2.8,2.3C6.9,20.1,5.9,19.7,5.2,19z',
    },
};

const referenceStrainsIcon = {
    path: {
        fill: 'white',
        stroke: "#565656",
       'stroke-width': '1px',
        d: 'M14.92,12L8,0L1.08,12C0.4,13.18,0,14.54,0,16c0,4.42,3.58,8,8,8c4.42,0,8-3.58,8-8C16,14.54,15.6,13.18,14.92,12z',
    },
    symbol: {
        fill: '#565656',
        d: 'M5.2,19L6,18c0.6,0.5,1.3,0.9,2,0.9c0.9,0,1.4-0.4,1.4-1c0-0.6-0.5-0.9-1.2-1.2l-1.1-0.5c-0.8-0.3-1.6-0.9-1.6-2.1C5.5,13,6.6,12,8.2,12c0.9,0,1.8,0.4,2.4,1l-0.7,0.9c-0.5-0.4-1-0.7-1.7-0.7c-0.7,0-1.2,0.3-1.2,0.9c0,0.6,0.6,0.8,1.2,1.1l1.1,0.4c0.9,0.4,1.6,1,1.6,2.1c0,1.3-1.1,2.3-2.8,2.3C6.9,20.1,5.9,19.7,5.2,19z',
    },
};

export {
    vaccineIcon,
    reassortmentIcon,
    referenceStrainIcon,
    referenceStrainsIcon
};
