import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { titleCase } from '../../../functions/functions';
import { getSelectedMeasureGeoMapLegend, getSelectedMeasureTreeLegend } from '../../../redux/selectors/rangeDataSelector';
import { setActiveLegendOption } from '../../../redux/actions/nodeActions';
import { useStyles } from './styles';
import { splitDataToChunks } from '../Elements/functions';
import LegendElement from '../Elements/LegendElement';

const TreeLegend = ( { value, collSize, setActiveLegendOption, exportMode, colorBy, editMode, colorData, activeLegendOption, searchStrainMode, nodeInfo } ) => {
    const classes = useStyles();
    const legendWrapper = useRef(null);

    useEffect(() => {
        const ele = document.getElementById(value);
        const parent = document.getElementById("legendWrapper");

        if (value && ele) {
            const offset = ele.getBoundingClientRect().top - parent.getBoundingClientRect().top;
            legendWrapper.current.scrollTop += offset;
        }
    }, []);

    const data = Object.keys(colorData || {}).map(key => ({
        key,
        color: colorData[key].color,
        label: (colorBy === 'loc') ? titleCase(colorData[key].label) : (colorData[key].label || colorData[key].info || colorData[key].value)
    }));

    const colorChunks = exportMode && editMode ? splitDataToChunks(data, collSize) : [];

    // console.log(colorData, data);
    return (
        <>
            {!exportMode && ( 
                <div  ref={legendWrapper} className={`${nodeInfo ? classes.rootHeightNodeInfo : classes.rootHeight} ${classes.rootTreeLegend}  active`} style={{ display: colorData ? 'block' : 'none' }}>
                    <div id="legendWrapper" className={`${nodeInfo ? classes.legendWrapperNodeInfo : classes.legendWrapperHeight} ${classes.legendWrapper} `}>
                        {data.map((color) => {
                            const border = `1px solid ${color.key === value || activeLegendOption.value == color.key ? '#474747' : color.color}`;
                            const textDecoration = color.key === value || activeLegendOption.value == color.key ? 'underline' : 'none';

                            return (<LegendElement 
                                key={`elem-${color.key}`}
                                keyId={color.key}
                                visible={true}
                                fontSize={colorBy === 'loc' ? '14px' : '12px'}
                                handleEnter={() => !searchStrainMode && setActiveLegendOption({ value: color.key, option: colorBy })}
                                handleLeave={() => !searchStrainMode && setActiveLegendOption({ value: '', option: colorBy })}
                                label={color.label}
                                color={color.color} 
                                border={border}
                                textDecoration={textDecoration}
                                size={17}
                            />);
                        })}
                    </div>
                </div>
            )}
            {exportMode && !editMode && (
                <div className={`${classes.rootExport}`} style={{ display: colorData ? 'block' : 'none' }}>
                    <div id="legendWrapper" ref={legendWrapper} className={classes.legendWrapperExport}>
                        {data.map(color => {
                            const border = `1px solid ${color.key === value || activeLegendOption.value == color.key ? '#474747' : color.color}`;
                            const textDecoration = color.key === value || activeLegendOption.value == color.key ? 'underline' : 'none';
                            const fontSize = exportMode ? '10px' : colorBy === 'loc' ? '14px' : '12px' ;
                            
                            return (<LegendElement 
                                key={`elem-${color.key}`}
                                keyId={color.key}
                                visible={true}
                                fontSize={fontSize}
                                label={color.label}
                                color={color.color} 
                                border={border}
                                textDecoration={textDecoration}
                                size={12}
                            />);
                        })}
                    </div>
                </div>
            )}
            {exportMode && editMode && (
                    <Grid id="legendWrapper" container columns={20} className={classes.legendEditWrapper}>
                        {colorChunks.map((colorChunk, index) => (
                            <Grid className={classes.legendEdit} key={`legend-cunk-${index}`}item xs={Math.floor(20 / collSize)}>
                                {colorChunk.map(color => (
                                    <div className={classes.legendElement} id={color.key} key={color.key}>
                                    <div className={classes.legendCircleWrapperExport}>
                                        <div className={classes.legendCircleExport} style={{ backgroundColor: color.color }} />
                                    </div>
                                    <div className={classes.legendTextExport}
                                        style={{ fontSize: exportMode ? '10px' : colorBy === 'loc' ? '14px' : '12px' }}>
                                        {color.label}
                                    </div>
                                </div>
                                ))}
                            </Grid>
                        ))}
                    </Grid>

            )}
        </>
    )

}


TreeLegend.propTypes = {
    colorBy: PropTypes.string,
    regions: PropTypes.shape({}),
    transmissions: PropTypes.shape({}),
    visibleTreeClades: PropTypes.shape({}),
    classes: PropTypes.PropTypes.shape({
        root: PropTypes.string,
        rootExport: PropTypes.string,
        title: PropTypes.string,
        titleExport: PropTypes.string,
        legendClass: PropTypes.string,
    }),
    exportMode: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const colorBy = ownProps.mapSelector
        ? state.parameters.geoMapColorBy
        : state.parameters.colorBy
    const { exportMode, editMode } = state.parameters;

    //const measures = getMetadataMeasuresWithScales(state);
    const { activeLegendOption } = state.nodeData;
    const { searchStrainMode } = state.parameters;


    const { valueGetter } = ownProps;
    const value = valueGetter ? valueGetter(state) : null;

    //const selectedMeasure = measures[colorBy] || {};
    const colorData = ownProps.mapSelector
        ? getSelectedMeasureGeoMapLegend(state)
        : getSelectedMeasureTreeLegend(state);// selectedMeasure.scale?.range.data; //visibleRange;
 //   console.log('[ColorCodingLegend]', colorBy, colorData);

    return ({
        colorBy,
        exportMode,
        editMode,
        colorData,
        value: `${value}`,
        activeLegendOption,
        searchStrainMode,

    });
};

const mapDispatchToProps = {
    setActiveLegendOption
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeLegend);
