import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, CircularProgress, Switch, MenuItem, Tooltip, FormControlLabel, Typography, Checkbox, Grid, FormGroup } from '@mui/material';
import { styles, EditMeasureDialogSX } from './styles';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

const dialogInitState = {
    key: '',
    label: '',
    highlightAll: false,
    showAll: false,
}

const EditSubsetDialog = ({ openDialog, handleCloseDialog, oldSubset, updateSubset }) => {
    const [newSubset, setNewSubset] = useState(dialogInitState);
    const [isRule, setIsRule] = useState(true);
    const classes = styles();

    const handleChangeKey = (e) => setNewSubset({ ...newSubset, key: e.target.value });
    const handleLabelChange = (e) => setNewSubset({ ...newSubset, label: e.target.value });
    const handleCheckboxChange = (e, type) => setNewSubset({ ...newSubset, [type]: e.target.checked });

    useEffect(() => {
        if (oldSubset.rule === 'from file')
            setIsRule(false);

        const newState = {
            key: oldSubset.key,
            label: oldSubset.label,
            highlightAll: !!oldSubset.highlightAll, // Ensure boolean
            showAll: !!oldSubset.showAll, // Ensure boolean
        }
        setNewSubset(newState);
    }, [oldSubset]);

    const handleSubmit = async () => {
        updateSubset(oldSubset.key, newSubset, isRule);
        handleCloseDialog();
    }

    const xs = 12;
    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={EditMeasureDialogSX}>
            <DialogTitle id="confirm-dialog-title">
                Edit subset:
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <StyledTextField
                    id="key"
                    type="text"
                    label="Key"
                    autoComplete='off'
                    className={classes.textField}
                    value={newSubset.key}
                    onChange={handleChangeKey}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    className={classes.textField}
                    value={newSubset.label}
                    onChange={handleLabelChange}
                />
                <Grid container >
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            label={<Typography className={classes.formControlLabel}>Show all</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={newSubset.showAll}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'showAll')}
                                        inputProps={{
                                            name: 'showAll',
                                            id: 'showAll',
                                        }}
                                    />
                                )
                            }
                        />
                    </Grid>
                    {!isRule &&
                        <Grid item xs={xs} >
                            <FormControlLabel
                                className={classes.boolOption}
                                value="highlightAll"
                                label={<Typography className={classes.formControlLabel}>Highlight all</Typography>}
                                control={
                                    (
                                        <Checkbox
                                            icon={<CircleUnchecked />}
                                            checked={newSubset.highlightAll}
                                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                            onChange={(e) => handleCheckboxChange(e, 'highlightAll')}
                                            inputProps={{
                                                name: 'highlightAll',
                                                id: 'highlightAll',
                                            }}
                                        />
                                    )
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
}

export default EditSubsetDialog;
