import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete,
    Paper, TablePagination, Button, InputAdornment, TableSortLabel, IconButton
} from '@mui/material';
import { styles, StyledTableCell } from './styles';
import { connect } from 'react-redux';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../../components/Alerts/styles';
import { getBiggerForSortTable } from '../../../functions/functions';
import AddNewScale from './Actions/Add';
import Edit from './Actions/Edit';
import { SymbolComponent } from './Elements/Symbol';
import Info from '../Info';
import Delete from './Actions/Delete';
import { fetchAxios } from '../../../functions/axiosRequests';
import config from '../../../config/envConfig';

const orderInitState = {
    name: 'none',
    label: 'none',
    lineage: 'none',
    mutGroup: 'none',
    color: 'none',
    symbol_sign: 'none'
}

const searchState = {
    name: '',
    lineage: '',
    mutGroup: '',
}

const searchOptionsState = {
    name: [],
    lineage: [],
    mutGroup: []
}

const MutationClassesTable = ({ fetchMutationClasses, mutationClasses, lineages }) => {
    const [tableData, setTableData] = useState([]);
    const [displayedTableData, setDisplayedTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searched, setSearched] = useState(searchState);
    const [orderBy, setOrderBy] = useState({ ...orderInitState, name: 'asc' });
    const [infoDialog, setInfoDialog] = useState(false);
    const [info, setInfo] = useState('');
    const [action, setAction] = useState('');
    const [searchOptions, setSearchOptions] = useState(searchOptionsState);
    const [mutationGenes, setMutationGenes] = useState([])
    const classes = styles();

    useEffect(() => {
        setTableMutationClasses();
        getMutationGenes();
    }, []);

    useEffect(() => {
        filterafterSearchClicked();
    }, [tableData]);
    
    useEffect(() => {
        setTableMutationClasses();
    }, [mutationClasses]);

    const updateTable = () => {
        fetchMutationClasses();
        setTableMutationClasses();
    }

    const getMutationGenes = () => {
        const url = `${config.serverLink}/api/admin/getLineagetMutationGenes`;
        fetchAxios(url).then((response) => {
            setMutationGenes(response.data.lineagetMutationGenes)
        })
    }

    const setTableMutationClasses = () => {
        const rows = mutationClasses
            .map(el => {
                return {
                    name: el.name,
                    label: el.label,
                    lineage: el.lineage,
                    mutGroup: el.mutGroup,
                    color: el.color,
                    symbol_sign: el.symbol_sign,
                    mutClass: el
                }
            });

        const newOptions = {
            name: ["", ...new Set(mutationClasses.map(el => el.name))],
            lineage: ["", ...new Set(mutationClasses.map(el => el.lineage))],
            mutGroup: ["", ...new Set(mutationClasses.map(el => el.mutGroup))],
        }
        setSearchOptions({ ...newOptions });
        setTableData(rows);
        const sorted = sortData([...rows], 'name', 'asc');
        setDisplayedTableData(sorted);
    }

    const sortData = (data, type, order) => {
        if (order === 'none')
            return [...tableData];

        const asc = order === 'asc';
        return data.sort((a, b) => getBiggerForSortTable(a, b, type, asc));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (e, type) => {
        setSearched({ ...searched, [type]: e });
    };

    const filterafterSearchClicked = () => {
        let filteredRows = [...tableData];

        if (searched.name !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.label.toLowerCase().includes(searched.name.toLowerCase()) || row.name.toLowerCase().includes(searched.name.toLowerCase())
            });
        }

        if (searched.mutGroup !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.mutGroup.toLowerCase().includes(searched.mutGroup.toLowerCase());
            });
        }

        if (searched.lineage !== '') {
            filteredRows = filteredRows.filter((row) => {
                return row.lineage.toLowerCase().includes(searched.lineage.toLowerCase());
            });
        }

        setDisplayedTableData(filteredRows);
        setPage(0);
    }

    const clearField = (type) => {
        setSearched({ ...searched, [type]: '' });
    }

    const cancelSearch = () => {
        setSearched({ ...searchState });
        setDisplayedTableData(tableData);
    };

    const handleSort = (type) => {
        let val = '';

        if (orderBy[type] === 'none')
            val = 'asc';
        else if (orderBy[type] === 'asc')
            val = 'desc';
        else
            val = 'asc'

        const newOrderBy = {
            ...orderInitState,
            [type]: val
        };

        setOrderBy(newOrderBy);
        const sorted = sortData([...displayedTableData], type, val);
        setDisplayedTableData(sorted)
    }

    const handleCloseInfo = () => {
        setInfoDialog(false);
    };

    return (
        <>
            <Paper sx={{ marginRight: '15px', marginLeft: '15px' }}>
                <div className={classes.searchcont}>
                    <Autocomplete
                        id="name"
                        options={searchOptions.name}
                        getOptionLabel={(option) => option}
                        value={searched.name}
                        className={classes.searchAuto}
                        onChange={(event, newValue) => requestSearch(newValue, 'name')}
                        inputValue={searched.name}

                        onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'name')}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                className={classes.search}
                                label="Name / Label"
                                InputProps={{
                                    style: { paddingRight: 0 },
                                    ...params.InputProps,
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearField('name')}>
                                                <HideIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="lineage"
                        options={searchOptions.lineage}
                        getOptionLabel={(option) => option}
                        value={searched.lineage}
                        className={classes.searchAuto}
                        onChange={(event, newValue) => requestSearch(newValue, 'lineage')}
                        inputValue={searched.lineage}
                        onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'lineage')}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                label="Lineage"
                                className={classes.search}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { paddingRight: 0 },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearField('lineage')}>
                                                <HideIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        id="mutGroup"
                        options={searchOptions.mutGroup}
                        getOptionLabel={(option) => option}
                        value={searched.mutGroup}
                        className={classes.searchAuto}
                        onChange={(event, newValue) => requestSearch(newValue, 'mutGroup')}
                        inputValue={searched.mutGroup}
                        onInputChange={(event, newInputValue) => requestSearch(newInputValue, 'mutGroup')}
                        renderInput={(params) => (
                            <StyledTextField
                                {...params}
                                label="Mutation group"
                                className={classes.search}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { paddingRight: 0 },
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => clearField('mutGroup')}>
                                                <HideIcon />
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        )}
                    />
                </div>
                <Button className={classes.saveButton} onClick={filterafterSearchClicked}>
                    Search
                </Button>
                <Button className={classes.cancelButton} onClick={cancelSearch}>
                    Cancel
                </Button>
                <TableContainer >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.name !== 'none'}
                                        direction={orderBy.name !== 'none' ? orderBy.name : 'asc'}
                                        onClick={(e) => handleSort('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.label !== 'none'}
                                        direction={orderBy.label !== 'none' ? orderBy.label : 'asc'}
                                        onClick={(e) => handleSort('label')}
                                    >
                                        Label
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.lineage !== 'none'}
                                        direction={orderBy.lineage !== 'none' ? orderBy.lineage : 'asc'}
                                        onClick={(e) => handleSort('lineage')}
                                    >
                                        Lineage
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.mutGroup !== 'none'}
                                        direction={orderBy.mutGroup !== 'none' ? orderBy.mutGroup : 'asc'}
                                        onClick={(e) => handleSort('mutGroup')}
                                    >
                                        Mutation group
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy.symbol_sign !== 'none'}
                                        direction={orderBy.symbol_sign !== 'none' ? orderBy.symbol_sign : 'asc'}
                                        onClick={(e) => handleSort('symbol_sign')}
                                    >
                                        Symbol sign
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="left">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                (rowsPerPage > 0
                                    ? displayedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : displayedTableData
                                ).map((row, index) => (
                                    <TableRow key={`${row.name}_${index}`} >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.label}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.lineage}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.mutGroup}
                                        </TableCell>
                                        <TableCell align="left">
                                            <SymbolComponent color={row.color} shape={row.symbol_sign} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <Edit
                                                mutationGenes={mutationGenes[row.lineage]}
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                                mutClass={row.mutClass}
                                                updateTable={updateTable}
                                            />
                                            <Delete
                                                setInfo={setInfo}
                                                setInfoDialog={setInfoDialog}
                                                setAction={setAction}
                                                updateTable={updateTable}
                                                name={row.name}
                                                lineage={row.lineage}
                                                mutGroup={row.mutGroup}
                                            />

                                        </TableCell>
                                    </TableRow>


                                ))
                            }
                            <TableRow
                                key='last'
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <td>
                                    <AddNewScale
                                        mutationGenes={mutationGenes}
                                        lineages={lineages}
                                        setInfo={setInfo}
                                        setInfoDialog={setInfoDialog}
                                        setAction={setAction}
                                        updateTable={updateTable}
                                    />
                                </td>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 100]}
                    component="div"
                    count={displayedTableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

            </Paper>
            <Info openDialog={infoDialog} handleCloseDialog={handleCloseInfo} info={info} action={action} />
        </>
    )
}

const mapStateToProps = (state) => {
    const { lineages } = state.lineages;

    return {
        lineages
    }
};

export default connect(mapStateToProps)(MutationClassesTable);