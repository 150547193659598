import { Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { signInStyles } from "./styles/signInStyles";
import * as React from 'react';
import Logo from '../../assets/images/Flupredict_logo_black.svg';

const WelcomeTitleBase = ({ classes }) => {
    return (
        <div className={classes.titleWrapper}>
            <Typography
                variant="h4"
                className={classes.text}
            >
                Welcome to the
            </Typography>
            <img src={Logo} alt="React Logo" className={classes.logo} />
            <br/><br/>
            <Typography
                variant="h4"
                className={classes.text}
            >
                A digital tool for tracking and&nbsp;
                predicting of fast evolving pathogens

            </Typography>
        </div>
    )
}

export const WelcomeTitle = withStyles(signInStyles)(WelcomeTitleBase);
