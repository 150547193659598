import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() => ({
    formControl: {
        minWidth: 100,
        margin: "8px 0px 0px 0px",
    },
    label: {
        whiteSpace: 'nowrap'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    },
    tooltip: {
        margin: 0,
        padding: 0
    }
}));
