import { received, request } from '../baseActions';
import {
    FETCH_GENOTYPE_DATA_REQUEST,
    FETCH_GENOTYPE_DATA_SUCCESS,
    FETCH_GENOTYPE_DATA_ERROR,
    RESET_GENOTYPE_STATUS,
    FETCH_MUT_POS_DICT_REQUEST,
    FETCH_MUT_POS_DICT_SUCCESS,
    FETCH_MUT_POS_DICT_ERROR,
    FETCH_MUTATION_GROUP_REQUEST,
    FETCH_MUTATION_GROUP_SUCCESS,
    FETCH_MUTATION_GROUP_ERROR
} from './actionTypes';


const fetchMutationsPositionsDictionary = (payload) => received(FETCH_MUT_POS_DICT_REQUEST, payload);
const fetchMutationsPositionsDictionarySuccess = (payload) => received(FETCH_MUT_POS_DICT_SUCCESS, payload);
const fetchMutationsPositionsDictionaryError = (payload) => received(FETCH_MUT_POS_DICT_ERROR, payload);
const fetchGenotypeData = (payload) => received(FETCH_GENOTYPE_DATA_REQUEST, payload);
const fetchGenotypeDataSuccess = (payload) => received(FETCH_GENOTYPE_DATA_SUCCESS, payload);
const fetchGenotypeDataError = (payload) => received(FETCH_GENOTYPE_DATA_ERROR, payload);
const fetchMutationGroupValues = (payload) => received(FETCH_MUTATION_GROUP_REQUEST, payload);
const fetchMutationGroupValuesSuccess = (payload) => received(FETCH_MUTATION_GROUP_SUCCESS, payload);
const fetchMutationGroupValuesError = (payload) => received(FETCH_MUTATION_GROUP_ERROR, payload);
const resetGenotypeStatus = () => request(RESET_GENOTYPE_STATUS);

export {
    fetchGenotypeData,
    fetchGenotypeDataSuccess,
    fetchGenotypeDataError,
    resetGenotypeStatus,
    fetchMutationsPositionsDictionary,
    fetchMutationsPositionsDictionarySuccess,
    fetchMutationsPositionsDictionaryError,
    fetchMutationGroupValues,
    fetchMutationGroupValuesSuccess,
    fetchMutationGroupValuesError
};
