import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import ConfirmDialog from '../../ConfirmDialog';
import { postAxios } from '../../../../functions/axiosRequests';

const DeleteRole = (props) => {
    const { roleName, setLoaded, setInfoDialog, setInfo, setAction } = props;
    const [openDialog, setOpenDialog] = useState(false);

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteRole = () => {
        const url = `${config.serverLink}/api/admin/deleteRole`;
        const body = { name: roleName }
        postAxios(url, body)
            .then((response) => {
                if (!response.status)
                    setInfo(`An error occured during deleting ${roleName}.`);
                else
                    setInfo(`Role ${roleName} deleted sucesfully.`);

                setAction('Delete role');
                setLoaded(false);
                handleCloseDialog();
                setInfoDialog(true);
            });
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog email={roleName} handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteRole} role={true}/>
        </>

    );
};

export default DeleteRole;
