
import React from 'react';
import { FormControl, InputLabel,MenuItem } from '@mui/material';
import { styles } from '../styles';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';

export const Select = ({
    options, handleChangeSelect, value, id
}) => {
    const classes = styles();

    return (
        <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="plotType">
                {id}
            </InputLabel>
            <CustomSelect
                className={classes.textField}
                value={value || ''}
                onChange={(e) => handleChangeSelect(e, id)}
                inputProps={{
                    name: id,
                    id: id,
                }}
            >
                {options.length > 0 &&
                    options.map((option) => (
                        <MenuItem key={option} value={option}>{option}</MenuItem> ))
                }
            </CustomSelect>
        </FormControl>
    )
}
