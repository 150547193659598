// Action Types
import {
    FETCH_HUMAN_POOLS_REQUEST,
    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_HUMAN_POOLS_ERROR,
    FETCH_HUMAN_SEROLOGY_REQUEST,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_HUMAN_SEROLOGY_ERROR,
    RESET_HUMAN_SEROLOGY_DATA
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';

export const fetchHumanPools = (lineage) => received(FETCH_HUMAN_POOLS_REQUEST, { lineage });
export const fetchHumanPoolsSuccess = (res) => received(FETCH_HUMAN_POOLS_SUCCESS, res);
export const fetchHumanPoolsError = (error) => received(FETCH_HUMAN_POOLS_ERROR, error);

export const fetchHumanSerologyData = (res) => received(FETCH_HUMAN_SEROLOGY_REQUEST, res);
export const fetchHumanSerologyDataSuccess = (res) => received(FETCH_HUMAN_SEROLOGY_SUCCESS, res);
export const fetchHumanSerologyDataError = (error) => received(FETCH_HUMAN_SEROLOGY_ERROR, error);

export const resetHumanSerologyData = () => received(RESET_HUMAN_SEROLOGY_DATA);


// const fetchModel = (payload) => received(FETCH_MODEL_DATA_REQUEST, payload);
// const fetchModelSuccess = (res) => received(FETCH_MODEL_DATA_SUCCESS, res);
// const fetchModelError = (error) => received(FETCH_MODEL_DATA_ERROR, error);

