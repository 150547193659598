
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { styles } from '../Tree/styles'
import { dateFormat } from '../../functions/checkBrowser';
import { daysToDate, dateToDays } from '../../functions/functions';
import { HideIcon } from '../Alerts/styles'
import { strainCutOffDateSelector } from '../../redux/selectors/parametersSelector';

const StrainCutoffDatePicker = (props) => {
    const { strainCutOffDate, strainCutoffDateMinMax, setParameters, initStrainTree,
        lineage, modelId, colorBy, zoomNodeId, strainSubset, vpMethod } = props;
    const [strainCutOffDateHolder, setStrainCutOffDateHolder] = useState(strainCutOffDate);
    // const treeAttrsParams = ['p', colorBy, 'time'].join(',');

    // useEffect(() => {
    //     setStrainCutOffDateHolder(strainCutOffDate);
    // }, [strainCutOffDate]);

    const handleStrainCutOffDateChange = date => {
        if (!date) {
            setParameters({ strainCutOffDate: null });
            setStrainCutOffDateHolder(date);
            initStrainTree({ lineage, colorBy, zoomNodeId, strainSubset, strainCutOffDate: date, vpMethod });
        }

        if (date && !isNaN(date.getTime()) && date !== strainCutOffDate)
            setStrainCutOffDateHolder(date);
    };

    const handleAccept = date => {
        setStrainCutOffDateHolder(date);
        setParameters({ strainCutOffDate: date });

        if (date !== strainCutOffDate)
            initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, strainCutOffDate: date, vpMethod  });
    }

    const handleKeyPress = event => {
       
        if (event.key === 'Enter') {
            const dateInt = dateToDays(strainCutOffDateHolder)

            if (dateInt > strainCutoffDateMinMax.max || dateInt < strainCutoffDateMinMax.min) {
                setStrainCutOffDateHolder({ strainCutOffDateHolder: strainCutOffDate });
            } else {
                setParameters({ strainCutOffDate: strainCutOffDateHolder });

                if (strainCutOffDateHolder !== strainCutOffDate)
                    initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, strainCutOffDate: strainCutOffDateHolder, vpMethod });
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                clearable
                // minDate={strainCutoffDateMinMax &&
                //     daysToDate(strainCutoffDateMinMax.min)
                // } 
                maxDate={strainCutoffDateMinMax &&
                    daysToDate(strainCutoffDateMinMax.max)
                }
                variant="inline"
                format={dateFormat}
                allowSameDateSelection
                id="strainCutOffDate"
                label="Show strains from"
                value={strainCutOffDateHolder}
                onChange={handleStrainCutOffDateChange}
                onAccept={handleAccept}
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                        letterSpacing: '-0.5px'
                    }
                }}
                slotProps={{
                    textField: {
                        variant: 'standard',
                        onKeyDown: handleKeyPress,
                        InputProps: {
                            endAdornment: (
                                <IconButton style={{ padding: 0 }} onClick={() => handleStrainCutOffDateChange(null)}>
                                    <HideIcon />
                                </IconButton>
                            )
                        },
                        style: {
                            margin: 0
                        }
                    },
                    inputAdornment: { position: 'start' },

                }}
            />
        </LocalizationProvider>
    );
}

StrainCutoffDatePicker.propTypes = {
    lineage: PropTypes.string,
    strainCutOffDate: PropTypes.instanceOf(Date),
    colorBy: PropTypes.string,
    modelId: PropTypes.string,
    zoomNodeId: PropTypes.number,
    strainSubset: PropTypes.string,
    vpMethod: PropTypes.string,
    setParameters: PropTypes.func,
    initStrainTree: PropTypes.func,
};

const mapStateToProps = (state) => {
    const { parameters, treeData } = state;
    return {
        colorBy: parameters.colorBy,
        strainCutoffDateMinMax: treeData.strainCutoffDateMinMax,
        strainCutOffDate: strainCutOffDateSelector(state),
        lineage: parameters.lineage,
        modelId: parameters.modelId,
        zoomNodeId: parameters.zoomNodeId,
        strainSubset: parameters.strainSubset,
        vpMethod: parameters.vpMethod,
    }
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        initStrainTree,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StrainCutoffDatePicker));
