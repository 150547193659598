import {
    FETCH_HUMAN_POOLS_REQUEST,
    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_HUMAN_POOLS_ERROR,
    SIGNOUT_REQUEST,
    RESET_SESSION,
    RESET_HUMAN_SEROLOGY_DATA,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_HUMAN_SEROLOGY_ERROR,
    FETCH_HUMAN_SEROLOGY_REQUEST,
    INIT_STRAIN_TREE_REQUEST,
    FETCH_SELECTED_STRAIN_REQUEST,
    ZOOM_TREE_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    // FETCH_VISIBLE_NODES_REQUEST,
} from '../actions/actionTypes';
import deepFreeze from 'deep-freeze';
let humanSerologyInitialState = {};
export const setHumanSerologyInitialState = (state) => {
    humanSerologyInitialState = state;
};



const humanSerology = (state = humanSerologyInitialState, action) => {
    deepFreeze(state);
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return humanSerologyInitialState;
        }

        case RESET_HUMAN_SEROLOGY_DATA: {
            return {
                ...state,
                humanSerologyDataStatus: 'none',
                humanSerologyData: {}
            }
        }
        case FETCH_HUMAN_POOLS_REQUEST: {
            return {
                ...state,
                humanPoolsStatus: 'loading',
            };
        }
        case FETCH_HUMAN_POOLS_SUCCESS: {
            const { humanPools } = action.payload;
            return {
                ...state,
                humanPools,
                humanPoolsStatus: 'loaded',
                humanSerologyDataStatus: 'refetchNeeded',
               
            };
        }
        case FETCH_HUMAN_POOLS_ERROR: {
            return {
                ...state,
                humanPoolsStatus: 'error'
            };
        }

        case FETCH_HUMAN_SEROLOGY_REQUEST: {
            return {
                ...state,
                humanSerologyData: {},
                humanSerologyDataStatus: 'loading'
            }
        }
        case FETCH_HUMAN_SEROLOGY_SUCCESS: {
            const { humanSerologyData, humanSerologyRefStrains } = action.payload;
            return {
                ...state,
                humanSerologyData,
                humanSerologyRefStrains,
                humanSerologyDataStatus: 'loaded'
            }
        }

        case FETCH_HUMAN_SEROLOGY_ERROR: {
            return {
                ...state,

                humanSerologyDataStatus: 'error'
            }
        }

        case INIT_STRAIN_TREE_REQUEST:
        case FETCH_SELECTED_STRAIN_REQUEST:
        case ZOOM_TREE_REQUEST:
        case FETCH_VP_VALUES_REQUEST:
        case FETCH_SUBSET_TREE_REQUEST:
        case FETCH_RECALCULATED_TREE_REQUEST:
        // case FETCH_VISIBLE_NODES_REQUEST: 
        {

            return {
                ...state,
                humanSerologyRefStrains: [],
                humanSerologyDataStatus: 'refetchNeeded'
            }
        }
        // case FETCH_COLOR_BY_RULE_STRAINS_SUCCESS: {
        //     const { humanSerologyRefStrains } = action.payload;
        //     return {
        //         ...state,
        //         humanSerologyRefStrains
        //     }
        // }

        default:
            return state || humanSerologyInitialState;
    }
};

export { humanSerology };
