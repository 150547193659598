const isAntigenicModel = (modelId, models) => models && models.includes(modelId);
const isValidModelId = (modelId, models, modelTypes) => modelTypes.filter((mt) => mt !== 'Antigenic').includes(modelId) || isAntigenicModel(modelId, models);

const propToString = (val) => {
    if (val instanceof Object) return `Object, keys: ${Object.keys(val).length}`;
    return val;
};
const printWhatChanged = (props, state, prevProps, prevState, prefix) => {
    const whatChanged = (props, prevProps) => {
        const merged = { ...(prevProps || {}), ...(props || {}) };
        console.log('[printWhatChanged]', merged);
        return Object.keys(merged)
            .filter((p) => props[p] !== prevProps[p])
            .map((key) => `${key}: ${propToString(prevProps[key])} => ${propToString(props[key])}`);
    };

    const whatChangedProps = whatChanged(props, prevProps);
    const whatChangedState = whatChanged(state, prevState);
    //this.counter += 1;

    //const _counter = this.counter;
    //let counter = 1;
    console.log(`[printWhatChanged] ${prefix||''}`, `${JSON.stringify(whatChangedProps, null, ' ')}, ${JSON.stringify(whatChangedState, null, ' ')}`);
    // Object.entries(props).forEach(([key, val]) => prevProps[key] !== val && console.log(`Prop '${key}' changed`));
    // if (state) {
    //     Object.entries(state).forEach(([key, val]) => prevState[key] !== val && console.log(` State '${key}' changed`));
    // }
   
};

class MeasureTimer {
    //startTime = null;
    layerStartTimes = {};

    // constructor() {
    //     startTime = new Date().getTime();
    // }
    setLayerStartTime = (layer) => {
        if (!this.layerStartTimes[layer])
            this.layerStartTimes[layer] = new Date().getTime();
    }
    getLayerStartTime = (layer) => this.layerStartTimes[layer];
            
    getLayerInterval = (layer) => {
        if (!this.layerStartTimes[layer])
            this.layerStartTimes[layer] = new Date().getTime();
        return new Date().getTime() - this.layerStartTimes[layer];
    }
    resetLayer = (layer) => {
        delete this.layerStartTimes[layer];
    }

}

const measureTimer = new MeasureTimer();

export { isAntigenicModel, isValidModelId, printWhatChanged, measureTimer };
