import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { styles, dialog } from '../styles';

const CacheDialog = ({ handleCloseDialog, openDialog }) => {
    const classes = styles();
    const { open, type } = openDialog;

    return (
        <Dialog open={open} onClose={() => handleCloseDialog(type)} sx={dialog}>
            <DialogTitle id="confirm-dialog-title">
                Clear cache
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    { type === 'success' ?
                        'Cache cleared successfully.' :
                        'Clearing cache failed.'
                    }
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className={classes.confirm}
                    onClick={() => handleCloseDialog(type)}>Close</Button>
            </DialogActions>
        </Dialog>);
};

export default CacheDialog;
