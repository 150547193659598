import {
    SET_RENDER_STATUS,
    SET_SEARCH_STRAIN_MODE,
    SET_COMPONENT_STATUS,
    RESET_COMPONENTS_STATUS,
    SET_STRAINS_TREE_DIMENSIONS,
    SET_LABEL_MOVEMENT,
    // SET_SYMBOL_POSITION,
    // SET_SYMBOL_DIMENSIONS,
    CORRECT_LABEL_POSITIONS,
    SET_HIDDEN_MENU,
    SET_HIDDEN_MENU_MOBILE,
    SET_ERROR_WEBSITE,
    SET_SESSION_TIMEOUT
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';

const setRenderStatus = renderStatus => received(SET_RENDER_STATUS, { renderStatus });

const setComponentStatus = (viewName, componentId, status, labels) => received(SET_COMPONENT_STATUS, { viewName, componentId, status, labels });

const resetComponentsStatus = (viewName, status) => received(RESET_COMPONENTS_STATUS, { viewName, status });

const setSearchStrainMode = searchStrainMode => received(SET_SEARCH_STRAIN_MODE, { searchStrainMode });

const setStrainTreeDimensions = (payload) => received(SET_STRAINS_TREE_DIMENSIONS, payload);

// const setSymbolPosition = (payload) => received(SET_SYMBOL_POSITION, payload);

// const setSymbolDimensions = (payload) => received(SET_SYMBOL_DIMENSIONS, payload);

const setLabelMovement = (payload) => received(SET_LABEL_MOVEMENT, payload);

const correctLabelPositions = (payload) => received(CORRECT_LABEL_POSITIONS, payload);

const setHiddenMenu = (payload) => received(SET_HIDDEN_MENU, payload);

const setHiddenMenuMobile = (payload) => received(SET_HIDDEN_MENU_MOBILE, payload);

const setErrorWebsite = (payload) => received(SET_ERROR_WEBSITE, payload);

const setSessionTimeout = (payload) => received(SET_SESSION_TIMEOUT, payload);

export {
    setRenderStatus,
    setSearchStrainMode,
    setComponentStatus,
    resetComponentsStatus,

    setLabelMovement,
    // setSymbolPosition,
    // setSymbolDimensions,
    setStrainTreeDimensions,
    correctLabelPositions,
    setHiddenMenu,
    setHiddenMenuMobile,
    setErrorWebsite,
    setSessionTimeout
};
