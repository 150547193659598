const getStyle = (oElm, strCssRule) => {
    let strValue = '';
    if (document.defaultView && document.defaultView.getComputedStyle) {
        strValue = document.defaultView.getComputedStyle(oElm, '').getPropertyValue(strCssRule);
    } else if (oElm.currentStyle) {
        strCssRule = strCssRule.replace(/-(\w)/g, (strMatch, p1) => p1.toUpperCase());
        strValue = oElm.currentStyle[strCssRule];
    }
    return strValue;
};

const pxToNum = (pxVal) => parseFloat(pxVal);

const getNodeWidth = (node) => pxToNum(getStyle(node, 'width'));
const getNodePaddingWidth = (node) => pxToNum(getStyle(node, 'padding-left'))+pxToNum(getStyle(node, 'padding-right'));

const getNodeHeight = (node) => pxToNum(getStyle(node, 'height'));

export { getStyle, pxToNum, getNodeWidth, getNodeHeight, getNodePaddingWidth };
