import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { Autocomplete, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce';
import { selectNodeData } from '../../redux/actions/nodeActions';
import { setSearchStrainMode } from '../../redux/actions/renderActions';
import { fetchSelectedStrain, setSelectedStrain, setStrainSearchStatus, fetchStrainsList, setStrainsList } from '../../redux/actions/treeDataActions';
import { emptyObject, shouldFetch } from '../../functions/functions';
import { getTreeNodeAttrs } from '../../redux/selectors/treeDataSelector';
import { isNil } from 'lodash';
import useSuppressWarnings from './helper/useSuppressWarnings';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'left',
        padding: '0px 0px',
        width: '100%'
    },
    formControl: {
        minWidth: 120,
        margin: '8px 0px'
    },
    field: {
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    error: {
        color: 'red',
        fontSize: '10pt'
    },
    listItem: {
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        lineHeight: 1.2,
        padding: theme.spacing(1),
        cursor: 'pointer', // Add pointer cursor
        '&:hover': {
            backgroundColor: theme.palette.grey[300], // Lighter grey on hover
        },
    },
    oddItem: {
        backgroundColor: theme.palette.grey[200],
    },
    selectedItem: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Darker blue on hover
        },
    }
}));

const emptyList = [];

const TreeStrainSearch = props => {
    const {
        strainId, strainName, lineage, loading, strainSearchStatus, fetchStrainsList, searchId, setStrainsList, searchedStrainList,
        selectNodeData, setSearchStrainMode, fetchSelectedStrain, treeAttrs, setStrainSearchStatus, setSelectedStrain,
        zoomNodeId, strainSubset, label, strainsListStatus
    } = props;

    const [error, setError] = useState(false);
    const [errorTxt, setErrorTxt] = useState('');
    const [shouldBeEmpty, setShouldBeEmpty] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const classes = useStyles();

    const txt = useRef();
    const currentPattern = useRef(strainName);
    const showNodeInfo = useRef(false);

  const handleKeyDown = (event) => {
    // Example: Prevent default behavior for Enter key (key code 13)
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    // Add any other key handling logic here if necessary
  };

    useEffect(() => {
        if (strainId && strainName) {
            if (shouldFetch(strainsListStatus)) fetchStrainsList({ lineage, strainsTxt: strainName, searchId });
            setShouldBeEmpty(false);
        }
    }, []);

    useEffect(() => {
        setError(false);
        setErrorTxt('');

        if (strainSearchStatus === 'loading' || strainSearchStatus === 'none') return;

        if (!isNil(strainId) && !shouldFetch(strainSearchStatus)) {
            const statuses = strainSearchStatus.split(',').reduce((acc, k) => { acc[k] = true; return acc }, {});

            let errorMessages = [];

            if (statuses['not_found']) {
                setSearchStrainMode(false);
                errorMessages.push('Strain not found.');
            }
            if (statuses['not_in_scope']) {
                setSearchStrainMode(false);
                errorMessages.push('Strain not in the selected branch.');
            }
            if (statuses['not_in_subset']) {
                setSearchStrainMode(false);
                errorMessages.push('Strain not in the selected subset.');
            }

            if (statuses['found']) {
                if (showNodeInfo.current) {
                    setSearchStrainMode(true);
                    selectNodeData({ nodeId: strainId });
                }
            }

            if (errorMessages.length > 0) {
                setError(true);
                setErrorTxt(errorMessages.join('\n'));
            }
        }
    }, [strainSearchStatus, strainId, setSearchStrainMode, selectNodeData]);

    const fetchStrains = useCallback(
        debounce((lineage, strainsTxt) => {
            if (strainsTxt.length < 3) {
                clearStrainsList();
                setShouldBeEmpty(true);
            }

            if (!emptyObject(strainsTxt)
                && (strainsTxt.length === 3
                    || (strainsTxt.length > 3
                        && (!strainsTxt.match(currentPattern.current) || currentPattern.current === '')
                    )
                )
            ) {
                currentPattern.current = strainsTxt;
                fetchStrainsList({ lineage, strainsTxt, searchId });
                setShouldBeEmpty(false);
            }
        }, 100),
        [fetchStrainsList, lineage, searchId]
    );

    const clearStrainsList = () => {
        setStrainsList({ strainsList: emptyList });
    };

    const handleStrainInputChange = (event, value, reason) => {
        value = value.toUpperCase();
        txt.current = value;

        if (!value.match(currentPattern.current)) currentPattern.current = '';
        if (reason === 'input' || reason === 'clear') {
            if (reason === 'clear' || !value) {
                clearStrainsList();
                setSelectedStrain({ searchId });
                selectNodeData();
            }
            fetchStrains(lineage, value);
        }
    };

    const handleSelectedStrain = (_, selectedStrain, reason) => {
        if (reason !== 'selectOption') {
            return;
        }

        if (emptyObject(selectedStrain) || loading) return;

        const id = selectedStrain.id;
        setSearchStrainMode(true);
        if (!treeAttrs[id]) {
            fetchSelectedStrain({ lineage, strainId: id, searchId, zoomNodeId, strainSubset });
        } else {
            setStrainSearchStatus({ strainSearchStatus: 'found', searchId });
            setSelectedStrain({ searchId });
            selectNodeData({ nodeId: id });
        }
        showNodeInfo.current = true;
    };

    const undefinedToNull = val => (val === undefined ? null : val);
    const strainsList = useMemo(() => (searchedStrainList || emptyList), [searchedStrainList]);
    const strainOption = undefinedToNull(!isNil(strainId) && strainsList.length > 0 ? strainsList.find(v => v.id === strainId) : null);

    const handleMouseEnter = () => {
        if (strainId) selectNodeData({ nodeId: strainId, nodeType: 'leaf', onlyHighlight: true });
    };

    const handleMouseLeave = () => {
        if (!isOpen) {
            if (strainId) selectNodeData();
        }
    };

    const renderErrorMessages = (errorTxt) => {
        return errorTxt.split('\n').map((msg, index) => (
            <div key={index}>{msg}</div>
        ));
    };

    return (
        <div className={classes.root}>
            <Autocomplete
                id="strainSearch"
                className={classes.field}
                options={shouldBeEmpty ? [] : strainsList}
                isOptionEqualToValue={(option, value) => value && option.n === value.n}
                getOptionLabel={option => (option ? option.n : '')}
                loading={loading}
                autoSelect
                disablePortal
                value={strainOption}
                defaultValue=""
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onInputChange={handleStrainInputChange}
                onChange={handleSelectedStrain}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                renderOption={(props, option, { selected }) => {
                    const index = strainsList.findIndex(item => item.id === option.id);
                    return (
                        <li
                            {...props}
                            className={`${classes.listItem} ${selected ? classes.selectedItem : ''} ${index % 2 !== 0 ? classes.oddItem : ''}`}
                        >
                            {option.n}
                        </li>
                    );
                }}
                renderInput={params => (
                    <StyledTextField
                    {...params}
                    label={label}
                    id="textField"
                    style={{ marginTop: '8px', minHeight: '56px' }}
                    multiline
                    fullWidth
                    onKeyDown={handleKeyDown}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: handleKeyDown, 
                        style: { overflow: 'hidden', whiteSpace: 'normal' }, 
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
                )}
            />
            {error && (
                <div className={classes.error}>
                    {renderErrorMessages(errorTxt)}
                </div>
            )}
        </div>
    );
}

TreeStrainSearch.propTypes = {
    strainId: PropTypes.number,
    lineage: PropTypes.string,
    loading: PropTypes.bool,
    strainSearchStatus: PropTypes.string,
    fetchStrainsList: PropTypes.func.isRequired,
    searchId: PropTypes.string.isRequired,
    setStrainsList: PropTypes.func.isRequired,
    searchedStrainList: PropTypes.array,
    selectNodeData: PropTypes.func.isRequired,
    setSearchStrainMode: PropTypes.func.isRequired,
    fetchSelectedStrain: PropTypes.func.isRequired,
    treeAttrs: PropTypes.object.isRequired,
    setStrainSearchStatus: PropTypes.func.isRequired,
    setSelectedStrain: PropTypes.func.isRequired,
    zoomNodeId: PropTypes.number,
    label: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const { parameters, treeData } = state;
    const strainSearchStatus = treeData.strainSearchStatuses?.[ownProps.searchId];
    const strainsListStatus = treeData.strainsListStatuses?.[ownProps.searchId];
    const searchedStrainList = treeData.strainsLists[ownProps.searchId];
    const { strainId, lineage, zoomNodeId, strainSubset } = parameters;
    const treeAttrs = getTreeNodeAttrs(state);
    const strainName = !isNil(strainId) && treeAttrs[strainId] ? treeAttrs[strainId].name : '';
    return {
        strainId,
        strainName,
        searchedStrainList,
        strainSearchStatus,
        lineage,
        zoomNodeId,
        strainSubset,
        strainsListStatus,
        loading: strainsListStatus === 'loading' || strainSearchStatus === 'loading',
        treeAttrs
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        selectNodeData,
        setSearchStrainMode,
        fetchSelectedStrain,
        setSelectedStrain,
        setStrainSearchStatus,
        fetchStrainsList,
        setStrainsList
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(TreeStrainSearch);
