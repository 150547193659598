import React from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';

import App from '../App';

import { history } from '../redux/configureStore';
import { connect } from 'react-redux';
import auth from '../functions/auth-helper';
import CustomRouter from './CustomRouter';

import AppRoutes from './AppRoutes';


const MainRouter = ({isAuthenticated, role, modules}) => {
   
    return (
        <>
        <CustomRouter history={history}>
            <App>
                <AppRoutes/>
            </App>
        </CustomRouter>
        </>
    )
};

const mapStateToProps = ({ user, metatada }) => {
    return ({
        isAuthenticated: auth.isAuthenticated(),
        role: user.role,
        modules: metatada?.modules
    });
};

export default connect(mapStateToProps)(MainRouter);
