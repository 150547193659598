import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { getYAxisTextWidth, getPlotFrequenciesForRegions } from '../../redux/selectors/frequenciesSelector';
import { getChartStatus, getIsMobile } from '../../redux/selectors/statusSelector';
import SequencesChartD3 from './helpers/SequencesChartD3';
import { getNodeWidth, getNodeHeight } from '../../functions/cssHelpers';
import { fetchClades } from '../../redux/actions/cladeActions';
import { setRenderStatus, setComponentStatus } from '../../redux/actions/renderActions';
import { COMPONENTS } from '../../config/consts';
import { useEventListener } from 'usehooks-ts';
import { useDebouncedCallback, usePrevious } from '../../functions/customHooks';
import { printWhatChanged } from '../../functions/utils';
import { predictionBaselineSelector, trackingFromSelector, trackingToSelector } from '../../redux/selectors/parametersSelector';

const styles = () => ({
    graph: {
        padding: '0px 20px 20px 20px',
        minHeight: '300px',
        height: '320px',
    },
    graphMobile: {
        padding: '2px 0px 20px 0px',
        minHeight: '300px',
        height: '320px',
    },
    multiexport: {
        // height: '150px',
        // margin: '30px 0',
        height: '100%',
    },
    // title: {
    //     padding: '8px',
    //     borderStyle: 'solid solid none solid',
    //     // borderColor: 'rgba(0, 0, 0, 0.3)',
    //     borderWidth: '0.5px',
    //     boxShadow: '0 1px 1px -1px rgba(0, 0, 0, 0.3)'
    // }
    border: {
        border: 'solid 1px #c1c1c1'
    },

});

const useStyles = makeStyles(styles);

const SequencesChart = React.memo(props => {

    const { ownPropsRegionId, exportMode, multiexport, data,
        subsetId, type, loading, hiddenMenu, viewName, yAxisTextWidth, isMobile } = props;
    const classes = useStyles();


    
    const _element = useRef();

    const componentId = useRef(`${COMPONENTS.SEQ_CHART}_${new Date().getTime()}_${subsetId}`);


    const chartD3 = useRef(new SequencesChartD3(type));

    const updateDimensions = () => {
        if (_element.current) {
            const mountNode = _element.current;
            const { parentNode } = mountNode;
            const width = getNodeWidth(parentNode);
            const height = getNodeHeight(parentNode);
            chartD3.current.resizeComponent(width, height);
        }
    };

    const debouncedResize = useDebouncedCallback(() => {
        updateDimensions();
    }, 200);

    if (!exportMode) {
        useEventListener('resize', debouncedResize);
    }


    useEffect(() => {
        const mountNode = _element.current;
        const { parentNode } = mountNode;
        const width = getNodeWidth(parentNode);
        const height = getNodeHeight(parentNode);
        // console.log({ subsetId, type, width});

        chartD3.current.setProps(props);
        chartD3.current.setMountNode(mountNode);
        chartD3.current.setComponentName(`${type || ''}${ownPropsRegionId || ''}Chart`);
        chartD3.current.setHeight(height);
        chartD3.current.setWidth(width);
        // console.log({ subsetId, type, width,height});

        chartD3.current.prepareGraphArea();
    }, [])

    useEffect(() => {
        chartD3.current.setProps(props);
        if (loading) {
            chartD3.current.removePlots();
            return;
        }
        if (yAxisTextWidth) {
            chartD3.current.setYAxisTextWidth(yAxisTextWidth);
            chartD3.current.changeAxes();
        }

        chartD3.current.renderD3Component(viewName, componentId.current, loading);
    }, [loading, data]);

    useEffect(() => {
        if (loading) return;
        updateDimensions();
    }, [hiddenMenu]);




    const style = multiexport
        ? classes.multiexport
        : isMobile
            ? classes.graphMobile
            : classes.graph

    return (
        <div className={style}>
            {/* {title && <div className={classes.title}>{title}</div>} */}
            <svg id={`${type}_svg`} className={`${classes.border} svg-bg`} ref={_element} />
            {/* <ChartTooltip position={tooltipPos} info={info} /> */}
        </div>
    );

});

// Mapping our Redux State to Props

SequencesChart.propTypes = {
    loading: PropTypes.bool,
    lineage: PropTypes.string,
    ownPropsRegionId: PropTypes.string,
    type: PropTypes.string,
    viewName: PropTypes.string,
    classes: PropTypes.shape({ graph: PropTypes.string, title: PropTypes.string })
};


// function hashCode(obj) {
//     if (!obj) return 0;
//     const str = JSON.stringify(obj);
//     let hash = 0, i, chr;

//     if (str.length === 0) return hash;

//     for (i = 0; i < str.length; i++) {
//         chr = str.charCodeAt(i);
//         hash = (hash << 5) - hash + chr;
//         hash |= 0; // Convert to 32-bit integer
//     }

//     return hash;
// }

const mapStateToProps = (state, ownProps) => {
    // const { clades } = state.cladeData;
    const { type } = ownProps;
    const { lineage, exportMode } = state.parameters;
    const subsetId = ownProps.regionId || state.parameters.strainSubset;

    const frequenciesPlotDataRegions = getPlotFrequenciesForRegions(state);
    const frequenciesPlotData = frequenciesPlotDataRegions[subsetId] || {};
    const { maxSequencesVal, maxCasesVal, sequencesPlot, casesPlot } = frequenciesPlotData;


    const startTime = trackingFromSelector(state);
    const trackingTo = trackingToSelector(state);

    const predictionBaseline = predictionBaselineSelector(state);

    const frequenciesStatusesForRegion = state.frequenciesData.frequenciesStatus;
    const loading = getChartStatus(state) || frequenciesStatusesForRegion[subsetId] !== 'loaded';

    const maxVal = ((type === 'seq') ? maxSequencesVal : maxCasesVal) || 0;
  

    const yAxisTextWidth = ownProps.multiexport ? 0 : getYAxisTextWidth(state);

    const data = (type === 'seq') ? sequencesPlot : casesPlot;
    // console.log({data: hashCode(data),d: data, yAxisTextWidth, loading});
    return ({
        type,
        lineage,
        subsetId,
        ownPropsRegionId: ownProps.regionId,
        startTime,
        endTime: trackingTo,
        predictionBaseline,
        maxVal, //add epsilon in order to fit the values in the chart area
        data,
        loading,
        exportMode,
        //title: ownProps.title,
        multiexport: ownProps.multiexport,
        hiddenMenu: state.render.hiddenMenu,
        isMobile: getIsMobile(),
        yAxisTextWidth
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchClades,
    setRenderStatus,
    setComponentStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SequencesChart);
