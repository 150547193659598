import { select, pointer } from 'd3-selection';
import { area, line, curveMonotoneX, curveStep } from 'd3-shape';
import D3Component from '../../D3Component/D3Component';
import {
    initFrequencyChartScales, initStackedFrequencyChartScales, getInvertedValue,
    reinitFrequencyChartScales, reinitStackedFrequencyChartScales
} from '../../../functions/scales';
import { dateToDays, daysToDate, emptyObject, isNumeric, getTextMetrics } from '../../../functions/functions';
import { numFormat } from '../../../functions/formats';
import { drawChartAxes } from './chartAxes';
import { RENDER_STATUS } from '../../../config/consts';

const missingDataColor = '#dee0e2';

class FrequencyChartD3 extends D3Component {
    exportWidthLabelsPadding = 200;

    constructor(componentId, handleTooltipPositionChange) {
        super();
        this.componentId = componentId;
        this.changePosition = (x, y, info) => handleTooltipPositionChange(x, y, info);
    }

    componentName = 'binFrequencies';

    margin = { top: 0, bottom: 0, left: 0, right: 0 }

    marginExport = { top: 0, bottom: 0, left: 0, right: 0 }

    padding = { top: 0, bottom: 32, left: 48, right: 0 };

    paddingExport = { top: 0, bottom: 32, left: 48, right: 0 };

    paddingMultiExport = { top: 10, bottom: 12, left: 18, right: 0 };

    grad = null;

    getLongestLabel = () => {
        const { clades, visibleBins } = this.props;
        let longest = 0;

        for (const bin of Object.keys(visibleBins)) {
            if (visibleBins[bin]) {
                const label = clades[bin]?.label || '';
                const length = getTextMetrics(label, `12px 'Inter', 'Verdana'`);
                if (length.width > longest)
                    longest = length.width;
            }
        }

        return longest;
    }

    getWidth = () => {
        const { exportMode, multiexport } = this.props;
        this.exportWidthLabelsPadding = exportMode ? this.getLongestLabel() : 0;
        const res = exportMode && !multiexport ? this.width - this.exportWidthLabelsPadding : this.width
        return res;
    }

    xScale = () => {
        const { chartLayout } = this.props;
        if (chartLayout === 'stacked') return 'xStackedFrequencyScale';
        return 'xFrequencyScale';
    }

    yScale = () => {
        if (!this.props) return 'yFrequencyScale';
        const { plotType, chartLayout } = this.props;
        // const { plotType, chartLayout } = this.props;
        if (plotType === 'frequencies' && chartLayout === 'nonstacked') return 'yFrequencyScale';
        if (plotType === 'frequencies' && chartLayout === 'stacked') return 'yStackedFrequencyScale';
        if (plotType === 'multiplicities' && chartLayout === 'nonstacked') return 'yMultiplicityScale';
        if (plotType === 'multiplicities' && chartLayout === 'stacked') return 'yStackedMultiplicityScale';
        return 'yFrequencyScale';
        // return ((this.props && this.props.plotType !== 'frequencies') ? 'yMultiplicityScale' : 'yFrequencyScale')
    };

    // curve = () => ((this.props && this.props.plotType === 'frequencies') ? curveMonotoneX : curveLinear);

    yVal = (d) => {

        if (!isNumeric(d) && this.props) {
            return d[(this.props.plotType !== 'frequencies') ? 'mx' : 'x'];
        }
        if (isNumeric(d)) return d;
        return null;
    }

    x = (d) => {
        const { chartLayout } = this.props;
        if (chartLayout === "stacked") {
            const time = d.data ? d.data.time : d.time;
            // if (time === 44113) console.log(`${d} => ${time} => ${this._getXValue(time)}`)
            return this._getXValue(time);
        }
        return this._getXValue(d.time);
    };

    y = d => this._getYValue(this.yVal(d));

    // margin = { top: 0, bottom: 50, left: 0, right: 1 }

    // padding = { top: 0, bottom: 0, left: 32, right: 0 };




    // Area generator
    freqArea = area()
        .x(this.x)
        .y0(d => this.y(Math.max(d.x - (d.stdX || 0), 0)))
        .y1(d => this.y(Math.min(d.x + (d.stdX || 0), 1)))
        .curve(curveMonotoneX);

    freqPlot = line()
        .x(this.x)
        .y(d => this.y(d))
        .curve(curveMonotoneX);

    stackedFreqArea = area()
        .x(this.x)
        .y0(d => this.y(d[0]))
        .y1(d => this.y(d[1]))
        .curve(curveMonotoneX);

    greyZoneArea = area()
        .x(this.x)
        .y0(d => this.y(d.x0))
        .y1(d => this.y(d.x1))
        .curve(curveStep);

    // Graph specific elements
    prepareGraphElements = () => {
        const svg = select(this.mountNode).select('g.graph');
        const axesSvg = svg.append('g').attr('id', 'axes');

        axesSvg.append('rect')
            .attr('id', 'chartAxis_background')
            .attr('pointer-events', 'all')
            .attr('rx', 7)
            .style('fill', '#FFFFFF');

        // const xAxis =
        axesSvg.append('g')
            .attr('id', `${this.componentName}TimeAxis`)
            .attr('transform', `translate(${this.getPadding().left}, 0)`);

        // const yAxis =
        axesSvg.append('g')
            .attr('id', `${this.componentName}YAxis`)
            .attr('transform', `translate(${this.getPadding().left}, 0)`);


        const plot = svg.append('g')
            .attr('id', `${this.componentName}Plot`)
            //.attr('rx', 7)
            .style('fill', '#FFFFFF')
            .attr('transform', `translate(${this.getPadding().left}, 0)`);

        plot.append('g')
            .attr('id', 'greyZone')

        plot.append('g')
            .attr('id', 'labelsLayer')

        plot.append('g')
            .attr('id', 'freqLayer')

        plot.append('g')
            .attr('id', 'predLayer')

        plot.append('g')
            .attr('id', 'tooltipNode')
            .append('circle')
            .attr('r', 5)
            .style('opacity', 0);

        const dataPointsLayer = svg.append('g')
            .attr('id', 'dataPointsLayer')
            .attr('transform', `translate(${this.getPadding().left}, 0)`);;

        this.grad = dataPointsLayer.append("defs")
            .append("linearGradient")
            .attr("id", "grad")
            .attr("x1", "100%")
            .attr("x2", "0%")
            .attr("y1", "0%")
            .attr("y2", "0%");
        this.grad.append("stop").attr("offset", "50%").attr("stop-opacity", "0");
        this.grad.append("stop").attr("offset", "50%").style("stop-color", "white");
    }

    translateGraphElements = () => {
        const { predictionBaseline, predictionsPlotData } = this.props;
        const svg = select(this.mountNode).select(`#${this.componentName}Plot`);
        const stdUpdate = svg.selectAll('path.freqStdLayers');
        stdUpdate.attr('d', d => this.freqArea(d.values));

        const freqUpdate = svg.selectAll('path.freqLayers');
        freqUpdate.attr('d', d => this.freqPlot(d.values));

        for (const predictData of predictionsPlotData) {
            // const predData = (predictData.data.plotPredictions || [])
            //     .map(([key, values]) => ({ key, values}))
            //     .filter(bin => visibleBins[bin.key] && selectedBins.includes(bin.key.toString()) && plotType === 'frequencies');
            // labelsInputData = [...labelsInputData, ...predData];
            const layerId = `${predictData.modelRegionId}_${predictData.modelType}_${predictData.modelId}`;

            //this.drawFrequencies(areaChart, predData, 'pred',);

            const predUpdate = svg.selectAll(`path.predLayers_${layerId}`);
            predUpdate.attr('d', d => this.freqPlot(d.values));
        }



        const stackedFreqUpdate = svg.selectAll('path.freqStackedLayers');
        stackedFreqUpdate.attr('d', d => this.stackedFreqArea(d));

        const stackedPredUpdate = svg.selectAll('path.predStackedLayers');
        stackedPredUpdate.attr('d', this.stackedFreqArea);

        const greyZoneUpdate = svg.selectAll('path.greyZoneLayers');
        greyZoneUpdate.attr('d', d => this.greyZoneArea(d.values));

        const dataPointsUpdate = select(this.mountNode).select('#dataPointsLayer').selectAll('g.dataPoints');
        dataPointsUpdate.attr('transform', d => `translate(${this._getXValue(d.time)}, ${this._getYValue(d.y)})`)

        svg.select(`g.predictionBaseline`).select('path').attr('d', `M${this.x({ time: dateToDays(predictionBaseline) })},${this.padding.top},V${this.height + this.padding.top}`);

        const width = this.getWidth();

        drawChartAxes(select(this.mountNode), this.componentName,
            this.xScale(), this.yScale(),
            width, this.height,
            this.props.size, this.props.size,
            this.getMargin(), this.getPadding(),
            this.props.multiexport);
    }

    removePlots = () => {
        const svg = select(this.mountNode).select(`#${this.componentName}Plot`);
        svg.selectAll('path.freqLayers').remove();
        svg.selectAll('#predLayer g').remove();
        svg.selectAll('path.freqStackedLayers').remove();
        svg.selectAll('path.predStackedLayers').remove();
        svg.selectAll('path.greyZoneLayers').remove();
        svg.selectAll('path.freqStdLayers').remove();
        svg.selectAll('path.predStdLayers').remove();
        svg.select('g.predictionBaseline').remove();
        select(this.mountNode).select('#dataPointsLayer').selectAll('g.dataPoints').remove();
    }

    reinitXYScales = () => {
        const width = this.getWidth();
        reinitFrequencyChartScales(width, this.height, this.getPadding());
        reinitStackedFrequencyChartScales(this.width, this.height, this.getPadding());
    }

    setComponentState = (component, state) => {
        component.setState(state);
    }

    mousePos = { x: null, y: null }

    invertX = posX => getInvertedValue(this.xScale(), posX)

    mouseMoveHandler = (event, d, prefix, elements) => {
        const { colorBins } = this.props;
        //const [posX, posY] = pointer(event, d); // nodes[i]);
        this.mousePos = { x: event.clientX, y: event.clientY };
        const posX = event.offsetX - this.getPadding().left;
        const time = dateToDays(this.invertX(posX));

        // console.log(event.screenX, event.clientX, event.x, event.layerX, this.invertX(posX))
        const val = d.values.reduce(
            (tmpClosest, v) => {
                if (tmpClosest === null
                    || Math.abs(tmpClosest.time - time) >= Math.abs(v.time - time)) tmpClosest = v;
                return tmpClosest;
            }, null);
        // const freqCat = d.key;
        const freqCat = colorBins[d.key]?.label || colorBins[d.key]?.value;
        const info = {
            time: daysToDate(val.time).toLocaleDateString(),
            freqCategory: freqCat,
            value: numFormat(val.x),
            valueY: numFormat(val.y),
            mx: numFormat(val.mx)
        };

        if (prefix === 'pred') {
            info.regionId = elements[0];
            info.modelType = elements[1];
            info.modelId = elements[2];
        }

        this.changePosition(event.clientX, event.clientY, info);

        this.selectChart()
            .select('#tooltipNode')
            .attr('transform', `translate(${this.x(val)}, ${this.y(val)})`)
            .select('circle')
            .style('fill', () => this.trajectoryColor(d))
            .style('opacity', 1);
        //console.log('mouseMoveHandler 2', event)
    }

    mouseHandlerStacked = (event, d) => {
        const { colorBins } = this.props;
        const posX = event.offsetX - this.getPadding().left;
        const time = dateToDays(this.invertX(posX));

        const timeArray = d.map(el => el.data.time);
        const val = timeArray.reduce(
            (tmpClosest, v) => {
                if (tmpClosest === null || Math.abs(tmpClosest - time) >= Math.abs(v - time))
                    tmpClosest = v;
                return tmpClosest;
            }, null);
        const freqCat = colorBins[d.key]?.label || colorBins[d.key]?.value;
        const elem = d.find(el => el.data.time === val);
        const freq = elem[1] - elem[0];
        const info = {
            time: daysToDate(val).toLocaleDateString(),
            freqCategory: freqCat,
            valueX: freq
        };

        this.changePosition(event.clientX, event.clientY, info);

        this.selectChart()
            .select('#tooltipNode')
            .attr('transform', `translate(${this._getXValue(val)}, ${event.offsetY})`)
            .select('circle')
            .style('fill', () => this.trajectoryColor(d))
            .style('opacity', 1);
    }

    mouseOutHandlerStacked = (event) => {
        const { clientX, clientY } = event; // mouse(nodes[i]);
        const dist = Math.max(Math.abs(this.mousePos.x - clientX), Math.abs(this.mousePos.y - clientY));
        // console.log(mouse(nodes[i]));
        // console.log(`[${clientX}, ${clientY}], ${JSON.stringify(this.mousePos)} => ${dist}`);
        if (dist > 0) {
            setTimeout(() => {
                this.changePosition(null, null);
                this.selectChart().select('#tooltipNode circle').style('opacity', 0);
            }, 1000);
        }
    }

    mouseOutHandler = (event) => {
        const { clientX, clientY } = event; // mouse(nodes[i]);
        const dist = Math.max(Math.abs(this.mousePos.x - clientX), Math.abs(this.mousePos.y - clientY));
        // console.log(mouse(nodes[i]));
        // console.log(`[${clientX}, ${clientY}], ${JSON.stringify(this.mousePos)} => ${dist}`);
        if (dist > 0) {
            setTimeout(() => {
                this.changePosition(null, null);
                this.selectChart().select('#tooltipNode circle').style('opacity', 0);
            }, 1000);
        }
    }


    trajectoryColor = d => {
        const { colorBins } = this.props;
        if (d === undefined) return missingDataColor;
        return colorBins && colorBins[d.key] ? colorBins[d.key].color : missingDataColor;
    };

    findMaxY = (inputData) => {
        let maxY = 0;

        for (const elem of inputData) {
            const indexOfLast = elem.values.length - 1;
            let y = this.y(elem.values[indexOfLast]);

            if (y > maxY)
                maxY = y;
        }

        return maxY;
    };

    getLabelData = (inputData, maxY) => {
        const { clades } = this.props;
        const resultData = [];

        for (const elem of inputData) {
            const indexOfLast = elem.values.length - 1;
            let y = this.y(elem.values[indexOfLast]);

            if (y < maxY - 20) {
                const item = {
                    label: clades[elem.key]?.label || '',
                    y: y,
                    key: elem.key
                }
                resultData.push(item);
            }
        };

        resultData.sort((a, b) => {
            const aY = a.y;
            const bY = b.y;
            if (aY < bY)
                return -1
            else if (aY > bY)
                return 1
            else
                return 0;
        });

        // const dataArr = resultData.map( item => [item.label, item]); 
        // const maparr = new Map(dataArr); 
        // const result = [...maparr.values()];

        return resultData;
    }

    getLabelsInputData = (inputData) => {
        const maxY = this.findMaxY(inputData);
        const labelsDataSorted = this.getLabelData(inputData, maxY);
        let labelsDataFixed = [];

        for (const [index, elem] of labelsDataSorted.entries()) {

            const diff = elem.y - labelsDataFixed?.[index - 1]?.y < 11;
            const item = {
                ...elem,
                y: diff ? labelsDataFixed?.[index - 1]?.y + 10 : elem.y
            }
            labelsDataFixed.push(item)
        }

        return labelsDataFixed;
    }

    drawLabels = (areaChart, labelsInputData) => {
        const labelsData = this.getLabelsInputData(labelsInputData);
        const parentLabels = areaChart.select(`#labelsLayer`);
        const classAttr = `predLayers`;
        const labelData = parentLabels
            .selectAll(`text.${classAttr}`)
            .data(labelsData, d => d.key);

        const labelDataEnter = labelData.enter();
        const width = this.getWidth();

        labelDataEnter.append('text')
            .attr('id', (d) => `m1_${d.key}`)
            .style('text-anchor', 'start')
            .style('alignment-baseline', 'start')
            .style('font-family', "'Inter'")
            .style('font-size', `12px`)
            .text((d) => d.label)
            .attr('transform', (d) => `translate(${width + 5},${d.y})`)
            .attr('class', 'strainName')
            .attr('fill', 'black')

        const labeltDataUpdate = labelDataEnter.merge(labelData);
        labelData.exit().remove();
    }

    drawFrequencies = (areaChart, inputData, prefix, layerId) => {
        const parent = areaChart.select(`#${prefix}Layer`);
        const elements = layerId ? layerId.split('_') : [];
        const classAttr = layerId ? `${prefix}Layers_${layerId}` : `${prefix}Layers`;
        const plotData = (layerId ? parent.select(`#${layerId}`) : parent)
            .selectAll(`path.${classAttr}`)
            .data(inputData, d => d.key);

        const idAttr = d => layerId ? `${prefix}_${d.key}_${layerId}` : `${prefix}_${d.key}`
        const plotDataEnter = plotData.enter()
            .append('path')
            .classed(`${classAttr}`, true)
            .attr('id', d => idAttr(d))
            .style('fill', 'none')
            .style('stroke', this.trajectoryColor)
            .style('stroke-width', 3)
            .on('mousemove', (e, d) => this.mouseMoveHandler(e, d, prefix, elements))
            .on('mouseout', this.mouseOutHandler);

        if (prefix === 'pred') {
            plotDataEnter
                .attr('stroke-dasharray', ('3, 3'));
        }

        const plotDataUpdate = plotDataEnter.merge(plotData);
        plotDataUpdate.attr('d', d => this.freqPlot(d.values));
        plotData.exit().remove();
    };

    drawStackedFrequencies = (areaChart, inputData, prefix) => {
        const stackedData = areaChart
            .selectAll(`path.${prefix}StackedLayers`)
            .data(inputData, d => d.key);
        const invertX = posX => getInvertedValue(this.xScale(), posX);
        // const invertY = posY => getInvertedValue(this.yScale, posY);
        const stackedDataEnter = stackedData.enter()
            .append('path')
            .attr('class', `${prefix}StackedLayers`)
            .attr('id', d => `${prefix}Stacked_${d.key}`)
            .style('fill', this.trajectoryColor)
            //.style('stroke', '1px black')
            .style('opacity', (prefix === 'pred') ? 0.7 : 0.9)
            .on('mousemove', this.mouseHandlerStacked)
            .on('mouseout', this.mouseOutHandlerStacked);

        const stackedDataUpdate = stackedDataEnter.merge(stackedData);

        stackedDataUpdate.attr('d', this.stackedFreqArea);
        //   console.log(`removing: ${stackedData.exit().size()}, added: ${stackedDataEnter.size()}, all: ${stackedDataUpdate.size()}`);
        stackedData.exit().remove();
    }

    drawErrorBars = (areaChart, inputData, displayErrorBars, prefix) => {
        const _inputData = inputData.filter((d) => displayErrorBars);
        // console.log(_inputData);
        const { exportMode } = this.props;
        const stdData = areaChart
            .selectAll(`path.${prefix}StdLayers`)
            .data(_inputData, d => d.key);

        stdData.exit().remove();
        const stdDataEnter = stdData.enter()
            .append('path')
            .attr('class', `${prefix}StdLayers`)
            .style('fill', this.trajectoryColor)
            .style('opacity', 0.3)
            .on('mousemove', this.mouseMoveHandler)
            .on('mouseout', this.mouseOutHandler);

        const stdDataUpdate = stdDataEnter.merge(stdData);

        stdDataUpdate.attr('d', d => this.freqArea(d.values));
    }

    drawGreyZone = (areaChart, inputData, displayGreyZone, predictionBaseline) => {
        const predictionBaselineAsDays = dateToDays(predictionBaseline);
        const _inputData = (inputData || [])
            .filter(d => displayGreyZone)
            .map(d => ({ ...d, values: d.values.filter(val => val.time <= predictionBaselineAsDays) }));

        const greyZoneData = areaChart.select('#greyZone')
            .selectAll('path.greyZoneLayers')
            .data(_inputData, d => d.key);
        greyZoneData.exit().remove();

        const greyZoneDataEnter = greyZoneData.enter()
            .append('path')
            .attr('class', 'greyZoneLayers')
            .style('fill', d => d.color)
            .style('opacity', 0.7);

        const greyZoneDataUpdate = greyZoneDataEnter.merge(greyZoneData);
        greyZoneDataUpdate
            // .transition()
            // .duration(exportMode ? 0 : 2000)
            .attr('d', d => this.greyZoneArea(d.values)); //.filter(val => val.time <= predictionBaselineAsDays )));
        //   console.log(`removing: ${stackedData.exit().size()}, added: ${stackedDataEnter.size()}, all: ${stackedDataUpdate.size()}`);

    }

    drawPredictionBaseline = (areaChart, predictionBaseline) => {
        if (!predictionBaseline) return;

        const pBaseline = areaChart.selectAll('g.predictionBaseline path').data([0]);

        const pBaselineEnter = pBaseline.enter().append('g')
            .attr('class', 'predictionBaseline')
            .append('path')
            .style("stroke-dasharray", ("4, 2"))
            .style('stroke', 'black')
            .style('stroke-width', 1.5);
        const pBaselineUpdate = pBaselineEnter.merge(pBaseline);
        pBaselineUpdate
            .attr('d', `M${this.x({ time: dateToDays(predictionBaseline) })},${this.getPadding().top},V${this.height + this.getPadding().top}`)
    };


    drawDataPoints = (dataPointsLayer, dataPoints) => {
        const radius = this.props.exportMode ? 2 : 4;
        const dataPointsCorrected = (dataPoints || []).map((d, i) => {
            // console.log(i);
            // console.log(d);
            const y1 = this._getYValue(d.y1);
            const y2 = this._getYValue(d.y2);
            const _y = this._getYValue(d.y);
            const yPos = Math.min(Math.max(y2 + radius, _y), y1 - radius);
            return { ...d, yPos };
        })
        const dataPointsData = dataPointsLayer
            .selectAll(`g.dataPoints`)
            .data(dataPointsCorrected, d => d.id);
        //console.log(dataPointsCorrected);
        dataPointsData.enter().append('g')
            .attr('class', 'dataPoints')
            .attr('transform', d => `translate(${this._getXValue(d.time)}, ${d.yPos})`)
            .append('circle')
            .attr('r', radius)
            .style('stroke', 'white')
            .style('stroke-width', '1')
            .style('fill', d => (d.value === 0) ? 'none' : ((d.value === 1) ? "url(#grad)" : 'white'))
    }
    clearChartData = () => {
        select(`#${this.componentName}`).select(`#${this.componentName}Plot`)
            .selectAll('path')
            .remove();
    }

    selectChart = () => select(`#${this.componentName}`).select(`#${this.componentName}Plot`);

    renderStackedD3Component = () => {
        const { stackedPlot, plotType, stackedPredictions, startTime, endTime, trackingTo, maxStackedMultiplicity, dataPoints } = this.props;
        const _endTime = plotType === 'frequencies' && this.props.showPrediction ? trackingTo : endTime;


        initStackedFrequencyChartScales(this.width, this.height, startTime, _endTime, maxStackedMultiplicity, this.getPadding());
        const freqData = stackedPlot[plotType].map(bin => {
            const c = bin.filter(d => d.data.time >= dateToDays(this.getXScale().domain()[0]));
            c.index = bin.index;
            c.key = bin.key;
            return c;
        });

        const svg = select(this.mountNode).select('g.graph');
 
        drawChartAxes(svg, this.componentName,
            this.xScale(), this.yScale(),
            this.width, this.height,
            this.props.size, this.props.size,
            this.getMargin(), this.getPadding(), this.props.multiexport);

        //  // Area generator

        const areaChart = select(`#${this.componentName}`).select(`#${this.componentName}Plot`);

        this.drawStackedFrequencies(areaChart, freqData, 'freq');
        if (this.props.showPrediction) {
            const predData = (stackedPredictions || []).map(bin => {
                const c = bin.filter(d => d.data.time >= dateToDays(this.getXScale().domain()[0]) && plotType === 'frequencies');
                c.index = bin.index;
                c.key = bin.key;
                return c;
            });


            this.drawStackedFrequencies(areaChart, predData, 'pred');
        }
        this.drawDataPoints(select('#dataPointsLayer'), dataPoints);
    }


    renderNonStackedD3Component = () => {
        const { predictionsPlotData, minLogSpace, nonStackedPlot, startTime, endTime, trackingTo, predictionBaseline, showPrediction, visibleBins, exportMode, selectedBins, displayErrorBars, displayGreyZone, maxMultiplicity, plotType, seqCaseCounts, logSpace } = this.props;
        const _endTime = plotType === 'frequencies' && this.props.showPrediction ? trackingTo : endTime;
        const width = this.getWidth();
        const padding = this.getPadding();

        // console.log(`[renderNonStackedD3Component] showPrediction = ${showPrediction}`);
        initFrequencyChartScales(width, this.height, startTime, _endTime, maxMultiplicity, padding, this.props.multiexport, logSpace, minLogSpace);
        //console.log('[renderNonStackedD3Component]: ', subsetId);
        // const freqData = (nonStackedPlot||[]).map(bin => ({
        //     key: bin.key,
        //     values: bin.values.filter(d => d.time >= dateToDays(this.getXScale().domain()[0]))
        // })).filter(bin => visibleBins[bin.key] && selectedBins.includes(bin.key.toString()));
        // console.log({type: 'freq', subsetId: this.props.subsetId, width, height: this.height});
        const freqData = (nonStackedPlot || []).map(([key, values]) => ({
            key: key,
            values: values.filter(d => d.time >= dateToDays(this.getXScale().domain()[0]))
        })).filter(bin => visibleBins[bin.key] && selectedBins.includes(bin.key.toString()));
        const svg = select(this.mountNode).select('g.graph');

        // console.log('[renderNonStackedD3Component]', this.props.subsetId, width)
        drawChartAxes(svg, this.componentName,
            this.xScale(), this.yScale(),
            width, this.height,
            this.props.size, this.props.size,
            this.getMargin(), this.getPadding(), this.props.multiexport);

        const areaChart = select(`#${this.componentName}`).select(`#${this.componentName}Plot`);

        this.drawGreyZone(areaChart, seqCaseCounts, displayGreyZone, predictionBaseline);
        this.drawPredictionBaseline(areaChart, showPrediction ? predictionBaseline : null);
        this.drawFrequencies(areaChart, freqData, 'freq');
        this.drawErrorBars(areaChart, freqData, displayErrorBars, 'freq');
        // svg.select('#predLayer').selectAll('path').remove();

        if (this.props.showPrediction) {
            const predDataNode = svg.select('#predLayer').selectAll('g').data(predictionsPlotData, d => `${d.modelRegionId}_${d.modelType.replace(/ /g, "")}_${d.modelId}`);
            predDataNode.enter().append('g').attr('id', d => `${d.modelRegionId}_${d.modelType.replace(/ /g, "")}_${d.modelId}`);//.style('pointer-events', 'none');
            predDataNode.exit().remove();

            let labelsInputData = [];
            for (const predictData of predictionsPlotData) {
                //console.log('predictData',  predictData.modelRegionId, predictData.modelId, predictData.data.plotPredictions.filter(arr => arr[0] == 20192).map(arr => arr[1][0]))
                const predData = (predictData.data.plotPredictions || [])
                    .map(([key, values]) => ({ key, values }))
                    .filter(bin => visibleBins[bin.key] && selectedBins.includes(bin.key.toString()) && plotType === 'frequencies');
                labelsInputData = [...labelsInputData, ...predData];
                this.drawFrequencies(areaChart, predData, 'pred', `${predictData.modelRegionId}_${predictData.modelType.replace(/ /g, "")}_${predictData.modelId}`);
            }

            if (exportMode) {
                const labeledDataNode = svg.select('#labelsLayer').selectAll('text').remove()
                this.drawLabels(areaChart, labelsInputData);
            }
            //this.drawErrorBars(areaChart, predData, displayErrorBars, 'pred');
        }
    }

    _renderD3Component = async (viewName, componentId, loading) => {
        const { plotType, chartLayout, setRenderStatus, setComponentStatus } = this.props;
        //console.log(`[FrequencyChartD3.renderD3Component]: plotType = ${plotType}, chartLayout = ${chartLayout}`);
        setComponentStatus(viewName, componentId, RENDER_STATUS.START);
        // console.log('[renderD3Component]', this.props.subsetId, this.props.viewName, this.width, this.getWidth());
        // console.log('FREQ', viewName, this.props.subsetId, this.props.startTime, this.props.endTime);
        // console.log(`[renderD3Component]: FrequencyChart: ${this.props.modelId}`)
        if (chartLayout === 'nonstacked') {
            this.renderNonStackedD3Component();
        }
        else if (chartLayout === 'stacked') this.renderStackedD3Component();
        // setRenderStatus(RENDER_STATUS.DONE);
        !loading && setComponentStatus(viewName, componentId, RENDER_STATUS.DONE);
    };
    get renderD3Component() {
        return this._renderD3Component;
    }
    set renderD3Component(value) {
        this._renderD3Component = value;
    }
}

export default FrequencyChartD3;
