
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    // Define a class that applies to the root of your component
    root: {
        // Apply the reset styles within the root
        '& *': {
            boxSizing: 'border-box',
            padding: 0,
            margin: 0,
        },
        '& strong': {
            fontFamily: 'Inter Bold',
            fontWeight: '400'
        }
    },
    mainSubpageHeader: {
        color: '#000',
        fontFamily: 'Inter Bold',
        fontSize: '42px',
        fontStyle: 'normal',
        lineHeight: '115%',
        letterSpacing: '-1.26px',
        padding: '80px 30px'
    },
    subpageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '30px',
        textAlign: 'left',
        flexDirection: 'column'
    },
    subpageSection: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    subpageTextContainer: {
        width: '45%',
        maxWidth: '730px',
        paddingBottom: '30px'
    },
    subpageDivider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#bababa'
    },
    noticeSubpageHeader: {
        marginBottom: '40px',
        marginTop: '5px',
        color: '#1d1d1d',
        fontFamily: 'Inter Bold',
        fontSize: '22px',
        fontStyle: 'normal',
        letterSpacing: '-.44px',
        lineHeight: '160%'
    },
    subpageText: {
        color: '#1d1d1d',
       // fontSize: '18px',
        fontSize: '16px', // default font size
        // Media query for screens larger than 600px
        [theme.breakpoints.up('sm')]: {
          fontSize: '18px', // larger font size for larger screens
        },
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '160%',
        letterSpacing: '-.36px',
        marginBottom: '40px',
        '& ul': {
            marginLeft: '25px'
        }
       
    },
    subpageTextRaw: {
        color: '#1d1d1d',
        // fontSize: '18px',
        fontSize: '16px', // default font size
        // Media query for screens larger than 600px
        [theme.breakpoints.up('sm')]: {
          fontSize: '18px', // larger font size for larger screens
        },
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '160%',
        letterSpacing: '-.36px',
        '& ul': {
            marginLeft: '25px'
        }

    },

}));

const LegalNotice = () => {

    const classes = useStyles();
    return <div className={classes.root}>
        <div className={classes.mainSubpageHeader}>Legal notice and privacy protection statement</div>
        <div>
            <div className={classes.subpageContainer}>
                <div id="center-for-predictive-analysis-of-viral-evolution-previr" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}></div>
                        <div className={classes.noticeSubpageHeader}>Center for Predictive Analysis of Viral Evolution (Previr)</div>
                        <div className={classes.subpageText}>
                            <p>
                                <strong>Coordinated by:</strong><br />
                                Prof. Dr. Michael Lässig<br />
                                Insitute for Biological Physics<br />
                                University of Cologne<br />
                                Zülpicher Str. 77a<br />
                                50937 Köln<br />mlaessig@uni-koeln.de<br />
                                +49 221 470 4309
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                <strong>Responsible for content:</strong><br />
                                Denis Ruchnewitz<br />
                                Insitute for Biological Physics<br />
                                University of Cologne<br />
                                Zülpicher Str. 77a<br />
                                50937 Köln<br />
                                ruchnewitz@uni-koeln.de<br />
                                +49 221 470 5203
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                The Previr websites previr.com and previr.app are part of the University of Cologne's websites.
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                This Legal Notice complies with the German laws under § 5 TMG and § 55 RStV.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="liability-for-content" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}>
                        </div>
                        <div className={classes.noticeSubpageHeader}>Liability for content</div>
                        <div className={classes.subpageText}>
                            <p>
                                The contents of our website have been created with the greatest possible care.
                                However, we cannot guarantee accuracy, completeness, or topicality of its content.
                                In particular, this website contains scientific analysis and inference, which is based on data provided by others and is intended for scientists.
                                We do not assume legal responsibility for the accuracy, completeness, or topicality of these data and of our inference.
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                According to Section 7, paragraph 1 of the TMG (Telemediengesetz - German Telemedia Act), we as service providers are liable
                                for our content on these pages by general laws. However, according to Sections 8 to 10 of the TMG,
                                service providers are not obliged to monitor external information transmitted or stored or investigate circumstances pointing to illegal activity.
                                Obligations to remove or block the use of information under general laws remain unaffected. However, a liability in this regard is only possible
                                from the moment of knowledge of a specific infringement. Upon notification of such violations, we will remove the content immediately.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="liability-for-links" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}></div>
                        <div className={classes.noticeSubpageHeader}>
                            Liability for links
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                Our website contains links to external websites, over whose contents we have no control. Therefore, we cannot accept any liability for these external contents.
                                The respective provider or operator of the websites is always responsible for the contents of the linked pages. The linked pages were checked for possible
                                legal violations at the time of linking. Illegal contents were not identified at the time of linking. However, permanent monitoring of the contents of the linked
                                pages is not reasonable without specific indications of a violation. Upon notification of violations, we will remove such links immediately.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="copyright" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}></div>
                        <div className={classes.noticeSubpageHeader}>Copyright</div>
                        <div className={classes.subpageText}>
                            <p>
                                The contents and works on these pages created by the site operator are subject to German copyright law.
                                The duplication, processing, distribution, and any kind of utilization outside the limits of copyright require
                                the written consent of the respective author or creator. Downloads and copies of these pages are only permitted
                                for private, non-commercial use. In so far as the contents on this site were not created by the operator,
                                the copyrights of third parties are respected. In particular, third-party content is marked as such.
                                Should you become aware of a copyright infringement, please inform us accordingly.
                                Upon notification of violations, we will remove such contents immediately.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="privacy-protection-statement" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}></div>
                        <div className={classes.noticeSubpageHeader}>Privacy protection statement</div>
                        <div className={classes.subpageTextRaw}>
                            <p>
                                <strong>Web protocols of the central web server</strong><br />
                                Previr as part of the University of Cologne collects and automatically stores information your browser sends in
                                the protocol files of the web server. This includes:
                            </p>
                        </div>
                        <div className={classes.subpageTextRaw}>
                            <ul><li>browser type and version</li></ul>
                        </div>
                        <div className={classes.subpageTextRaw}>
                            <ul><li>computer name or IP address of the accessing computer</li></ul>
                        </div>
                        <div className={classes.subpageTextRaw}>
                            <ul><li>time of the request to the server</li></ul>
                        </div>
                        <div className={classes.subpageText}>
                            <ul><li>requested website</li></ul>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                The temporary storage of the IP address by the system is necessary to enable the website to be delivered to your computer.
                                To do this, the IP address of your computer must remain stored for the duration of the session.
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>In addition, the data helps us to ensure the security of our information technology systems and to optimize our website.
                                An evaluation of the data for marketing purposes does not take place in this context.
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                Of the above data, the IP address is considered to be personal. Your IP address will be stored for a maximum of 7 days
                                and then completely anonymized (deleted or alienated). After that time, the above data is no longer personal. It shall be deleted as soon as it
                                is no longer necessary to achieve the purpose for which it was collected.
                            </p>
                        </div>
                        <div className={classes.subpageText}>
                            <p>
                                The legal basis for the temporary storage of data and log files is Chapter 6 Art. 1 (f) of the GDPR, because Previr
                                and the University of Cologne has a legitimate interest in identifying and eliminating malfunctions and in optimizing its website.
                            </p>
                        </div>
                    </div>
                </div>
                <div id="cookies" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}></div>
                        <div className={classes.noticeSubpageHeader}>Cookies</div>
                        <div className={classes.subpageText}>
                            <p>
                                <strong>Cookies in general</strong>
                                <br />
                                We use so-called cookies in various places on the website. Cookies are small text files that your browser stores
                                on your computer and sends to our server when you visit our pages. Cookies do not cause any damage to your
                                computer and do not contain any viruses. They make our website more user-friendly, effective, and secure.
                                The cookies we use are so-called session cookies, which are automatically deleted at the end of your visit.
                                You have full control over the use of cookies: By changing the settings in your Internet browser, you can deactivate
                                or restrict the use of cookies. Cookies that have already been saved can be deleted at any time.
                                This can also be done automatically. If cookies are deactivated for our website, it may no longer be possible
                                to use all functions in full.
                                <br /><br />
                                <strong>Legal basis</strong><br />
                                The legal basis for processing the personal data of users in connection with the use of cookies
                                is Chapter 6 Art. 1 (f) of the GDPR, because the University has a legitimate interest in understanding
                                how and how often our websites are accessed as well as in placing advertisement on our pages.<br />
                            </p>
                        </div>
                    </div>
                </div>
                <div id="your-rights" className={classes.subpageSection}>
                    <div className={classes.subpageTextContainer}>
                        <div className={classes.subpageDivider}></div>
                        <div className={classes.noticeSubpageHeader}>Your rights</div>
                        <div className={classes.subpageText}>
                            <p>Every person affected by the processing of personal data has the right, in accordance with the legal
                                provisions, to request free information or confirmation from us regarding the personal data stored about him or her.
                                <br /><br />
                                In addition, you have the right to the immediate correction or deletion of incorrect personal data concerning you,
                                or to the restriction of the processing, or to object to the processing. (However, the collection of data for the provision
                                of the website and the storage of data in log files is necessary for the operation of the website. Consequently,
                                there is no possibility of objection in this respect.)
                                <br /><br />
                                You can revoke your consent to the processing of personal data at any time. This does not affect the legality of the
                                processing of your data on the basis of your consent until your revocation. Data subjects have the right to request
                                information regarding their personal data in a structured, common and machine-readable format and to transmit it
                                to other persons responsible.
                                <br /><br />
                                We are happy to answer your questions regarding the processing of your personal data at any time. If you would
                                like to know which personal data we store about you or your pseudonym, or if you have questions that this data
                                protection statement could not answer, or if you would like more detailed information on a particular point,
                                please contact us, preferably using our contact address: contact(at)previr.com. If you communicate by email,
                                we cannot guarantee the complete confidentiality of the provided information. If your information is confidential,
                                please send it to us by post.
                                <br /><br />
                                <strong>Last updated:</strong> 25. October 2023
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};


export default LegalNotice;
