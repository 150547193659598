import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types';
import { FormControl, MenuItem, Grid } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import { resetFrequencies } from '../../redux/actions/frequenciesActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { getFrequencyCategories } from '../../redux/selectors/metadataSelector';
import { resetGenotypeStatus } from '../../redux/actions/genotypeActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';
import GenotypeSelector from './GenotypeSelector';
import HumanSerologySelector from './HumanSerologySelector';
import CladeTypeSelector from './CladeTypeSelector';

const styles = theme => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        margin: '8px 0px'
    },
});

const FrequenciesCategorySelector = props => {
    const { intro, lineage,
        freqCategory,
        freqCategoriesMap, hlaOptions, geneOptions,
        hla, gene, mutgene, mutposition, mutationsPositionsDictStatus,
        freqCategoriesOptions, classes,
        setParameters,
        resetFrequencies,
        resetPredictions,
        fetchMeasureScalesDomains,
        permissions
    } = props;


    const handleFreqCategoryChange = async event => {

        const freqCategory = event.target.value;

        const bins = freqCategoriesMap && freqCategoriesMap[freqCategory] ? freqCategoriesMap[freqCategory].bins : null;
      /*  const _binMethod = freqCategoriesMap[freqCategory].discrete ? 'discrete' : (binMethod || (bins ? bins.binMethod : null) || 'quantile');
        const _binCnt = binCnt || (bins ? bins.number : null);
*/
        setParameters({ freqCategory /*, binMethod: _binMethod, binCnt: _binCnt*/ });

        const _gene = gene || geneOptions[0];
        const _hla = hla || hlaOptions[0];
        const params = { lineage, gene: _gene, hla: _hla };
        if (freqCategoriesMap[freqCategory].custom) {
            // params.customTreeAttrId = freqCategory;
            // setParameters(params);
        } else if (freqCategory === 'tcellAntigenicity') {
            params.colorBy = freqCategory;
            setParameters(params)
        } 
        // else if (freqCategory === 'genotype' && genotypeFilterGenesList.length) {
            // params.mutgene = mutgene || genotypeFilterGenesList[0].val;
            // setParameters(params)

        // }
        // console.log('AAA', {lineage, colorBy: freqCategory, mutgene, mutposition});
        if (freqCategory !== 'genotype' || (mutgene && mutposition)) {
            // console.log('freq selector fetchMeasureScalesDomains', freqCategory);
            fetchMeasureScalesDomains({
                lineage, 
                freqCategory,
                mutgene: (freqCategory === 'genotype') ? mutgene : undefined,
                mutposition: (freqCategory === 'genotype') ? mutposition : undefined,
            });
        }
        resetFrequencies();
        resetPredictions();
    }

    // const handleMutChange = event => {

    //     setParameters({ mutgene: event.target.value, mutposition: '1' });
    //     resetGenotypeStatus();
    //     resetFrequencies();

    // }

    const handleTcellAntigenicityChange = name => async event => {

        // console.log(`${name} => ${event.target.value}`);
        const { value } = event.target;
        const _gene = name === 'gene' ? value : gene;
        const _hla = name === 'hla' ? value : hla;
        await setParameters({ [name]: value });
        resetFrequencies();
        resetPredictions();

    }

    const introOptions = ['loc', 'clade', 'genotype'];
    const freqCategoriesOptionsItems = intro ?
        freqCategoriesOptions.filter(el => introOptions.includes(el.key)) : freqCategoriesOptions;

    return (
        <>
            {freqCategoriesOptions.length > 0 && (
                <FormControl className={classes.formControl} fullWidth>
                    <CustomLabel id={'freqCategory'} label={'Frequencies category'} />
                    <CustomSelect
                        value={freqCategory}
                        onChange={handleFreqCategoryChange}
                        // error={colorByError}
                        inputProps={{
                            name: 'freqCategory',
                            id: 'freqCategory',
                        }}
                    >
                        {freqCategoriesOptionsItems
                            .map((cB) => (
                                <MenuItem key={cB.key} value={cB.key}>{cB.label}</MenuItem>
                            ))}
                    </CustomSelect>
                </FormControl>
            )}
            {(freqCategory === 'tcellAntigenicity') && (
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id={'gene'} label={'Gene'} />
                            <CustomSelect
                                value={gene || ''}
                                onChange={handleTcellAntigenicityChange('gene')}
                                // error={error}
                                inputProps={{ name: `${gene}`, id: `${gene}` }}
                            >
                                {geneOptions.map(id => (
                                    <MenuItem key={id || '_gene'} value={id}>{id}</MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={'hla'} label={'HLA'} />
                            <CustomSelect
                                value={hla || ''}
                                onChange={handleTcellAntigenicityChange('hla')}
                                // error={error}
                                inputProps={{ name: `${gene}`, id: `${gene}` }}
                            >
                                {hlaOptions.map(id => (
                                    <MenuItem key={id || 'hla'} value={id}>{id}</MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            {(freqCategory === 'genotype') /*&& mutationsPositionsDictStatus === 'loaded'*/ && (
                <GenotypeSelector resetData={resetFrequencies} colorByVar='freqCategory'/>
            )}
            {freqCategory === 'humanSerology' && (
                <HumanSerologySelector resetData={resetFrequencies} />
            )}
            {(freqCategory === 'clade' && permissions.cladeTypeSelector) &&
                <CladeTypeSelector resetData={resetFrequencies}/>
            }
        </>
    );

}

FrequenciesCategorySelector.propTypes = {
    freqCategory: PropTypes.string,
    lineage: PropTypes.string,
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    freqCategoriesOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    freqCategoriesMap: PropTypes.objectOf(PropTypes.shape({ show: PropTypes.string, custom: PropTypes.bool, bins: PropTypes.shape({ binMethod: PropTypes.string, number: PropTypes.number }) })),
    customAttrsStatus: PropTypes.objectOf(PropTypes.string),
    // nodeCustomTreeAttrs: PropTypes.shape({}),
    hla: PropTypes.string,
    gene: PropTypes.string,
    geneOptions: PropTypes.arrayOf(PropTypes.string),
    hlaOptions: PropTypes.arrayOf(PropTypes.string),
    binMethod: PropTypes.string,
    binCnt: PropTypes.number,

    fetchPredictions: PropTypes.func,
    setParameters: PropTypes.func,
    fetchTCellAntigenicityScores: PropTypes.func,
};


const mapStateToProps = (state) => {
    const { freqCategoriesOptions, freqCategoriesMap } = getFrequencyCategories(state);
    return ({
        lineage: state.parameters.lineage,
        freqCategory: state.parameters.freqCategory,
        freqCategoriesOptions,
        freqCategoriesMap,
        geneOptions: state.metadata.tcellAntigenicityOptions.geneOptions,
        hlaOptions: state.metadata.tcellAntigenicityOptions.hlaOptions,
        gene: state.parameters.gene,
        hla: state.parameters.hla,
        binMethod: state.parameters.binMethod,
        binCnt: state.parameters.binCnt,
        strainSubset: state.parameters.strainSubset,
        customAttrsStatus: state.customTreeData.status,
        genotypeFilterGenesList: state.genotype.genotypeFilterGenesList,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        mutgene: state.parameters.mutgene,
        mutposition: state.parameters.mutposition,
        permissions: state.user.permissions.frequenciesPermissions
    });
};

const mapDispatchToProps = dispatch => ({
    resetFrequencies: payload => dispatch(resetFrequencies(payload)),
    resetPredictions: payload => dispatch(resetPredictions(payload)),
    setParameters: payload => dispatch(setParameters(payload)),
    resetGenotypeStatus: () => dispatch(resetGenotypeStatus()),
    fetchMeasureScalesDomains: (payload) => dispatch(fetchMeasureScalesDomains(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FrequenciesCategorySelector));
