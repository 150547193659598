import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { FETCH_PREDICTIONS_REQUEST, } from '../actions/actionTypes';
import { prepareUrl } from '../../functions/functions';
import { fetchPredictionsError, fetchPredictionsSuccess } from '../actions/predictionsActions';
import config from '../../config/envConfig';
import { fetchAxios } from '../../functions/axiosRequests';
import { sessionTimeOut } from './helpers/functions';

export const fetchPredictions = (action$, store) => action$.pipe(
    ofType(FETCH_PREDICTIONS_REQUEST),
    mergeMap(action => {

        const { allRegions, } = action.payload;

        const subsetId = allRegions ? null : action.payload.subsetId;
        const url = prepareUrl(`${config.serverLink}/api/frequencies/predictions`, action.payload)
        console.log(`fetchPredictions: url = ${url}`);

        return fetchAxios(url).then(function (response) {
            // console.log(response.data)
            return fetchPredictionsSuccess({...response.data, subsetId});
        }).catch(function (error) {
            const { status, data } = error.response;
            if (status === 408) return sessionTimeOut(error, store);
            return fetchPredictionsError({ error: data.error, errorWebsiteText: 'An error has occured during downloading predictions.', errorWebsiteType: 'fetch'});
        });
    }),
);
