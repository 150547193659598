import {
    FETCH_MODELS_REQUEST,
    FETCH_MODELS_SUCCESS,
    FETCH_MODEL_TYPES_REQUEST,
    FETCH_MODEL_TYPES_SUCCESS,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_TYPES_ERROR,
    // FETCH_ANTIGENIC_MODELS_LIST_REQUEST,
    // FETCH_ANTIGENIC_MODELS_LIST_SUCCESS,
    // FETCH_ANTIGENIC_MODELS_LIST_ERROR,
    FETCH_MODEL_DATA_REQUEST,
    FETCH_MODEL_DATA_SUCCESS,
    FETCH_MODEL_DATA_ERROR,
    FETCH_SELECTED_STRAIN_REQUEST,
    SET_MODEL_TYPE,
    SIGNOUT_REQUEST,
    RESET_SESSION,
    ZOOM_TREE_REQUEST,
    INIT_STRAIN_TREE_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    // FETCH_VISIBLE_NODES_REQUEST,
    RESET_MODEL_DATA,
    SET_MODEL_REGION_ID,
    RESET_MODEL_TYPES
} from '../actions/actionTypes';
import deepFreeze from 'deep-freeze';

import { emptyObject, emptyObjectOrArray } from '../../functions/functions';

let modelsInitialState = { };
export const setModelsInitialState = (state) => {
    modelsInitialState = state;
};

let modelDataInitalState = { };
export const setModelDataInitalState = (state) => {
    modelDataInitalState = state;
};

const modelsData = (state = modelsInitialState, action) => {
    deepFreeze(state);
    if (action.payload && action.payload.settings) return state;
    
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return modelsInitialState;
        }
        case FETCH_MODEL_TYPES_REQUEST: {
            const { colorBy } = action.payload;
            const modelTypesStatus = { ...state.modelTypes };
            modelTypesStatus[colorBy] = 'loading';
            return {
                ...state,
                modelTypesStatus
            };
        }
        case FETCH_MODELS_REQUEST: {
            const { colorBy } = action.payload;

            const modelsStatus = { ...state.modelsStatus };
            modelsStatus[colorBy] = 'loading';
            
            return {
                ...state,
                modelsStatus
            };
        }
        case FETCH_MODELS_SUCCESS: {
            const { models, colorBy } = action.payload;

            const _models = { ...state.models };
            _models[colorBy] = emptyObject(models) ? [] : models;
            const modelsStatus = { ...state.modelsStatus };
            modelsStatus[colorBy] = (!emptyObjectOrArray(models)) ? 'loaded' : 'nodata';

            return {
                ...state,
                models: _models,
                modelsStatus,
            };
        }
        case FETCH_MODEL_TYPES_SUCCESS: {
            const { modelTypes, colorBy } = action.payload;
            const _modelTypes = { ...state.modelTypes };
            _modelTypes[colorBy] = modelTypes;
            const modelTypesStatus = { ...state.modelTypesStatus };
            modelTypesStatus[colorBy] = modelTypes && modelTypes.length > 0  ? 'loaded' : 'nodata';
            return {
                ...state,
                modelTypes: _modelTypes,
                modelTypesStatus
            };
        }

        case FETCH_MODEL_TYPES_ERROR:
        case FETCH_MODELS_ERROR: {
            const { colorBy } = action.payload;
            const modelsVar = colorBy === 'antigenic' ? 'antigenicModels': 'models'
            return {
                ...state,
                [`${modelsVar}Status`]: 'error'
            };
        }
        // case FETCH_ANTIGENIC_MODELS_LIST_REQUEST: {
        //     return {
        //         ...state,
        //         antigenicModelsStatus: 'loading'
        //     };
        // }
        // case FETCH_ANTIGENIC_MODELS_LIST_SUCCESS: {
        //     const { antigenicModels } = action.payload;
        //     return {
        //         ...state,
        //         antigenicModels,
        //         antigenicModelsStatus: 'loaded'
        //     };
        // }
        // case FETCH_ANTIGENIC_MODELS_LIST_ERROR: {
        //     return {
        //         ...state,
        //         antigenicModelsStatus: 'error'
        //     };
        // }

        case SET_MODEL_REGION_ID: {
            const { colorBy } = action.payload;
            const modelTypesStatus = { ...state.modelTypesStatus };
            modelTypesStatus[colorBy] = 'none';

            const modelsStatus = { ...state.modelsStatus };
            modelsStatus[colorBy] = 'none';
            return {
                ...state,
                modelTypesStatus,
                modelsStatus
            }
        }
        case SET_MODEL_TYPE: {
            const { colorBy } = action.payload;
            const modelsStatus = { ...state.modelsStatus };
            modelsStatus[colorBy] = 'none';
            return {
                ...state,
                modelsStatus
            }
        }

        case RESET_MODEL_TYPES: {
            const modelTypesStatus = modelsInitialState.modelTypesStatus;
            const modelsStatus = modelsInitialState.modelsStatus;
            return {
                ...state,
                modelsStatus,
                modelTypesStatus
            }
        }

        default:
            return state || modelsInitialState;
    }
};


// const emptyModel = (model) => (emptyObject(model)
//     || !Object.values(model).some(entry => !emptyObject(entry) && Object.values(entry).some(k => !emptyObject(k))));


const modelData = (state = modelDataInitalState, action) => {
    deepFreeze(state);
    if (action.payload && action.payload.settings) return state;

    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return modelDataInitalState;
        }
        case FETCH_MODEL_DATA_REQUEST: {
            const { colorBy } = action.payload;
            const modelStatus = { ...state.modelStatus };
            modelStatus[colorBy] = 'loading';
            return {
                ...state,
                modelStatus
            };
        }
        case FETCH_MODEL_DATA_SUCCESS: {
            // console.log(`FETCH_MODEL_DATA_SUCCESS: action.payload = ${Object.keys(action.payload)}`)
            const { treeModel, colorBy } = action.payload;


            const model = { ...state.model };
            model[colorBy] = treeModel;
            const modelStatus = { ...state.modelStatus };
            modelStatus[colorBy] = emptyObject(treeModel) ? 'nodata' : 'loaded';
            return {
                ...state,
                model,
                modelStatus
            };
        }
        case FETCH_MODEL_DATA_ERROR: {
            const { colorBy } = action.payload;
            const modelStatus = { ...state.modelStatus };
            modelStatus[colorBy] = 'error';
            // console.log(`FETCH_MODEL_DATA_ERROR: action.payload = ${JSON.stringify(action.payload)}`);
            return {
                ...state,
                modelStatus
            };
        }
        case SET_MODEL_TYPE: {
            const { colorBy } = action.payload;
            const modelStatus = { ...state.modelStatus };
            modelStatus[colorBy] = 'none';
            return {
                ...state,
                modelStatus
            };
        }
        case FETCH_MODELS_REQUEST: {
            return {
                ...state,
                ...modelDataInitalState
            }
        }
        case INIT_STRAIN_TREE_REQUEST:
        case FETCH_SELECTED_STRAIN_REQUEST:
        case ZOOM_TREE_REQUEST:
        case FETCH_VP_VALUES_REQUEST:
        case FETCH_SUBSET_TREE_REQUEST:
        case FETCH_RECALCULATED_TREE_REQUEST:
        // case FETCH_VISIBLE_NODES_REQUEST: 
        case RESET_MODEL_DATA: {
            // console.log(action.type, action.payload);
            const nodata = action.payload?.nodata;
            const modelStatus = Object.keys(state.modelStatus).reduce((acc, modelVar) => ({ ...acc, [modelVar]: nodata ? 'nodata' : 'refetchNeeded'}), {})
            const model = modelDataInitalState.model; // Object.keys(state.model).reduce((acc, modelVar) => ({ ...acc, [modelVar]: {}}), {})
            return {
                ...state,
                model,
                modelStatus
            }
        }
        default:
            return state || modelDataInitalState;
    }
};

export { modelsData, modelData };
