import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@mui/material';
import { initStrainTree } from '../../redux/actions/sessionActions';
import { shouldFetch, isColorByModel, isLoadedOrNA, dateToDays } from '../../functions/functions';
import { fetchModel } from '../../redux/actions/modelActions';
import { getCustomMeasures, getIgnoreStrainCutOffDateForColorBy, } from '../../redux/selectors/metadataSelector';
import { fetchCustomTreeAttrs, fetchMutationClasses, fetchVpValues } from '../../redux/actions/treeDataActions';
import { fetchGenotypeData, fetchMutationGroupValues, fetchMutationsPositionsDictionary } from '../../redux/actions/genotypeActions';
import {  getIsMobile, shouldFetchModelsSelector } from '../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../config/consts';
import TreeGraph from '../../components/Tree/TreeGraph';
import TreeGraphOptions from '../../components/Tree/options/TreeGraphOptions';
// import TreeGraphOptions
import NodeInfo from '../../components/Tree/NodeInfo';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { styles } from './styles';
import { setParameters } from '../../redux/actions/parametersActions';
import { fetchClades } from '../../redux/actions/cladeActions';
import ExportableComponent from '../Export/ExportableComponent';
import MetaInformations from '../../components/MetaInformations/MetaInformations';
import { strainCutOffDateSelector } from '../../redux/selectors/parametersSelector';


const Intro = (props) => {
    const {
        lineage, regionId, modelId, colorBy, antigenicTiterType, antigenicDataType, tcellAntigenicityOptionsStatus, exportMode, zoomNodeId, strainSubset, exportParams,
        gene, hla, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, humanPool, humanSerologyDataType, strainId, modelRegionId, modelType, antigenicModelId, mutationsGroup,
        showCladeBar, showMutationsGroups, models,
        editMode, menuRight, hiddenMenuMobile, isMobile, renderStatus, hiddenMenu,

        shouldFetchModels,
        treeDataStatus, cladesStatus, modelsStatus, lineageStatus, lineagesStatus, antigenicModelStatus, antigenicObservedDataStatus, antigenicRawModelStatus, mutationsPositionsDictStatus, genotypeDataStatus,
        customMeasures, customTreeDataStatus, tcellStatus, vpMethodsStatus, mutationClassesStatus, vpValuesStatus, humanPoolsStatus, humanSerologyDataStatus, strainSearchLoading,
        modelStatus, modelTypesStatus, antigenicModelsStatus, mutationGroupValuesStatus,

        fetchModel, fetchClades, initStrainTree, fetchCustomTreeAttrs,  fetchMutationClasses, fetchVpValues,
         fetchGenotypeData, fetchMutationGroupValues,
       

    } = props;
    const classes = styles();

    const canRefetchData = treeDataStatus === 'loaded' && !strainSearchLoading && vpValuesStatus === 'loaded';


    const _fetchModel = async () => {
        // console.log(`
        // isColorByModel(${colorBy}) = ${isColorByModel(colorBy)}  
        // modelId = ${modelId} 
        // models.includes(modelId) = ${(models||[]).includes(modelId)}
        // modelType = ${modelType} 
        // modelRegionId = ${modelRegionId} 
        // exportMode = ${exportMode}
        // isLoadedOrNA(modelTypesStatus) = ${isLoadedOrNA(modelTypesStatus)}
        // modelsStatus = ${modelsStatus} 
        // canRefetchData = ${canRefetchData} 
        // shouldFetch(modelStatus) = ${shouldFetch(modelStatus)}
        // `)

        if (isColorByModel(colorBy)
            && modelId
            && (!shouldFetchModels || models.includes(modelId))
            && modelType
            && modelRegionId
            && (!shouldFetchModels || (isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded'))
            && canRefetchData
            && shouldFetch(modelStatus)
        ) return fetchModel({ lineage, colorBy,  modelRegionId, modelType, modelId, zoomNodeId, strainSubset});


        return null;
    }

    const _fetchTree = async () => {
        const genotypeParams = colorBy === 'genotype' ? { mutgene, mutposition } : {};
        const humanSerologyParams = colorBy === 'humanSerology' ? { humanPool, humanSerologyDataType } : {};
        if (shouldFetch(treeDataStatus)) {
            return initStrainTree({ lineage, /*modelId,*/ colorBy, zoomNodeId, strainSubset, strainCutOffDate: strainCutOffDate, ignoreStrainCutOffDate, vpMethod, ...genotypeParams, strainId, ...humanSerologyParams, /*treeAttrsParams,*/ ...(exportParams || {}) })
         } 
         return null;
    }

    const _fetchClades = async () => {
        const cladesNeeded = colorBy === 'clade' || showCladeBar;
        if (shouldFetch(cladesStatus) && cladesNeeded) {
            return fetchClades({ lineage }) 
        };
        return null;
    }

    const _fetchVpValues = async () => {
        const initStrainTreeNeeded = shouldFetch(treeDataStatus) || shouldFetch(cladesStatus);
        const fetchVpValuesNeeeded = !initStrainTreeNeeded && shouldFetch(vpValuesStatus);
        if (fetchVpValuesNeeeded) 
            return fetchVpValues({ lineage, zoomNodeId, strainSubset, strainCutOffDate, vpMethod })
        return null;
    }

    const _fetchMutationGroupValues = async () => {
        if (showMutationsGroups && mutationsGroup && shouldFetch(mutationGroupValuesStatus) && treeDataStatus === 'loaded')
            return fetchMutationGroupValues({ lineage, mutationsGroup });
        return null;
    }

    const _fetchMutationClasses = async () => {
        if (shouldFetch(mutationClassesStatus)) 
            return fetchMutationClasses({ lineage });
        return null;
    }

    const _fetchCustomTreeAttrs = async() => {
       if (shouldFetch(customTreeDataStatus[colorBy]) && customMeasures[colorBy] && canRefetchData && !exportMode)
            return fetchCustomTreeAttrs({ lineage, colorBy, strainSubset, zoomNodeId });
        return;
    }
   
    
    const _fetchGenotypeData = async() => {
        if (colorBy === 'genotype' && shouldFetch(genotypeDataStatus) && mutgene && mutposition)
            return fetchGenotypeData({ lineage, mutgene, mutposition, zoomNodeId });
        return null;
    }

    const initComponentData = async () => {
        const initStrainTreeNeeded = shouldFetch(treeDataStatus) || shouldFetch(cladesStatus);
        const fetchVpValuesNeeeded = !initStrainTreeNeeded && shouldFetch(vpValuesStatus);
        // const treeAttrsParams = [].join(',');
        const canRefetchData = treeDataStatus === 'loaded' && vpValuesStatus === 'loaded';

        
        await Promise.all([
            // (isColorByModel(colorBy) && modelId && modelType && modelRegionId && isLoadedOrNA(modelTypesStatus) && modelsStatus === 'loaded' && canRefetchData && shouldFetch(modelStatus)) ? fetchModel({ lineage, modelRegionId, strainSubset, modelId, zoomNodeId, modelType, colorBy }) : null,
            _fetchModel(),
            _fetchTree(),
            // shouldFetch(treeDataStatus) ? initStrainTree({ lineage, modelId, colorBy, zoomNodeId, strainSubset, strainCutOffDate, ignoreStrainCutOffDate, vpMethod, mutgene, mutposition, strainId, humanPool, humanSerologyDataType, /*treeAttrsParams*/ }) : null,
            _fetchClades(),
            _fetchVpValues(),
            // shouldFetch(tcellAntigenicityOptionsStatus) && !exportMode ? fetchTCellAntigenicityOptions({ lineage }) : null,
            _fetchMutationClasses(),
            // (colorBy === 'genotype' && shouldFetch(mutationsPositionsDictStatus)) ? fetchMutationsPositionsDictionary({ lineage }) : null,
            _fetchCustomTreeAttrs(),
            _fetchGenotypeData(),
            _fetchMutationGroupValues()
        ])
    }
    useEffect(() => {
        if (lineageStatus !== 'loaded') return;

        initComponentData();
    });


    return (
        <div className={isMobile ? classes.rootMobile : classes.root}>
            <ErrorAlert />
            <Grid container className={classes.container}>
                {isMobile ?
                    <>
                        {treeDataStatus &&
                            <TreeGraph />
                        }
                        {!hiddenMenuMobile &&
                            <TreeGraphOptions intro={true} />
                        }
                        <NodeInfo />
                    </>
                    :
                    <>
                        <Grid item xs className={classes.item}>
                            {lineageStatus === 'loaded' && (
                                <>
                                    <ExportableComponent filename="strainTree">
                                        <TreeGraph />
                                    </ExportableComponent>
                                    <MetaInformations />
                                </>
                            )
                            }
                        </Grid>
                        <Grid item className={`${classes.treeSidebar} ${hiddenMenu ? classes.hidden : ''}`}>
                            {!hiddenMenu && <NodeInfo />}
                            <TreeGraphOptions intro={true} />
                        </Grid>
                    </>
                }
            </Grid>
            {(renderStatus === RENDER_STATUS.DONE) && (
                <div id="exportDone" />
            )}
        </div>
    )
}

const mapStateToProps = (state) => {

    return ({
        treeDataStatus: state.treeData.treeDataStatus,
        cladeSchema: state.cladeData.cladeSchema,
        cladesStatus: state.cladeData.cladesStatus,
        modelStatus: state.modelData.modelStatus[state.parameters.colorBy],
        modelsStatus: state.models.modelsStatus[state.parameters.colorBy],
        modelTypesStatus: state.models.modelTypesStatus[state.parameters.colorBy] || 'NA',
        lineagesStatus: state.lineages.lineagesStatus,
        lineageStatus: state.lineages.lineageStatus,
        genotypeDataStatus: state.genotype.genotypeDataStatus,
        mutationsPositionsDictStatus: state.genotype.mutationsPositionsDictStatus,
        lineage: state.parameters.lineage,
        modelId: state.parameters.modelId,
        modelRegionId: state.parameters.modelRegionId,
        regionId: state.parameters.regionId,
        colorBy: state.parameters.colorBy,
        modelType: state.parameters.modelType,
        zoomNodeId: state.parameters.zoomNodeId,
        showCladeBar: state.parameters.showCladeBar,
        showMutationsGroups: state.parameters.showMutationsGroups,
        mutationsGroup: state.parameters.mutationsGroup,
        strainSubset: state.parameters.strainSubset,
        refClade: state.parameters.refClade,
        editMode: state.parameters.editMode,
        mutgene: state.parameters.mutgene,
        mutposition: state.parameters.mutposition,
        mutationGroup: state.parameters.mutationGroup,
        mutationGroupValuesStatus: state.genotype.mutationGroupValuesStatus,
        vpMethod: state.parameters.vpMethod,
        treeScaleTypeX: state.parameters.treeScaleTypeX,
        strainCutOffDate: strainCutOffDateSelector(state), //state.parameters.strainCutOffDate,
        lineageStatus: state.lineages.lineageStatus,
        strainId: state.parameters.strainId,
        ignoreStrainCutOffDate: getIgnoreStrainCutOffDateForColorBy(state),
        clades: state.cladeData.clades,
        tcellAntigenicityOptionsStatus: state.metadata.tcellAntigenicityOptionsStatus,
        vpMethodsStatus: state.metadata.vpMethodsStatus,
        mutationClassesStatus: state.metadata.mutationClassesStatus,
        exportMode: state.parameters.exportMode,
        renderStatus: state.render.renderStatus, 
        customTreeDataStatus: state.customTreeData.status,
        vpValuesStatus: state.treeData.vpValuesStatus,
        genotypeDataStatus: state.genotype.genotypeDataStatus,
        hiddenMenu: state.render.hiddenMenu,
        hiddenMenuMobile: state.render.hiddenMenuMobile,
        customMeasures: getCustomMeasures(state),
        isMobile: getIsMobile(),
        shouldFetchModels: shouldFetchModelsSelector(state), 
        lineages: state.lineages.lineages,
        humanPool: state.parameters.humanPool,
        humanSerologyDataType: state.parameters.humanSerologyDataType,
        models: state.models.models[state.parameters.colorBy],
    })
};


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchModel,
            initStrainTree,
            fetchCustomTreeAttrs,
            fetchVpValues,
            fetchGenotypeData,
            fetchMutationClasses,
            fetchMutationGroupValues,
            setParameters,
            fetchClades
        },
        dispatch,
    );


export default connect(mapStateToProps, mapDispatchToProps)(Intro);
