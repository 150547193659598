import { ZOOM_TREE_REQUEST, ZOOM_TREE_SUCCESS, ZOOM_TREE_ERROR } from './actionTypes';

// Base Actions
import { received } from '../baseActions';

const selectZoomNode = (payload) => received(ZOOM_TREE_REQUEST, payload)
const selectZoomNodeSuccess = (res) => received(ZOOM_TREE_SUCCESS, res)
const selectZoomNodeError = (error) => received(ZOOM_TREE_ERROR, error)

export { selectZoomNode, selectZoomNodeSuccess, selectZoomNodeError };
