/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@mui/styles';
import { getStrainTreeStatus } from '../../../redux/selectors/statusSelector';
import { styles } from '../styles';
import { treeD3 } from '../d3/TreeD3';
import { RENDER_STATUS } from '../../../config/consts';
import { bindActionCreators } from 'redux';
import { setComponentStatus } from '../../../redux/actions/renderActions';


const viewToRender = 'strainTree';
const componentId = 'axes';

const AxesLayer = (props) => {
    const _element = useRef(null);

    const { lineage, loading, renderStatus, setComponentStatus, zoomedIn } = props;

    const startRender = async () => {
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    }

    const stopRender = () => {
        //if (renderStatus !== RENDER_STATUS.DONE)
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    }


    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        //console.log('[AxesLayer], renderStatus', renderStatus);
        startRender()
            .then(() => treeD3.drawDateAxisLayer(_element.current))
            .then(() => stopRender());
    }, [renderStatus])

    useEffect(() => {
        treeD3.removeAxesLayer(_element.current);
    }, [lineage]);

    useEffect(() => {
        // if (loading) {
        //     startRender();   
        //     return;
        // }
        if (loading) return;
        
        startRender()
            .then(() => treeD3.drawDateAxisLayer(_element.current))
            .then(() => stopRender());
    }, [loading])

    return (
        <g id='axes' ref={_element}>
            <g id='treeAxis'>
                <rect id='treeAxis_background' pointerEvents='all' className={zoomedIn ? 'zoomed-in' : ''}  ></rect>
            </g>
            <g id='treeYAxis'></g>
            <g id='fanAxis'>
                <rect id='fanAxis_background' pointerEvents='all' className={zoomedIn ? 'zoomed-in' : ''}></rect>
                <g id="fanAxis_ticks" />
            </g>
        </g>
    );

}

AxesLayer.propTypes = {
    layout: PropTypes.string,
    loading: PropTypes.bool,
    classes: PropTypes.shape({
        graph: PropTypes.string,
        graphExport: PropTypes.string
    })
};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized;
    return ({
        loading,
        lineage: state.parameters.lineage,
        renderStatus: state.render.viewToRender ? state.render.viewToRender.components[componentId] : null,
        layout: state.parameters.layout,
        zoomedIn: (state.treeData.zoomNodeStack || []).length > 1
    });
};


const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setComponentStatus,
        },
        dispatch,
    );


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AxesLayer));
