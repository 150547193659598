import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';
import EditSubsetDialog from '../Dialogs/EditSubsetDialog';

const EditSubset = ({ subset, setInfoDialog, setInfo, setAction, fetchCustomTreeSubsets }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
    };

    const updateSubset = (oldKey, newSubset, isRule) => {
        const url = `${config.serverLink}/api/admin/updateCustomTreeSubset`;
        const body = { oldKey, newSubset, lineage: subset.lineage };
        if (isRule)
            delete body.newSubset.highlightAll;

        postAxios(url, body)
            .then((response) => {
                if (response.data.status === 'success') {
                    setOpenDialog(false);
                    setAction('Update subset');
                    setInfo(`Subset ${newSubset.key} was updated sucessfully`);
                    setInfoDialog(true);
                    fetchCustomTreeSubsets();
                }
                else {
                    setOpenDialog(false);
                    setAction('Subset scale');
                    setInfo(`Subset ${newSubset.key} wasn't updated`);
                    setInfoDialog(true);
                }
            });
    };


    return <>
        <IconButton className={classes.button} onClick={handleClick}>
            <EditIcon />
        </IconButton>
        < EditSubsetDialog oldSubset={subset} openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateSubset={updateSubset} />
    </>





};

export default EditSubset;
