import { cloneDeep } from 'lodash';
import deepFreeze from 'deep-freeze';
import { emptyObject, daysToDate } from '../../functions/functions';
import alertsConfig from '../../config/alertsConfig.json';

import {
    FETCH_TREE_FREQS_SUCCESS,
    SHOW_ALERT,
    CLOSE_ALERT,
    FETCH_RECALCULATED_TREE_ERROR,
    ZOOM_TREE_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    SET_ALERT_STATUS,
    FETCH_VP_METHODS_ERROR,
    FETCH_SUBSET_TREE_ERROR,
    FETCH_TREE_FREQS_ERROR,
    FETCH_STRAINS_LIST_ERROR,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_MUT_POS_DICT_ERROR,
    FETCH_GENOTYPE_DATA_ERROR,
    FETCH_PREDICTIONS_ERROR,
    FETCH_MODEL_DATA_ERROR,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_TYPES_ERROR,
    FETCH_LINEAGES_ERROR,
    FETCH_LINEAGE_ERROR,
    FETCH_GEO_DATA_ERROR,
    FETCH_FREQUENCIES_ERROR,
    FETCH_CLADES_ERROR,
    FETCH_ANTIGENIC_OBSERVED_DATA_ERROR,
    FETCH_ANTIGENIC_CLADES_ERROR,
    FETCH_ANTIGENIC_RAW_MODEL_ERROR,
    FETCH_MODEL_DATA_SUCCESS,
    SET_MODEL_TYPE,
    INIT_STRAIN_TREE_SUCCESS,
    INIT_STRAIN_TREE_ERROR,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_FREQUENCIES_SUCCESS,
    SIGNIN_SUCCESS,
} from '../actions/actionTypes';

let alertInitialState = { };
export const setAlertInitialState = (state) => {
    alertInitialState = state;
};

const setShow = (errors, infos) => {
    return Object.keys(errors).length > 0 || Object.keys(infos).length > 0;
};

// const updateMessage = (obj, messageVar) => {
//     let retObj = { ...obj };
//     const message = retObj.message.replace('{X}', messageVar);
//     retObj.message = message;

//     return retObj;
// };

const changeActionTypeIntoInfo = (actionType) => {
    //console.log(actionType);
    let ret = actionType.replaceAll('_', ' ').toLowerCase();
    return ret.charAt(0).toUpperCase() + ret.slice(1);
};


const adjustAlertInfoAndMesage = (alert, actionType, messageVar) => {
    const _alert = {...alert}
    if (actionType) {
        const formattedAction = changeActionTypeIntoInfo(actionType);
        _alert.input = formattedAction;
    }
    if (messageVar) {
        const message = _alert.message.replace('{X}', messageVar);
        _alert.message = message;
    }
    return _alert;
}

const addAlerts = (state, alertId, messageVar, alertType, actionType) => {
    //console.log('[addAlerts]: messageVar = ',messageVar, alertId, alertType)
    const retMod = {};
    const alerts = alertType === 'error' ? alertsConfig.errors : alertsConfig.infos;


    const alert = adjustAlertInfoAndMesage(alerts[alertId], actionType, messageVar)
    const { modules } = alert;
    modules.forEach(module => {
        const mod = cloneDeep(state[module]);
        const modAlerts = alertType === 'error' ? mod.errors : mod.infos;
        modAlerts[alertId] = alert;
        mod.show = setShow(mod.errors, mod.infos);
        retMod[module] = mod;
    });

    return retMod;
}

const removeAlerts = (state, alertId, alertType) => {
    const retMod = {};
    const alerts = alertType === 'error' ? alertsConfig.errors : alertsConfig.infos;
    const alert = alerts[alertId];

    if (!alert) return retMod;
    //const error = alertsConfig.errors[errorId];
    const { modules } = alert;
    modules.forEach(module => {
        const mod = cloneDeep(state[module]);
        const modAlerts = alertType === 'error' ? mod.errors : mod.infos;
        delete modAlerts[alertId];
        mod.show = setShow(mod.errors, mod.infos);
        retMod[module] = mod;
    });
    return retMod;
}

const setErrors = (state, errorId, show = true, actionType = null, messageVar = null) => show ? addAlerts(state, errorId, messageVar, 'error', actionType) : removeAlerts(state, errorId, 'error');

const setInfos = (state, infoId, show = true, actionType = null, messageVar = null) => show ? addAlerts(state, infoId, messageVar, 'info', actionType) : removeAlerts(state, infoId, 'info');


export const alertReducer = (state = alertInitialState, action) => {
    deepFreeze(state);
    switch (action.type) {
        case INIT_STRAIN_TREE_ERROR: {
            const moduleErrors = setErrors(state, action.payload.error || 'strainTreeEmptyData');
            return {...state, ...moduleErrors  };
        }

        case FETCH_SUBSET_TREE_SUCCESS: {
            const { visibleNodes } = action.payload;
            const moduleErrors = setErrors(state, 'emptySubsetTree', emptyObject(visibleNodes));
            return { ...state, ...moduleErrors };
        }

        case INIT_STRAIN_TREE_SUCCESS: {
            const { visibleNodes } = action.payload;

            const errorId = 'strainTreeEmptyData'
            const moduleErrors = setErrors(state, errorId, emptyObject(visibleNodes));
            return { ...state, ...moduleErrors };
        }
        case FETCH_TREE_FREQS_SUCCESS: {
            const { origPredictionBaseline, parameters } = action.payload;

            const origDateString = daysToDate(origPredictionBaseline).toLocaleDateString();
            const showPredictionBaseline = origPredictionBaseline !== parameters.predictionBaseline;

            if (!showPredictionBaseline) {
                const moduleErrors = setInfos(state, 'predictionBaseline', false);
                return { ...state, ...moduleErrors };
            }

            const moduleInfos = setInfos(state, 'predictionBaseline', true, null, origDateString);
            return { ...state, ...moduleInfos };
        }
        case SET_ALERT_STATUS: {
            const { id, status, messageVar } = action.payload;

            const info = alertsConfig.infos[id];
            const error = alertsConfig.errors[id];
            let moduleAlerts = {}
            if (info) {
                moduleAlerts = setInfos(state, id, status, null, messageVar);
            }
            else if (error) { //TO DO - concatenate infos and errors
                moduleAlerts = setErrors(state, id, status, null, messageVar);
            }


            return {...state, ...moduleAlerts };
        }
        // case FETCH_VISIBLE_NODES_ERROR:
        case FETCH_STRAINS_LIST_ERROR:
        case FETCH_SUBSET_TREE_ERROR:
        case FETCH_TREE_FREQS_ERROR:
        case FETCH_VP_METHODS_ERROR:
        case FETCH_MUT_POS_DICT_ERROR:
        case FETCH_GENOTYPE_DATA_ERROR:
        case FETCH_MODEL_DATA_ERROR:
        case FETCH_MODELS_ERROR:
        case FETCH_MODEL_TYPES_ERROR:
        case FETCH_LINEAGES_ERROR:
        case FETCH_LINEAGE_ERROR:
        case FETCH_CLADES_ERROR:
        case FETCH_CUSTOM_TREE_ATTRS_ERROR:
        case FETCH_RECALCULATED_TREE_ERROR:
        case FETCH_MUTATION_CLASSES_ERROR:
        case ZOOM_TREE_ERROR: {

            const errorId =  action.payload.error || 'fetchError';
            const moduleErrors = setErrors(state, errorId, true, action.type);
            return { ...state, ...moduleErrors };
        }
        case FETCH_ANTIGENIC_OBSERVED_DATA_ERROR:
        case FETCH_ANTIGENIC_RAW_MODEL_ERROR:
        case FETCH_ANTIGENIC_CLADES_ERROR:  {

            const errorId =  action.payload.error || 'fetchError';
            const moduleErrors = setErrors(state, errorId, true, action.type);
            return {...state, ...moduleErrors}
        }
        case FETCH_MODEL_DATA_SUCCESS: {
            const isError = emptyObject(action.payload.treeModel);
            const moduleErrors = setErrors(state, 'noModelError', isError)
            return { ...state, ...moduleErrors };
        }
        case SET_MODEL_TYPE: {
            const moduleErrors = setErrors(state, 'noModelError', false)
            return { ...state, ...moduleErrors };
        }
        case FETCH_FREQUENCIES_ERROR: {
            const moduleErrors = setErrors(state, 'fetchFrequenciesError', true, action.type);
            return { ...state, ...moduleErrors };
        }
        case FETCH_PREDICTIONS_ERROR: {
            const moduleErrors = setErrors(state, 'fetchPredictionsError', true, action.type);
            return { ...state, ...moduleErrors };
        }

        case FETCH_FREQUENCIES_SUCCESS: {
            const { frequencies, subsetId} = action.payload;
            const noDataError = !frequencies[subsetId] || frequencies[subsetId].length === 0;
            const moduleErrors = setErrors(state, 'fetchFrequenciesNoData', noDataError, action.type);
            return { ...state, ...moduleErrors };
        }
        case SIGNIN_SUCCESS:
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            //const alerts = { };
            //const predictionBaselineOrgDate = 0;
            return {
                // ...state,
                // strainTree: {
                //     show: false,
                //     errors: [],
                //     infos: []
                // },
                // frequencies: {
                //     show: false,
                //     errors: [],
                //     infos: []
                // },
                ...alertInitialState
                //alerts,
                //predictionBaselineOrgDate
            }
        }
        case FETCH_GEO_DATA_ERROR: {
            const moduleErrors = setErrors(state, 'fetchGeoMapError', true, action.type);
            return { ...state, ...moduleErrors };
        }
        case SHOW_ALERT: {
            return state || alertInitialState;
        }
        case CLOSE_ALERT: {
            return state || alertInitialState;
        }
        default:
            return state || alertInitialState;
    }
}
