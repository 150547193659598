import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { isEqual } from 'lodash';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { trackingToSelector } from '../../redux/selectors/parametersSelector';
import { setAlertStatus } from '../../redux/actions/alertActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { styles } from '../Tree/styles';
import { dateFormat } from '../../functions/checkBrowser';

const TrackingToPicker = (props) => {
    const { trackingTo, intro } = props;
    const { resetPredictions, setParameters, setAlertStatus, alertStatus } = props;
    let maxTrackingToDate = new Date();
    maxTrackingToDate.setFullYear(maxTrackingToDate.getFullYear() + 5);
    let trackingToHolder = trackingTo;
    const now = new Date();

    const handleTrackingToChange = (value) => {
        // console.log('[TrackingToPicker.change] value = ',value)
        if (trackingToHolder < maxTrackingToDate && alertStatus)
            setAlertStatus({status: false, model: 'frequencies', id: 'trackingToError', messageVar: maxTrackingToDate});
        if (value && !isNaN(value.getTime())) trackingToHolder = value;
    };

    const handleAccept = (value) => {
        if (isEqual(value, trackingTo)) return;

        // console.log(`[handleAccept]: ${trackingToHolder.current} / ${trackingTo}`);
        setParameters({ trackingTo: value, ...(intro && { predictionBaseline: trackingToHolder}) });
        resetPredictions();
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !isEqual(trackingToHolder, trackingTo)) {
            if (trackingToHolder > maxTrackingToDate){
                setAlertStatus({status: true, model: 'frequencies', id: 'trackingToError', messageVar: maxTrackingToDate});
                return;
            }
            setParameters({ trackingTo: trackingToHolder, ...(intro && { predictionBaseline: trackingToHolder}) });
            resetPredictions();
        }
    };

    // useEffect(() => {
    //     console.log(`[TrackingToPicker.useEffect]:
    //     current:    ${trackingToHolder.current},
    //     trackingTo: ${trackingTo}` );
    //     trackingToHolder.current = trackingTo;
    // },[trackingTo])
    //    render = () => {

    //useEffect(() => trackingToHolder = trackingTo);

    //    console.log('AAA trackingTo:', trackingToHolder.current, '/', trackingTo);
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                disableToolbar
                variant="inline"
                format={dateFormat}
                id="trackingTo"
                maxDate = {maxTrackingToDate}
                allowSameDateSelection
                label="Tracking to"
                value={trackingToHolder || now}
                onChange={handleTrackingToChange}
                onAccept={handleAccept}
                autoOk="true"
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                        letterSpacing: '-0.5px'
                    }
                }}
                slotProps={{
                    textField: { 
                        variant: 'standard',
                        onKeyDown: handleKeyPress
                    },
                    inputAdornment: { position: 'start'}
                }}
                fullWidth
               />
        </LocalizationProvider>
    );
};

TrackingToPicker.propTypes = {
    trackingTo: PropTypes.instanceOf(Date),
    setParameters: PropTypes.func,
    resetFrequencies: PropTypes.func,
};

const mapStateToProps = (state) => {
    const freqAlertsError = state.alert.frequencies.errors;
    const alertStatus = freqAlertsError['trackingToError'];

    return {
        alertStatus,
        trackingTo: trackingToSelector(state),
        intro: state.parameters.intro
    }
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setParameters,
            resetPredictions,
            setAlertStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TrackingToPicker));
