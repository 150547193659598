import { Button, Checkbox, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { HideIcon } from "../Alerts/styles";
import { styles } from "./styles/antigenicFitnessOptionsStyles";
import { setMeasureScale, setParameters } from '../../redux/actions/parametersActions';
import { resetAntigenicHiddenClades } from '../../redux/actions/antigenicActions';
import ModelSelector from "../ModelSelector/ModelSelector";
import ColorScaleSelector from '../OptionsSelector/ColorScaleSelector';
import ScalesLegend from '../ColorLegend/Legends/ScalesLegend';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FitnessInfo from './FitnessInfo';


const FitnessOptions = (props) => {
  
    const { hiddenAlphaCladesCnt, hiddenRhoCladesCnt, handleHideClick, permissions, showAntigenicTableValues, wrapAntigenicTableHeaders,
        setParameters,
        resetAntigenicHiddenClades, undoDeleting,
        cellInfo
    } = props;

    const classes = styles();

    const handleReset = () => {
        resetAntigenicHiddenClades();
    }

    const handleChangeNumbers = () => event => {
        const value = event.target.checked;
        setParameters({ showAntigenicTableValues: value })
    }

    const handleChangeWrap = () => event => {
        const value = event.target.checked;
        setParameters({ wrapAntigenicTableHeaders: value })
    }

    const handleUndo = () => {
        undoDeleting();
    }

    return (
        <form className={classes.root} autoComplete="off">
            <div className={classes.buttonWrapper}>
                <IconButton className={classes.hideButton} onClick={handleHideClick}>
                    <HideIcon />
                </IconButton>

            </div>
            { permissions.modelSelector && <ModelSelector fixedModelType="Antigenic" modelContext="antigenicFitness" /> }
            <FormControlLabel
                className={classes.formControl}
                value="showAntigenicNumbers"
                label={<Typography className={classes.formControlLabel}>Show values</Typography>}
                control={
                    (
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={showAntigenicTableValues}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                            onChange={handleChangeNumbers()}
                            className={classes.checkbox}
                            id="showAntigenicNumbers"
                        />
                    )
                }
            />
            <FormControlLabel
                className={classes.formControl}
                value="wrapAntigenicTableHeaders"
                label={<Typography className={classes.formControlLabel}>Wrap labels</Typography>}
                control={
                    (
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={wrapAntigenicTableHeaders}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                            onChange={handleChangeWrap()}
                            className={classes.checkbox}
                            id="wrapAntigenicTableHeaders"
                        />
                    )
                }
            />

            <Typography style={{ fontSize: '15px', fontFamily: 'Inter' }}>
                DELETED: {hiddenAlphaCladesCnt} Rows / {hiddenRhoCladesCnt} Columns
            </Typography>

            <Grid container >
                <Grid item xs={12} style={{ marginTop: '5px' }}>
                    <Button style={{ marginRight: '10px' }} className={classes.button} onClick={handleReset}>
                        Reset
                    </Button>
                    <Button className={classes.button} onClick={handleUndo}>
                        Undo
                    </Button>
                </Grid>
            </Grid>
            <ColorScaleSelector colorBy='antigenicFitness' />
            <ScalesLegend
                scaleId="antigenicFitness"
                measureName="antigenicFitness"
                valueGetter={() => cellInfo && cellInfo.antigenicFitness}
                ticks={3}
            />
            <FitnessInfo cellInfo={cellInfo}/>
        </form>
    )
}
const mapStateToProps = (state) => ({
    hiddenAlphaCladesCnt: Object.keys(state.parameters.hiddenAlphaClades).length,
    hiddenRhoCladesCnt: Object.keys(state.parameters.hiddenRhoClades).length,
    showAntigenicTableValues: state.parameters.showAntigenicTableValues,
    permissions: state.user.permissions.fitnessPermissions,
    measures: state.metadata.measures,
    wrapAntigenicTableHeaders: state.parameters.wrapAntigenicTableHeaders
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    setMeasureScale,
    resetAntigenicHiddenClades
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FitnessOptions);
