import { useState } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Menu,
    Divider,
    IconButton,
    Tooltip,
    MenuItem,
} from '@mui/material';
import { UserIcon, linksMenuStyles } from '../userMenu/styles';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
        color: 'black',
        fontSize: '13px'
    },
    dividerUp: {
        margin: '24px 24px 24px 24px'
    },
}));

const Hamburger = () => <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="1" x2="15" y2="1" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="1" y1="7.05505" x2="15" y2="7.05506" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <line x1="1" y1="13" x2="15" y2="13" stroke="white" strokeWidth="2" strokeLinecap="round" />
</svg>

export default function OutsideLinks() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small" style={{ margin: 0, padding: 0 }}>
                        <Hamburger />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: linksMenuStyles
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem >
                    <Link to='#data_sources' className={classes.link}>
                        Data sources
                    </Link>
                </MenuItem>
                <MenuItem >
                    <Link to='https://previr.com/analysis#previr-app' className={classes.link}>
                        Guide
                    </Link>
                </MenuItem>
                <MenuItem >
                    <Link to='https://previr.com/' className={classes.link}>
                        Previr Center
                    </Link>
                </MenuItem>
                <Divider className={classes.dividerUp} />
                <MenuItem >
                    <Link to='/signin' className={classes.link}>
                        Sign in
                    </Link>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

