import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { elementToSVG, inlineResources } from 'dom-to-svg'
import { Dialog, DialogTitle, MenuItem, Menu } from '@mui/material';
import { prepareUrl, pick, prepareParametersToExportUrl } from '../../functions/functions';
import { getIgnoreStrainCutOffDateForColorBy } from '../../redux/selectors/metadataSelector';
import config from '../../config/envConfig';
import { PDFDimensions } from '../../components/Dialogs/PDFDimensions';
import { withStyles } from '@mui/styles';

const PDFDialog = (props) => {
    const { pdfLoading } = props;
    return (
        <Dialog open={pdfLoading}>
            <DialogTitle id="pdf-dialog-title">PDF loading...</DialogTitle>
        </Dialog>);
};

PDFDialog.propTypes = {
    pdfLoading: PropTypes.bool,
};

const getFileNameWithParams = (props) => {
    const { filename, parameters } = props;
    const getParams = () => {
        switch (filename) {
            case 'antigenic':
                return pick('lineage', 'modelId', 'antigenicDataType', 'antigenicTiterType')(parameters);
            case 'strainTree':
                return pick('lineage', 'modelId', 'colorBy')(parameters);
            case 'prediction':
            case 'clade':
                return pick('lineage', 'modelId', 'regionId')(parameters);
            default: return {};
        }
    };
    const paramsString = Object.values(getParams()).join('_');
    return `${filename}_${paramsString}.pdf`;
};

const getAntigenicDefaultDimn = (filename) => {
    const id = filename === 'antigenicDetails' ? '#table_details': '#antigenic_table'
    const element = document.querySelector(id);

    if (!element)
        return { height: 0, width: 0 };

    const width = 400 + element.offsetWidth;
    const height = 200 + element.offsetHeight;

    return { height, width };
}

const ExportContextMenu = (props) => {
    const { filename } = props;
    const [pdfLoading, setPdfLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const getPDF = async (dimensions) => {
        const { parameters, mapDimensions } = props;
        const antigenicDefaults = getAntigenicDefaultDimn(filename);
        const isAntigenic = filename === 'antigenic' || filename === 'antigenicDetails';

        parameters.height = isAntigenic  ? antigenicDefaults.height : filename === 'geomap' ? mapDimensions.height : parseInt(dimensions.height) + 50;
        parameters.width = isAntigenic ? antigenicDefaults.width : filename === 'geomap' ? mapDimensions.width : parseInt(dimensions.width) + 250;
        
        let url = '';
        if (filename === 'antigenicDetails'){
            const { alpha, rho } = props;
            url = prepareUrl(`${config.serverLink}/api/export/antigenic`, parameters || {});
            url += `&rho=${rho}&alpha=${alpha}`
        }
        else {
            url = prepareUrl(`${config.serverLink}/api/export/${filename}`, parameters || {});
        }
        

        return fetch(url, { headers: { 'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER } }).then(function (response) {
            return response.arrayBuffer();
        }).then(function (buffer) {
            const blob = new Blob([buffer], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const downloadName = getFileNameWithParams(props);
            link.download = downloadName;
            link.click();
            setPdfLoading(false);
        });
    };

    const savePDF = (dimensions) => {
        setPdfLoading(true);
        handleCloseDialog();
        props.handleClose();
        return getPDF(dimensions) // API call
    };

    const saveAsPDF = async () => {
        if (filename === 'geomap' || filename === 'antigenic') {
            setPdfLoading(true);
            handleClose();
            await getPDF();
            return;
        }

        setOpenDialog(true);
        handleClose();
    }

    const openEditView = async (dimensions) => {
        const { parameters } = props;
        const isAntigenic = filename === 'antigenic' || filename === 'antigenicDetails';
        parameters.height = isAntigenic ? dimensions.height : parseInt(dimensions.height) + 50;
        parameters.width = isAntigenic ? dimensions.width : parseInt(dimensions.width) + 250;
        parameters.editMode = true;

        const url = prepareUrl(`${config.serverLink}/api/export/getLink`, parameters || {})
        const editUrl = await fetch(url, { headers: { 'Secret': config.REACT_APP_FRONTEND_SECRET_HEADER } })
            .then(response => response.json())
            .then(data => data.exportUrl);

        handleCloseDialog();
        parameters.editMode = false;
        window.open(editUrl);
    }

    const handleClose = () => {
        props.handleClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        handleClose();
    }

    const Download = async () => {
        handleClose();
        const selector = 'frequencies_svg';
        const svgDocument = elementToSVG(document.getElementById(selector));
        await inlineResources(svgDocument.documentElement);
        const svgString = new XMLSerializer().serializeToString(svgDocument);
        const element = document.createElement('a');
        const file = new Blob([svgString], { type: 'text/html' });

        element.href = URL.createObjectURL(file);
        element.download = `freqChart.svg`;
        document.body.appendChild(element);
        element.click();;
    }

    const { menuPos, menuOn } = props;
    const { x, y } = menuPos;
    const dimn = { height: 800, width: 1100 };

    const StyledMenu = withStyles({
        paper: {
            borderRadius: '12px'
        }
    })(props => (
        <Menu
            keepMounted
            open={menuOn}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={y !== null && x !== null ? { top: y, left: x } : undefined}
            {...props}
        />
    ));

    return (
        <>
            <PDFDialog pdfLoading={pdfLoading} />
            {openDialog && <PDFDimensions handleCloseDialog={handleCloseDialog} filename={filename} savePDF={savePDF} openEditView={openEditView} />}
            <StyledMenu>
                <MenuItem onClick={saveAsPDF}>Save as PDF </MenuItem>
                {filename === 'frequencies' && <MenuItem onClick={Download}> Save as SVG </MenuItem>}
                {filename === 'strainTree' && <MenuItem onClick={() => openEditView(dimn)}> Open Edit Tab </MenuItem>}
            </StyledMenu>
        </>
    );
};

ExportContextMenu.propTypes = {
    menuOn: PropTypes.bool,
    menuPos: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
    filename: PropTypes.string,
    handleClose: PropTypes.func
};

const mapStateToProps = (state) => {
    const params = {
        ...state.parameters,
    }
    //console.log('1. predictionBaseline', params.predictionBaseline);
    const _parameters = prepareParametersToExportUrl(params);
    _parameters.ignoreStrainCutOffDate = getIgnoreStrainCutOffDateForColorBy(state);

    //console.log('2. predictionBaseline', _parameters.predictionBaseline);
    return ({
        parameters: _parameters,
        alpha: state.antigenic.alpha,
        rho: state.antigenic.rho
    });
};

export default connect(mapStateToProps, null)(ExportContextMenu);
