import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Card, CardContent, CardActions, Collapse, Typography } from '@mui/material';
import { ExpandMore, ErrorIcon, InfoIcon, HideIcon, ExpandIcon, styles } from './styles';

const Alerts = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { showAlert, errors, infos, isError, isInfo, alertsOn, errorsLength } = props;
    const [ focused, setFocused ] = useState([]);
    const classes = styles();

    useEffect(() => {
        if (isError)
            setExpanded(true);
    }, [isError]);

    useEffect(() => {
        if (!errorsLength){
            focused.forEach(id => {
                const input = document.querySelector(id);
                input.classList.remove("red")
            });
            setFocused([]);
        }

    }, [errorsLength]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const focusIn = (inputId) => {
        if (!inputId) return;
            inputId.forEach(id => {
            const input = document.querySelector(id);
            input.focus();
        });
    }

    const focusInError = (inputId) => {
        if (!inputId) return;

        setFocused(inputId);
        inputId.forEach(id => {
            const input = document.querySelector(id);
            input.classList.add("red")
        });

        const input = document.querySelector(inputId[0]);
        input.focus();
    }

    return (
        <>
        {showAlert && alertsOn && (
            <div className={classes.root}>
                <div className={expanded ? classes.rootExpandedTree : classes.rootNotExpanded}>
                    <CardActions disableSpacing className={expanded ? classes.cardActionsExpanded : classes.cardActions }>
                        {!expanded && isInfo && <InfoIcon isLine={false}/>}
                        {!expanded && isError && <ErrorIcon isLine={false}/> }
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            style={expanded ? {padding: '2px 0 0 0'} : {padding: '4px 0 0 0'}}
                        >
                            {expanded ?
                                <HideIcon className={classes.close}/> :
                                <ExpandIcon className={classes.add}/>
                            }
                        </ExpandMore>
                    </CardActions>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent style={{paddingTop: 0, paddingBottom: '18px'}}>
                            {
                                Object.values(infos).map(info => {
                                    return (
                                        <Typography key={info.id} className={classes.typography} onClick={() => {focusIn(info.inputId)}}>
                                            <InfoIcon isLine={true}/>
                                            <span className={classes.span}>
                                                <span className={classes.colorInfo}>{info.input} &nbsp;</span>
                                                {info.message}
                                            </span>
                                        </Typography>
                                    );
                                })
                            }
                            {
                                Object.values(errors).map(error => {
                                    return (
                                        <Typography key={error.id} className={classes.typography} onClick={() => {focusInError(error.inputId)}}>
                                            <ErrorIcon isLine={true}/>
                                            <span className={classes.span}>
                                                <span className={classes.colorError}>{error.input} &nbsp;</span>
                                                {error.message}
                                            </span>
                                        </Typography>
                                    );
                                })
                            }
                        </CardContent>
                    </Collapse>
                </div>
                </div>
            )
        }
        </>
    );
}

const mapStateToProps = ({alert, nodeData}, ownProps) => {
    const module = ownProps.module;
    const errors = alert[module]?.errors;
    const infos = alert[module]?.infos;
    const errorsLength = Object.keys(errors).length;
    const isError = errorsLength > 0;
    const infosLength = Object.keys(infos).length;
    const isInfo = infosLength > 0;

    return {
        showAlert: alert[module]?.show,
        alertsOn: module !== 'strainTree' || !nodeData.nodeId,
        errorsLength,
        infosLength,
        errors,
        infos,
        isError,
        isInfo,
    }
};

export default connect(mapStateToProps)(Alerts);
