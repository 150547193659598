import { FETCH_FITNESS_MODEL_REQUEST } from "../actions/actionTypes";
import { ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { prepareUrl } from '../../functions/functions';
import {
    fetchFitnessModelSuccess,
    fetchFitnessModelError
} from '../actions/fitnessActions';
import config from "../../config/envConfig";
import { fetchAxios } from '../../functions/axiosRequests';

export const fetchFitnessModelEpic = action$ => action$.pipe(
    ofType(FETCH_FITNESS_MODEL_REQUEST),
    mergeMap(action => {
        const { lineage, antigenicFitnessModelId } = action.payload;
        const url = prepareUrl(`${config.serverLink}/api/fitnessModel`, { lineage, antigenicFitnessModelId });

        return fetchAxios(url).then(function (response) {
            return fetchFitnessModelSuccess(response.data)
        }).catch(function (error) {
            const { status, data } = error.response;

            if (status === 408)
                return sessionTimeOut(error);

            return fetchFitnessModelError({ error: data.error, errorWebsiteText: 'An error has occured during downloading fitness data.', errorWebsiteType: 'fetch'});
        })
    })
);
