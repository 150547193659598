import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import { FormControl, Grid, MenuItem, InputAdornment, IconButton } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import { setParameters } from '../../redux/actions/parametersActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { StyledTextField } from '../../assets/GlobalStyles/TextField';
import { MinusIcon, PlusIcon } from '../SvgIcons/SequencesInputIcons';

const logSpaceOptions = [
    { key: true, label: 'Logarithmic' },
    { key: false, label: 'Linear' },
];

const styles = theme => ({
    formControl: {
        minWidth: 120,
        margin: '0px 0px 8px 0px'
    },
    icon: {
        padding: 0,
        marginLeft: '3px',
    },
    textField: {
        width: '100%',
        padding: 0,
        margin: 0
    },
    input: {
        marginTop: '1px',
    }
});

class YScaleSelector extends PureComponent {
    state = {
        minLogSpace: this.props.minLogSpace
    }
    handleYScaleChange = event => {
        const { setParameters } = this.props;
        const logSpace = event.target.value;
        setParameters({ logSpace });
    };

    handleChange = e => {
        const minLogSpace = e.target.value;
        this.setState({ minLogSpace });
    }

    handleKeyPress = e => {
        const { setParameters } = this.props;

        if(e.key === 'Enter'){
            setParameters({ minLogSpace: this.state.minLogSpace});
        }
    }

    render = () => {
        const { logSpace, classes, minLogSpace } = this.props;
        return (
            <Grid container spacing={2} style={{paddingTop: '8px'}}>
                <Grid item sm={12} md={logSpace ? 6 : 12}>
                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id={'logSpace'} label={'Y scale'} />
                        <CustomSelect
                            value={logSpace}
                            onChange={this.handleYScaleChange}
                            inputProps={{
                                name: 'logSpace',
                                id: 'logSpace',
                            }}
                        >
                            {logSpaceOptions.map((t) => (
                                <MenuItem key={t.key} value={t.key}>{t.label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                </Grid>
                {logSpace && <Grid item sm={12} md={6} className={classes.input}>
                    <StyledTextField
                        id="minLogSpace"
                        label="Min. value"
                        type='number'
                        className={classes.textField}
                        value={this.state.minLogSpace}
                        onChange={this.handleChange.bind(this)}
                        onKeyPress={this.handleKeyPress.bind(this)}

                    />
                </Grid>}
            </Grid>


        );
    }
}

const mapStateToProps = ({ parameters }) => {
    return ({
        logSpace: parameters.logSpace,
        minLogSpace: parameters.minLogSpace
    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(YScaleSelector));
