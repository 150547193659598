import {
    FETCH_GENOTYPE_DATA_REQUEST,
    FETCH_GENOTYPE_DATA_SUCCESS,
    FETCH_GENOTYPE_DATA_ERROR,
    RESET_GENOTYPE_STATUS,
    FETCH_MUT_POS_DICT_REQUEST,
    FETCH_MUT_POS_DICT_SUCCESS,
    FETCH_MUT_POS_DICT_ERROR,
    RESET_SESSION,
    SIGNOUT_REQUEST,
    FETCH_MUTATION_GROUP_REQUEST,
    FETCH_MUTATION_GROUP_SUCCESS,
    FETCH_MUTATION_GROUP_ERROR,
    ZOOM_TREE_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    // FETCH_VISIBLE_NODES_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST
} from '../actions/actionTypes';
import deepFreeze from 'deep-freeze';

let genotypeInitialState = { };
export const setGenotypeInitialState = (state) => {
    genotypeInitialState = state;
};

const GENES_LABELS = {
    genome: 'Nucleotide'
}

// don't use zero as a value
const GENES_ORDER = {
    genome: 1,
    default: 2
}

export const genotypeReducer = (state = genotypeInitialState, action) => {
    deepFreeze(state);
    if (action.payload && action.payload.settings) return state;
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return { ...genotypeInitialState }
        }
        case FETCH_GENOTYPE_DATA_REQUEST: {
            return {
                ...state,
                genotypeDataStatus: 'loading',
                geneMutationValues: {}
            }
        }
        case FETCH_GENOTYPE_DATA_SUCCESS: {
            const { genotypeData } = action.payload;
            return {
                ...state,
                genotypeData,
                genotypeDataStatus: 'loaded',
            };
        }
  
        case FETCH_GENOTYPE_DATA_ERROR: {
            return {
                ...state,
                genotypeDataStatus: 'error',
            }
        }
        case RESET_GENOTYPE_STATUS: {
            return {
                ...state,
                genotypeData: {},
                genotypeDataStatus: 'none'
            }
        }
        case FETCH_MUT_POS_DICT_REQUEST: {
            return {
                ...state,
                mutationsPositionsDictStatus: 'loading'
            }
        }
        case FETCH_MUT_POS_DICT_SUCCESS: {
            const { mutPositions } = action.payload;
            const genesList = Object.keys(mutPositions);
            const genotypeFilterGenesList = genesList
                .map(e => ({ label: GENES_LABELS[e] || e, val: e, order: GENES_ORDER[e] || GENES_ORDER.default }))
                .sort((a, b) => {
                    if (a.order === b.order) {
                        return a.label.localeCompare(b.label);
                    }
                    return a.order > b.order;
                });

            return {
                ...state,
                genotypeFilterGenesList,
                mutPositions,
                mutationsPositionsDictStatus: 'loaded'
            };

        }
        case FETCH_MUT_POS_DICT_ERROR: {
            return {
                ...state,
                mutationsPositionsDictStatus: 'error'
            }
        }

        case FETCH_MUTATION_GROUP_REQUEST: 
        {
            return {
                ...state,
                mutationGroupValues: {},
                mutationGroupValuesStatus: 'loading'
            }
        }

        case FETCH_MUTATION_GROUP_SUCCESS:
        // case INIT_STRAIN_TREE_SUCCESS: 
        {
            const { mutationGroupValues } = action.payload;
            return {
                ...state,
                mutationGroupValues,
                mutationGroupValuesStatus: 'loaded'
            }
        }

        case FETCH_MUTATION_GROUP_ERROR: {
            return {
                ...state,
                mutationGroupValuesStatus: 'error'
            }
        }

        case ZOOM_TREE_REQUEST: 
        case FETCH_SUBSET_TREE_REQUEST:
            // case FETCH_TREE_FREQS_REQUEST:
        case FETCH_RECALCULATED_TREE_REQUEST:
        // case FETCH_VISIBLE_NODES_REQUEST:
            {
            return {
                ...state,
                mutationGroupValues: {},
                mutationGroupValuesStatus: 'none'
            }
        }


        default:
            return state || genotypeInitialState;
    }
}
