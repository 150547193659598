import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { StyledTextField } from '../../assets/GlobalStyles/TextField';


import { selectNodeData } from '../../redux/actions/nodeActions';
import { setReferenceClade, setReferenceStrain } from '../../redux/actions/parametersActions';
import { setSearchStrainMode } from '../../redux/actions/renderActions';
import { getStrainTreeStatus } from '../../redux/selectors/statusSelector';
import {
    fetchVaccineCandidates,
    fetchStrainsList,
    setStrainsList,
} from '../../redux/actions/treeDataActions';
import { emptyObject, shouldFetch } from '../../functions/functions';
import {
    getLabeledVaccineCandidates,
    getLabeledStrainsListWithVaccineCandidates,
    getIgnoreStrainCutOffDateForColorBy,
} from '../../redux/selectors/metadataSelector';
import { getRawAntigenicReferenceStrains, getLabeledEpitopeCladesStrains } from '../../redux/selectors/antigenicDataSelector';
import { treeAttrsSelector, treeOrderDictSelector } from '../../redux/selectors/treeDataSelector';

const styles = (theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'left',
      padding: '0px 0px',
      lineHeight: 1.2,
      width: '100%',
    },
    formControl: {
      minWidth: 120,
      lineHeight: 1.2,
      margin: '8px 0px',
    },
    field: {
      width: '100%',
      lineHeight: 1.2,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    listItem: {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      padding: theme.spacing(1),
      lineHeight: 1.2,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.grey[300],
      },
    },
    oddItem: {
      backgroundColor: theme.palette.grey[200],
    },
    selectedItem: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main, // Keep the same background color on hover
      },
    },
  });
  

const useStyles = makeStyles(styles);

const isNullRefStrain = (refStrain) => `${refStrain || ''}`.length === 0;

const getDifferentProps = (props, prevProps) => {
    const p1 = props;
    const p2 = prevProps;
    return Object.keys(p1).reduce((acc, k) => {
        if (p1[k] !== p2[k]) acc[k] = { act: p1[k], prev: p2[k] };
        return acc;
    }, {});

}

const TreeAntigenicStrainSearch = (props) => {
  
    const [error, setError] = useState(false);
    const [errorTxt, setErrorTxt] = useState(null);

    const txt = useRef();
    const currentPattern = useRef('');
    // const _element = useRef();
    // let _isMounted = false;
    const classes = useStyles();
    //lastChosenId = null;
    //lastSelectedStrain = null;

    // const allProps = {...props, error, errorTxt};
    // const prevProps = useRef(allProps);
    // console.log('DIFF', getDifferentProps(allProps, prevProps.current));
    // prevProps.current = allProps;
    // let fetchingStrainData = false;

    const {
        lineage,
        colorBy,
        vaccineCandidatesStatus,
        antigenicDataType,
        fetchVaccineCandidates,
        strainSearchStatus,
        refStrain,
        refClade,

        zoomNodeId,
        ignoreStrainCutOffDate,
        strainTreeLoading,
        loading,
        searchId,
        label,
        multiple,
        treeAttrs, clades, cladesStatus, selectedRhos,
        initStrainsList,
        filteredStrainsList,
        setReferenceStrain,
        setReferenceClade,
        fetchStrainsList,
        // fetchAntigenicReferenceStrain,
        // fetchVisibleNodes,
        setStrainsList,
        // setParameters,

    } = props;



    useEffect(() => {
        if (shouldFetch(vaccineCandidatesStatus) && !strainTreeLoading) {
            fetchVaccineCandidates({ lineage });
        }
    });


    useEffect(() => {
        // console.log('strainSearchStatus = ', strainSearchStatus)
        if (strainSearchStatus && strainSearchStatus !== 'loading' && strainSearchStatus !== 'none')
            showAntigenicSelectedStrain(refStrain);
    }, [strainSearchStatus])

    const fetchStrains = (lineage, strainsTxt) => {
        const _strainsTxt = strainsTxt ? strainsTxt.toUpperCase() : strainsTxt;
        //console.log(`[fetchStrains]: strainsTxt = ${_strainsTxt}`);
        if (
            !emptyObject(strainsTxt) &&
            antigenicDataType !== 'epitope_clades' &&
            antigenicDataType !== 'raw_strain' &&
            antigenicDataType !== 'observed_strain' &&
            (_strainsTxt.length === 3 ||
                (_strainsTxt.length > 3 && (!_strainsTxt.match(currentPattern.current) || currentPattern.current === '')))
        ) {
            currentPattern.current = _strainsTxt;
            fetchStrainsList({ lineage, strainsTxt: _strainsTxt, searchId });
        }
    };

    const clearStrainsList = () => {
        setStrainsList({ strainsList: null, searchId: searchId });
    };

    // componentWillUnmount() {
    //     _isMounted = false;
    // }

    const handleStrainInputChange = (event, value, reason) => {

        // console.log(`[handleStrainInputChange]: reason = ${reason}, value = ${value}, antigenicDataType = ${antigenicDataType}`);
        txt.current = value;
        try {
            if (!value.match(currentPattern.current)) currentPattern.current = '';
        } catch (err) {
            currentPattern.current = '';
        }
        if (reason === 'input' || reason === 'clear') {
            if (reason === 'clear' || !value) {
                clearStrainsList();
                setError(false);
                setErrorTxt(null);
            }
            fetchStrains(lineage, value);
        }
    };

    const showAntigenicSelectedStrain = (id) => {

        //console.log('[showAntigenicSelectedStrain], antigenicDataType = ', antigenicDataType, id, treeAttrs[id])
        if (loading || !id) return;
        if (antigenicDataType === 'observed' || antigenicDataType === 'inferred') {
            // console.log('[showAntigenicSelectedStrain] id = ', id, treeAttrs[id]);
            if (!emptyObject(id) && !emptyObject(treeAttrs[id])) {
                const alphaCladeId = clades[treeAttrs[id].clade].cladeMapping['antigenic_clade'].alpha;
                // const selectedRhosDict = selectedRhos.reduce((acc, rho) => { acc[rho] = true; return acc }, {});
                // while (alphaCladeId && !selectedRhosDict[alphaCladeId] && clades[alphaCladeId].p) {
                //     alphaCladeId = clades[alphaCladeId].p
                // }
                //console.log(`[showAntigenicSelectedStrain]: alphaCladeId = ${alphaCladeId}, name = ${treeAttrs[id].name}`)
                const alpha = cladesStatus === 'loaded' && !emptyObject(clades) && alphaCladeId ? clades[alphaCladeId] : null;
                if (alpha) {
                    setReferenceClade(alphaCladeId);
                    setError(false);
                    setErrorTxt(null);
                    return;
                }

                setReferenceClade(null);
                setError(true);
                setErrorTxt(`No such alpha clade: ${alphaCladeId}`);
                return;
            }

            setReferenceClade(null);
            setError(true);
            setErrorTxt('No data for strain');
        }
    };

    const handleSelectedStrain = async (_, selectedStrains) => {
        // console.log('[handleSelectedStrain]');
        if (loading) return;
        // console.log('[handleSelectedStrains]', selectedStrains)
        if (!selectedStrains || selectedStrains.length === 0) {
            // console.log('[handleSelectedStrain] reset');
            setReferenceStrain(null, searchId);
            setReferenceClade(null);

            //setStrainSearchStatus({ strainSearchStatus: 'none', searchId });
            return;
        }
        const ids = multiple
            ? selectedStrains.map((selectedStrain) => selectedStrain.id)
            : selectedStrains.id;

        const refStrain = ids.length === 0 ? null : multiple ? ids.join(',') : ids;

        setReferenceStrain(refStrain, searchId);
    };



    const strainsList =
        ((!refStrain || `${refStrain}`.length === 0) && (!txt.current || txt.current.length === 0)) ||
            antigenicDataType === 'raw_strain' ||
            antigenicDataType === 'epitope_clades' ||
            antigenicDataType === 'observed_strain'
            ? initStrainsList
            : (filteredStrainsList && filteredStrainsList.length ? filteredStrainsList : initStrainsList);


    const strainsListDict = strainsList.reduce((acc, strain) => {
        acc[strain.id] = strain;
        return acc;
    }, {});

    const strainOption =
        !refStrain || `${refStrain}`.length === 0
            ? multiple
                ? []
                : null
            : multiple
                ? `${refStrain || ''}`
                    .split(',')
                    .filter((rs) => strainsListDict[rs])
                    .map((rs) => ({ id: rs, n: strainsListDict[rs].n }))
                : strainsListDict[refStrain] || (treeAttrs[refStrain] && treeAttrs[refStrain].name)
                    ? { id: refStrain, n: (strainsListDict[refStrain] || {}).n || treeAttrs[refStrain].name }
                    : null;
    // console.log({ filteredStrainsList, initStrainsList, strainsList, refStrain, txt, strainOption }, 'strainOption', strainOption);
    const refCladeName = refClade && (antigenicDataType === 'observed' || antigenicDataType === 'inferred')
        ? `Clade: ${clades[refClade].label}`
        : null;
    const helperText = error
        ? errorTxt
        : refCladeName;

    return (
        <div className={classes.root}>
<Autocomplete
  multiple={multiple}
  id={`${searchId}Search`}
  className={classes.field}
  options={strainsList}
  isOptionEqualToValue={(option, value) => strainOption && value && option && option.id === value.id}
  getOptionLabel={(option) => option.n}
  loading={loading}
  autoSelect={true}
  value={strainOption}
  onInputChange={handleStrainInputChange}
  onChange={handleSelectedStrain}
  renderOption={(props, option, { selected }) => {
    const index = strainsList.findIndex(item => item.id === option.id);
    return (
      <li
        {...props}
        className={`${classes.listItem} ${selected ? classes.selectedItem : ''} ${index % 2 !== 0 ? classes.oddItem : ''}`}
      >
        {option.n}
      </li>
    );
  }}
  renderInput={(params) => (
    <StyledTextField
      {...params}
      label={label}
      id={`${searchId}input`}
      fullWidth
      multiline
      InputProps={{
        ...params.InputProps,
        sx: {
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          '& .MuiAutocomplete-tag': {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            display: 'flex',
            lineHeight: 1.2,
            alignItems: 'center',
            padding: '24px 8px', // Added padding top and bottom
            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Ensure background color on hover
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)', // Maintain background color on hover
            },
          },
          '& .MuiChip-root': {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            maxWidth: '100%',
            display: 'flex',
            lineHeight: 1.2,
            alignItems: 'center',
            padding: '24px 8px', // Added padding top and bottom
            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Ensure background color on hover
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)', // Maintain background color on hover
            },
          },
          '& .MuiChip-label': {
            whiteSpace: 'normal',
            lineHeight: 1.2,
            wordBreak: 'break-word',
            flex: 1,
          },
          '& .MuiChip-deleteIcon': {
            marginLeft: '4px',
          },
        },
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      style={{ marginTop: '8px' }}
      helperText={helperText}
      error={error}
    />
  )}
/>


            {/* {error && <div style={{ color: 'red' }}>{errorTxt}</div>} */}
        </div>
    );
    //};
}

TreeAntigenicStrainSearch.propTypes = {
    searchId: PropTypes.string.isRequired,
    lineage: PropTypes.string,
    strainSearchStatus: PropTypes.string,
    // treeAttrs: PropTypes.shape({ id: PropTypes.string }),
    fetchStrains: PropTypes.func,
    setReferenceClade: PropTypes.func,
    setReferenceStrain: PropTypes.func,
    selectNodeData: PropTypes.func,
    classes: PropTypes.shape({ root: PropTypes.string, field: PropTypes.string }),
    label: PropTypes.string,
    treeOrderDict: PropTypes.shape({}),
    // fetchAntigenicReferenceStrain: PropTypes.func,
    fetchVaccineCandidates: PropTypes.func,
    setSearchStrainMode: PropTypes.func,
};


const mapStateToProps = (state, ownProps) => {

    const treeAttrs = treeAttrsSelector(state);
    //console.log(treeAttrs[state.parameters.refStrain])

    return ({
        treeAttrs, //: state.treeData.treeAttrs, //getTreeNodeAttrs(state), //state.treeData.treeAttrs,
        filteredStrainsList: getLabeledStrainsListWithVaccineCandidates(state)[ownProps.searchId], //.treeData.strainsLists[ownProps.searchId],
        initStrainsList:
            state.parameters.antigenicDataType === 'epitope_clades'
                ? getLabeledEpitopeCladesStrains(state)
                : (state.parameters.antigenicDataType === 'raw_strain' || state.parameters.antigenicDataType === 'observed_strain')
                    ? getRawAntigenicReferenceStrains(state)
                    : getLabeledVaccineCandidates(state),

        searchedStrainList: state.treeData.strainsLists[ownProps.searchId],
        treeOrderDict: treeOrderDictSelector(state),
        strainSearchStatus: state.treeData.strainSearchStatuses && state.treeData.strainSearchStatuses[ownProps.searchId],
        cladesStatus: state.cladeData.cladesStatus,
        clades: state.cladeData.clades,
        lineage: state.parameters.lineage,
        colorBy: state.parameters.colorBy,
        modelId: state.parameters.modelId,
        colorBy: state.parameters.colorBy,
        zoomNodeId: state.parameters.zoomNodeId,
        vaccineCandidates: state.metadata.vaccineCandidates,
        vaccineCandidatesStatus: state.metadata.vaccineCandidatesStatus,
        antigenicObservedRefStrains: state.antigenic.antigenicObservedRefStrains,
        antigenicDataType: state.parameters.antigenicDataType,
        ignoreStrainCutOffDate: getIgnoreStrainCutOffDateForColorBy(state),
        refStrain: state.parameters.refStrain,
        strainsListStatus: state.treeData.strainsListStatuses[ownProps.searchId] || 'nodata',
        strainTreeLoading: getStrainTreeStatus(state),
        loading:
            state.metadata.vaccineCandidatesStatus === 'loading' ||
            (state.treeData.strainsListStatuses && state.treeData.strainsListStatuses[ownProps.searchId] === 'loading') ||
            state.treeData.strainSearchStatuses[ownProps.searchId] === 'loading',
        selectedRhos: state.antigenic.antigenicClades.selectedRhos,
        refClade: state.parameters.refClade
    })
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            selectNodeData,
            setReferenceClade,
            setReferenceStrain,
            setSearchStrainMode,
            // fetchAntigenicReferenceStrain,
            fetchVaccineCandidates,
            //setStrainSearchStatus,
            fetchStrainsList,
            setStrainsList,
            // fetchVisibleNodes,
            // setParameters,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(TreeAntigenicStrainSearch);
