import config from '../config/envConfig';

// const auth = {
//     isAuthenticated() {
//         if (typeof window === 'undefined') return false;
//         if (sessionStorage.getItem(`jwt${config.frontendPrefix}`)) {
//             return true;
//         }
//         return false;
//     },
//     authenticationOn() {
//         return true
//     },
//     authenticate(jwt, cb) {
//         if (typeof window !== 'undefined') sessionStorage.setItem(`jwt${config.frontendPrefix}`, JSON.stringify(jwt));
//         cb();

//     },
//     signout(cb) {
//         if (typeof window !== 'undefined') sessionStorage.removeItem(`jwt${config.frontendPrefix}`);
//         if (cb) cb();
      
//     },
// };

// export default auth;


// Utility functions for cookie manipulation
function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";

    // console.log('[setCookie]', document.cookie, name + "=" + (value || "") + expires + "; path=/");
}

function getCookie(name) {
    // console.log('[getCookie]', 'cookie',document.cookie, 'cookies',document.cookies);
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const auth = {

    setInstanceId(INSTANCE_ID) {
        sessionStorage.setItem(`t_cookie`, INSTANCE_ID);
    },

    getCookieName() {
        return sessionStorage.getItem('t_cookie');
    },

    isAuthenticated() {
        
        if (typeof window === 'undefined') return false;

        const cookieName = this.getCookieName();
        // Check if the cookie named 't' exists
        // if (getCookie('t')) {
        if (getCookie(cookieName)){        
            return true;
        }
        return false;
    },
    authenticate(jwt, cb) {
        if (typeof window !== 'undefined') {
            const cookieName = this.getCookieName();
            // Store JWT in the cookie named 't'
            // setCookie('t', JSON.stringify(jwt), 1); // Expires in 1 day, adjust as needed
            setCookie(cookieName, JSON.stringify(jwt), 1); // Expires in 1 day, adjust as needed
            // sessionStorage.setItem(`t_cookie`, cookieName);
        }
        cb();
    },
    signout(cb) {
        console.log('[signout]');
        if (typeof window !== 'undefined') {
            const cookieName =  this.getCookieName();
            // Remove the cookie named 't'
            // eraseCookie('t');
            eraseCookie(cookieName);
        }
        if (cb) cb();
    },
};

export default auth;
