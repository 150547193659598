import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { styles } from './styles';
import { ExpandIcon } from '../../../../components/Alerts/styles';
import AddNewScaleDialog from '../Dialogs/AddNewScaleDialog';
import { postAxios } from '../../../../functions/axiosRequests';
import config from '../../../../config/envConfig';

const AddNewScale = ({ colorScales, scalesPalette, fetchAllScales, setInfoDialog, setInfo, setAction }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (type) => {
        setOpenDialog(false)
    };

    const addNewScale = (scale, domain) => {
        const url = `${config.serverLink}/api/admin/addNewScale`;
        const body = { scale, domain };

        postAxios(url, body)
            .then((response) => {
                setOpenDialog(false);
                fetchAllScales();
        });
    };

    return (
        <>
            <IconButton className={classes.addScale} onClick={handleClick}>
                <ExpandIcon color={'#0000FF'} />
            </IconButton>
            <AddNewScaleDialog colorScales={colorScales} scalesPalette={scalesPalette} handleCloseDialog={handleCloseDialog} openDialog={openDialog} addNewScale={addNewScale} />
        </>
    );
};

export default AddNewScale;
