import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { deleteStyles, dialog } from './deleteStyles';


const DeleteMutClassDialog = (props) => {
    const { handleCloseDialog, openDialog, deleteFunc, name} = props;
    const classes = deleteStyles();

    const handleSubmit = () => {
        deleteFunc();
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={dialog}>
            <DialogTitle id="confirm-dialog-title">
                Confirmation
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Please confirm deleting mutation class<br/>{name}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>);
};

export default DeleteMutClassDialog;