import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../../../config/envConfig';
import { postAxios } from '../../../../functions/axiosRequests';
import { styles } from './styles';

const DeleteMeasure = ({measure, setInfoDialog, setAction, setInfo, updateTable}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const classes = styles();

    const handleClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const deleteMeasure = () => {
        const url = `${config.serverLink}/api/admin/deleteMeasure`;
        const body = { measure: measure.measure, lineage: measure?.lineage }

        postAxios(url, body)
            .then((response) => {
                const status = response.data.status;
                setAction('Delete measure');

                if (status === 'default measure'){
                    handleCloseDialog();
                    setInfo(`Measure: ${measure.measure} is an application default measure that cannot be deleted.`);
                    setInfoDialog(true);
                } else if (status === 'done'){
                    handleCloseDialog();
                    setInfo(`Measure ${measure.measure} deleted sucesfully.`);
                    setInfoDialog(true);
                    updateTable();
                } else {
                    handleCloseDialog();
                    setInfo(`An error occured during deleting ${measure.measure}.`);
                    setInfoDialog(true);
                }
            });
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog handleCloseDialog={handleCloseDialog} openDialog={openDialog} deleteFunc={deleteMeasure} name={measure.name} scale={false}/>
        </>

    );
};

export default DeleteMeasure;
