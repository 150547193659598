/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { PropTypes } from 'prop-types';

import { getLegendSelectedNodes, getVisibleNodes } from '../../../redux/selectors/treeDataSelector';
import { treeD3 } from '../d3/TreeD3';

import { getStrainTreeStatus, getAntigenicDistanceStatus } from '../../../redux/selectors/statusSelector';
import { RENDER_STATUS } from '../../../config/consts';
import { setComponentStatus } from '../../../redux/actions/renderActions';
import { bindActionCreators } from 'redux';


// import { printWhatChanged } from '../../../functions/utils';
// const viewToRender = 'strainTree';
// const epitopeMutationsGroups = 'cladeLabels';

const viewToRender = 'strainTree';
const componentId = 'selectedNodes';

const SelectedStrainsLayer = (props) => {
    const { 
        setComponentStatus,
        nodeId, 
        activeLegendOption, 
        searchStrainMode, 
        legendSelectedNodes, 
        loading, 
        renderStatus } = props;
    const _element = useRef();


    const startRender = async () => {
        // console.log('[SelectedStrainsLayer.startRender]');
        if (renderStatus !== RENDER_STATUS.START) setComponentStatus(viewToRender, componentId, RENDER_STATUS.START);
    };

    const stopRender = () => {
        // console.log('[SelectedStrainsLayer.stopRender]');
        setComponentStatus(viewToRender, componentId, RENDER_STATUS.DONE);
    };

    // const prevProps = {
    //     nodeId: usePrevious(props.nodeId),
    //     searchStrainMode: usePrevious(props.searchStrainMode),
    //     activeLegendOption: usePrevious(props.activeLegendOption),
    // };

    

    // console.log({renderStatus});
    useEffect(() => {
        if (loading) return;
        if (activeLegendOption) treeD3.selectNodes(legendSelectedNodes);
    }, [activeLegendOption]);

    useEffect(() => {
        if (loading) return;
        // console.log('[useEffect] nodeId, loading', { nodeId, loading, renderStatus });
        //    treeD3.drawSelectedStrainsLayer(_element.current, nodeId, searchStrainMode);

        startRender()
            .then(() => treeD3.drawSelectedStrainsLayer(_element.current, nodeId, searchStrainMode))
            .then(() => stopRender());
    }, [nodeId, loading]);


    useEffect(() => {
        if (renderStatus !== RENDER_STATUS.NONE) return;
        // if (renderStatus !== 'loading') return;
        // console.log('[useEffect] renderStatus', { nodeId, loading, renderStatus })
        startRender()
            .then(() => treeD3.drawSelectedStrainsLayer(_element.current, nodeId, searchStrainMode))
            .then(() => stopRender());
        // treeD3.drawSelectedStrainsLayer(_element.current, nodeId, searchStrainMode);
    }, [renderStatus]);


    return <g id="selectedNodes" transform={treeD3.translate(false)} ref={_element} />;
};

SelectedStrainsLayer.propTypes = {
    nodeId: PropTypes.number,
    layout: PropTypes.string,
    loading: PropTypes.bool,
    activeLegendOption: PropTypes.object,
    searchStrainMode: PropTypes.bool,
    legendSelectedNodes: PropTypes.arrayOf(PropTypes.string),
    renderStatus: PropTypes.string

};

const mapStateToProps = (state, ownProps) => {
    const loading = getStrainTreeStatus(state) || !ownProps.initialized || getAntigenicDistanceStatus(state);
    return {
        loading,
        // renderStatus: state.render.renderStatus,
        renderStatus:  state.render.viewToRender.components[componentId] || null,
        nodeId: state.nodeData.nodeId,
        visibleNodes: getVisibleNodes(state),
        activeLegendOption: state.nodeData.activeLegendOption,
        searchStrainMode: state.parameters.searchStrainMode,
        legendSelectedNodes: getLegendSelectedNodes(state),
    };
};
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        //         getLegendSelectedNodes
        setComponentStatus
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectedStrainsLayer);
