const isOpera =  false; //(!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!window.CSS;

const dateFormat = navigator.languages[0] === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
let isSafari;

if (navigator.userAgentData) {
    isSafari = navigator.userAgentData.brands.some(
      brand => brand.brand.toLowerCase() === 'safari'
    );
  } else {
    isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

export {
    isOpera,
    isFirefox,
    isIE,
    isEdge,
    isChrome,
    isSafari,
    isBlink,
    dateFormat
    
}
