import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper } from '@mui/material';
import { styles } from '../styles';
import { treeD3 } from '../../../components/Tree/d3/TreeD3';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { connect } from 'react-redux';

const TreeMenu = (props) => {
    const classes = styles();
    const { initHeight, initWidth } = props;
    const [width, setWidth] = useState(treeD3.width);
    const [height, setHeight] = useState(treeD3.width);

    useEffect(() => {
        setWidth(initWidth);
        setHeight(initHeight)
    }, [initWidth, initHeight]);

    const handleChangeWi =e => {
        setWidth(e.target.value);
    };

    const handleChangeHi = e => {
        setHeight(e.target.value);
    };

    const handleKeyPress = e => {
        if (e.key === 'Enter'){
            treeD3.setWidth(width);
            treeD3.setHeight(height);
            //treeD3.reinitXYScales();
            treeD3.initScales();
            treeD3.setGraphAreaSize();
            treeD3.translateGraphElements();
        }
    };

    const download = async () => {
        const selector = 'tree_svg';
        const svg = document.getElementById(selector);
        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svg);

        if (!source.match(/^<svg[^>]*?\sxmlns=(['"`])https?\:\/\/www\.w3\.org\/2000\/svg\1/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]*?\sxmlns:xlink=(['"`])http\:\/\/www\.w3\.org\/1999\/xlink\1/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);
        const element = document.createElement('a');

        element.href = url;
        element.download = `tree.svg`;
        document.body.appendChild(element);
        element.click();;
    }

    return (
        <Paper variant="outlined" square className={classes.menu} >
            <Grid container>
                <Grid item xs={6} className={classes.item}>
                    <StyledTextField
                        id="width"
                        type="number"
                        label="Width"
                        className={classes.textField}
                        value={width}
                        onChange={handleChangeWi}
                        onKeyPress={handleKeyPress}
                    />
                </Grid>
                <Grid item xs={6} className={classes.item}>
                    <StyledTextField
                        id="height"
                        type="number"
                        label="Height"
                        className={classes.textField}
                        value={height}
                        onChange={handleChangeHi}
                        onKeyPress={handleKeyPress}
                    />
                </Grid>
            </Grid>
            <Button className={classes.export} onClick={download}>
                Export
            </Button>
        </Paper>
    );
};

const mapStateToProps = () => {
    const initWidth = treeD3.width;
    const initHeight = treeD3.height;
    return {
        initWidth,
        initHeight
    };
};

export default connect(mapStateToProps, null)(TreeMenu);
