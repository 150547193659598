import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import { FormControl, Button, Typography, Grid, MenuItem, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { resetAntigenicHiddenClades } from '../../redux/actions/antigenicActions';
import { setMeasureScale, setParameters } from '../../redux/actions/parametersActions';
import appConfig from '../../config/appConfig';
import ModelSelector from '../ModelSelector/ModelSelector';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { isFirefox } from '../../functions/checkBrowser';
import ColorScaleSelector from '../OptionsSelector/ColorScaleSelector';
import { getComplexDomainParameterValues } from '../../redux/selectors/rangeDataSelector';
import AntigenicInfo from './AntigenicInfo';
import { HideIcon } from '../Alerts/styles';
import Alerts from '../Alerts/Alerts';
import ScalesLegend from '../ColorLegend/Legends/ScalesLegend';

const styles = theme => (
    {
        root: {
            height: '100%',
            overflowY: isFirefox ? 'auto' : 'overlay',
            flexWrap: 'wrap',
            textAlign: 'left',
            padding: '10px 15px 10px 15px',
            backgroundColor: '#fff',
            overflow: 'hidden'
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            margin: '8px 0px'
        },
        colFormControl: {
            // minWidth: 120,
            width: '49%',
            margin: '8px 4px 8px 0px',
            // padding: '1px'
        },
        lastColFormControl: {
            minWidth: 120,
            width: '50%',
            margin: '8px 0px 8px 0px',
            // padding: '1px'
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        },
        button: {
            // boxShadow: 'none'
            width: '80px',
            height: '32px',
            marginTop: '5px',
            marginBottom: '10px',
            backgroundColor: '#e0e0e0',
            color: '#000000',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#e0e0e0',
            }
        },
        deletednfo: {
            margin: '24px 0 8px'
        },
        hideButton: {
            padding: '0px',
            float: 'right'
        },
        buttonWrapper: {
            width: '100%',
            height: '30px'
        },
        formControlLabel: {
            fontSize: '13px',
            marginBottom: '5px',
            lineHeight: 1
        },
        checkbox: {
            padding: '0 4px 0 0',
            margin: 0,
            display: 'table-cell',
            verticalAlign: 'top'
        },
    });

const antigenicDataTypes = [
    // { id: 'observed_strain', label: 'Observed strain level' },
    { id: 'observed', label: 'Observed clade level' },
    { id: 'inferred', label: 'Inferred clade level' },

];

const AntigenicOptions = props => {

    const { antigenicDataType, antigenicTiterType, classes, hiddenAlphaCladesCnt, hiddenRhoCladesCnt, handleHideClick, permissions, showAntigenicTableValues, wrapAntigenicTableHeaders,
        measures, params, cellInfo,
        setParameters, setMeasureScale,
        resetAntigenicHiddenClades, undoDeleting } = props;

    const { alpha, rho, titer } = cellInfo || {}; 

    const handleChange = name => event => {

        const value = event.target.value;

        setParameters({ [name]: value /*, colorScale*/ });

        if (name === 'antigenicTiterType') {
            const colorScale = measures['antigenic'].scale[name][value];
            setMeasureScale({ measure: 'antigenic', scaleName: colorScale, parameters: { ...params, [name]: value } });
        }
    };

    const handleReset = () => {
        resetAntigenicHiddenClades();
    }

    const handleChangeNumbers = () => event => {
        const value = event.target.checked;
        setParameters({ showAntigenicTableValues: value })
    }

    const handleChangeWrap = () => event => {
        const value = event.target.checked;
        setParameters({ wrapAntigenicTableHeaders: value })
    }

    const handleUndo = () => {
        undoDeleting();
    }

    const _antigenicDataType = antigenicDataType;
    //const _antigenicTiterType = antigenicTiterType;

    // console.log(wrapAntigenicTableHeaders)
    return (
        <form className={classes.root} autoComplete="off">
            <div className={classes.buttonWrapper}>
                <IconButton className={classes.hideButton} onClick={handleHideClick}>
                    <HideIcon />
                </IconButton>
            </div>
            <Alerts module="antigenic" />
            {permissions.dataTypeSelector &&
                <FormControl className={classes.formControl} fullWidth>
                    <CustomLabel id={'antigenicDataType'} label={'Data'} />
                    <CustomSelect
                        value={_antigenicDataType}
                        onChange={handleChange('antigenicDataType')}
                        inputProps={{ name: 'antigenicDataType', id: 'antigenicDataType' }}
                    >
                        {antigenicDataTypes.map(({ id, label }) => (
                            <MenuItem key={id || '_'} value={id}>{label}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
            }
            {permissions.modelSelector && <ModelSelector fixedModelType="Antigenic" modelContext="antigenic" />}
            {permissions.titerTypeSelector &&
                <FormControl className={classes.formControl} fullWidth>
                    <CustomLabel id={'antigenicTiterType'} label={'Titer type'} />
                    <CustomSelect
                        value={antigenicTiterType}
                        onChange={handleChange('antigenicTiterType')}
                        inputProps={{ name: 'antigenicTiterType', id: 'antigenicTiterType' }}
                    >
                        {appConfig.titerTypes.map(({ id, label }) => (
                            <MenuItem key={id || '_'} value={id}>{label}</MenuItem>
                        ))}
                    </CustomSelect>
                </FormControl>
            }

            <FormControlLabel
                className={classes.formControl}
                value="showAntigenicNumbers"
                label={<Typography className={classes.formControlLabel}>Show values</Typography>}
                control={
                    (
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={showAntigenicTableValues}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                            onChange={handleChangeNumbers()}
                            className={classes.checkbox}
                            id="showAntigenicNumbers"
                        />
                    )
                }
            />
            <FormControlLabel
                className={classes.formControl}
                value="wrapAntigenicTableHeaders"
                label={<Typography className={classes.formControlLabel}>Wrap labels</Typography>}
                control={
                    (
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={wrapAntigenicTableHeaders}
                            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                            onChange={handleChangeWrap()}
                            className={classes.checkbox}
                            id="wrapAntigenicTableHeaders"
                        />
                    )
                }
            />

            <Typography style={{ fontSize: '15px', fontFamily: 'Inter' }}>
                DELETED: {hiddenAlphaCladesCnt} Rows / {hiddenRhoCladesCnt} Columns
            </Typography>

            <Grid container >
                <Grid item xs={12} style={{ marginTop: '5px' }}>
                    <Button style={{ marginRight: '10px' }} className={classes.button} onClick={handleReset}>
                        Reset
                    </Button>
                    <Button className={classes.button} onClick={handleUndo}>
                        Undo
                    </Button>
                </Grid>
            </Grid>

            <ColorScaleSelector colorBy='antigenic' />
            <ScalesLegend
                scaleId="antigenic"
                measureName="antigenic"
                // valueGetter={(state) => state.antigenic.titer}
                valueGetter={() => titer} 
                ticks={3}
            />
            <ScalesLegend
                scaleId="alphaY"
                measureName="alphaY"
                // valueGetter={(state) => state.antigenic.alphaY}
                valueGetter={({antigenic}) => alpha ? antigenic.antigenicClades?.alphas[alpha] : null} 
               
                ticks={3}
                title="Clade frequency"
            />
            <ScalesLegend
                scaleId="rhoR"
                measureName="rhoR"
                valueGetter={({antigenic}) => rho ? antigenic.antigenicClades?.rhos[rho] : null} //state.antigenic.rhoR}
                ticks={3}
                title="Immune cohort weight"
            />

            <AntigenicInfo cellInfo={cellInfo}/>
        </form>
    )
}

AntigenicOptions.propTypes = {
    antigenicDataType: PropTypes.string,
    antigenicTiterType: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string,
        button: PropTypes.string,
        formControl: PropTypes.string,
        buttonWrapper: PropTypes.string,
        hideButton: PropTypes.string,
        formControlLabel: PropTypes.string,
        checkbox: PropTypes.string,
    }),
    measures: PropTypes.shape({ label: PropTypes.string, antigenic: PropTypes.object }),
    hiddenAlphaCladesCnt: PropTypes.number,
    hiddenRhoCladesCnt: PropTypes.number,
    params: PropTypes.object,
    permissions: PropTypes.object,
    setParameters: PropTypes.func,
    resetAntigenicHiddenClades: PropTypes.func,
    setMeasureScale: PropTypes.func,
    undoDeleting: PropTypes.func,
    handleHideClick: PropTypes.func,
    showAntigenicTableValues: PropTypes.bool,
    wrapAntigenicTableHeaders: PropTypes.bool
};

const mapStateToProps = (state) => ({
    antigenicDataType: state.parameters.antigenicDataType,
    antigenicTiterType: state.parameters.antigenicTiterType,
    hiddenAlphaCladesCnt: Object.keys(state.parameters.hiddenAlphaClades).length,
    hiddenRhoCladesCnt: Object.keys(state.parameters.hiddenRhoClades).length,
    showAntigenicTableValues: state.parameters.showAntigenicTableValues,
    permissions: state.user.permissions.antigenicPermissions,
    measures: state.metadata.measures,
    params: getComplexDomainParameterValues(state),
    wrapAntigenicTableHeaders: state.parameters.wrapAntigenicTableHeaders
    // antigenicModelStatus: antigenic.antigenicData.antigenicModelStatus,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters,
    setMeasureScale,
    resetAntigenicHiddenClades
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AntigenicOptions));
