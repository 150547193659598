// Action Types
import {
    INIT_STRAIN_TREE_REQUEST,
    INIT_STRAIN_TREE_SUCCESS,
    INIT_STRAIN_TREE_ERROR,
    RESET_SESSION
} from './actionTypes';
// Base Actions
import { received } from '../baseActions';


const initStrainTree = (parameters) => received(INIT_STRAIN_TREE_REQUEST, parameters)
const initStrainTreeSuccess = (parameters) => received(INIT_STRAIN_TREE_SUCCESS, parameters)
const initStrainTreeError = (parameters) => received(INIT_STRAIN_TREE_ERROR, parameters)

const resetSession = ({ lineage }) => received(RESET_SESSION, { lineage });

export { initStrainTree, initStrainTreeSuccess, initStrainTreeError, resetSession };
