import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    textField: {
        width: '100px',
        paddingRight: '20px'
    },
    card: {
        margin: 0,
        paddingTop: 0
    },
    button: {
        paddingTop: '35px'
    }
}));
