import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import { Grid } from '@mui/material';
import config from '../../../config/envConfig';
import { fetchAxios } from '../../../functions/axiosRequests';
import MeasuresTable from './MeasuresTable';
import { emptyObject } from '../../../functions/functions';

const MeasuresPanel = () => {
    const [ allMeasures, setAllMeasures] = useState({});
    const classes = styles();

    useEffect(() => {
        fetchMeasures();
    }, []);

    const fetchMeasures = () => {
        const url = `${config.serverLink}/api/admin/getAllMeasures`;

        fetchAxios(url,)
            .then((response) => {
                setAllMeasures(response.data.measures)
        });
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.item}>
                { !emptyObject(allMeasures) &&
                    <MeasuresTable allMeasures={allMeasures} fetchMeasures={fetchMeasures}/>
                }
            </Grid>
        </Grid>
    )
}

export default MeasuresPanel;
