/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleMutationClassLabelVisibility } from '../../redux/actions/parametersActions';

const LabelFlagIcon = (props) => {
    const { mutclass, toggleMutationClassLabelVisibility, visibleMutationClassesLabels, disabled, intro } = props;
    const selected = visibleMutationClassesLabels[mutclass] || false;

    const handleClick = () => {
        if (disabled || intro) return;

        toggleMutationClassLabelVisibility(mutclass);
    };

    const color = selected ? '#6F6CFF' : '#000000';
    return (
        <div onClick={handleClick}>
            <SvgIcon
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
                style={{ width: 9, height: 10 }}
            >
                {selected && <path d="M8 1H1V6.14286H8V1Z" fill="#6F6CFF" />}
                <path d="M1 10V6.14286M1 6.14286V1H8V6.14286H1Z" stroke={color} fill="none" />
            </SvgIcon>
        </div>
    );
};

LabelFlagIcon.propTypes = {
    selectedMutationGroupsDict: PropTypes.shape({}),
    toggleMutationClassLabelVisibility: PropTypes.func
};

const mapStateToProps = ({ parameters }) => (
{
    visibleMutationClassesLabels : parameters.visibleMutationClassesLabels,
    intro: parameters.intro
}
);

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleMutationClassLabelVisibility,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(LabelFlagIcon);
