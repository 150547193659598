import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setAntigenicHiddenAlphaClade,
    setAntigenicHiddenRhoClade,
} from '../../redux/actions/antigenicActions';
import { antigenicFitnessModelStatusSelector } from '../../redux/selectors/statusSelector';
import { getAntigenicFitnessMatrixData, selectedCladesSelector } from '../../redux/selectors/antigenicDataSelector';
import { getRanges } from '../../redux/selectors/rangeDataSelector';
import { setRenderStatus } from '../../redux/actions/renderActions';
import { emptyObject, isNull } from '../../functions/functions';
import { RENDER_STATUS } from '../../config/consts';
import { antigenicFitnessStyles } from './styles/antigenicFitnessStyles';
import MetaInformations from '../MetaInformations/MetaInformations';
import Matrix from '../Matrix/Matrix';

const useStyles = makeStyles(antigenicFitnessStyles);

const getClasses = (exportMode, classes) => {
   
    const exportPropsMap = Object.keys(classes)
        .filter((key) => key.match('Export$'))
        .reduce((tmp, key) => {
            tmp[key] = key;
            return tmp;
        }, {});
    const regProps = Object.keys(classes)
        .filter((key) => !key.match('Export$'))
        .map((key) => ({ key, exportKey: exportPropsMap[`${key}Export`] }));
    return regProps.reduce((tmpProps, prop) => {
        tmpProps[prop.key] =
            exportMode && exportPropsMap[prop.exportKey] ? classes[prop.exportKey] : classes[prop.key];
        return tmpProps;
    }, {});
}


const FitnessData = (props) => {

    const _element = useRef();
    const { rows, columns, data, clades, dataInitialized, exportMode, setRenderStatus, loading } = props;

    useEffect(() => {
        if (dataInitialized) setRenderStatus(RENDER_STATUS.DONE);
    })

  

    const classes = getClasses(props.exportMode, useStyles());
   
    if (emptyObject(data) && !loading)
        return <div className={classes.root}>No data for this combination of parameters</div>;
    if (emptyObject(clades) || !columns || !rows) {
        return <div />;
    }
  
    return (
        <div className={`${classes.rootData} ${exportMode ? classes.rootEx : ''}`} ref={_element} width="100%" height="100%">
            <Matrix {...props}/>
            <div />
            <MetaInformations />
        </div>
    );
}


FitnessData.propTypes = {
    exportMode: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const loading = antigenicFitnessModelStatusSelector(state);
    // const { detailsOpen } = state.antigenic;
    const { hiddenAlphaClades, hiddenRhoClades, exportMode, showAntigenicTableValues, wrapAntigenicTableHeaders } = state.parameters;
    const { alphas, rhos } = selectedCladesSelector(state);
    const data = getAntigenicFitnessMatrixData(state); 
    const ranges = getRanges(state);
    const { clades } = state.cladeData;

    // const model = state.fitness.fitnessModel;
    // console.log(data);
    // console.log(state.fitness.fitnessModel);
    // console.log(alphas?.map(cr => ({ 
    //     clade: cr, 
    //     label: clades[cr].label, 
    //     row: model[cr], 
    //     columns: rhos?.map(cc => ({
    //         clade: cc, 
    //         label: clades[cc].label, 
    //         val: model?.[cr]?.[cc]
    //     }))
    // })));

   
    return {
        componentId: 'antigenicFitness', 
        data,
        dataScale: 'antigenicFitnessColorScale',
        // lastColumnLabel: 'CLADE FREQUENCY',
        // lastColumnScale: 'alphaYColorScale',
        // lastColumnValues: alphasY,
        // lastRowLabel: 'IMMUNE COHORT WEIGHT',
        // lastRowScale: 'rhoRColorScale',
        // lastRowValues: rhosR,
        rows: alphas,
        columns: rhos,
        clades,
        hiddenRows: hiddenAlphaClades,
        hiddenColumns: hiddenRhoClades,
        ranges,
        loading,
        exportMode,
        // detailsOpen,
        showValues: showAntigenicTableValues,
        wrapTableHeaders: wrapAntigenicTableHeaders
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            // selectAntigenicValues,
            setAntigenicHiddenAlphaClade,
            setAntigenicHiddenRhoClade,
            setRenderStatus,
            // toggleAntigenicDetails,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(FitnessData);
