import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider , DesktopDatePicker } from '@mui/x-date-pickers';
import { setParameters } from '../../redux/actions/parametersActions';
import { styles } from '../Tree/styles'
import { dateFormat } from '../../functions/checkBrowser';
import { isValidDate } from '../../functions/functions';
import { HideIcon } from '../Alerts/styles'
import { submissionDateSelector } from '../../redux/selectors/parametersSelector';

const SubmissionDatePicker = (props) => {
    const { submissionDate, setParameters } = props;
    const [submissionDateHolder, setSubmissionDateHolder] = useState(submissionDate);

    // useEffect(() => {
    //     if (submissionDate !== null)
    //         setSubmissionDateHolder(submissionDate);
    // }, [submissionDate]);

    const handleSubmissionDateChange = submissionDateEvent => {
        
        if (!submissionDateEvent){
            setParameters({ submissionDate: null});
            setSubmissionDateHolder(null);
        }

        if (submissionDateEvent && !isNaN(submissionDateEvent.getTime()) && submissionDateEvent !== submissionDate)
            setSubmissionDateHolder(submissionDateEvent);
    };

    const handleAccept = submissionDateEvent => {
        setSubmissionDateHolder(submissionDateEvent);
        setParameters({ submissionDate: submissionDateEvent });
    }

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
    
            const sDate = event.target.value;
            if (isValidDate(sDate)) {
                const _submissionDate = new Date(sDate)
                setParameters({ submissionDate: _submissionDate});
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                clearable
                variant="inline"
                format={dateFormat}
                allowSameDateSelection
                id="submissionDate"
                label="Submitted before"
                value={submissionDateHolder}
                onChange={handleSubmissionDateChange}
                onAccept={handleAccept}
                sx={{
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                        letterSpacing: '-0.5px'
                    }
                }}
                slotProps={{
                    textField: { 
                        variant: 'standard',
                        onKeyDown: handleKeyPress,
                        InputProps: {
                            endAdornment: (
                              <IconButton style={{padding: 0}} onClick={() => handleSubmissionDateChange(null)}>
                                <HideIcon />
                              </IconButton>
                            )
                        },
                        style: { 
                            margin: 0
                        }
                    },
                    inputAdornment: { position: 'start'},
                }}
                />
        </LocalizationProvider>
    );
}


const mapStateToProps = (state) => ({
    submissionDate: submissionDateSelector(state)
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubmissionDatePicker));
