// Action Types
import {
    FETCH_MODELS_REQUEST,
    FETCH_MODELS_SUCCESS,
    FETCH_MODELS_ERROR,
    FETCH_MODEL_DATA_REQUEST,
    FETCH_MODEL_DATA_ERROR,
    FETCH_MODEL_DATA_SUCCESS,
    FETCH_MODEL_TYPES_REQUEST,
    FETCH_MODEL_TYPES_SUCCESS,
    FETCH_MODEL_TYPES_ERROR,
    RESET_MODEL_DATA,
    RESET_MODEL_TYPES
} from './actionTypes';

// Base Actions
import { received, request } from '../baseActions';

export const fetchModels = (payload) => received(FETCH_MODELS_REQUEST, payload); 
export const fetchModelsSuccess = (res) => received(FETCH_MODELS_SUCCESS, res);
export const fetchModelsError = (error) => received(FETCH_MODELS_ERROR, error);

export const fetchModel = (payload) => received(FETCH_MODEL_DATA_REQUEST, payload);
export const fetchModelSuccess = (res) => received(FETCH_MODEL_DATA_SUCCESS, res);
export const fetchModelError = (error) => received(FETCH_MODEL_DATA_ERROR, error);

export const fetchModelTypes = (payload) => received(FETCH_MODEL_TYPES_REQUEST, payload);
export const fetchModelTypesSuccess = (res) => received(FETCH_MODEL_TYPES_SUCCESS, res);
export const fetchModelTypesError = (error) => received(FETCH_MODEL_TYPES_ERROR, error);

export const resetModelData = (payload) => received(RESET_MODEL_DATA, payload);
export const resetModelTypes = () => request(RESET_MODEL_TYPES);

