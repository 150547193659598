import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const styles = makeStyles(theme =>({
    textField: {
        width: 350,
        display: 'block',
        backgroundColor: 'white !important',
        '& > div': {
            width: '100%',
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        }
    },
    autoComplete: {
        width: 350,
        margin: '15px 0px 8px 0px'
    },
    formControl: {
        width: 350,
        margin: '15px 0px 8px 0px'
    },
    submit: {
        minWidth: 120,
        backgroundColor: '#e0e0e0',
        color: '#000000',
        float: 'right',
        marginTop: '20px',
        marginRight: '10%',
        marginBottom: '15px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    confirm: {
        backgroundColor: '#6F6CFF',
        color: 'white',
        marginBottom: '16px',
        textTransform: 'none',
        marginRight: '16px',
        '&:hover': {
            backgroundColor: '#6F6CFF',
            color: 'white',
        },
    },
    cancel: {
        marginRight: '6px',
        marginBottom: '16px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    addUser: {
        marginLeft: '10px'
    },
    search: {
        width: 350,
        marginLeft: '15px'
    },
    clearCache: {
        float: 'right',
        marginTop: '20px',
        marginRight: '20px',
        backgroundColor: '#e0e0e0',
        color: '#000000',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    checkbox: {
        padding: '0 4px',
        display:'table-cell',
        verticalAlign: 'top'
    },
    formControlLabel: {
        fontSize: '13px',
        marginTop: '2px'
    },
    formControlAdd: {
        marginRight: 0,
        display: 'table',
        verticalAlign: 'top'
    },
    newRoleGrid: {
        paddingLeft: '5px',
        marginTop: '8px',
        marginBottom: '5px',
    },
    divider: {
        margin: '0px 20px 10px 15px',
    },
}));

export const dialog = {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '300px',
      },
    },
};

export const NewRoleDialog = {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        height: '900px',
        maxWidth: '800px',
      },
    },
};

export const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: 'white',
    },
  }));
