import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { connect } from 'react-redux';
import Signin from '../components/Auth/SignIn';
import Frequencies from '../pages/Frequencies/Frequencies';
import RegionalFrequenciesReport from '../pages/RegionalFrequenciesReport/connect';
import { AdminPanel } from '../pages/AdminPanel/AdminPanel';
import { SettingsPanel } from '../pages/SettingsPanel/SettingsPanel';
import NewPassword from '../components/Auth/NewPassword/NewPassword';
import StrainTree from '../pages/StrainTree/StrainTree';
import Intro from '../pages/Intro/Intro';
import IntroFreq from '../pages/Intro/IntroFreq';
import Error404 from '../pages/Error404/Error404';
import Antigenic from '../pages/Antigenic/Antigenic';
import GeoMap from '../pages/GeoMap/GeoMap';
import config from '../config/envConfig';
import Fitness from '../pages/Fitness/Fitness';
import LegalNotice from '../pages/LegalNotice/LegalNotice';
import auth from '../functions/auth-helper';

const pathnameToModule = {
    '/antigenic': 'antigenic',
    '/frequencies': 'frequencies',
    '/intro-freq': 'frequencies',
    '/fitness': 'fitness',
    '/geomap': 'geomap',
    '/intro-map': 'geomap',
    '/intro': 'strainTree',
    '/': 'strainTree',
}

const AppRoutes = ({ isAuthenticated, role, modules, adminRights, dataAdminRights }) => {
    const Redirecor = (path, location) => <Navigate to={{ pathname: `${path}`, state: { from: location } }} />;
    //console.log('isAuthenticated', isAuthenticated);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!modules) return;
        // Define the logic to determine if the current tab is permitted
        const isCurrentTabPermitted = () => {
            const currentModel = pathnameToModule[location.pathname];
            if (currentModel && !modules[currentModel]) return false;
            //    console.log(modules, location.pathname, pathnameToModule[location.pathname]);
            return true;
            // Implement the logic based on lineage and current route
            // Return true if permitted, false otherwise
        };

        //    isCurrentTabPermitted();
        if (!isCurrentTabPermitted()) {
            navigate(`${config.frontendPrefix}/`, { replace: true });
        }
    }, [modules, location, navigate]);


    return <Routes>
        <Route path={`${config.frontendPrefix}/signin`} Component={(props) => !isAuthenticated ? <Signin /> : Redirecor(`${config.frontendPrefix}/`, props.location)} exact />
        <Route path={`${config.frontendPrefix}/intro`} Component={Intro} exact />
        <Route path={`${config.frontendPrefix}/intro-freq`} Component={IntroFreq} exact />
        <Route path={`${config.frontendPrefix}/newPassword`} Component={NewPassword} exact />
        <Route path={`${config.frontendPrefix}/intro-map`} Component={GeoMap} exact />
        <Route path={`${config.frontendPrefix}/notice`} Component={LegalNotice} exact />
        <Route path={`${config.frontendPrefix}/`} Component={(props) => isAuthenticated ? <StrainTree /> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
        <Route path={`${config.frontendPrefix}/antigenic`} Component={(props) => isAuthenticated ? <Antigenic /> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
        <Route path={`${config.frontendPrefix}/fitness`} Component={(props) => isAuthenticated ? <Fitness /> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
        <Route path={`${config.frontendPrefix}/geomap`} Component={(props) => isAuthenticated ? <GeoMap /> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
        <Route path={`${config.frontendPrefix}/frequencies`} Component={(props) => isAuthenticated ? <Frequencies /> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
        <Route path={`${config.frontendPrefix}/settings`} Component={(props) => isAuthenticated && dataAdminRights ? <SettingsPanel /> : Redirecor('/intro', props.location)} exact />
        <Route path={`${config.frontendPrefix}/manage`} Component={(props) => isAuthenticated && adminRights ? <AdminPanel /> : Redirecor(`${config.frontendPrefix}/intro`, props.location)} exact />
        <Route
            path={`${config.frontendPrefix}/export/frequencies/`}
            Component={Frequencies}
            exact
        />
        <Route
            path={`${config.frontendPrefix}/export/strainTree/`}
            Component={StrainTree}
            exact
        />
        <Route
            path={`${config.frontendPrefix}/export/Antigenic/`}
            Component={Antigenic}
            exact
        />
        <Route
            path={`${config.frontendPrefix}/export/Fitness/`}
            Component={Fitness}
            exact
        />
        <Route
            path={`${config.frontendPrefix}/export/regionalFrequenciesReport/`}
            Component={RegionalFrequenciesReport}
            exact
        />
        <Route
            path={`${config.frontendPrefix}/export/geomap/`}
            Component={GeoMap}
            exact
        />
        <Route path='/*' Component={Error404} />
    </Routes>

}


const mapStateToProps = ({ user, metadata }) => {
    return ({
        isAuthenticated: auth.isAuthenticated(),
        adminRights: user.permissions.adminRights,
        dataAdminRights: user.permissions.dataAdminRights,
        role: user.role,
        modules: metadata.modules
    });
};
export default connect(mapStateToProps)(AppRoutes);
