import { withStyles } from '@mui/styles';
import { RegionalFrequenciesReport } from './RegionalFrequenciesReport';

const styles = () => ({
    rootExport: {
        flexGrow: 1,
        height: '100%',
        maxHeight: '100%',
        padding: '60px',
        position: 'relative',
    },
    container: {
        height: 'calc(100% - 24px)',
        maxHeight: 'calc(100% - 24px)'
    },
    containerTitles: {
        paddingBottom: '12px',
    },
    containerExport: {
        height: '100%',
        maxHeight: '100%',
        // marginBottom: '24px',
        borderTop: '1px solid black',
        position: 'relative',
        pageBreakInside: 'avoid'
    },
    item: {
        height: '100%',
        maxHeight: '100%'
    },
    itemExport: {
        height: '174px',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingRight: '24px',
    },
    headerTitle: {
        fontSize: '12px',
        fontWeight: 600,
        paddingRight: '24px',
        textTransform: 'uppercase',
    },
    regionLabel: {
        fontSize: '12px',
        fontWeight: 600,
        padding: '15px 10px 15px 0',
        textTransform: 'capitalize',
    },
    seqChart: {
        padding: 0
    }
});

export const StyledRegionalFrequenciesReport = withStyles(styles)(RegionalFrequenciesReport);
