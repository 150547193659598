import { connect } from 'react-redux';
import { useStyles } from './styles';

const LegendElement = ({ keyId, visible, color, label, fontSize, handleClick, handleEnter, handleLeave, border, textDecoration, exportMode, size } ) => {
    const classes = useStyles();

    return (
        <div 
            className={classes.legendElement}
            id={keyId}
            key={keyId}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
        >
            <div className={exportMode ? classes.legendCircleWrapperExport : classes.legendCircleWrapper}>
                <div className={exportMode ? classes.legendCircleExport : classes.legendCircle}
                    style={{
                        backgroundColor: visible ? color : '#fff',
                        border,
                        width: size,
                        height: size,
                    }}
                    onClick={handleClick}
                />
            </div>

            <div className={exportMode ? classes.legendTextExport : classes.legendText} style={{fontSize, textDecoration}}>
                {label}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const { exportMode } = state.parameters;

    return {
        exportMode
    }
}

export default connect(mapStateToProps)(LegendElement);
