import { isFirefox } from '../../functions/checkBrowser';

export const styles = () => ({
    root: {
        flexGrow: 1,
        height: 'calc(100vh - 132px)',
        maxHeight: 'calc(100vh - 132px)'
    },
    rootExport: {
        flexGrow: 1,
        height: '100%',
        maxHeight: '100%',
        padding: '24px'
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    containerExport: {
        height: '100%',
        maxHeight: '100%',
    },
    item: {
        height: '100%',
        maxHeight: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
    },
    itemExport: {
        height: '100%',
        maxHeight: '100%',
        flex: '1 0 auto'
    },
    legend: {
        // padding: '30px 0px 0px 0px',
        // height: '100%',
        width: '50%',
        float: 'right'
    },
    legendExport: {
        position: 'absolute',
        top: '24px',
        right: '24px',
        paddingBottom: '24px'
    },
    seqChart: {
        padding: 0
    },
    cladeSidebar: {
        right: 0,
        height: 'calc(100%)',
        overflowY: isFirefox ? 'auto' : 'overlay',
        width: '25%',
        backgroundColor: 'white',
        maxWidth: '400px'
    }
});
