// Action Types
import { received, request } from '../baseActions';
import {
    SHOW_ALERT,
    CLOSE_ALERT,
    SET_ALERT_STATUS,
} from './actionTypes';

const showAlert = (payload) => received(SHOW_ALERT, payload);
const showInfo = (payload) => received(SHOW_ALERT, { message: payload, type: 'info' })
const showWarning = (payload) => received(SHOW_ALERT, { message: payload, type: 'warning' })
const showError = (payload) => received(SHOW_ALERT, { message: payload, type: 'error' })
const setAlertStatus = (payload) => received(SET_ALERT_STATUS, payload);

const closeAlert = () => request(CLOSE_ALERT)

export {
    showAlert,
    showInfo,
    showWarning,
    showError,
    closeAlert,
    setAlertStatus
};
