import { createTheme } from '@mui/material/styles';
import { teal, orange } from '@mui/material/colors';

const theme = createTheme({
    typography: {
        fontFamily: ['"Inter"', 'Verdana'].join(','),
    },
    palette: {
        primary: {
            light: '#52c7b8',
            main: '#504D4D',
            dark: '#504D4D', // '#00675b',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ffffff', // '#ffd95b',
            main: '#6F6CFF', // '#ffa726',
            dark: '#c77800',
            contrastText: '#000',
            gray: '#474747'
        },
        background: {
            dark: '#E0DCDC', // '#e5e5e5',
            default: '#F7F4F4', // '#f7f7f7'
        },
        openTitle: teal['700'],
        protectedTitle: orange['700'],
        type: 'light',
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
                variant: 'contained'
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                label: {
                    textTransform: 'none',
                },
                outlinedSecondary: {
                    borderColor: '#ffffff',
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: '32px',
                    height: '32px'
                },
            }
        },
        MuiInputLabel: {
            defaultProps: {
                disableAnimation: true,
                shrink: true, // Disable label animation for all input types
            },
            styleOverrides: {
                position: 'relative',
            }
        },

        MuiFormControl: {
            defaultProps: {
                variant: 'standard', // or "standard" or "filled"
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard", // or "standard" or "filled"
                margin: "normal",

            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard", // or "standard" or "filled"
            },
        },
        MuiInput: {
            defaultProps: {
                variant: "standard", // or "standard" or "filled"
            },
            styleOverrides: {
                root: {
                    //borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    transition: 'none',
                    '&::before': {
                        webkitTransition: 'none',
                        transition: 'none',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                        '&::hover': {
                            webkitTransition: 'none',
                            transition: 'none',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                        },
                    },
                    '&::after': {
                        webkitTransition: 'none',
                        transition: 'none',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    },
                    '&&.Mui-disabled::before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.38)',
                        '&::hover': {
                            webkitTransition: 'none',
                            transition: 'none',
                            borderBottom: '1px solid rgba(255, 0, 0, 0.38)',
                        },
                    },
                    '&&:hover::before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                        transition: 'none'
                    },
                },
                underline: {
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottomWidth: '1px',
                    },
                    '&:after': {
                        borderBottomWidth: '1px',
                    },
                }
            }
        },

        MuiToolbar: {
            styleOverrides: {
                regular: {
                    '@media (min-width: 600px)': {
                        minHeight: '60px',
                    },
                },
            }
        },
    },
});

export default theme;
