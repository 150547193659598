import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, FormControl, MenuItem, FormHelperText } from "@mui/material";
import CustomSelect from "../../assets/GlobalStyles/CustomSelect";
import { AntigenicModelInput } from "../ModelSelector/AntigenicModelInput";
import { connect } from 'react-redux';
import { setParameters } from '../../redux/actions/parametersActions';
import { getRegionsArrWithAll, getRegionsLabelsArr } from "../../redux/selectors/parametersSelector";
import { styles } from './styles';
import { checkModelState, fetchModelTypes, fetchModels } from "./functions";
import { getSegments } from '../../functions/functions';
import { getAntigenicModelTypes, getAntigenicSegmentsNamesForFitness, getAntigenicSegmentsFields } from '../../redux/selectors/metadataSelector';
import CustomLabel from "../../assets/GlobalStyles/CustomLabel";

const SingleModelSelector = (props) => {
    const {
    antigenicModelTypes, antigenicSegmentsFields,
    modelRegionId, modelType, modelId, invalid, idIncomplete, colorBy, 
    regions, regionsLabels, index, updateModelState, lineage, updateSelectedModels,
    lineageStatus } = props;

    const antigenicSegmentsNames = props.antigenicSegmentsNames || [];



    //console.log('[SingleModelSelector] modelId = ', modelId);
    const classes = styles();
    const [modelTypeOptions, setModelTypeOptions] = useState();
    const [modelIdOptions, setModelIdOptions] = useState();//['']);
    const [collaboratingCenter, setColaboratingCenter] = useState('');
    const [strainPropagation, setStrainPropagation] = useState('');
    const [refStrainPropagation, setRefStrainPropagation] = useState('');
    const [assay, setAssay] = useState('');
    const [assayOptions, setAssayOptions] = useState([]);
    const [collaboratingCenterOptions, setColaboratingCenterOptions] = useState([]);
    const [strainPropagationOptions, setStrainPropagationOptions] = useState([]);
    const [refStrainPropagationOptions, setRefStrainPropagationOptions] = useState([]);

    const [error, setError] = useState(false);
    // const [errorTxt, setErrorTxt] = useState(null);
    const [errorSource, setErrorSource] = useState(null);

    const modelRegionOptions = regions; //useMemo(() => {return ['', ...regions];}, [regions]);
    const modelRegionLabels = regionsLabels; //useMemo(() => { return ['', ...regionsLabels]; }, [regionsLabels]);

    const antigenicSegmentsDict = {
        assay: {
            value: assay,
            options: assayOptions,
            setAction: setAssay,
            setOptions: setAssayOptions
        },
        collaboratingCenter: {
            value: collaboratingCenter,
            options: collaboratingCenterOptions,
            setAction: setColaboratingCenter,
            setOptions: setColaboratingCenterOptions
        },
        strainPropagation: {
            value: strainPropagation,
            options: strainPropagationOptions,
            setAction: setStrainPropagation,
            setOptions: setStrainPropagationOptions
        },
        refStrainPropagation: {
            value: refStrainPropagation,
            options: refStrainPropagationOptions,
            setAction: setRefStrainPropagation,
            setOptions: setRefStrainPropagationOptions
        }
    };

    const isAntigenicModel = (modelType) => (antigenicModelTypes || []).includes(modelType)

    useEffect(() => {
        // if (!modelRegionId) // || !modelType)
        //     return;
        // const fetch = async () => {
        //     console.log('Single model selector', {lineageStatus});
        //     const modelTypes = await fetchModelTypes(lineage, modelRegionId);
        //     console.log({modelTypes});
        //     setModelTypeOptions(modelTypes || []);
        //     if (modelType) { 
        //         const modelIds = await fetchModels(lineage, modelRegionId, modelType);
        //         setModelIdOptions(modelIds || []);
        //         if (isAntigenicModel(modelType)) updateComplexSelectors({ modelId, modelRegionId, modelType }, modelIds, false);
        //     }
        // }

        // fetch();


        return () => {
            // Clean up any ongoing tasks or subscriptions
            setModelTypeOptions(null);
            setModelIdOptions(null);
            setColaboratingCenter(null);
            setStrainPropagation(null);
            setRefStrainPropagation(null);
            setAssay(null);
            setAssayOptions(null);
            setColaboratingCenterOptions(null);
            setStrainPropagationOptions(null);
            setRefStrainPropagationOptions(null);
            setError(false);
            // setErrorTxt(null);
            setErrorSource(null);
        };
    }, []);


    useEffect(() => {
        if (!modelRegionId || lineageStatus !== 'loaded') // || !modelType)
            return;
        const fetch = async () => {
            console.log('Single model selector', {lineageStatus});
            const modelTypes = await fetchModelTypes(lineage, modelRegionId);
            console.log({modelTypes});
            setModelTypeOptions(modelTypes || []);
            if (modelType) { 
                const modelIds = await fetchModels(lineage, modelRegionId, modelType);
                setModelIdOptions(modelIds || []);
                if (isAntigenicModel(modelType)) updateComplexSelectors({ modelId, modelRegionId, modelType }, modelIds, false);
            }
        }

        fetch();

    }, [lineageStatus]);




    const updateComplexSelectors = (mod, modelIds, newModel) => {
        const options = getSegments(modelIds, antigenicSegmentsNames);
        const segments = mod.modelId.split('-').reduce((acc, value, index) => ({ ...acc, [antigenicSegmentsNames[index]]: value }), {});

        antigenicSegmentsNames.forEach(segmentName => {
            antigenicSegmentsDict[segmentName].setAction(newModel || !segments[segmentName] ? '' : segments[segmentName]);
            const ops = options[`${segmentName}s`];
            antigenicSegmentsDict[segmentName].setOptions(ops);
        })
    }

    const handleModelRegionChange = async (e) => {
        const modelRegionId = e.target.value;
        const updatedModel = {
            modelRegionId: modelRegionId,
            modelType: '',
            modelId: ''
        }

        setColaboratingCenter('');
        setStrainPropagation('');
        setRefStrainPropagation('');
        setAssay('');
        setError(false);
        setErrorSource(null);
        const modelTypes = await fetchModelTypes(lineage, modelRegionId);
        setModelTypeOptions(modelTypes);
        updateSelectedModels(index, updatedModel, false, true);
        //updateModelState(index, updatedModel);
    };

    const handleModelTypeChange = async (e) => {
        const modelType = e.target.value;
        const updatedModel = {
            modelRegionId: modelRegionId,
            modelType: modelType,
            modelId: ''
        }
        setColaboratingCenter('');
        setStrainPropagation('');
        setRefStrainPropagation('');
        setAssay('');
        setError(false);
        setErrorSource(null);

        updateSelectedModels(index, updatedModel, false, true);
        //updateModelState(index, updatedModel);

        if (modelType && modelType.length) {
            const modelIds = await fetchModels(lineage, modelRegionId, modelType, colorBy);
            setModelIdOptions(modelIds);

            if (isAntigenicModel(modelType)) updateComplexSelectors(updatedModel, modelIds, true)
        }
    };

    const commitIdChange = async (modelId, idIncomplete) => {
        const updatedModel = {
            modelRegionId: modelRegionId,
            modelType: modelType,
            modelId: modelId
        }
        // updateModelState(index, updatedModel);

        const valid = !idIncomplete && checkModelState(updatedModel, modelTypeOptions, modelIdOptions);

        //console.log('[commitIdChange] valid', valid, modelTypeOptions, modelIdOptions, modelType, modelId,);
        // if (valid) {
        updateSelectedModels(index, updatedModel, valid ? false : true, idIncomplete);
        //}
    }

    const handleModelIdChange = async (e) => {
        const modelId = e.target.value;
        await commitIdChange(modelId)
    }

    const handleAntigenicParamChange = async (e) => {
        let { name, value } = e.target;

        //console.log(`[handleAntigenicParamChange] name = ${name}, value = ${value}`)
        const getModelIdFromSegments = (name, value) => {
            const val = {
                collaboratingCenter: name === "collaboratingCenter" ? value : collaboratingCenter,
                assay: name === "assay" ? value : assay,
                strainPropagation: name === "strainPropagation" ? value : strainPropagation,
                refStrainPropagation: name === "refStrainPropagation" ? value : refStrainPropagation
            }
            const _modelId = antigenicSegmentsNames.map(segmentName => val[segmentName]).join('-');
            return _modelId;
        };
        const id = getModelIdFromSegments(name, value);
        antigenicSegmentsDict[name].setAction(value);

        const idSet = !id.split('-').some(el => el.length === 0);
        const nodata = idSet && !modelIdOptions?.includes(id);
        if (nodata) {
            setError(true);
            // setErrorTxt(`No model for ${value}`);
            setErrorSource(name);
            //return;
        } else {
            setError(false);
            // setErrorTxt(null);
            setErrorSource(null);
        }

        // const idSet = id.split('-').some(el => el.length === 0)
        //console.log(modelIdOptions, id, modelIdOptions.includes(id), idSet);
        await commitIdChange(id, !idSet);
    }

    // console.log('modelIdOptions = ',modelIdOptions, `modelId = '${modelId}`, modelIdOptions?.includes(modelId))

    return (
        <>
            {modelRegionOptions && (
                <Grid container spacing={2} >
                    <Grid item sm={12} md={6}>
                        <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={modelRegionId} label={'Model region'} />
                            <CustomSelect
                                value={modelRegionId}
                                onChange={handleModelRegionChange}
                                inputProps={{
                                    name: 'modelRegionId',
                                    id: 'modelRegionId',
                                }}
                            >
                                {modelRegionOptions.map((region, index) => (
                                    <MenuItem className={classes.tooltip} key={region} value={region}>
                                        {region === 'ALL' ? 'All' : modelRegionLabels[index - 1]}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                    {modelTypeOptions && (modelTypeOptions || []).includes(modelType) &&
                        <Grid item sm={12} md={6}>
                            <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={'modelType'} label={'Model type'} />
                                <CustomSelect
                                    value={modelType}
                                    onChange={handleModelTypeChange}
                                    inputProps={{
                                        name: "modelType",
                                        id: "modelType",
                                    }}
                                >
                                    {modelTypeOptions.map((id) => (
                                        <MenuItem key={id || "_"} value={id}>
                                            {id}
                                        </MenuItem>
                                    ))}
                                </CustomSelect>
                            </FormControl>
                        </Grid>
                    }
                    {modelType && modelIdOptions && !isAntigenicModel(modelType) && modelIdOptions.includes(modelId) &&
                        <Grid item sm={12} md={12}>
                            <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={modelId} label={'Model id'} />
                                <CustomSelect
                                    value={modelId}
                                    onChange={handleModelIdChange}
                                    inputProps={{
                                        name: "modelId",
                                        id: "modelId",
                                    }}
                                >
                                    {modelIdOptions.map((id) => (
                                        <MenuItem key={id || "_"} value={id}>
                                            {id}
                                        </MenuItem>
                                    ))}
                                </CustomSelect>
                            </FormControl>
                        </Grid>
                    }
                    {modelType && modelIdOptions && isAntigenicModel(modelType) &&

                        antigenicSegmentsFields.map(({ name, label }) =>
                            <AntigenicModelInput
                                key={name}
                                name={name}
                                label={label}
                                value={antigenicSegmentsDict[name].value}
                                options={antigenicSegmentsDict[name].options}
                                error={error && errorSource === name}
                                // errorTxt={
                                //     error && errorSource === name
                                //         ? errorTxt
                                //         : null
                                // }
                                changeHandler={handleAntigenicParamChange}
                                classes={classes}
                            />)
                    }
                </Grid>
            )}
            {invalid && !idIncomplete && modelId && (
                <Grid item sm={12} md={12}>
                    <FormHelperText error={true}>
                        No such model: {modelId}
                    </FormHelperText>
                </Grid>
            )}


        </>
    )
}


SingleModelSelector.propTypes = {
    lineage: PropTypes.string,
    modelRegionOptions: PropTypes.arrayOf(PropTypes.string),
    modelRegionLabels: PropTypes.arrayOf(PropTypes.string),
    model: PropTypes.object,
    index: PropTypes.number,
    updateModelState: PropTypes.func,
    updateSelectedModels: PropTypes.func
}

const mapStateToProps = (state) => {
    const regions = getRegionsArrWithAll(state);
    const regionsLabels = getRegionsLabelsArr(state);

    const { lineage } = state.parameters;

    const antigenicModelTypes = getAntigenicModelTypes(state);
    const antigenicSegmentsNames = getAntigenicSegmentsNamesForFitness(state);
    const antigenicSegmentsFields = getAntigenicSegmentsFields(state)['fitness'];


    return ({
        lineage,
        lineageStatus: state.lineages.lineageStatus,
        regions,
        // colorBy: 'fitness',
        regionsLabels,
        antigenicSegmentsNames,
        antigenicSegmentsFields,
        antigenicModelTypes,
    });
};

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleModelSelector);
