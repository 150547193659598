/* eslint-disable */
import {
    ZOOM_TREE_REQUEST,
    ZOOM_TREE_SUCCESS,
    ZOOM_TREE_ERROR,

    INIT_STRAIN_TREE_REQUEST,
    INIT_STRAIN_TREE_SUCCESS,
    INIT_STRAIN_TREE_ERROR,

    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_SUCCESS,
    FETCH_RECALCULATED_TREE_ERROR,

    FETCH_SUBSET_TREE_REQUEST,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_SUBSET_TREE_ERROR,

    FETCH_TREE_FREQS_REQUEST,
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_TREE_FREQS_ERROR,

    FETCH_SELECTED_STRAIN_REQUEST,
    FETCH_SELECTED_STRAIN_SUCCESS,
    FETCH_SELECTED_STRAIN_ERROR,

    FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR,

    FETCH_VP_VALUES_REQUEST,
    FETCH_VP_VALUES_SUCCESS,
    FETCH_VP_VALUES_ERROR,

    FETCH_HUMAN_SEROLOGY_REQUEST,
    FETCH_HUMAN_SEROLOGY_SUCCESS,
    FETCH_HUMAN_SEROLOGY_ERROR,

    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,

    FETCH_HUMAN_POOLS_SUCCESS,
    FETCH_GENOTYPE_DATA_SUCCESS,

    FETCH_STRAINS_LIST_REQUEST,
    FETCH_STRAINS_LIST_SUCCESS,
    FETCH_STRAINS_LIST_ERROR,

    FETCH_REFERENCE_STRAINS_REQUEST,
    FETCH_REFERENCE_STRAINS_SUCCESS,
    FETCH_REFERENCE_STRAINS_ERROR,
    
    RESET_REFERENCE_STRAINS,
    RESET_SESSION,
    RESET_VP_VALUES,
    RESET_HUMAN_SEROLOGY_DATA,

    SET_REFERENCE_STRAIN,
    SET_STRAINS_LIST,
    SET_STRAIN_SEARCH_STATUS,
    SET_COLOR_BY,
    SET_SEARCH_STRAIN_MODE,

    SIGNOUT_REQUEST,
    FETCH_LINEAGE_ERROR,
    FETCH_BRANCH_NODES_SUCCESS,
    SET_BRANCH_NODES,
    SET_SELECTED_STRAIN,


} from '../actions/actionTypes';

import { ZOOM_TYPES } from '../../config/consts';
import { emptyObject } from '../../functions/functions';

import deepFreeze from 'deep-freeze';

let treeDataInitialState = {};
export const setTreeDataInitialState = (state) => {
    treeDataInitialState = state;
};

const getZoomStack = (state, zoomNodeId, zoomType, resetLast, rootNodeId) => {
    // console.log(zoomNodeId, zoomType, resetLast, rootNodeId)
    let zoomNodeStack = [];
    if (zoomType === ZOOM_TYPES.ZOOM_IN || state.zoomNodeStack.length === 0) {

        zoomNodeStack = [...state.zoomNodeStack];
        if (rootNodeId && rootNodeId !== zoomNodeId) {
            zoomNodeStack.push(rootNodeId);
        }
        zoomNodeStack.push(zoomNodeId);
    } else if (resetLast || zoomType === undefined) {
        zoomNodeStack = state.zoomNodeStack.slice(0, state.zoomNodeStack.length - 1);
        if (rootNodeId && rootNodeId !== zoomNodeId) {
            zoomNodeStack.push(rootNodeId);
        }
        zoomNodeStack.push(zoomNodeId);
    } else if (zoomType === ZOOM_TYPES.ZOOM_OUT) {
        zoomNodeStack = [...state.zoomNodeStack];
        zoomNodeStack.pop();
    } else if (zoomType === ZOOM_TYPES.RESET) {
        zoomNodeStack = state.zoomNodeStack.slice(0, 1);
    }
    return zoomNodeStack;
};



const getTreeAttrs = (state, treeAttrs) => {
    if (emptyObject(treeAttrs)) {
        return state.treeAttrs;
    }
    return { ...state.treeAttrs, ...treeAttrs };
}


const treeDataReducer = (state = treeDataInitialState, action) => {
    deepFreeze(state);
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return treeDataInitialState;
        }

        case INIT_STRAIN_TREE_REQUEST: {
            return {
                ...state,
                treeDataStatus: 'loading',
                vpValuesStatus: 'loading'
            };
        }

        case FETCH_SELECTED_STRAIN_REQUEST: {
            const { searchId } = action.payload;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: 'loading' }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : 'loading';
            const additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodes[searchId] = {};
            additionalNodesStatus[searchId] = 'loading';

            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses,
                additionalNodesStatus,
                additionalNodes
            };
        }

        case SET_SELECTED_STRAIN: {
            // console.log(SET_SELECTED_STRAIN)
            const { searchId } = action.payload;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: 'none' }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : 'none';
            const additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodes[searchId] = {};
            additionalNodesStatus[searchId] = 'none';

            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses,
                additionalNodesStatus,
                additionalNodes
            };
        }


        case FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST: {
            const { searchId } = action.payload;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: 'loading' }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : 'loading';
            const additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodes.colorBy = {};
            additionalNodesStatus.colorBy = 'loading';

            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses,
                additionalNodesStatus,
                additionalNodes
            };
        }


        case INIT_STRAIN_TREE_SUCCESS: {
            const { treeAttrs, strainCutoffDateMinMax, treeFreqs, visibleNodes, antigenicityScores, zoomType,
                // additionalNodes: { colorBy, searchStrain },
                highlightedStrainIds = {},
                parameters: { zoomNodeId, rootNodeId }
            } = action.payload;

            return {
                ...state,
                treeDataStatus: 'loaded',
                vpValuesStatus: 'loaded',
                treeAttrs: getTreeAttrs(state, treeAttrs),
                treeFreqs,
                visibleNodes,
                highlightedStrainIds,
                zoomNodeStack: getZoomStack(state, zoomNodeId, zoomType, true, rootNodeId),
                zoomType,
                strainCutoffDateMinMax,
                tcellAntigenicityScores: antigenicityScores ? antigenicityScores.scores : {},
                tcellAntigenicityScoresStatus: antigenicityScores ? 'loaded' : 'none',
                rootNodeId,
                // additionalNodes: {
                //     ...state.additionalNodes,
                //     colorBy,
                //     searchStrain
                // },
                // additionalNodesStatus: {
                //     ...state.additionalNodesStatus,
                //     colorBy: 'loaded',
                //     searchStrain: 'loaded'
                // }
            };
        }


        case RESET_VP_VALUES: {
            return {
                ...state,
                vpValuesStatus: 'none'
            }
        }

        case FETCH_VP_VALUES_REQUEST: {
            console.log(action.payload)
            return {
                ...state,
                vpValuesStatus: 'loading',
                treeDataStatus: 'loading'
            }
        }
        case FETCH_VP_VALUES_SUCCESS: {
            const { visibleNodes, treeAttrs } = action.payload;;
            return {
                ...state,
                treeDataStatus: 'loaded',
                vpValuesStatus: 'loaded',
                treeAttrs: getTreeAttrs(state, treeAttrs),
                visibleNodes
            };
        }

        case FETCH_VP_VALUES_ERROR: {
            //console.log(action.payload);
            return {
                ...state,
                treeDataStatus: 'error',
                vpValuesStatus: 'error',
            };
        }
        case FETCH_REFERENCE_STRAINS_REQUEST: {
            return {
                ...state,
                referenceStrainNodesStatus: 'loading',
            }
        }
        case FETCH_REFERENCE_STRAINS_SUCCESS: {
            const { nodes } = action.payload;;
            return {
                ...state,
                referenceStrainNodesStatus: 'loaded',
                referenceStrainNodes: nodes,
            };
        }
        case RESET_REFERENCE_STRAINS: {
            return {
                ...state,
                referenceStrainNodes: {},
                referenceStrainNodesStatus: 'none',
            };
        }

        case FETCH_REFERENCE_STRAINS_ERROR: {
            return {
                ...state,
                referenceStrainNodesStatus: 'error',
            };
        }

        case FETCH_RECALCULATED_TREE_SUCCESS:
            {
                const { visibleNodes, treeAttrs } = action.payload;
                if (!visibleNodes || !treeAttrs) return { ...state, treeDataStatus: 'loaded' };

                return {
                    ...state,
                    treeAttrs: getTreeAttrs(state, treeAttrs),
                    visibleNodes,
                    treeDataStatus: 'loaded',
                    vpValuesStatus: 'loaded'
                };
            }

        case FETCH_CUSTOM_TREE_ATTRS_SUCCESS:
            {
                const { additionalNodes } = action.payload;
           
                const _additionalNodes = { ...state.additionalNodes };
                const additionalNodesStatus = { ...state.additionalNodesStatus };
                _additionalNodes.colorBy = additionalNodes.colorBy;
                additionalNodesStatus.colorBy = 'loaded';
                return {
                    ...state,
                    additionalNodes: _additionalNodes,
                    additionalNodesStatus,
                    treeDataStatus: 'loaded',
                    vpValuesStatus: 'loaded'
                };
            }

        case FETCH_SELECTED_STRAIN_SUCCESS: {
            const status = (!action.payload || action.payload.noDataReason) ? action.payload.noDataReason : 'found';
            const searchId = !action.payload || !action.payload.searchId ? null : action.payload.searchId;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: status }
                : state.strainSearchStatuses;

            // console.log({status, _strainSearchStatuses});
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : status;

            // if (!action.payload)
            //     return {
            //         ...state,
            //         strainSearchStatuses: _strainSearchStatuses,
            //     }

            const { /*visibleNodes, treeAttrs,*/ additionalNodes } = action.payload;

            const _additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            _additionalNodes[searchId] = additionalNodes[searchId];
            additionalNodesStatus[searchId] = 'loaded';

            return {
                ...state,
                //treeAttrs: getTreeAttrs(state, treeAttrs),
                //visibleNodes,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses,
                additionalNodes: _additionalNodes,
                additionalNodesStatus
            };

        }

        case FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS: {

            const status = (!action.payload || action.payload.noDataReason) ? (action.payload.noDataReason || 'not_found') : 'found';
            const searchId = !action.payload || !action.payload.searchId ? null : action.payload.searchId;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: status }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : status;

            if (!action.payload || action.payload.noDataReason)
                return {
                    ...state,
                    strainSearchStatuses: _strainSearchStatuses,
                    strainSearchStatus: _strainSearchStatus
                }

            const { additionalNodes } = action.payload;

            const _additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            _additionalNodes.colorBy = additionalNodes.colorBy;
            additionalNodesStatus.colorBy = 'loaded';

            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses,
                additionalNodes: _additionalNodes,
                additionalNodesStatus
            };
        }

        case FETCH_BRANCH_NODES_SUCCESS:
            {
                const { additionalNodes } = action.payload;
                //if (!visibleNodes || !treeAttrs) return { ...state, treeDataStatus: 'loaded' };

                const _additionalNodes = { ...state.additionalNodes };
                const additionalNodesStatus = { ...state.additionalNodesStatus };
                _additionalNodes.branch = additionalNodes.branch;
                additionalNodesStatus.branch = 'loaded';
                return {
                    ...state,
                    additionalNodes: _additionalNodes,
                    additionalNodesStatus
                };
            }

        case FETCH_TREE_FREQS_SUCCESS: {
            const { treeFreqs } = action.payload;
            return {
                ...state,
                treeFreqs,
                treeDataStatus: 'loaded',
                vpValuesStatus: 'loaded'
            };
        }
        case FETCH_SUBSET_TREE_ERROR:
        case FETCH_TREE_FREQS_ERROR:
        case INIT_STRAIN_TREE_ERROR:
        case FETCH_RECALCULATED_TREE_ERROR:
        // case FETCH_VISIBLE_NODES_ERROR:
        case FETCH_LINEAGE_ERROR: {
            return {
                ...state,
                visibleNodes: {},
                treeDataStatus: 'error',
                vpValuesStatus: 'error'
            };
        }
        case FETCH_SUBSET_TREE_REQUEST:
        case FETCH_TREE_FREQS_REQUEST:
        case ZOOM_TREE_REQUEST:
        case FETCH_RECALCULATED_TREE_REQUEST:
            {
                const additionalNodesStatuses = Object.keys(state.additionalNodesStatus).reduce((acc, attr) => { acc[attr] = 'none'; return acc; }, {});
                const strainSearchStatuses = Object.keys(state.strainSearchStatuses).reduce((acc, attr) => { acc[attr] = 'none'; return acc; }, {});
                return {
                    ...state,
                    treeDataStatus: 'loading',
                    vpValuesStatus: 'loading',
                    additionalNodesStatuses,
                    strainSearchStatuses
                };
            }

        case ZOOM_TREE_SUCCESS: {
            const { visibleNodes, zoomType, treeAttrs, treeFreqs, highlightedStrainIds, parameters } = action.payload;
            const { zoomNodeId } = parameters;
            return {
                ...state,
                treeDataStatus: 'loaded',
                vpValuesStatus: 'loaded',
                visibleNodes,
                treeFreqs,
                treeAttrs: getTreeAttrs(state, treeAttrs),
                zoomNodeStack: getZoomStack(state, zoomNodeId, zoomType),
                zoomType,
                highlightedStrainIds: highlightedStrainIds || {},
            };
        }

        case ZOOM_TREE_ERROR: {
            return {
                ...state,
                treeDataStatus: 'error',
                vpValuesStatus: 'error'
            };
        }

        case FETCH_SELECTED_STRAIN_ERROR:
        case FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR: {
            const { searchId } = action.payload;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: 'error' }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : 'error';
            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses
            };
        }


        case FETCH_SUBSET_TREE_SUCCESS: {
            const { visibleNodes, treeAttrs, additionalNodes, subsetTreeStatus, highlightedStrainIds } = action.payload;
            const status = Object.keys(visibleNodes).length === 0 ? 'NO_DATA' : '';
            const _treeAttrs = getTreeAttrs(state, treeAttrs);

            const _additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            _additionalNodes.subset = additionalNodes.subset;
            // console.log('[FETCH_SUBSET_TREE_SUCCESS], additionalNodes.subset = ', Object.keys(additionalNodes.subset || {}).length)
            additionalNodesStatus.subset = 'loaded';
            return {
                ...state,
                treeAttrs: _treeAttrs,
                visibleNodes,
                treeDataStatus: 'loaded',
                vpValuesStatus: 'loaded',
                subsetTreeStatus: subsetTreeStatus || status,
                highlightedStrainIds: highlightedStrainIds || {},
                additionalNodes,
                additionalNodesStatus
            };
        }

        // case FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS: {
        //     console.log('FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS. tree 1', action.payload)
        //     if (emptyObject(action.payload)) {
        //         return {
        //             ...state,
        //         };
        //     }
        //     console.log('FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS. tree 2')
        //     const { treeAttrs, visibleNodes } = action.payload;
        //     if (!treeAttrs)
        //         return { ...state };
        //     const _treeAttrs = getTreeAttrs(state, treeAttrs);
        //     console.log('FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS. tree 3')
        //     return {
        //         ...state,
        //         treeAttrs: _treeAttrs,
        //         visibleNodes: visibleNodes ? visibleNodes : state.visibleNodes,
        //     };
        // }

        case SET_REFERENCE_STRAIN: {
            const { searchId } = action.payload;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: 'none' }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : 'none';


            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodesStatus.colorBy = 'none';

            const additionalNodes = { ...state.additionalNodes };
            additionalNodes.colorBy = {};

            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses,
                additionalNodes,
                additionalNodesStatus
            }
        }

        case SET_STRAIN_SEARCH_STATUS: {
            const { strainSearchStatus, searchId } = action.payload;
            const _strainSearchStatuses = searchId
                ? { ...state.strainSearchStatuses, [searchId]: strainSearchStatus }
                : state.strainSearchStatuses;
            // const _strainSearchStatus = searchId ? state.strainSearchStatus : strainSearchStatus;
            return {
                ...state,
                // strainSearchStatus: _strainSearchStatus,
                strainSearchStatuses: _strainSearchStatuses
            }
        }
        case FETCH_STRAINS_LIST_REQUEST: {
            const { searchId } = action.payload;
            const _strainsListStatuses = searchId
                ? { ...state.strainsListStatuses, [searchId]: 'loading' }
                : state.strainsListStatuses;
            const _strainsListStatus = searchId ? state.strainsListStatus : 'loading';
            return {
                ...state,
                strainsListStatus: _strainsListStatus,
                strainsListStatuses: _strainsListStatuses
            }
        }
        case FETCH_STRAINS_LIST_SUCCESS:
        case SET_STRAINS_LIST: {
            const { strainsList, searchId } = action.payload;

            const _strainsListStatuses = searchId
                ? { ...state.strainsListStatuses, [searchId]: strainsList && strainsList.length > 0 ? 'loaded' : 'nodata' }
                : state.strainsListStatuses;
            const _strainsLists = searchId
                ? { ...state.strainsLists, [searchId]: strainsList ? strainsList : undefined }
                : state.strainsLists;

            const _strainsList = searchId ? state.strainsList : (strainsList ? strainsList : undefined)
            const _strainsListStatus = searchId ? state.strainsListStatus : (strainsList ? 'loaded' : 'nodata');

            return {
                ...state,
                strainsList: _strainsList,
                strainsLists: _strainsLists,
                strainsListStatus: _strainsListStatus,
                strainsListStatuses: _strainsListStatuses
            }
        }

        case FETCH_STRAINS_LIST_ERROR: {
            const { searchId } = action.payload;
            const _strainsListStatuses = searchId
                ? { ...state.strainsListStatuses, [searchId]: 'loading' }
                : state.strainsListStatuses;
            const _strainsLists = searchId
                ? { ...state.strainsLists, [searchId]: null }
                : state.strainsLists;
            const _strainsListStatus = searchId ? state.strainsListStatus : 'error';
            return {
                ...state,
                strainList: searchId ? state.strainsList : null,
                strainsListStatus: _strainsListStatus,
                strainsLists: _strainsLists,
                strainsListStatuses: _strainsListStatuses
            }
        }

        case FETCH_GENOTYPE_DATA_SUCCESS: {
            const { parentNodes } = action.payload;
            //console.log(parentNodes)
            return {
                ...state,
                treeAttrs: getTreeAttrs(state, parentNodes)
            };
        }
        case FETCH_HUMAN_POOLS_SUCCESS:
        case RESET_HUMAN_SEROLOGY_DATA: {
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodesStatus.colorBy = 'refetchNeeded';
            const additionalNodes = { ...state.additionalNodes };
            additionalNodes.colorBy = {};
            return {
                ...state,
                additionalNodesStatus,
                additionalNodes
            }
        }
        case FETCH_HUMAN_SEROLOGY_REQUEST:
        case FETCH_CUSTOM_TREE_ATTRS_REQUEST:
            {
                const additionalNodes = { ...state.additionalNodes };
                const additionalNodesStatus = { ...state.additionalNodesStatus };
                additionalNodes.colorBy = {}
                additionalNodesStatus.colorBy = 'loading';
                return {
                    ...state,
                    additionalNodes,
                    additionalNodesStatus
                }
            }

        case FETCH_HUMAN_SEROLOGY_SUCCESS:
            //case FETCH_COLOR_BY_RULE_STRAINS_SUCCESS: 
            {
                // console.log(action);
                const { additionalNodes } = action.payload;
                const _additionalNodes = { ...state.additionalNodes };
                const additionalNodesStatus = { ...state.additionalNodesStatus };
                _additionalNodes.colorBy = additionalNodes.colorBy;
                additionalNodesStatus.colorBy = 'loaded';
                return {
                    ...state,
                    additionalNodes: _additionalNodes,
                    additionalNodesStatus
                }
            }
        case FETCH_HUMAN_SEROLOGY_ERROR: { }
        case FETCH_CUSTOM_TREE_ATTRS_ERROR: {
            const additionalNodes = { ...state.additionalNodes };
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodes.colorBy = {};
            additionalNodesStatus.colorBy = 'error';
            return {
                ...state,
                additionalNodesStatus,
                additionalNodes
            }
        }

        // // Reset additional nodes after color by change
        // case SET_PARAMETERS: {
        //     //console.log('SET_PARAMETERS treeDataReducer')
        //     const { parameters } = action.payload;
        //     const colorBy = parameters?.colorBy;
        //     //console.log('SET_PARAMETERS', colorBy);
        //     const additionalNodesStatus = colorBy ? 'none' : state.additionalNodesStatus;
        //     const additionalNodes = colorBy ? {} : state.additionalNodes;
        //     //console.log('SET_PARAMETERS', colorBy, additionalNodes);
        //     return {
        //         ...state,
        //         additionalNodes,
        //         additionalNodesStatus
        //     }
        // }

        // Reset additional nodes after color by change
        case SET_COLOR_BY: {
            //console.log('SET_PARAMETERS treeDataReducer')
            //const { colorBy } = action.payload;
            //console.log('SET_PARAMETERS', colorBy);
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodesStatus.colorBy = 'none';

            const additionalNodes = { ...state.additionalNodes };
            additionalNodes.colorBy = {};
            //console.log('SET_PARAMETERS', colorBy, additionalNodes);
            return {
                ...state,
                additionalNodes,
                additionalNodesStatus
            }
        }

        case SET_BRANCH_NODES: {
            //console.log('SET_PARAMETERS treeDataReducer')
            //const { colorBy } = action.payload;
            //console.log('SET_PARAMETERS', colorBy);
            const additionalNodesStatus = { ...state.additionalNodesStatus };
            additionalNodesStatus.branch = 'none';

            const additionalNodes = { ...state.additionalNodes };
            additionalNodes.branch = {};
            //console.log('SET_PARAMETERS', colorBy, additionalNodes);
            return {
                ...state,
                additionalNodes,
                additionalNodesStatus
            }
        }

        // case SET_SEARCH_STRAIN_MODE: {
        //     const { searchStrainMode } = action.payload;
        //     if (searchStrainMode) return state;
        //     const additionalNodes = { ...state.additionalNodes };
        //     const additionalNodesStatus = { ...state.additionalNodesStatus };
        //     additionalNodes.searchStrain = {};
        //     additionalNodesStatus.searchStrain = 'none';
        //     return {
        //         ...state,
        //         additionalNodes,
        //         additionalNodesStatus
        //     }
        // }

        default:
            return state || treeDataInitialState;
    }
};

export { treeDataReducer };
