import { makeStyles } from '@mui/styles';

const styles =  makeStyles({
    bar: {
        boxShadow: 'none',
        zIndex: 10,
        position: 'relative',
        overflowY: 'none',
    },
    logoButton: {
        // marginLeft: -12,
        marginRight: 20,
        display: 'flex',
        color: '#FFFFFF',
        '&:hover': {
            color: '#FFFFFF',
            boxShadow: 'none',
            borderColor: 'white',
        },
    },
    root: {
        backgroundColor: '#E2E2E2',
        overflowY: 'overlay',
        height: '100%'
    },
    colorLegend: {
        marginTop: '5px',
        overflowY: 'overlay',
        overflowX: 'overlay',
    },
    overflow: {
        overflowY: 'overlay',
        overflowX: 'overlay',
    },
    tree: {
        overflowY: 'overlay',
        overflowX: 'overlay',
        marginTop: '15px'
    },
    menu: {
        margin: '15px 15px 0px 15px',
        height: '130px',
        display: 'flex'
    },
    textField: {
        backgroundColor: 'white !important',
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            fontFamily: 'Inter, Verdana',
            fontSize: '16px'
        },
        '& .MuiInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
            },
        }
    },
    item:{
        paddingRight: '10px',
        paddingLeft: '10px'
    },
    export: {
        position: 'absolute',
        marginTop: '80px',
        marginLeft: '10px',
        backgroundColor: '#e0e0e0', 
        color: '#000000', 
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        }
    },
    cladeMenu: {
        marginTop: '20px'
    }
});

export { styles };