import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography, Checkbox, FormControl, MenuItem, Tooltip, DialogContentText } from '@mui/material';
import { styles, NewScaleDialog } from './styles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import DraggableList from '../Elements/DraggableList';
import ScaleIdConfigurator from '../Elements/ScaleIdConfigurator';
import { checkDomainDiscrete, checkDomainLinear, checkId } from '../Elements/functions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const initState = {
    scaleId: '',
    discrete: false,
    numeric: true,
    palette: 'custom',
    lineage: 'none',
};

const domainInitState = [
    {
        value: 0,
        quantile: true,
        color: '#FBFBFF'
    },
    {
        value: 1,
        quantile: true,
        color: 'red'
    }
];

const additionalDomainDiscrete = {
    value: Infinity,
    color: 'FBFBFF'
}

const errorInitState = { scaleType: {status: false, message: ''}, antigenicScaleType: {status: false, message: ''}, id: {status: false, message: ''} };

const reorder = (
    list,
    startIndex,
    endIndex
  )=> {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

const AddNewScaleDialog = ({ handleCloseDialog, openDialog, addNewScale, scalesPalette, lineages  }) => {
    const [newScale, setNewScale] = useState(initState);
    const [paletteOptions, setPaletteOptions] = useState(['custom']);
    const [domain, setDomain] = useState([ ...domainInitState ]);
    const [domainValid, setDomainValid] = useState(true);
    const [error, setError] = useState({...errorInitState});
    const classes = styles();

    useEffect(() => {
        const options = paletteOptions.concat(Object.keys(scalesPalette));
        setPaletteOptions(options);
    }, [scalesPalette]);

    const handleScaleIdChange = (scaleId) => {
        setNewScale({ ...newScale, scaleId });
        setError(errorInitState);
    }

    const handleChangeLineage = (e) => setNewScale({ ...newScale, lineage: e.target.value });
    const handleCheckboxChange = (e) => {
        const discrete = e.target.checked;
        if (!discrete){
            let newDomain = domain.slice(0, domain.length - 1);
            setDomain(newDomain);
            setNewScale({ ...newScale, discrete });
        } else {
            let newDomain = [ ...domain, additionalDomainDiscrete];
            setDomain(newDomain);
            setNewScale({ ...newScale, discrete });
        }
    }

    const handleChange = (e) => {
        const palette = e.target.value;
        let newDomain = [];

        if ( palette === 'custom'){
            newDomain = [ ...domainInitState];
        } else {
            const colorsArray = scalesPalette[palette];
            newDomain = colorsArray.map(col => {
                return {
                    value: 0,
                    quantile: false,
                    color: col
                };
            });
        }
        setNewScale({ ...newScale, palette });
        setDomain(newDomain);
    }

    const handleValueChange = (e, index) => {
        const newDomain = [ ...domain];
        const isQuantile = domain[index].quantile;
        const newValue = e.target.value;
        newDomain[index].value = isQuantile && (newValue > 1 || newValue < 0) ? 1 : newValue;
        setDomain(newDomain);
    }

    const handleColorChange = (value, index) => {
        const newDomain = [ ...domain];
        newDomain[index].color = `${value.hex}`;
        setDomain(newDomain);
    };

    const handleQuantileChange = (e, index) => {
        const newDomain = [ ...domain];
        newDomain[index].quantile = e.target.checked;
        if (e.target.checked && (newDomain[index].value > 1 || newDomain[index].value < 0))
            newDomain[index].value = '';
        setDomain(newDomain);
    }

    const addNewElement =  () => {
        const newDomain = [ ...domain];
        newDomain.splice(1, 0, { ...domainInitState[0]});
        setDomain(newDomain);
    }

    const removeElement = (index) => {
        const newDomain = [ ...domain];
        newDomain.splice(index, 1);
        setDomain(newDomain);
    }

    const onEndDrag = ({ destination, source }) => {
        if (!destination) return;
        const newDomain = reorder(domain, source.index, destination.index);
        setDomain(newDomain);
    }

    const handleSubmit = async () => {
        const isDomainValid = newScale.discrete ? checkDomainDiscrete(domain) : checkDomainLinear(domain);
        setDomainValid(isDomainValid);

        const { validId, newErrorState } = checkId(newScale.scaleId, errorInitState);
        setError({ ...newErrorState });

        const valid = isDomainValid && validId;

        if (valid)
            addNewScale(newScale, domain);
    };

    const handleClose = () => {
        setError(errorInitState);
        setDomain( [...domainInitState]);
        setNewScale({...initState});
        handleCloseDialog();
    };

    return (
        <Dialog open={openDialog} onClose={handleClose} sx={NewScaleDialog}>
            <DialogTitle id="confirm-dialog-title">
                Add new scale
            </DialogTitle>

            <DialogContent style={{height: '450px', overflow: 'overlay',}}>
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'lineage'} label={'Lineage'} />
                    <CustomSelect
                        value={newScale.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        { lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}
                        <MenuItem key={'empty'} value={'none'} >{`None (all lineages)`}</MenuItem>

                    </CustomSelect>
                </FormControl>
                <DialogContentText className={classes.helpText}>
                    ScaleId is a combination of measure and its name,<br/> current scale id: {newScale.scaleId}
                </DialogContentText>
                <ScaleIdConfigurator
                    handleScaleIdChange={handleScaleIdChange}
                    newScale={newScale}
                    error={error}
                    edit={false}
                />
                <FormControlLabel
                    className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                    value={newScale.discrete}
                    label={<Typography className={classes.formControlLabel}>Discrete</Typography>}
                    control={(
                        <Checkbox
                            icon={<CircleUnchecked />}
                            checked={newScale.discrete}
                            checkedIcon={<RadioButtonCheckedIcon style={{color: '#6F6CFF'}}/>}
                            onChange={handleCheckboxChange}
                            className={classes.checkbox}
                        />
                    )}
                />
                <div style={{height: '14px'}}/>
                <FormControl className={classes.formControl} fullWidth>
                    <CustomLabel id={'palette'} label={'Scale palette'} />
                    <CustomSelect
                        value={newScale.palette}
                        onChange={handleChange}
                        className={classes.textField}
                        inputProps={{
                            name: 'palette',
                            id: 'palette',
                        }}
                    >
                        {paletteOptions.map((color, index) => {
                            if (color === 'custom' || !scalesPalette[color]?.length)
                                return ( <MenuItem key={`${color}-${index}`} value={color} > {color[0].toUpperCase() + color.slice(1)}</MenuItem> );

                            let gradientStyle = 'linear-gradient(0.25turn, ';

                            scalesPalette[color].forEach((t, index) => {

                                if (index === scalesPalette[color].length - 1)
                                    gradientStyle += index === t.length - 1 ? `${t})` :`${t})`;
                                else
                                    gradientStyle += index === t.length - 1 ? `${t})` :`${t}, `;
                            });

                            const text = <div style={{whiteSpace: 'pre-line'}}>
                                <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                            </div>



                            return (
                                <Tooltip followCursor key={`${color}-${index}`} value={color} placement="bottom-start" title={text}  className={classes.tooltip} >
                                    <MenuItem className={classes.tooltip} >{color[0].toUpperCase() + color.slice(1)}</MenuItem>
                                </Tooltip>
                            )
                        })}

                    </CustomSelect>
                </FormControl>

                {!domainValid &&
                <DialogContentText className={classes.domainError}>
                    Please make sure that values are increasing!
                </DialogContentText>
                }

                <DraggableList
                    domain={domain}
                    newScale={newScale}
                    handleValueChange={handleValueChange}
                    handleColorChange={handleColorChange}
                    handleQuantileChange={handleQuantileChange}
                    addNewElement={addNewElement}
                    onEndDrag={onEndDrag}
                    removeElement={removeElement}
                />
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleClose}>Cancel</Button>
                <Button className={classes.confirm}
                    onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    const { lineages } = state.lineages;
    return {
        lineages
    }
};

// const mapDispatchToProps = (dispatch) =>
//     bindActionCreators(
//         {

//         },
//         dispatch,
// );

export default connect(mapStateToProps)(AddNewScaleDialog);
