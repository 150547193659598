import React from 'react';
import { withStyles } from '@mui/styles';
import { InputLabel } from '@mui/material';

const styles = () => ({
    label: {
        color: 'white',
        transform: 'none', 
        fontSize: '12px',
        '&.Mui-focused': { 
            color: 'white'
        },
    }
})

const LineageSelectorLabel = (props) => {
    const { classes, id, label, required, error } = props;
    return (
        <InputLabel error={error} required={required} className={classes.label} htmlFor={id} id={`${id}-label`}>
            {label}
        </InputLabel>
    )
}

export default withStyles(styles)(LineageSelectorLabel)
