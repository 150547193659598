import config from "../../config/envConfig";
import { fetchAxios } from "../../functions/axiosRequests";

export const fetchModelTypes = async ( lineage, modelRegionId ) => {
    const colorBy = 'fitness';
    const url = `${config.serverLink}/api/models/types?lineage=${lineage}&modelRegionId=${modelRegionId}&colorBy=${colorBy}`;

    const result = await fetchAxios(url).then(function (response) {
        return response.data.modelTypes;
    }).catch(function (error) {
        console.log(error)
    });

    return [ '', ...result ];
};

export const fetchModels = async (lineage, modelRegionId, modelType) => {
    const colorBy = 'fitness';
    const url = `${config.serverLink}/api/models?lineage=${lineage}&colorBy=${colorBy}&modelRegionId=${modelRegionId}&modelType=${modelType}`;

    const result = await fetchAxios(url).then(function (response) {
        return response.data.models;
        // setModels(ret);
    }).catch(function (error) {
        console.log(error)
    })

    return ['', ...result];
};

export const checkModelState = (model, modelTypeOptions, modelIdOptions) => { 
    let good = true;
    if (!model.modelId || !model.modelType)
        good = false;
    if (!modelTypeOptions?.includes(model.modelType) || !modelIdOptions?.includes(model.modelId))
        good = false;

    return good;
};

