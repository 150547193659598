import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { postAxios } from '../../../../functions/axiosRequests';
import { styles } from './styles';
import config from '../../../../config/envConfig';
import EditMutClassDialog from '../Dialogs/Edit';

const Edit = (props) => {
    const [ openDialog, setOpenDialog ] = useState(false);
    const { mutClass, setInfo, setInfoDialog, setAction, updateTable, mutationGenes } = props;
    const classes = styles();

    const handleClick = async () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const updateMutClass = async (body) => {
        const keys = Object.keys(body.positions);
        let result = {};

        keys.forEach(key => {
            body.positions[key].forEach(range => {
                for (let i = range.min; i <= range.max; i++) {
                    result[i] = true;
                }
            })
            body.positions[key] = result;
            result = {};
        });
        const url = `${config.serverLink}/api/admin/updateMutationClass`;

        postAxios(url, { mutClass: { ...body }})
            .then((response) => {
                if (response.data.status){
                    handleCloseDialog();
                    setInfoDialog(true);
                    setAction('Update mutation class');
                    updateTable();
                    setInfo(`Mutation class ${body.name} was updated sucessfully`);
                } else {
                    handleCloseDialog();
                    setInfoDialog(true);
                    setAction('Update mutation class');
                    updateTable();
                    setInfo(`Mutation class ${body.name} wasn't updated`);
                }
        });
    };

    return (
        <>
            <IconButton onClick={handleClick} className={classes.button}>
                <EditIcon />
            </IconButton>
            {  openDialog &&
                <EditMutClassDialog mutationGenes={mutationGenes} openDialog={openDialog} handleCloseDialog={handleCloseDialog} updateMutClass={updateMutClass} mutClass={mutClass} />
            }
        </>
    );
};

export default Edit;
