import { makeStyles } from '@mui/styles';

export const styles = makeStyles(() =>({
    addScale: {
        marginLeft: '10px'
    },
    button: {
        padding: '0px'
    }
}));
