import {
    SET_COLOR_BY,
    SET_MODEL_TYPE,
    SET_REFERENCE_CLADE,
    SET_REFERENCE_STRAIN,
    SET_PARAMETERS,
    SET_LAYOUT_PARAMETERS,
    SET_MEASURE_DOMAIN,
    SET_MEASURE_SCALE,
    TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY,
    SET_MODEL_REGION_ID,
    SET_SELECTED_MODELS,
    SET_SHOW_MUTATIONS_GROUPS,
    SET_SHOW_REFERENCE_STRAINS,
    SET_SHOW_CLADE_LABELS,
    SET_BRANCH_NODES,
} from './actionTypes';

// Base Actions
import { received } from '../baseActions';

export const setColorBy = (payload) => received(SET_COLOR_BY, payload);

export const setBranchNodes = (payload) => received(SET_BRANCH_NODES, payload);

export const setModelRegionId = (modelRegionId, colorBy) => received(SET_MODEL_REGION_ID, { modelRegionId, colorBy })

export const setModelType = (modelType, colorBy) => received(SET_MODEL_TYPE, { modelType, colorBy })

export const setMeasureDomain = (parameters) => received(SET_MEASURE_DOMAIN, parameters);

export const setReferenceClade = refClade => received(SET_REFERENCE_CLADE, { refClade })

export const setReferenceStrain = (refStrain, searchId) => received(SET_REFERENCE_STRAIN, { refStrain, searchId });

export const toggleMutationClassLabelVisibility = (mutOptionId) => received(TOGGLE_MUTATION_CLASS_LABEL_VISIBILITY, { id: mutOptionId });

export const setParameters = parameters => received(SET_PARAMETERS, { parameters });

export const setLayoutParameters = parameters => received(SET_LAYOUT_PARAMETERS, { parameters });

export const setShowMutationsGroups = showMutationsGroups => received(SET_SHOW_MUTATIONS_GROUPS, { showMutationsGroups });

export const setShowReferenceStrains = showReferenceStrains => received(SET_SHOW_REFERENCE_STRAINS, { showReferenceStrains });

export const setShowCladeLabels = showCladeLabels => received(SET_SHOW_CLADE_LABELS, { showCladeLabels});

export const setMeasureScale = (payload) => received(SET_MEASURE_SCALE, payload);

export const setSelectedModels = (payload) => received(SET_SELECTED_MODELS, payload);

