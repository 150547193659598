import { cloneDeep, forEach } from 'lodash';
import deepFreeze from 'deep-freeze';
import { emptyObject } from '../../functions/functions';
import {
    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST,
    FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_SCORES_ERROR,
    FETCH_FREQUENCIES_SUCCESS,
    FETCH_RECALCULATED_TREE_SUCCESS,
    // INIT_STRAIN_TREE_SUCCESS,
    // ZOOM_TREE_SUCCESS,
    SIGNOUT_REQUEST,
    RESET_SESSION,
    INIT_STRAIN_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_VP_VALUES_REQUEST,
    ZOOM_TREE_REQUEST,
    FETCH_SELECTED_STRAIN_REQUEST,
    // FETCH_VISIBLE_NODES_REQUEST,
    SET_COLOR_BY,
    SET_BRANCH_NODES,
    FETCH_BRANCH_NODES_REQUEST,
    FETCH_BRANCH_NODES_SUCCESS,
    FETCH_BRANCH_NODES_ERROR
} from '../actions/actionTypes';

// Initial State
let customTreeDataInitialState = {};
export const setCustomTreeDataInitialState = (state) => {
    customTreeDataInitialState = state;
};

const customTreeDataReducer = (state = customTreeDataInitialState, action) => {
    deepFreeze(state);
    switch (action.type) {
        case RESET_SESSION:
        case SIGNOUT_REQUEST: {
            return customTreeDataInitialState;
        }

        case FETCH_CUSTOM_TREE_ATTRS_REQUEST: {
            const { colorBy, branchNodes } = action.payload;
            const status = { ...state.status };
            if (colorBy) status[colorBy] = 'loading';
            if (branchNodes) branchNodes.forEach(k => { status[k] = 'loading'; });
            return {
                ...state,
                status
            };
        }

        case FETCH_CUSTOM_TREE_ATTRS_SUCCESS: {
            const { customTreeAttrData, colorBy, branchNodes } = action.payload;
            const customTreeAttrs = { ...state.customTreeAttrs, ...customTreeAttrData };
            //const customTreeAttrsBins = customTreeAttrDataBins ? { ...state.customTreeAttrsBins, ...customTreeAttrDataBins} : state.customTreeAttrsBins;
            const status = { ...state.status };//cloneDeep(state.status); //{ ...state.status };
            if (colorBy) status[colorBy] = 'loaded';
            if (branchNodes) branchNodes.forEach(k => { status[k] = 'loaded'; });

            // Object.keys(customTreeAttrData||{}).forEach(k => { status[k] = 'loaded'; });
            return {
                ...state,
                customTreeAttrs,
                //customTreeAttrsBins,
                status
            };
        }


        case FETCH_BRANCH_NODES_REQUEST: {
            const { branchNodes } = action.payload;
            const status = { ...state.status };
            if (branchNodes) branchNodes.forEach(k => { status[k] = 'loading'; });
            return {
                ...state,
                status
            };
        }

        case FETCH_BRANCH_NODES_SUCCESS: {
            const { branchNodesData, branchNodes } = action.payload;
            const customTreeAttrs = { ...state.customTreeAttrs, ...branchNodesData };
            //const customTreeAttrsBins = customTreeAttrDataBins ? { ...state.customTreeAttrsBins, ...customTreeAttrDataBins} : state.customTreeAttrsBins;
            const status = { ...state.status };//cloneDeep(state.status); //{ ...state.status };
           
            if (branchNodes) 
                branchNodes.forEach(k => { status[k] = 'loaded'; });

            return {
                ...state,
                customTreeAttrs,
                //customTreeAttrsBins,
                status
            };
        }

        // case ZOOM_TREE_SUCCESS:
        // case FETCH_RECALCULATED_TREE_SUCCESS: {
        //     const { customTreeAttrData } = action.payload;
        //     //console.log(action.payload);
        //     if (!customTreeAttrData || emptyObject(customTreeAttrData)) return { ...state };
        //     const customTreeAttrs = cloneDeep(state.customTreeAttrs);
        //     Object.keys(customTreeAttrData).forEach(k => {
        //         customTreeAttrs[k] = { ...customTreeAttrs[k], ...customTreeAttrData[k] };
        //     })
        //     return {
        //         ...state,
        //         customTreeAttrs
        //     }
        // }

        case FETCH_CUSTOM_TREE_ATTRS_ERROR: {
            const { colorBy } = action.payload;
            const status = { ...state.status };
            if (colorBy) status[colorBy] = 'error';
            return {
                ...state,
                status
            };
        }

        case FETCH_BRANCH_NODES_ERROR: {
            const { branchNodes } = action.payload;
            const status = { ...state.status };
            if (branchNodes) branchNodes.forEach(k => { status[k] = 'error'; });
            return {
                ...state,
                status
            };
        }



        case INIT_STRAIN_TREE_REQUEST:
        case FETCH_SELECTED_STRAIN_REQUEST:
        case ZOOM_TREE_REQUEST:
        case FETCH_VP_VALUES_REQUEST:
        case FETCH_SUBSET_TREE_REQUEST:
        case FETCH_RECALCULATED_TREE_REQUEST:
            // case FETCH_VISIBLE_NODES_REQUEST: 
            {
                //const { colorBy } = action.payload;
                const status = Object.keys(state.status).reduce((acc, attr) => { acc[attr] = 'refetchNeeded'; return acc; }, {});
                return {
                    ...state,
                    status
                    // /*isColorByModel(colorBy) ?*/ 'refetchNeeded' //: state.modelStatus            
                }
            }

        case FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST: {
            const { gene, hla } = action.payload;
            const tcellStatus = { ...state.tcellStatus };
            if (gene && hla) tcellStatus[`${gene}_${hla}`] = 'loading';
            return {
                ...state,
                tcellStatus
            };
        }

        case FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS: {
            console.log('FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS. custom 1')
            if (emptyObject(action.payload)) {
                return {
                    ...state,
                };
            }
            const { gene, hla, antigenicityScores, antigenicityScoresBins } = action.payload;

            const tcellStatus = { ...state.tcellStatus };
            if (gene && hla) tcellStatus[`${gene}_${hla}`] = 'loaded';

            const tcellAntigenicityScores = { ...state.tcellAntigenicityScores, [`${gene}_${hla}`]: antigenicityScores };
            console.log('tcellAntigenicityScores', tcellAntigenicityScores)
            const tcellAntigenicityScoresBins = antigenicityScoresBins ? { ...state.antigenicityScoresBins, [`${gene}_${hla}`]: antigenicityScoresBins } : state.antigenicityScoresBins;
            console.log('FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS. custom 2')
            return {
                ...state,
                tcellAntigenicityScores,
                tcellAntigenicityScoresBins,
                tcellStatus,
            };
        }

        // case INIT_STRAIN_TREE_SUCCESS:
        case FETCH_FREQUENCIES_SUCCESS: {
            // console.log(action.payload);
            const { customTreeAttrData /*, customTreeAttrDataBins*/ } = action.payload;

            const customTreeAttrs = { ...state.customTreeAttrs, ...customTreeAttrData };
            //const customTreeAttrsBins = customTreeAttrDataBins ? { ...state.customTreeAttrsBins, ...customTreeAttrDataBins} : state.customTreeAttrsBins;
            const status = { ...state.status };
            if (customTreeAttrData) Object.keys(customTreeAttrData).forEach(k => { status[k] = 'loaded'; });

            const { gene, hla, antigenicityScores, antigenicityScoresBins } = action.payload;
            const tcellStatus = { ...state.tcellStatus };
            if (gene && hla) tcellStatus[`${gene}_${hla}`] = 'loaded';

            const tcellAntigenicityScores = { ...state.tcellAntigenicityScores, ...antigenicityScores };
            const tcellAntigenicityScoresBins = antigenicityScoresBins ? { ...state.antigenicityScoresBins, ...antigenicityScoresBins } : state.antigenicityScoresBins;


            return {
                customTreeAttrs,
                //customTreeAttrsBins,
                status,
                tcellAntigenicityScores,
                tcellAntigenicityScoresBins,
                tcellStatus
            };
        }

        case FETCH_TCELL_ANTIGENICITY_SCORES_ERROR: {
            return {
                ...state,
                tcellAntigenicityScoresStatus: 'error'
            };
        }

        case SET_COLOR_BY: {
            const { colorBy } = action.payload;
            const customTreeAttrs = { ...state.customTreeAttrs };
            const status = { ...state.status };
            delete customTreeAttrs[colorBy];
            delete status[colorBy];

            return {
                ...state,
                customTreeAttrs,
                status
            }
        }


        case SET_BRANCH_NODES: {
            const { branchNodes } = action.payload;
            const customTreeAttrs = { ...state.customTreeAttrs };
            const status = { ...state.status };
            (branchNodes || []).forEach(customTreeAttrId => {
                delete customTreeAttrs[customTreeAttrId];
                delete status[customTreeAttrId];
            });

            return {
                ...state,
                customTreeAttrs,
                status
            }
        }

        default:
            return state || customTreeDataInitialState;
    }
};

export { customTreeDataReducer };
