// Action Types
import {
    SIGNIN_REQUEST,
    SIGNIN_SUCCESS,
    SIGNIN_ERROR,
    SIGNOUT_REQUEST,
    //SIGNOUT_REQUEST,
    SIGNOUT_ERROR,
    SET_USERNAME_SUCCESS,
    SET_USERNAME_ERROR,
    GET_SIGNED_USER_REQUEST,
    GET_SIGNED_USER_SUCCESS,
    GET_SIGNED_USER_ERROR,
    SET_USER_PREFERENCES,
    SIGNOUT_SUCCESS
} from '../actions/actionTypes';
import appConfig from '../../config/appConfig';
import { emptyObject } from '../../functions/functions';
import defaults from '../../components/Admin/Roles/defaults';
import deepFreeze from 'deep-freeze';

let userInitialState = {};
export const setUserInitialState = (state) => {
    userInitialState = state;
};

export default function userReducer(state = userInitialState, action) {
    deepFreeze(state);
    switch (action.type) {
        case SIGNIN_REQUEST:
        case GET_SIGNED_USER_REQUEST: {
            return {
                ...state,
                isAuthenticated: false,
                status: 'loading',
                error: null
            };
        }
        case SET_USER_PREFERENCES: {
            const { menuRight, defaultLineage, name } = action.payload;

            return {
                ...state,
                menuRight,
                defaultLineage,
                name
            };
        }
        case SIGNIN_SUCCESS:
        case GET_SIGNED_USER_SUCCESS: {
            // console.log(action.payload);
            let defaultLineageSettled = action.payload.defaultLineage || appConfig.default.lineage;
            const { lineages } = action.payload;
            if (lineages && lineages.length > 0 && !lineages.includes(defaultLineageSettled))
                defaultLineageSettled = lineages[0];
            
            const mergePermissions = (role) => {
                const mergeDefaults = (key) => {
                    return  ({ ...defaults[key], ...role[key] });
                }
                role.antigenicPermissions = mergeDefaults('antigenicPermissions');
                role.frequenciesPermissions = mergeDefaults('frequenciesPermissions');
                role.mapPermissions = mergeDefaults('mapPermissions');
                role.strainTreePermissions = mergeDefaults('strainTreePermissions');
                return role;

            }
            return {
                ...state,
                status: action.payload.userName ? 'loaded' : 'signedOut',
                isAuthenticated: !emptyObject(action.payload.userName),
                userName: action.payload.userName,
                defaultLineage: defaultLineageSettled,
                lineages: (lineages) ? lineages : null,
                role: action.payload.role,
                name: action.payload.name,
                permissions: mergePermissions(action.payload.permissions),
                menuRight: action.payload.menuRight,
                error: null
            };
        }
        case SIGNIN_ERROR:
        case GET_SIGNED_USER_ERROR: {
            return {
                ...state,
                isAuthenticated: false,
                status: 'error',
                error: action.payload.error?.response?.data?.error,
                userName: null,
                defaultLineage: null
            };
        }

        case SIGNOUT_REQUEST: {
            return {
                // ...state,
                ...userInitialState,
                isAuthenticated: false,
                status: 'signedOut',
                error: null,
                defaultLineage: null,

            };
        }
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                status: 'none'
            }
        }
        case SIGNOUT_ERROR: {
            return {
                ...state,
                isAuthenticated: false,
                status: 'error',
                error: action.payload.error,
                defaultLineage: null
            };
        }
        case SET_USERNAME_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                userName: action.payload.userName,
                defaultLineage: (action.payload.lineage) ? action.payload.lineage.defaultLineage : appConfig.default.lineage,
            };
        }
        case SET_USERNAME_ERROR: {
            return {
                ...state,
                isAuthenticated: false,
                userName: null
            };
        }
        default:
            return state || userInitialState;
    }
}
