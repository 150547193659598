import React, { useState, useEffect } from 'react';
// import { ColorBox } from 'mui-color';
import { Button, Popper, Fade, Popover, Typography } from '@mui/material'
import { SketchPicker} from 'react-color'
import { styles } from './styles';

const colors = [ '#008AF2', '#EAEA2E', '#FF4E54', '#4EF399', '#E769A8',
'#8F9BF3', '#F6B74B', '#006697', '#5EE2F2', '#D89C82',
'#5CC3BE', '#A7465D', '#B2FFCA', '#22837A', '#9B59B6',
'#882853'];

const ColorPickerComponent = ({handleColorChange, color, index}) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >

                <SketchPicker disableAlpha presetColors={colors} color={color} onChange={(col) => handleColorChange(col, index) }/>
            </Popover>
            <Button onClick={handleClick}>
                <div style={{ backgroundColor: color}} className={index !== undefined ? classes.colorButton : classes.colorButtonMutations}/>
            </Button>
        </>
    );
};

export default ColorPickerComponent;
