import { THEME } from '../../../config/consts';
import { antigenicStyles } from './antigenicStyles';
import { isFirefox } from '../../../functions/checkBrowser';

const line = {
    position: 'absolute',
    left: '19px',
    content: '""',
    height: '38px',
    width: '2px',
    backgroundColor: THEME.color.white,
};

export const antigenicDetailStyles = () => ({
    ...antigenicStyles,
    root: {
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 60,
        boxSizing: 'border-box',
        overflow: 'auto',
        zIndex: 15,
        display: 'flex',
        flexGrow: 1,
        height: 'calc(100vh - 106px)',
        backgroundColor: THEME.color.darkGray,
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    closeBtn: {
        width: '38px',
        height: '38px',
        cursor: 'pointer',
        zIndex: 20,
        position: 'absolute',
        marginLeft: '70%',
        marginTop: '50px',
        // margin: '30px 30px 30px auto',
        '&::before': {
            ...line,
            transform: 'rotate(45deg)',
        },
        '&::after': {
            ...line,
            transform: 'rotate(-45deg)',
        },
    },
    parentClade: {
        color: THEME.color.white,
        lineHeight: '24px',
        fontSize: '15px',
        fontFamily: 'Inter Bold',
        verticalAlign: 'top',
        textAlign: 'left',
    },
    legend: {
        position: 'absolute',
        right: 0,
        height: '100%',
        width: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
        backgroundColor: '#e2e2e2',
        padding: '30px 20px'
    },
    dataContainer: {
        fontSize: '15px',
        lineHeight: '24px',
        height: '100%',
        maxHeight: '100%',
        overflowY: isFirefox ? 'auto' : 'overlay',
    },
    doDataMsg: {
        margin: '30px',
        color: THEME.color.white,
        fontSize: '21px'
    }
});
