import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@mui/styles';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import { ListItem, Grid, IconButton, FormControlLabel, Typography, Checkbox } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { ColorButton } from "mui-color";
import ColorPickerComponent from './ColorPicker';
import { styles } from '../Dialogs/styles';


const DraggableListItem = ({ discrete, element, prevValue, index, keyId, middleLinear, newScale, handleValueChange, handleColorChange, handleQuantileChange, addNewElement, removeElement, itemsLength, lastDiscrete }) => {
    const classes = styles();

    return (
      <Draggable draggableId={keyId} index={index} >
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? classes.draggingListItem : classes.listItems}
          >

            <Grid container className={classes.domain}>
                <Grid item xs={middleLinear ? 6 : 1.5} className={middleLinear ? classes.colorsGridLinear : classes.colorsGrid} >
                    {  newScale.palette === 'custom' ?
                    <ColorPickerComponent
                        color={element.color}
                        handleColorChange={handleColorChange}
                        index={index}
                    />
                    :
                    <ColorButton disabled color={element.color} />
                    }
                </Grid>
                { lastDiscrete &&
                <>
                    <Grid item xs={2} style={{marginRight: '15px'}} >
                        <StyledTextField
                            id="range"
                            type={index === 0 ? "text" : "number"}
                            disabled
                            label="value min"
                            autoComplete='off'
                            value={prevValue}
                            onChange={(e) => handleValueChange(e, index)}
                        />
                    </Grid>
                    <Grid item xs={2} >
                        <StyledTextField
                            id="range"
                            type="text"
                            disabled
                            label="value max"
                            autoComplete='off'
                            value={Infinity}
                        />
                    </Grid>
                </>

                }
                { !middleLinear && !lastDiscrete &&
                <>
                    {discrete &&
                        <Grid item xs={2} style={{marginRight: '15px'}} >
                            <StyledTextField
                                id="range"
                                type={index === 0 ? "text" : "number"}
                                disabled
                                label="value min"
                                autoComplete='off'
                                value={index === 0 ? -Infinity : prevValue}
                                onChange={(e) => handleValueChange(e, index)}
                            />
                        </Grid>
                    }
                    <Grid item xs={2} style={{marginRight: '15px'}} >
                        <StyledTextField
                            id="range"
                            type="number"
                            label={discrete ? "value max" : 'value'}
                            autoComplete='off'
                            value={element.value}
                            onChange={(e) => handleValueChange(e, index)}
                        />
                    </Grid>
                    </>
                }

                { !middleLinear && !lastDiscrete &&
                    <Grid item xs={2} className={classes.colorsGrid}>
                        <FormControlLabel
                            className={`${classes.newRoleGrid} ${classes.formControlAdd}`}
                            value={element.quantile}
                            label={<Typography className={classes.formControlLabel}>Quantile</Typography>}
                            control={(
                                <Checkbox
                                    icon={<CircleUnchecked />}
                                    checked={element.quantile}
                                    checkedIcon={<RadioButtonCheckedIcon style={{color: '#6F6CFF'}}/>}
                                    onChange={(e) => handleQuantileChange(e, index)}
                                    className={classes.checkbox}
                                />
                            )}
                        />
                    </Grid>
                }
                {
                    itemsLength > 2 && index !== 0 && itemsLength -1 !== index &&  newScale.palette === 'custom' &&
                    <Grid item xs={1} className={middleLinear ? classes.empty : classes.colorsGrid}>
                        <IconButton onClick={() => removeElement(index)} className={middleLinear ? classes.removeLinear : classes.removeDiscrete}>
                            <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Grid>
                }

                { index === 0 && newScale.palette === 'custom' &&
                    <Grid item xs={1} className={`${classes.colorsGrid} ${classes.addMargin}`} >
                        <IconButton onClick={addNewElement}>
                            <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                    </Grid>

                }

            </Grid>
        </ListItem>
        )}
      </Draggable>
    );
  };

export default DraggableListItem;
