import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, FormControl, MenuItem } from "@mui/material";
import CustomLabel from "../../assets/GlobalStyles/CustomLabel";
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import { getAntigenicSegmentsCount } from '../../functions/functions';
import { showSelect } from './functions';
import { resetModelData } from '../../redux/actions/modelActions';
import { resetAntigenicModel } from '../../redux/actions/antigenicActions';
import { resetPredictions } from '../../redux/actions/predictionsActions';
import { setParameters } from '../../redux/actions/parametersActions';
import { styles } from './styles';


const ModelIdSelector = props => {
    const { antigenicSegmentsCount, models, modelId,
        resetModelData, resetAntigenicModel, resetPredictions, setParameters } = props;
    const classes = styles();

    const handleModelIdChange = (e) => {
        const modelIdVar =(modelContext === 'antigenic') 
            ? 'antigenicModelId' 
            : (modelContext === 'antigenicFitness'
                ? 'antigenicFitnessModelId' 
                : 'modelId'
            );
        setParameters({ [modelIdVar]: e.target.value });
        if (modelContext === 'strainTree')
            resetModelData();
        if (modelContext === "antigenic")
            resetAntigenicModel();
        if (modelContext === "frequencies")
            resetPredictions();
    };

    return <>
        {antigenicSegmentsCount === 0 && models &&
            <Grid item sm={12} md={12}>
                {modelId && showSelect(modelId, models) &&
                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id={'model'} label={'Model id'} />
                        <CustomSelect
                            value={modelId}
                            onChange={handleModelIdChange}
                            inputProps={{ name: "model", id: "model" }}
                        >
                            {models.map((id) => (
                                <MenuItem key={id || "_"} value={id}>
                                    {id}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                }
            </Grid>
        }
    </>;
}

const mapStateToProps = (state, ownProps) => {
    //const { modelContext } = ownProps;
    const { colorBy } = state.parameters;

    const modelContext = /*(ownProps.modelContext === "strainTree" && colorBy === "antigenic") ? 'antigenic' :*/ ownProps.modelContext;
    const modelVariable = (modelContext === 'antigenic' || modelContext === 'antigenicFitness')
        ? modelContext
        : ((modelContext === 'frequencies') ? 'fitness' : colorBy);

    const modelId = modelContext === "antigenic" ? state.parameters.antigenicModelId : state.parameters.modelId;
    // console.log('modelVariable', modelVariable, 'modelContext', modelContext)
    const models = state.models.models[modelVariable];
    const antigenicSegmentsCount = getAntigenicSegmentsCount(modelId);

    // console.log(collaboratingCenters, assays, strainPropagations, refStrainPropagations);
    return {
        //modelType: ownProps.fixedModelType || modelType,
        modelId,
        models,
        antigenicSegmentsCount,
        modelContext
    };
};


const mapDispatchToProps = dispatch => bindActionCreators({
    resetAntigenicModel,
    setParameters,
    resetPredictions,
    resetModelData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModelIdSelector);
