import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import { styles, dialog } from './Users/styles';


const ConfirmDialog = (props) => {
    const { handleCloseDialog, openDialog, deleteFunc, email, role } = props;
    const classes = styles();

    const handleSubmit = () => {
        deleteFunc();
    };

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={dialog}>
            <DialogTitle id="confirm-dialog-title">
                Confirmation
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Please confirm deleting {role ? 'role:'  : 'user:'}<br/>{email}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel}
                    onClick={handleCloseDialog}>Cancel</Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>);
};

export default ConfirmDialog;
