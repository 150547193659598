// Action Types
import {
    FETCH_SUBSET_TREE_REQUEST,
    FETCH_SUBSET_TREE_SUCCESS,
    FETCH_SUBSET_TREE_ERROR,
    FETCH_TREE_FREQS_REQUEST,
    FETCH_TREE_FREQS_SUCCESS,
    FETCH_TREE_FREQS_ERROR,
    FETCH_CUSTOM_TREE_ATTRS_REQUEST,
    FETCH_CUSTOM_TREE_ATTRS_SUCCESS,
    FETCH_CUSTOM_TREE_ATTRS_ERROR,
    FETCH_BRANCH_NODES_REQUEST,
    FETCH_BRANCH_NODES_SUCCESS,
    FETCH_BRANCH_NODES_ERROR,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR,
    FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST,
    FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS,
    FETCH_TCELL_ANTIGENICITY_SCORES_ERROR,
    FETCH_SELECTED_STRAIN_REQUEST,
    FETCH_SELECTED_STRAIN_SUCCESS,
    FETCH_SELECTED_STRAIN_ERROR,
    SET_SELECTED_STRAIN,
    SET_STRAIN_SEARCH_STATUS,
    FETCH_VACCINE_CANDIDATES_REQUEST,
    FETCH_VACCINE_CANDIDATES_SUCCESS,
    FETCH_VACCINE_CANDIDATES_ERROR,
    FETCH_VP_METHODS_REQUEST,
    FETCH_VP_METHODS_SUCCESS,
    FETCH_VP_METHODS_ERROR,
    FETCH_MUTATION_CLASSES_REQUEST,
    FETCH_MUTATION_CLASSES_SUCCESS,
    FETCH_MUTATION_CLASSES_ERROR,
    FETCH_VP_VALUES_REQUEST,
    FETCH_VP_VALUES_SUCCESS,
    FETCH_VP_VALUES_ERROR,
    RESET_VP_VALUES,
    FETCH_STRAINS_LIST_REQUEST,
    FETCH_STRAINS_LIST_SUCCESS,
    FETCH_STRAINS_LIST_ERROR,
    SET_STRAINS_LIST,
    FETCH_RECALCULATED_TREE_REQUEST,
    FETCH_RECALCULATED_TREE_SUCCESS,
    FETCH_RECALCULATED_TREE_ERROR,
    SET_MUTATION_CLASSES,
    FETCH_REFERENCE_STRAINS_REQUEST,
    FETCH_REFERENCE_STRAINS_SUCCESS,
    FETCH_REFERENCE_STRAINS_ERROR,
    FETCH_NODE_REQUEST,
    FETCH_NODE_SUCCESS,
    FETCH_NODE_ERROR,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS,
    FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR,
    RESET_REFERENCE_STRAINS
} from './actionTypes';
// Base Actions
import { received } from '../baseActions';

export const fetchRecalculatedTree = (payload) => received(FETCH_RECALCULATED_TREE_REQUEST, payload);
export const fetchRecalculatedTreeSuccess = (payload) => received(FETCH_RECALCULATED_TREE_SUCCESS, payload);
export const fetchRecalculatedTreeError = (payload) => received(FETCH_RECALCULATED_TREE_ERROR, payload);

export const fetchSubsetTree = (params) => received(FETCH_SUBSET_TREE_REQUEST, params);
export const fetchSubsetTreeSuccess = (response) => received(FETCH_SUBSET_TREE_SUCCESS, response);
export const fetchSubsetTreeError = (error) => received(FETCH_SUBSET_TREE_ERROR, error);

export const fetchTreeFreqs = (payload) => received(FETCH_TREE_FREQS_REQUEST, payload);
export const fetchTreeFreqsSuccess = (response) => received(FETCH_TREE_FREQS_SUCCESS, response);
export const fetchTreeFreqsError = (error) => received(FETCH_TREE_FREQS_ERROR, error);

export const fetchCustomTreeAttrs = (payload) => received(FETCH_CUSTOM_TREE_ATTRS_REQUEST, payload);
export const fetchCustomTreeAttrsSuccess = (response) => received(FETCH_CUSTOM_TREE_ATTRS_SUCCESS, response);
export const fetchCustomTreeAttrsError = (error) => received(FETCH_CUSTOM_TREE_ATTRS_ERROR, error);

export const fetchBranchNodes = payload => received(FETCH_BRANCH_NODES_REQUEST, payload);
export const fetchBranchNodesSuccess = response => received(FETCH_BRANCH_NODES_SUCCESS, response);
export const fetchBranchNodesError = error => received(FETCH_BRANCH_NODES_ERROR, error);

export const fetchTCellAntigenicityOptions = (payload) => received(FETCH_TCELL_ANTIGENICITY_OPTIONS_REQUEST, payload);
export const fetchTCellAntigenicityOptionsSuccess = (response) => received(FETCH_TCELL_ANTIGENICITY_OPTIONS_SUCCESS, response);
export const fetchTCellAntigenicityOptionsError = (error) => received(FETCH_TCELL_ANTIGENICITY_OPTIONS_ERROR, error);

export const fetchTCellAntigenicityScores = (payload) => received(FETCH_TCELL_ANTIGENICITY_SCORES_REQUEST, payload);
export const fetchTCellAntigenicityScoresSuccess = (response) => received(FETCH_TCELL_ANTIGENICITY_SCORES_SUCCESS, response);
export const fetchTCellAntigenicityScoresError = (error) => received(FETCH_TCELL_ANTIGENICITY_SCORES_ERROR, error);

export const fetchSelectedStrain = (payload) => received(FETCH_SELECTED_STRAIN_REQUEST, payload);
export const fetchSelectedStrainSuccess = (response) => received(FETCH_SELECTED_STRAIN_SUCCESS, response);
export const fetchSelectedStrainError = (error) => received(FETCH_SELECTED_STRAIN_ERROR, error);
export const setSelectedStrain = (payload) => received(SET_SELECTED_STRAIN, payload);

export const fetchAntigenicReferenceStrain = (payload) => received(FETCH_ANTIGENIC_REFERENCE_STRAIN_REQUEST, payload);
export const fetchAntigenicReferenceStrainSuccess = (response) => received(FETCH_ANTIGENIC_REFERENCE_STRAIN_SUCCESS, response);
export const fetchAntigenicReferenceStrainError = (error) => received(FETCH_ANTIGENIC_REFERENCE_STRAIN_ERROR, error);

export const fetchStrainsList = (payload) => received(FETCH_STRAINS_LIST_REQUEST, payload);
export const fetchStrainsListSuccess = (response) => received(FETCH_STRAINS_LIST_SUCCESS, response);
export const fetchStrainsListError = (error) => received(FETCH_STRAINS_LIST_ERROR, error);

export const fetchVaccineCandidates = (payload) => received(FETCH_VACCINE_CANDIDATES_REQUEST, payload);
export const fetchVaccineCandidatesSuccess = (response) => received(FETCH_VACCINE_CANDIDATES_SUCCESS, response);
export const fetchVaccineCandidatesError = (error) => received(FETCH_VACCINE_CANDIDATES_ERROR, error);

export const fetchVpMethods = (payload) => received(FETCH_VP_METHODS_REQUEST, payload);
export const fetchVpMethodsSuccess = (payload) => received(FETCH_VP_METHODS_SUCCESS, payload);
export const fetchVpMethodsError = (payload) => received(FETCH_VP_METHODS_ERROR, payload);

export const fetchMutationClasses = (payload) => received(FETCH_MUTATION_CLASSES_REQUEST, payload);
export const fetchMutationClassesSuccess = (payload) => received(FETCH_MUTATION_CLASSES_SUCCESS, payload);
export const fetchMutationClassesError = (payload) => received(FETCH_MUTATION_CLASSES_ERROR, payload);

export const setMutationClasses = (payload) => received(SET_MUTATION_CLASSES, payload);

export const fetchVpValues = (payload) => received(FETCH_VP_VALUES_REQUEST, payload);
export const fetchVpValuesSuccess = (payload) => received(FETCH_VP_VALUES_SUCCESS, payload);
export const fetchVpValuesError = (payload) => received(FETCH_VP_VALUES_ERROR, payload);
export const resetVpValues = (payload) => received(RESET_VP_VALUES, payload);

export const fetchNode = (payload) => received(FETCH_NODE_REQUEST, payload);
export const fetchNodeSuccess = (payload) => received(FETCH_NODE_SUCCESS, payload);
export const fetchNodeError = (payload) => received(FETCH_NODE_ERROR, payload);

export const fetchReferenceStrains = ( payload ) => received(FETCH_REFERENCE_STRAINS_REQUEST, payload);
export const fetchReferenceStrainsSuccess = ( payload ) => received(FETCH_REFERENCE_STRAINS_SUCCESS, payload);
export const fetchReferenceStrainsError = ( payload ) => received(FETCH_REFERENCE_STRAINS_ERROR, payload);
export const resetReferenceStrains = (payload) => received(RESET_REFERENCE_STRAINS, payload);

// export export const fetchColorByRuleStrains = (payload) => received(FETCH_COLOR_BY_RULE_STRAINS_REQUEST, payload);
// export export const fetchColorByRuleStrainsSuccess = (payload) => received(FETCH_COLOR_BY_RULE_STRAINS_SUCCESS, payload);
// export export const fetchColorByRuleStrainsError = (payload) => received(FETCH_COLOR_BY_RULE_STRAINS_ERROR, payload);

export const setStrainSearchStatus = (payload) => received(SET_STRAIN_SEARCH_STATUS, payload);
export const setStrainsList = (payload) => received(SET_STRAINS_LIST, payload);
