import { makeStyles } from '@mui/styles';

export const styles = makeStyles(theme =>
    ({
    root: {
        flexGrow: 1,
        height: 'calc(100vh - 132px)',
        maxHeight: 'calc(100vh - 132px)',
    },
    container: {
        height: '100%',
        maxHeight: '100%'
    },
    item: {
        position: 'relative',
        height: '100%',
        overflowY: 'overlay',
        maxHeight: '80vh',
    },
    sidebar: {
        position: 'absolute',
        width: '100%',
        right: 0,
        height: 'calc(100% - 84px)',
        overflowY: 'overlay',
        padding: '0px 15px 0px 0px'
    },
    rootSidebar: {
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        width: '100%',
        textAlign: 'left',
        backgroundColor: '#fff',
        padding: '0px 0px 0px 0px',
    },
    submit: {
        minWidth: 120,
        backgroundColor: '#e0e0e0',
        color: '#000000',
        marginBottom: '15px',
        width: '80%',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    formControl: {
        margin: '15px 0px 0px 0px',
        alignItems: 'center',
    },
    welcome: {
        position: 'absolute',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '23px',
        marginLeft: '2%',
        color: '#000000'
    }
}));
