import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setAntigenicHiddenAlphaClade,
    setAntigenicHiddenRhoClade,
} from '../../redux/actions/antigenicActions';
import { antigenicModelStatusSelector } from '../../redux/selectors/statusSelector';
import { getAntigenicMatrixData, selectedCladesSelector } from '../../redux/selectors/antigenicDataSelector';
import { getRanges } from '../../redux/selectors/rangeDataSelector';
import { setRenderStatus } from '../../redux/actions/renderActions';
import { emptyObject, isNull } from '../../functions/functions';
import { RENDER_STATUS } from '../../config/consts';
import { antigenicStyles } from './styles/antigenicStyles';
import MetaInformations from '../MetaInformations/MetaInformations';
import Matrix from '../Matrix/Matrix';

const AntigenicData = (props) => {
    const _element = useRef();

    const { rows, columns, data, clades, dataInitialized, exportMode, setRenderStatus, loading } = props;

    useEffect(() => {
        if (dataInitialized) setRenderStatus(RENDER_STATUS.DONE);
    })

    const getClasses = () => {
        const { classes, exportMode } = props;
        const exportPropsMap = Object.keys(classes)
            .filter((key) => key.match('Export$'))
            .reduce((tmp, key) => {
                tmp[key] = key;
                return tmp;
            }, {});
        const regProps = Object.keys(classes)
            .filter((key) => !key.match('Export$'))
            .map((key) => ({ key, exportKey: exportPropsMap[`${key}Export`] }));
        return regProps.reduce((tmpProps, prop) => {
            tmpProps[prop.key] =
                exportMode && exportPropsMap[prop.exportKey] ? classes[prop.exportKey] : classes[prop.key];
            return tmpProps;
        }, {});
    }

    const classes = getClasses();

    if (emptyObject(data) && !loading)
        return <div className={classes.root}>No data for this combination of parameters</div>;
    if (emptyObject(clades) || !columns || !rows) {
        return <div />;
    }


    return (
        <div className={`${classes.rootData} ${exportMode ? classes.rootEx : ''}`} ref={_element} width="100%" height="100%">
            <Matrix {...props} />
            <div />
            <MetaInformations />
        </div>
    );
}


AntigenicData.propTypes = {
    exportMode: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const loading = antigenicModelStatusSelector(state);
    const { hiddenAlphaClades, hiddenRhoClades, exportMode, showAntigenicTableValues, wrapAntigenicTableHeaders } = state.parameters;
    const { rhosR, alphasY, alphas, rhos } = selectedCladesSelector(state);
    const data = getAntigenicMatrixData(state);
    const ranges = getRanges(state);
    const { clades } = state.cladeData;
    return {
        componentId: 'antigenic',
        data,
        dataScale: 'antigenicColorScale',
        lastColumnLabel: 'CLADE FREQUENCY',
        lastColumnScale: 'alphaYColorScale',
        lastColumnValues: alphasY,
        lastRowLabel: 'IMMUNE COHORT WEIGHT',
        lastRowScale: 'rhoRColorScale',
        lastRowValues: rhosR,
        rows: alphas,
        columns: rhos,
        clades,
        hiddenRows: hiddenAlphaClades,
        hiddenColumns: hiddenRhoClades,
        ranges,
        loading,
        exportMode,
        showValues: showAntigenicTableValues,
        wrapTableHeaders: wrapAntigenicTableHeaders
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setAntigenicHiddenAlphaClade,
            setAntigenicHiddenRhoClade,
            setRenderStatus,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(antigenicStyles)(AntigenicData));
