import PropTypes from 'prop-types';

export const RegionalFrequenciesResportType = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        container: PropTypes.string,
        item: PropTypes.string,
        itemExport: PropTypes.string,
        legend: PropTypes.string,
        rootExport: PropTypes.string,
        containerExport: PropTypes.string
    }),
    modelsStatus: PropTypes.string,
    cladesStatus: PropTypes.string,
    lineage: PropTypes.string,
    freqCategory: PropTypes.string,
    lineageStatus: PropTypes.string,
    modelId: PropTypes.string,
    regionId: PropTypes.string,
    trackingFrom: PropTypes.instanceOf(Date),
    predictionBaseline: PropTypes.instanceOf(Date),
    trackingTo: PropTypes.instanceOf(Date),
    gene: PropTypes.string,
    hla: PropTypes.string,
    // binMethod: PropTypes.string,
    // binCnt: PropTypes.number,
    exportMode: PropTypes.bool,
    renderStatus: PropTypes.string,
    exportParams: PropTypes.shape({}),
    frequenciesStatus: PropTypes.string,
    fetchLineage: PropTypes.func,
    fetchFrequencies: PropTypes.func,
    predictionsStatus: PropTypes.string,
    colorBy: PropTypes.string,
    zoomNodeId: PropTypes.number,
    strainSubset: PropTypes.string,
    treeDataStatus: PropTypes.string,
    tcellAntigenicityOptionsStatus: PropTypes.string,
    fetchTCellAntigenicityOptions: PropTypes.func,
    fetchPredictions: PropTypes.func,
    fetchClades: PropTypes.func,
    initStrainTree: PropTypes.func
};
