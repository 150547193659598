// Base Actions
export const request = (type) => ({
    type
});


export const received = (type, payload) => ({
    type,
    payload,
});




// export const received =
//     (type, payload) => {
//         return (dispatch, getState) => {
//             dispatch({
//                 type,
//                 payload,
//             });
//         };
//     };

export const error = (type, payload) => ({
    type,
    payload
});
