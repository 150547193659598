import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton } from '@mui/material';
import ScalesLegend from '../../ColorLegend/Legends/ScalesLegend';
import { setLayoutParameters, setParameters } from '../../../redux/actions/parametersActions';
import { getNodeDataById } from '../../../redux/selectors/nodeDataSelector';
import ModelSelector from '../../ModelSelector/ModelSelector';
import ColorBySelector from '../../OptionsSelector/ColorBySelector';
import BranchColorBySelector from '../../OptionsSelector/BranchColorBySelector';
import LineageSelector from '../../OptionsSelector/LineageSelector';
import TreeScaleTypeSelector from '../../OptionsSelector/TreeScaleTypeSelector';
import NodeVisibilitySelector from '../../OptionsSelector/NodeVisibilitySelector';
import StrainsSubsetSelector from '../../OptionsSelector/StrainsSubsetSelector';
import StrainsHighlightSelector from '../../OptionsSelector/StrainsHighlightSelector';
import VpMethodSelector from '../../OptionsSelector/VpMethodSelector';
import ShowLeafNumberSelector from '../../OptionsSelector/ShowLeafNumberSelector';
import DisplayOrderSelector from '../../OptionsSelector/DisplayOrderSelector';
import AdditionalColorBySelector from '../../OptionsSelector/AdditionalColorBySelector';
import PredictionBaselinePicker from '../../DatePickers/PredictionBaselinePicker';
import StrainCutoffDatePicker from '../../DatePickers/StrainCutoffDatePicker';
import ColorScaleSelector from '../../OptionsSelector/ColorScaleSelector';
import Alerts from '../../Alerts/Alerts';
import TreeStrainSearch from '../TreeStrainSearch';
import { emptyObject } from '../../../functions/functions';
import TreeLegend from '../../ColorLegend/Legends/TreeLegend';
import { styles } from './styles';
import HamburgerIcon from '../../SvgIcons/HamburgerIcon';
import { HideIcon } from '../../Alerts/styles';
import { setHiddenMenu } from '../../../redux/actions/renderActions';
import { getIsMobile } from '../../../redux/selectors/statusSelector';
import CladeBarTypeSelector from '../../OptionsSelector/CladeBarTypeSelector';
import SubmissionDatePicker from '../../DatePickers/SubmissionDatePicker';
import LayoutSelector from '../../OptionsSelector/LayoutSelector';


const introSelectors = {
    colorBySelector: true,
    modelSelector: true,
    antigenicColorByOptionsSelector: true,
    layoutSelector: true,
    treeScaleTypeSelector: true,
    strainCutoffDateSelector: true,
    nodeVisibilitySelector: true,
    searchStrainSelector: true
}


const useStyles = makeStyles(styles);


const TreeGraphOptions = props => {
    const { colorBy, nodeId, permissions, showAlert, hiddenMenu, isMobile, showCladeBar,
        intro, setHiddenMenu, modelParam } = props;

    const showSelector = (selectorName) => permissions[selectorName] || (intro && introSelectors[selectorName]);

    const classes = useStyles();


    const handleHideClick = () => {
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    }


    // console.log(( permissions.modelSelector || intro) && modelParam);

    return (
        <>
            {!hiddenMenu ?
                <div
                    style={nodeId ? { padding: 0, margin: 0 } : {}}
                    className={showAlert ?
                        isMobile ?
                            classes.rootWithAlertsMobile
                            : classes.rootWithAlerts
                        : isMobile ?
                            classes.rootWithoutAlertsMobile
                            : classes.rootWithoutAlerts
                    }>
                    <div style={{ width: '100%' }}>
                        {emptyObject(nodeId) && (
                            <form autoComplete="off" style={{ paddingBottom: '55px' }}>
                                {
                                    !isMobile && !nodeId &&
                                    <div className={classes.buttonWrapper}>
                                        <IconButton className={classes.hideButton} onClick={handleHideClick}>
                                            <HideIcon />
                                        </IconButton>
                                    </div>
                                }
                                <Alerts module="strainTree" />
                                {showSelector('colorBySelector') && <ColorBySelector intro={intro} />}
                                {showSelector('modelSelector') && modelParam && <ModelSelector modelContext="strainTree" fixedModelType={colorBy === 'antigenic' ? 'Antigenic' : undefined} />}
                                {showSelector('antigenicColorByOptionsSelector') && <AdditionalColorBySelector />}
                                {showSelector('cladeBarTypeSelector') && showCladeBar && <CladeBarTypeSelector />}
                                <TreeLegend />
                                {showSelector('colorScaleSelector') && <ColorScaleSelector />}
                                <ScalesLegend
                                    scaleId="strainTree"
                                    valueGetter={(state) => getNodeDataById(state)[colorBy]}
                                    ticks={3}
                                    inContainer
                                    precision={3}
                                    mode="strainTree" />

                                {showSelector('branchNodesSelector') && <BranchColorBySelector />}
                                {showSelector('vpMethodSelector') && <VpMethodSelector />}
                                {showSelector('layoutSelector') && <LayoutSelector/>}
                                {showSelector('treeScaleTypeSelector') && <TreeScaleTypeSelector intro={intro} />}

                                {showSelector('predictionBaselineSelector') && <PredictionBaselinePicker type={'predictionBaseline'} />}
                                <Grid container className={classes.datePickers} spacing={2}>
                                    {showSelector('strainCutoffDateSelector') && <Grid item sm={12} md={showSelector('submissionDateSelector') ? 6 : 12}><StrainCutoffDatePicker /></Grid>}
                                    {showSelector('submissionDateSelector') && <Grid item sm={12} md={6}><SubmissionDatePicker /></Grid>}
                                </Grid>
                                <Grid container spacing={2} style={{ paddingTop: '15px' }}>
                                    {showSelector('showLeafNumberSelector') && <Grid style={{ paddingTop: 0 }} item sm={12} md={6}><ShowLeafNumberSelector /></Grid>}
                                    {showSelector('displayOrderSelector') && <Grid style={{ paddingTop: 0 }} item sm={12} md={intro ? 12 : 6}><DisplayOrderSelector /></Grid>}
                                </Grid>
                                {showSelector('nodeVisibilitySelector') && <NodeVisibilitySelector intro={intro} />}
                                {showSelector('strainsSubsetSelector') && <StrainsSubsetSelector context="strainTree" />}
                                {showSelector('strainsHighlightSelector') && <StrainsHighlightSelector />}
                                {showSelector('searchStrainSelector') && <TreeStrainSearch label="Search strain" searchId="searchStrain" />}
                            </form>
                        )}
                    </div>
                </div>
                :
                <IconButton className={classes.expandButton} onClick={handleHideClick}>
                    <HamburgerIcon />
                </IconButton>

            }
        </>
    );

}
TreeGraphOptions.propTypes = {
    colorBy: PropTypes.string,
    modelParam: PropTypes.bool,
    nodeId: PropTypes.number,
    setParameters: PropTypes.func,
    fetchTreeFreqs: PropTypes.func
};

const mapStateToProps = ({ metadata, models, lineages, parameters, nodeData, user, alert, render }) => ({
    ...models,
    ...lineages,
    colorBy: parameters.colorBy,
    lineage: parameters.lineage,
    showCladeBar: parameters.showCladeBar,
    modelParam: metadata.measures[parameters.colorBy] && metadata.measures[parameters.colorBy].model ? true : false,
    nodeId: nodeData.onlyHighlight ? undefined : nodeData.nodeId,
    permissions: user.permissions.strainTreePermissions,
    showAlert: alert.strainTree.show,
    hiddenMenu: render.hiddenMenu,
    isMobile: getIsMobile()
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setParameters,
        setLayoutParameters,
        setHiddenMenu
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TreeGraphOptions);
