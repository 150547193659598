import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { FormControl, FormControlLabel, Checkbox, Grid, Typography, InputAdornment, IconButton } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import DatesSelector from '../../OptionsSelector/DatesSelector';
import PlotTypeSelector from '../../OptionsSelector/PlotTypeSelector';
import ChartLayoutSelector from '../../OptionsSelector/ChartLayoutSelector';
import FrequenciesCategorySelector from '../../OptionsSelector/FrequenciesCategorySelector';
import StrainsSubsetSelector from '../../OptionsSelector/StrainsSubsetSelector';
import RegionalFreqButton from '../../Reports/connect';
import FrequenciesLegend from '../../ColorLegend/Legends/FrequenciesLegend';
import ScalesLegend from '../../ColorLegend/Legends/ScalesLegend';
import ColorScaleSelector from '../../OptionsSelector/ColorScaleSelector';
import YScaleSelector from '../../OptionsSelector/YScaleSelector';
import MutlipleModelsSelector from '../../MultipleModelsSelector/MutlipleModelsSelector';
import { resetPredictions } from '../../../redux/actions/predictionsActions';
import { setParameters } from '../../../redux/actions/parametersActions';
import { radioButtonsStyles } from '../../../assets/GlobalStyles/radioButtons';
import { MinusIcon, PlusIcon } from '../../SvgIcons/SequencesInputIcons';
import { numFormat } from '../../../functions/formats';
import { StyledTextField } from '../../../assets/GlobalStyles/TextField';
import { HideIcon } from '../../Alerts/styles';
import { setHiddenMenu } from '../../../redux/actions/renderActions';
import { getIsMobile } from '../../../redux/selectors/statusSelector';
import HamburgerIcon from '../../SvgIcons/HamburgerIcon';
import Alerts from '../../Alerts/Alerts';
import MultiplicityMethodSelector from '../../OptionsSelector/MultiplicityMethodSelector';
import { styles } from './styles';
import { predictionBaselineSelector, trackingFromSelector, trackingToSelector } from '../../../redux/selectors/parametersSelector';

class ChartOptions extends PureComponent {
    state = {
        sigmaAg: 0,
        tau: 0,
    }

    componentDidMount() {
        const { sigmaAg, tau } = this.props;

        if (sigmaAg && !this.state.sigmaAg)
            this.setState({ sigmaAg: sigmaAg })

        if (tau && !this.state.tau)
            this.setState({ tau: tau })
    }

    handleClick = name => (type) => {
        const { setParameters, resetPredictions } = this.props;
        let floatInput = this.state[name];

        if (type === 'up')
            floatInput += 1;
        else
            floatInput -= 1;

        this.setState({ [name]: floatInput });
        setParameters({ [name]: floatInput });
        resetPredictions();
    }

    handleSigmaAgChange = event => {
        this.setState({ sigmaAg: event.target.value });
    };

    handleTauChange = event => {
        this.setState({ tau: event.target.value });
    };

    handleKeyPress = name => event => {
        const { resetPredictions, setParameters } = this.props;
        if (event.key === 'Enter') {
            setParameters({ [name]: this.state[name] });
            resetPredictions();
        }
        return;
    }

    handleChange = name => event => {
        const { setParameters } = this.props;
        setParameters({ [name]: event.target.checked });
    };

    handleHideClick = () => {
        const { hiddenMenu, setHiddenMenu } = this.props;
        setHiddenMenu({ hiddenMenu: !hiddenMenu });
    }

    render = () => {
        const { classes, intro, isMobile, displayErrorBars, displayGreyZone, permissions, hiddenMenu, showAlert, freqCategory, chartLayout, plotType, showPrediction } = this.props;

        return (
            <>
                {
                    !hiddenMenu ?
                        <div className={showAlert ? classes.rootWithAlerts : classes.rootWithoutAlerts}>
                            <div style={{ width: '100%' }}>
                                <Alerts module="frequencies" />
                                <form autoComplete="off" className={classes.form}>
                                    {
                                        !isMobile &&
                                        <div className={classes.buttonWrapper}>
                                            <IconButton className={classes.hideButton} onClick={this.handleHideClick}>
                                                <HideIcon />
                                            </IconButton>
                                        </div>
                                    }
                                    {(permissions.frequenciesCategorySelector) && <FrequenciesCategorySelector intro={intro} />}

                                    <ColorScaleSelector colorBy={freqCategory} onlyDiscrete />
                                    <ScalesLegend scaleId="frequencies" measureName={freqCategory} ticks={3} inContainer precision={3} mode="frequencies" />

                                    <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                        <Grid item sm={12} md={chartLayout !== 'stacked' ? 6 : 12}>
                                            {(permissions.layoutSelector || intro) && <ChartLayoutSelector intro={intro} />}
                                        </Grid>
                                        <Grid item sm={12} md={chartLayout !== 'stacked' ? 6 : 12}>
                                            {(permissions.plotTypeSelector || intro) && chartLayout !== 'stacked' && <PlotTypeSelector />}
                                        </Grid>
                                    </Grid>
                                    {(permissions.plotTypeSelector || intro) && chartLayout !== 'stacked' && plotType === 'frequencies' && <YScaleSelector />}
                                    {permissions.modelSelector && showPrediction && <MutlipleModelsSelector />}
                                    {!intro &&
                                        <Grid container spacing={2} style={{ paddingTop: '10px' }}>
                                            <Grid item sm={12} md={intro ? 12 : 6}>
                                                {(permissions.strainsSubsetSelector) && <StrainsSubsetSelector intro={intro} context="clade" />}
                                            </Grid>
                                            <Grid item sm={12} md={6}>
                                                {permissions.multiplicityMethodSelector && <MultiplicityMethodSelector />}
                                            </Grid>
                                        </Grid>
                                    }


                                    {(permissions.trackingDatesSelector || intro) && <DatesSelector intro={intro} />}
                                    {!intro &&
                                        <Grid container spacing={2} style={{paddingTop: '13px'}}>

                                            {permissions.sigmaAgSelector &&
                                                <Grid item sm={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <StyledTextField
                                                            id="sigmaAg"
                                                            label="SigmaAg"
                                                            type='number'
                                                            className={classes.textField}
                                                            value={this.state.sigmaAg}
                                                            onChange={this.handleSigmaAgChange.bind(this)}
                                                            onKeyDown={this.handleKeyPress('sigmaAg')}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton className={classes.icon} onClick={() => this.handleClick('sigmaAg')('down')}>
                                                                            <MinusIcon />
                                                                        </IconButton>
                                                                        <IconButton className={classes.icon} onClick={() => this.handleClick('sigmaAg')('up')}>
                                                                            <PlusIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            }
                                            {permissions.tauSelector &&
                                                <Grid item sm={12} md={6} >
                                                    <FormControl fullWidth>
                                                        <StyledTextField
                                                            id="tau"
                                                            label="Tau"
                                                            type='number'
                                                            className={classes.textField}
                                                            value={this.state.tau}
                                                            onChange={this.handleTauChange.bind(this)}
                                                            onKeyDown={this.handleKeyPress('tau')}
                                                            InputProps={{
                                                                endAdornment:
                                                                    <InputAdornment position="end">
                                                                        <IconButton className={classes.icon} onClick={() => this.handleClick('tau')('down')}>
                                                                            <MinusIcon />
                                                                        </IconButton>
                                                                        <IconButton className={classes.icon} onClick={() => this.handleClick('tau')('up')}>
                                                                            <PlusIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                            }
                                        </Grid>
                                    }

                                    <Grid container className={classes.rootRadio} style={{ paddingTop: intro ? '20px' : '10px' }}>
                                        {(permissions.displayErrorBarsSelector /*|| intro*/) &&
                                            <Grid item xs={6} className={classes.option}>
                                                <FormControlLabel
                                                    className={classes.formControl}
                                                    value="displayErrorBars"
                                                    size='small'
                                                    label={<Typography className={classes.formControlLabel}>Error bars</Typography>}
                                                    control={
                                                        (
                                                            <Checkbox
                                                                icon={<CircleUnchecked />}
                                                                checked={displayErrorBars}
                                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                                onChange={this.handleChange('displayErrorBars')}
                                                                className={classes.checkbox}
                                                                id='displayErrorBars'
                                                            />
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        }
                                        {(permissions.displayGreyZoneSelector || intro) &&
                                            <Grid item xs={6} style={{ marginRight: '1%' }}>
                                                <FormControlLabel
                                                    className={classes.formControl}
                                                    value="displayGreyZone"
                                                    size='small'
                                                    label={<Typography className={classes.formControlLabel}>Count density</Typography>}
                                                    control={
                                                        (
                                                            <Checkbox
                                                                icon={<CircleUnchecked />}
                                                                checked={displayGreyZone}
                                                                checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                                                onChange={this.handleChange('displayGreyZone')}
                                                                className={classes.checkbox}
                                                                id='displayGreyZone'
                                                            />
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        }

                                    </Grid>
                                    <FrequenciesLegend />
                                    {permissions.generateReportButton && !intro && <RegionalFreqButton />}
                                </form>
                            </div>



                        </div>
                        :
                        <IconButton className={classes.expandButton} onClick={this.handleHideClick}>
                            <HamburgerIcon />
                        </IconButton>
                }
            </>
        );
    };
}
ChartOptions.propTypes = {
    classes: PropTypes.object.isRequired,
    lineage: PropTypes.string,
    modelId: PropTypes.string,
    trackingTo: PropTypes.instanceOf(Date),
    predictionBaseline: PropTypes.instanceOf(Date),
    freqCategory: PropTypes.string,
    sigmaAg: PropTypes.number,
    gene: PropTypes.string,
    hla: PropTypes.string,
    binMethod: PropTypes.string,
    binCnt: PropTypes.number,
    tau: PropTypes.number,
    hiddenMenu: PropTypes.bool,
    displayErrorBars: PropTypes.bool,
    showPrediction: PropTypes.bool,
    isMobile: PropTypes.bool,
    displayGreyZone: PropTypes.bool,
    showAlert: PropTypes.bool,
    chartLayout: PropTypes.string,
    plotType: PropTypes.string,
    fetchPredictions: PropTypes.func,
    resetPredictions: PropTypes.func,
    setParameters: PropTypes.func,
    setHiddenMenu: PropTypes.func,
    permissions: PropTypes.object
}

const mapStateToProps = (state) => ({
    lineage: state.parameters.lineage,
    modelId: state.parameters.modelId,
    sigmaAg: state.parameters.sigmaAg,
    tau: state.parameters.tau,
    freqCategory: state.parameters.freqCategory,
    predictionBaseline: predictionBaselineSelector(state),
    trackingFrom: trackingFromSelector(state), //state.parameters.trackingFrom,
    trackingTo: trackingToSelector(state), //state.parameters.trackingTo,
    gene: state.parameters.gene,
    hla: state.parameters.hla,
    binMethod: state.parameters.binMethod,
    binCnt: state.parameters.binCnt,
    displayErrorBars: state.parameters.displayErrorBars,
    displayGreyZone: state.parameters.displayGreyZone,
    plotType: state.parameters.plotType,
    cladesStatus: state.cladeData.cladesStatus,
    permissions: state.user.permissions.frequenciesPermissions,
    hiddenMenu: state.render.hiddenMenu,
    hiddenMenuMobile: state.render.hiddenMenuMobile,
    isMobile: getIsMobile(),
    showAlert: state.alert.strainTree.show,
    chartLayout: state.parameters.chartLayout,
    showPrediction: state.parameters.showPrediction,
    ...state.models
});

const mapDispatchToProps = dispatch => ({
    resetPredictions: () => dispatch(resetPredictions()),
    setParameters: payload => dispatch(setParameters(payload)),
    setHiddenMenu: payload => dispatch(setHiddenMenu(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...styles(theme),
    ...radioButtonsStyles(theme),
}), {
    withTheme: true
})(ChartOptions));
