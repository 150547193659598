// Action Types
import {
    FETCH_CLADES_REQUEST,
    FETCH_CLADES_SUCCESS,
    FETCH_CLADES_ERROR,
    FETCH_ACTIVE_CLADES_REQUEST,
    FETCH_ACTIVE_CLADES_SUCCESS,
    FETCH_ACTIVE_CLADES_ERROR,
    RESET_ACTIVE_CLADES,
} from './actionTypes';

// Base Actions
import { received, request } from '../baseActions';

export const fetchClades = (payload) => received(FETCH_CLADES_REQUEST, payload);
export const fetchCladesSuccess = (payload) => received(FETCH_CLADES_SUCCESS, payload);
export const fetchCladesError = (payload) => received(FETCH_CLADES_ERROR, payload);

export const fetchActiveClades = (payload) => received(FETCH_ACTIVE_CLADES_REQUEST, payload);
export const fetchActiveCladesSuccess = (payload) => received(FETCH_ACTIVE_CLADES_SUCCESS, payload);
export const fetchActiveCladesError = (payload) => received(FETCH_ACTIVE_CLADES_ERROR, payload);

export const resetActiveClades = () => request(RESET_ACTIVE_CLADES);
