import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { FormControl, MenuItem } from '@mui/material';
import { bindActionCreators } from 'redux';
import CustomLabel from '../../../assets/GlobalStyles/CustomLabel';
import CustomSelect from '../../../assets/GlobalStyles/CustomSelect';
// import { setColoring } from '../../../redux/actions/geomapActions';
import { setParameters } from '../../../redux/actions/parametersActions';

// const styles = () => ({
//     formControl: {
//         minWidth: 120,
//         margin: '8px 0px',
//     },
// });

const useStyles = makeStyles({
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
})

const coloringOptions = [
    {
        key: 'count-key',
        value: 'count',
        label: 'Sequences'
    },
    {
        key: 'multiplicity-key',
        value: 'multiplicity',
        label: 'Cases'
    }
];

const GeoFreqCategorySelector = (props) => {
    const { geoFreqCategory,
        // classes,
        setParameters } = props;

    const classes = useStyles();
    const handleColoringChange = async event => {
        setParameters({ geoFreqCategory: event.target.value })
    }

    return (
        <FormControl className={classes.formControl} fullWidth>
            <CustomLabel id={'geoFreqCategory'} label={'Frequencies category'} />
            <CustomSelect
                value={geoFreqCategory}
                onChange={handleColoringChange}
                inputProps={{
                    name: 'geoFreqCategory',
                    id: 'geoFreqCategory',
                }}
            >
                {coloringOptions.map((cB) => (
                    <MenuItem key={cB.key} value={cB.value}> {cB.label}</MenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );

}

GeoFreqCategorySelector.propTypes = {
    geoFreqCategory: PropTypes.string,
    setParameters: PropTypes.func

}
const mapStateToProps = ({ parameters }) => ({
    geoFreqCategory: parameters.geoFreqCategory
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setParameters
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeoFreqCategorySelector);
