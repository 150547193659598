import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { styles, EditMeasureDialogSX } from './styles';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import ColorPickerComponent from '../../Scales/Elements/ColorPicker';

const dialogInitState = {
    key: '',
    color: '#ffffff',
    label: ''
}

const UpdateLineageRegionDialog = ({ openDialog, handleCloseDialog, oldLineageRegion, updateLineageRegion }) => {
    const [newLineageRegion, setNewLineageRegion] = useState(dialogInitState);
    const classes = styles();

    useEffect(() => {
        setNewLineageRegion({ ...oldLineageRegion });
    }, [])

    const handleChangeKey = (e) => setNewLineageRegion({ ...newLineageRegion, key: e.target.value });
    const handleLabelChange = (e) => setNewLineageRegion({ ...newLineageRegion, label: e.target.value });
    const handleColorChange = (color) => {
        const hexColor = color.hex;
        setNewLineageRegion({ ...newLineageRegion, color: hexColor });
    }

    const handleSubmit = async () => {
        await updateLineageRegion(oldLineageRegion.key, newLineageRegion);
        setNewLineageRegion({ ...dialogInitState });
        handleCloseDialog();
    }

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={EditMeasureDialogSX}>
            <DialogTitle id="confirm-dialog-title">
                Update lineage region:
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <StyledTextField
                    id="key"
                    type="text"
                    label="Key"
                    autoComplete='off'
                    className={classes.textField}
                    value={newLineageRegion.key}
                    onChange={handleChangeKey}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    className={classes.textField}
                    value={newLineageRegion.label}
                    onChange={handleLabelChange}
                />
                <div>
                    Color:
                    <ColorPickerComponent handleColorChange={handleColorChange} color={newLineageRegion.color} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpdateLineageRegionDialog;