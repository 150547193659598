import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import { signIn } from '../../redux/actions/userActions';
import { signInStyles } from './styles/signInStyles';
import { WelcomeTitle } from './WelcomeTitle';
import { LoginForm } from './LoginForm';
import { ResetPasswordMongo } from './ResetPassword/ResetPasswordMongo';
import { resetSession } from '../../redux/actions/sessionActions';
import Register from './Register/Register';

class Signin extends Component {
    state = {
        email: '',
        password: '',
        errorMsg: null,
        show: 'login'
    };

    componentDidMount = () => {
        document.body.classList.remove('patient-view');
    };

    clickSubmit = () => {
        const { email, password } = this.state;
        const { resetSession } = this.props;
        const user = {
            email: email || undefined,
            password: password || undefined
        };
        resetSession({lineage: ''});
        const { dispatchSignIn } = this.props;
        dispatchSignIn(user);
    };

    handleChange = (e, name )=> {
        this.setState({ [name]: e.target.value, errorMsg: null });
    };

    changeView = (value) => {
        this.setState({
            show: value
        })
    }

    setError = (error) => {
        console.log(error.message);
        this.setState({ errorMsg: error.message });
    }

    render = () => {
        const { error, classes } = this.props;
        const { email, password, errorMsg, show } = this.state;
        const err = error || errorMsg;

        return (
            <>
                <Card className={classes.card} elevation={0}>
                    <CardContent className={classes.cardContent}>
                        <WelcomeTitle />
                        { show === 'reset' &&
                            <ResetPasswordMongo
                                email={email}
                                handleChange={this.handleChange}
                                changeView={this.changeView}
                                setError={this.setError} 
                            />
                        } 
                        { show === 'login' &&
                            <LoginForm
                                email={email}
                                password={password}
                                err={err}
                                handleChange={this.handleChange}
                                clickSubmit={this.clickSubmit}
                                changeView={this.changeView} 
                            />
                        }
                        { show === 'register' &&
                            <Register
                                changeView={this.changeView} 
                            />
                        }
                    </CardContent>
                </Card>
            </>
        );
    }
}

Signin.propTypes = {
    error: PropTypes.string,
    dispatchSignIn: PropTypes.func,
    classes: PropTypes.shape({ submit: PropTypes.string, error: PropTypes.string, textField: PropTypes.string, title: PropTypes.string, card: PropTypes.string })
};

// export default Signin;

const mapStateToProps = ({ user }) => ({ ...user });

const mapDispatchToProps = dispatch => bindActionCreators({
    dispatchSignIn: signIn,
    resetSession
}, dispatch);

export default withStyles(signInStyles)(connect(mapStateToProps, mapDispatchToProps)(Signin));

