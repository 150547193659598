import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, FormControl, MenuItem, Tooltip, FormControlLabel, Typography, Checkbox, Grid } from '@mui/material';
import { styles, EditMeasureDialogSX } from './styles';
import CustomLabel from '../../../../assets/GlobalStyles/CustomLabel';
import { StyledTextField } from '../../../../assets/GlobalStyles/TextField';
import CustomSelect from '../../../../assets/GlobalStyles/CustomSelect';
import { getMeasureScaleParamName } from '../../../../functions/functions';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

const dialogInitState = {
    name: '',
    lineage: '',
    scaleType: '',
    scale: '',
    size: '',
    shape: '',
    label: '',
    discrete: false,
    numeric: false,
    colorBy: false,
    frequenciesChart: false,
    geoColorBy: false,
    xScale: false,
    yScale: false,
    ignoreStrainCutOffDate: false,
    show: 'allValues',
    element: 'node'
}

const EditMeasureDialog = ({ measure, scales, openDialog, handleCloseDialog, lineages, updateMeasure }) => {
    const [updatedMeasure, setUpdatedMeasure] = useState(dialogInitState);
    const [scalesOptions, setScalesOptions] = useState([]);
    const [scaleTypes, setScaleTypes] = useState([]);
    const [param, setParam] = useState();
    //const [isCustom, setIsCustom] = useState(false);
    const type = measure.element;
    const classes = styles();

    const getScalesOptions = (lineage, scaleType) => {
        const scalesOpts = scales.filter(scale => {
            const good = (!scale.lineage || (scale.lineage && scale.lineage === lineage)) && scale.id.split('.')[0] === scaleType;
            return good;
        }).map(scale => scale.id);
        return [...new Set(scalesOpts)];
    };

    const getScalesTypesOptions = () => {
        const opts = scales.map(el => el.id.split('.')[0]);
        return [...new Set(opts)];
    }

    useEffect(() => {
        const defaultOptions = getScalesTypesOptions()
        setScaleTypes(defaultOptions);
        let scaleType = '';
        if (typeof measure.scale === 'string') {
            scaleType = measure.scale.split('.')[0];
            const scalesOptionsfilter = getScalesOptions(measure.lineage, scaleType);
            setScalesOptions(scalesOptionsfilter);
        } else {
            scaleType = 'antigenic';
            const paramName = getMeasureScaleParamName(measure);
            const paramValues = Object.keys(measure.scale[paramName]);
            const scalesOptionsfilter = getScalesOptions(measure.lineage, scaleType);
            setScalesOptions(scalesOptionsfilter);
            setParam({ paramName, paramValues });
        }
       // setIsCustom(measure.custom ? true : false);
        setUpdatedMeasure({
            name: measure.measure,
            label: measure.label,
            scaleType,
            scale: measure.scale,
            size: measure?.size,
            shape: measure?.shape,
            show: typeof measure?.show !== 'object' && measure?.show || 'none',
            lineage: measure?.lineage || 'none',
            discrete: measure?.discrete || false,
            numeric: measure?.numeric || false,
            colorBy: measure?.colorBy || false,
            frequenciesChart: measure?.frequenciesChart || false,
            geoColorBy: measure?.geoColorBy || false,
            xScale: measure?.xScale || false,
            yScale: measure?.yScale || false,
            element: measure?.element || 'node',
            ignoreStrainCutOffDate: typeof measure?.ignoreStrainCutOffDate !== 'object' && measure?.ignoreStrainCutOffDate  || false,
        });

    }, []);

    useEffect(() => {
        const scalesOptionsfilter = getScalesOptions(updatedMeasure.lineage, updatedMeasure.scaleType)
        setScalesOptions(scalesOptionsfilter);
    }, [updatedMeasure.lineage, updatedMeasure.scaleType]);

    const handleLabelChange = (e) => setUpdatedMeasure({ ...updatedMeasure, label: e.target.value });
    const handleChangeLineage = (e) => setUpdatedMeasure({ ...updatedMeasure, lineage: e.target.value });
    const handleChangeShow = (e) => setUpdatedMeasure({ ...updatedMeasure, show: e.target.value });
    const handleChangeElement = (e) => setUpdatedMeasure({ ...updatedMeasure, element: e.target.value });
    const handleChangeScaleType = (e) => setUpdatedMeasure({ ...updatedMeasure, scaleType: e.target.value });
    const handleScaleChange = (e) => setUpdatedMeasure({ ...updatedMeasure, scale: e.target.value });
    const handleSizeChange = (e) => setUpdatedMeasure({ ...updatedMeasure, size: e.target.value });
    const handleShapeChange = (e) => setUpdatedMeasure({ ...updatedMeasure, shape: e.target.value });
    const handleCheckboxChange = (e, type) => setUpdatedMeasure({ ...updatedMeasure, [type]: e.target.checked});
    const handleScaleChangeParametrized = (e, type) =>  
        setUpdatedMeasure({ ...updatedMeasure, scale: { [param.paramName]: { ...updatedMeasure.scale[param.paramName], [type]: e.target.value}}});

    const handleSubmit = () => {
        const newMeasure = {...updatedMeasure};

        const body = {
            oldMeasure: measure,
            newMeasure
        };
        updateMeasure(body);
        setParam('');
        setUpdatedMeasure({ ...dialogInitState});
    }

    const _scale = typeof updatedMeasure.scale == 'string' ? updatedMeasure.scale : '';
    const xs = 12;

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog} sx={EditMeasureDialogSX}>
            <DialogTitle id="confirm-dialog-title">
                Edit measure: {measure.measure}
            </DialogTitle>

            <DialogContent style={{ height: '450px', width: 400, overflow: 'overlay' }}>
                <StyledTextField
                    disabled
                    id="name"
                    type="text"
                    label="Measure name"
                    autoComplete='off'
                    className={classes.textField}
                    value={updatedMeasure.name}
                    onChange={() => { }}
                />
                <StyledTextField
                    id="label"
                    type="text"
                    label="Label"
                    autoComplete='off'
                    className={classes.textField}
                    value={updatedMeasure.label}
                    onChange={handleLabelChange}
                />
                <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'plotType'} label={'Lineage'} />
                    <CustomSelect
                        value={updatedMeasure.lineage}
                        onChange={handleChangeLineage}
                        inputProps={{
                            name: 'lineage',
                            id: 'lineage',
                        }}
                    >
                        <MenuItem key={'empty'} value={'none'} >{'(all)'}</MenuItem>
                        {lineages.length > 0 && lineages.map(option =>
                            (<MenuItem key={option} value={option} >{option}</MenuItem>)
                        )}

                    </CustomSelect>
                </FormControl>
                {measure.custom && <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'show'} label={'Show'} />
                    <CustomSelect
                        value={updatedMeasure.show}
                        onChange={handleChangeShow}
                        inputProps={{
                            name: 'show',
                            id: 'show',
                        }}
                    >
                        <MenuItem key={'none'} value={'none'} >none</MenuItem>
                        <MenuItem key={'allValues'} value={'allValues'} >allValues</MenuItem>
                        <MenuItem key={'valueChange'} value={'valueChange'} >valueChange</MenuItem>
                    </CustomSelect>
                </FormControl>}
                {measure.custom && <FormControl fullWidth className={classes.formControlLineage}>
                    <CustomLabel id={'element'} label={'Element'} />
                    <CustomSelect
                        value={updatedMeasure.element}
                        onChange={handleChangeElement}
                        inputProps={{
                            name: 'element',
                            id: 'element',
                        }}
                    >
                        <MenuItem key={'node'} value={'node'} >Node</MenuItem>
                        <MenuItem key={'branch'} value={'branch'} >Branch</MenuItem>
                    </CustomSelect>
                </FormControl>}
                <Grid container >
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="discrete"
                            label={<Typography className={classes.formControlLabel}>Discrete</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.discrete}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'discrete')}
                                        inputProps={{
                                            name: 'discrete',
                                            id: 'discrete',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="numeric"
                            label={<Typography className={classes.formControlLabel}>Numeric</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.numeric}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'numeric')}
                                        inputProps={{
                                            name: 'numeric',
                                            id: 'numeric',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="colorBy"
                            label={<Typography className={classes.formControlLabel}>Color by</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.colorBy}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'colorBy')}
                                        inputProps={{
                                            name: 'colorBy',
                                            id: 'colorBy',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="frequenciesChart"
                            label={<Typography className={classes.formControlLabel}>Frequencies chart</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.frequenciesChart}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'frequenciesChart')}
                                        inputProps={{
                                            name: 'frequenciesChart',
                                            id: 'frequenciesChart',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="geoColorBy"
                            label={<Typography className={classes.formControlLabel}>Geo map color by</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.geoColorBy}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'geoColorBy')}
                                        inputProps={{
                                            name: 'geoColorBy',
                                            id: 'geoColorBy',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="xScale"
                            label={<Typography className={classes.formControlLabel}>X scale</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.xScale}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'xScale')}
                                        inputProps={{
                                            name: 'xScale',
                                            id: 'xScale',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="yScale"
                            label={<Typography className={classes.formControlLabel}>Y scale</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.yScale}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'yScale')}
                                        inputProps={{
                                            name: 'yScale',
                                            id: 'yScale',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={xs} >
                        <FormControlLabel
                            className={classes.boolOption}
                            value="ignoreStrainCutOffDate"
                            label={<Typography className={classes.formControlLabel}>Ignore strainCutOffDate</Typography>}
                            control={
                                (
                                    <Checkbox
                                        icon={<CircleUnchecked />}
                                        checked={updatedMeasure.ignoreStrainCutOffDate}
                                        checkedIcon={<RadioButtonCheckedIcon style={{ color: '#6F6CFF' }} />}
                                        onChange={(e) => handleCheckboxChange(e, 'ignoreStrainCutOffDate')}
                                        inputProps={{
                                            name: 'ignoreStrainCutOffDate',
                                            id: 'ignoreStrainCutOffDate',
                                        }}

                                    />
                                )
                            }
                        />
                    </Grid>                    
                </Grid>
                
                { param ? 
                    <>
                        {
                            scalesOptions.length > 0 && param.paramValues.map((elem) => {
                                const filteredOptions =  scalesOptions.filter(opt => opt.includes(elem));
                
                                return ( 
                                    <div key={elem}>
                                        <FormControl fullWidth className={classes.formControlLineage}>
                                            <CustomLabel id={'scale'} label={`Scale for ${param.paramName}: ${elem}`} />
                                            <CustomSelect
                                                value={updatedMeasure.scale[param.paramName][elem]}
                                                onChange={(e) => handleScaleChangeParametrized(e, elem)}
                                                inputProps={{
                                                    name: 'scale',
                                                    id: 'scale',
                                                }}
                                            >
                                                {filteredOptions.length > 0 && filteredOptions.map((option, index) => {
                                                    const { colors } = scales.find(el => el.id === option);

                                                    if (!Array.isArray(colors))
                                                        return (<MenuItem key={`${option}-${index}`} value={option} >{option}</MenuItem>)

                                                    let gradientStyle = 'linear-gradient(0.25turn, ';

                                                    colors.forEach((t, index) => {
                                                        if (index === colors.length - 1)
                                                            gradientStyle += index === t.length - 1 ? `${t})` : `${t})`;
                                                        else
                                                            gradientStyle += index === t.length - 1 ? `${t})` : `${t}, `;
                                                    });

                                                    const text = <div style={{ whiteSpace: 'pre-line' }}>
                                                        <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                                                    </div>

                                                    return (
                                                        <Tooltip followCursor key={`${option}-${index}`} value={option} placement="bottom-start" title={text} className={classes.tooltip} >
                                                            <MenuItem  className={classes.tooltip} >{option}</MenuItem>
                                                        </Tooltip>
                                                    )
                                                })}

                                            </CustomSelect>
                                        </FormControl>
                                    </div>

                                )
                            })
                        }
                    </>
                    : 
                    <>  
                        <FormControl fullWidth className={classes.formControlLineage}>
                            <CustomLabel id={'scaleType'} label={'Scale type'} />
                            <CustomSelect
                                value={updatedMeasure.scaleType}
                                onChange={handleChangeScaleType}
                                inputProps={{
                                    name: 'scaleType',
                                    id: 'scaleType',
                                }}
                            >
                                {scaleTypes.length > 0 && scaleTypes.map(option =>
                                    (<MenuItem key={option} value={option} >{option}</MenuItem>)
                                )}
                            </CustomSelect>
                        </FormControl>

                        <FormControl fullWidth className={classes.formControlLineage}>
                            <CustomLabel id={'scale'} label={'Scale'} />
                            <CustomSelect
                                value={_scale}
                                onChange={handleScaleChange}
                                inputProps={{
                                    name: 'scale',
                                    id: 'scale',
                                }}
                            >
                                {scalesOptions.length > 0 && scalesOptions.map((option, index) => {
                                    const { colors } = scales.find(el => el.id === option);

                                    if (!Array.isArray(colors))
                                        return (<MenuItem key={`${option}-${index}`} value={option} >{option}</MenuItem>)

                                    let gradientStyle = 'linear-gradient(0.25turn, ';

                                    colors.forEach((t, index) => {
                                        if (index === colors.length - 1)
                                            gradientStyle += index === t.length - 1 ? `${t})` : `${t})`;
                                        else
                                            gradientStyle += index === t.length - 1 ? `${t})` : `${t}, `;
                                    });

                                    const text = <div style={{ whiteSpace: 'pre-line' }}>
                                        <div style={{ height: '15px', width: '120px', background: gradientStyle }} />
                                    </div>

                                    return (
                                        <Tooltip followCursor key={`${option}-${index}`} value={option} placement="bottom-start" title={text} className={classes.tooltip} >
                                            <MenuItem className={classes.tooltip} >{option}</MenuItem>
                                        </Tooltip>
                                    )
                                })}

                            </CustomSelect>
                        </FormControl>
                    </>
                }
                {type === 'branch' &&
                    <>
                        <FormControl fullWidth className={classes.formControlLineage}>
                            <CustomLabel id={'size'} label={'Size'} />
                            <CustomSelect
                                value={updatedMeasure.size}
                                onChange={handleSizeChange}
                                inputProps={{
                                    name: 'size',
                                    id: 'size',
                                }}
                            >

                                <MenuItem key='small' value='small' >Small</MenuItem>
                                <MenuItem key='medium' value='medium' >Medium</MenuItem>
                                <MenuItem key='big' value='big' >Big</MenuItem>
                            </CustomSelect>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControlLineage}>
                            <CustomLabel id={'shape'} label={'Shape'} />
                            <CustomSelect
                                value={updatedMeasure.shape}
                                onChange={handleShapeChange}
                                inputProps={{
                                    name: 'shape',
                                    id: 'shape',
                                }}
                            >
                                <MenuItem key='circle' value='circle' >Circle</MenuItem>
                                <MenuItem key='cross' value='cross' >Cross</MenuItem>
                                <MenuItem key='diamond' value='diamond' >Diamond</MenuItem>
                                <MenuItem key='square' value='square' >Square</MenuItem>
                                <MenuItem key='star' value='star' >Star</MenuItem>
                                <MenuItem key='triangle' value='triangle' >Triangle</MenuItem>
                            </CustomSelect>
                        </FormControl>
                    </>

                }
            </DialogContent>

            <DialogActions>
                <Button className={classes.cancel} onClick={handleCloseDialog}>
                    Cancel
                </Button>
                <Button className={classes.confirm} onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditMeasureDialog
