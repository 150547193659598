import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControl, Grid, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import TreeAntigenicStrainSearch from '../Tree/TreeAntigenicStrainSearch';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import appConfig from '../../config/appConfig';
import { getComplexDomainParameterValues } from '../../redux/selectors/rangeDataSelector';
import GenotypeSelector from './GenotypeSelector';
import HumanSerologySelector from './HumanSerologySelector';
import CladeTypeSelector from './CladeTypeSelector';
import { setParameters, setReferenceStrain, setMeasureScale } from '../../redux/actions/parametersActions';
import { resetGenotypeStatus } from '../../redux/actions/genotypeActions';



const styles = () => ({
    formControl: {
        margin: '8px 0px'
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});

const antigenicDataTypes = appConfig.antigenicDataTypes;

const AdditionalColorBySelector = (props) => {
    const { setParameters, setMeasureScale, refStrain, setReferenceStrain,
        measures, colorBy, colorByVar, params, antigenicTiterType, antigenicDataType, classes,
        gene, hla, geneOptions, hlaOptions, genotypeFilterGenesList, permissions } = props;
    const _gene = gene || '';
    const _hla = hla || '';

    const handleChange = name => event => {
        // console.log(name, event.target.value) 

        const value = event.target.value;
        if (name === 'antigenicDataType') {
            if (value !== 'raw_strain' && `${refStrain || ''}`.match(','))
                setReferenceStrain(refStrain.split(',')[0], 'antigenic') 
        }
        if (name === 'antigenicTiterType') {
            const colorScale = measures[colorBy].scale[name][value];
            setParameters({ [name]: value /*, colorScale*/ });
            setMeasureScale({ measure: colorBy, scaleName: colorScale, parameters: { ...params, [name]: value } });
            return;
        }

        setParameters({ [name]: value });
        //console.log(`[handle Change]: name = ${name}`);
    };





    return (
        <>
            {(colorBy === 'clade' && permissions.cladeTypeSelector) &&
                <CladeTypeSelector />
            }
            {(colorBy === 'antigenic') && (
                <>
                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id='antigenicDataType' label='Data' />
                        <CustomSelect
                            value={antigenicDataType}
                            onChange={handleChange('antigenicDataType')}
                            inputProps={{ name: 'antigenicDataType', id: 'antigenicDataType' }}
                        >
                            {antigenicDataTypes.map(({ id, label }) => (
                                <MenuItem key={id || '_'} value={id}>{label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                    <FormControl className={classes.formControl} fullWidth>
                        <CustomLabel id='antigenicTiterType' label={'Titer type'} />
                        <CustomSelect
                            value={antigenicTiterType}
                            onChange={handleChange('antigenicTiterType')}
                            inputProps={{ name: 'antigenicTiterType', id: 'antigenicTiterType' }}
                        >
                            {appConfig.titerTypes.map(({ id, label }) => (
                                <MenuItem key={id || '_'} value={id}>{label}</MenuItem>
                            ))}
                        </CustomSelect>
                    </FormControl>
                    <TreeAntigenicStrainSearch
                        label="Reference strain"
                        // context="antigenic" 
                        searchId="antigenic"
                        multiple={antigenicDataType === 'raw_strain' || antigenicDataType === 'observed_strain'}
                    />
                </>
            )}
            {(colorBy === 'tcellAntigenicity') && (
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={'gene'} label={'Gene'} />
                            <CustomSelect
                                id={'gene_selector'}
                                value={_gene}
                                onChange={handleChange('gene')}
                                // error={error}
                                inputProps={{ name: `${_gene}` }}
                            >{/*  */}
                                {geneOptions.map(id => (
                                    <MenuItem key={id || '_gene'} value={id}>{id}</MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <FormControl className={classes.formControl} fullWidth>
                            <CustomLabel id={'hla'} label={'HLA'} />
                            <CustomSelect
                                value={_hla}
                                onChange={handleChange('hla')}
                                // error={error}
                                inputProps={{ name: `${_hla}`, id: `${_hla}` }}
                            >
                                {hlaOptions.map(id => (
                                    <MenuItem key={id || 'hla'} value={id}>{id}</MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            )}

            {/* {colorBy === 'genotype' && genotypeFilterGenesList.length > 0 && ( */}
            {colorBy === 'genotype' && (
                <GenotypeSelector colorByVar={colorByVar} />
            )}
            {colorBy === 'humanSerology' && (
                <HumanSerologySelector />
            )}
        </>

    );
}

AdditionalColorBySelector.propTypes = {
    colorBy: PropTypes.string,
    antigenicTiterType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]),
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    hla: PropTypes.string,
    gene: PropTypes.string,
    geneOptions: PropTypes.arrayOf(PropTypes.string),
    hlaOptions: PropTypes.arrayOf(PropTypes.string),
    setParameters: PropTypes.func,
};


const mapStateToProps = (state, ownProps) => {
    return ({
        antigenicDataType: state.parameters.antigenicDataType,
        antigenicTiterType: state.parameters.antigenicTiterType,
        colorBy: ownProps.mapSelector
            ? state.parameters.geoMapColorBy
            : state.parameters.colorBy,
        colorByVar: ownProps.mapSelector ? 'geoMapColorBy' : 'colorBy',
        geneOptions: state.metadata.tcellAntigenicityOptions.geneOptions,
        hlaOptions: state.metadata.tcellAntigenicityOptions.hlaOptions,
        gene: state.parameters.gene,
        hla: state.parameters.hla,
        genotypeFilterGenesList: state.genotype.genotypeFilterGenesList,
        refStrain: state.parameters.refStrain,
        measures: state.metadata.measures,
        params: getComplexDomainParameterValues(state),
        permissions: state.user.permissions.strainTreePermissions,
    });
};

const mapDispatchToProps = dispatch => ({
    setParameters: payload => dispatch(setParameters(payload)),
    resetGenotypeStatus: () => dispatch(resetGenotypeStatus()),
    //setStrainSearchStatus: (payload) => dispatch(setStrainSearchStatus(payload)),
    setReferenceStrain: (payload) => dispatch(setReferenceStrain(payload)),
    setMeasureScale: payload => dispatch(setMeasureScale(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdditionalColorBySelector));
