import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, MenuItem } from '@mui/material';
import CustomLabel from '../../assets/GlobalStyles/CustomLabel';
import {  /*fetchVisibleNodes,*/ setStrainSearchStatus } from '../../redux/actions/treeDataActions';
import { setColorBy, setParameters, setReferenceStrain } from '../../redux/actions/parametersActions';
import { fetchMeasureScalesDomains } from '../../redux/actions/settingsActions';

import { getCustomMeasures, getColorOptions, getGeoMapColorsOptions } from '../../redux/selectors/metadataSelector';
import { resetModelData, resetModelTypes } from '../../redux/actions/modelActions';
import CustomSelect from '../../assets/GlobalStyles/CustomSelect';
import appConfig from '../../config/appConfig';
import { resetHumanSerologyData } from '../../redux/actions/humanSerologyActions';
import { bindActionCreators } from 'redux';

const styles = () => ({
    formControl: {
        minWidth: 120,
        margin: '8px 0px',
    },
    input: {
        height: '35px',
        lineHeight: '35px',
        boxSizing: 'border-box'
    }
});

const ColorBySelector = (props) => {
    //let lastOption = { show: 'none', colorBy: '' };

    const { colorBy, antigenicModelId, zoomNodeId, antigenicModelStatus, modelStatus, /*antigenicTiterType, antigenicDataType,*/
        classes, colorOptions, gene, hla, geneOptions, hlaOptions, mutgene, mutposition, customMeasures,
        lineage, genotypeFilterGenesList, strainSubset, /*fetchVisibleNodes,*/ setParameters, setColorBy,
        mapSelector, setReferenceStrain, regionId, fetchMeasureScalesDomains, modelRegionId,
        resetHumanSerologyData, resetModelData, resetModelTypes
    } = props;

    const handleColorByChange = async event => {
        const colorBy = event.target.value;
        if (mapSelector){
            setParameters({ geoMapColorBy: colorBy });
            return;
        }
        
        //const customTreeAttrId = customMeasures[colorBy] ? colorBy : null;
      
        setColorBy({ colorBy })
        if (colorBy !== 'antigenic') setReferenceStrain(null, 'antigenic');
        const mutRegExp = /^(.*)Mutations$/;
        
        const params = { lineage };

        //console.log(`[handleColorByChange] colorBy = ${colorBy}, antigenicModelStatus  = ${antigenicModelStatus}`);

        switch (colorBy) {
            case 'fitness':
            case 'advance':
            case 'flux': {
                resetModelTypes()
                resetModelData();
                break;
            }
            case 'antigenic': {
                // } else if (colorBy === 'antigenic') {

                params.modelType = 'Antigenic';
                params.modelId = antigenicModelId;
                break;

            }
            case 'trans': {

                // if (colorBy === 'trans') {
                //console.log(`[handleColorByChange] trans`);
                params.showLeaves = true;
                params.showInternalNodes = true;
                params.showColoredNodesOnly = true;
                break;

            }
            case 'ALLMutations':
            case 'NSMutations':
            case 'SMutations': {
                // setParameters({ showLeaves: true, showInternalNodes: true, showColoredNodesOnly: true });
                // } else if (colorBy.match(mutRegExp)) {
                console.log(`[handleColorByChange] mutRegExp`, colorBy);
                const mutationsType = mutRegExp.exec(colorBy)[1];
                params.mutationsType = mutationsType;
                break;

            }
            case 'tcellAntigenicity': {
                //else if (colorBy === 'tcellAntigenicity') {
                //console.log(`[handleColorByChange] tcellAntigenicity, params = ${params}`);
                params.gene = gene || geneOptions[0];
                params.hla = hla || hlaOptions[0];
                params.colorBy = colorBy;
                break;
                //console.log(params);

                // setParameters(params);

                //await fetchTCellAntigenicityScores(params);
            }
            case 'genotype': {
                // else if (colorBy === 'genotype' && genotypeFilterGenesList.length) {

                // console.log(`[handleColorByChange] tcellAntigenicity, params = genotype`);
                if (genotypeFilterGenesList.length)
                    params.mutgene = mutgene || genotypeFilterGenesList[0].val;
                break;
            }
            case 'humanSerology': {
                resetHumanSerologyData();
                break;
            }
            default: {
                if (customMeasures[colorBy]) {
                    // params.customTreeAttrId = colorBy;
                    params.strainSubset = strainSubset;
                }
            }
        }

        setParameters(params);
    
        if (colorBy !== 'genotype' || (mutgene && mutposition)) {
            // console.log('color by selector fetchMeasureScalesDomains', colorBy);
            fetchMeasureScalesDomains({  
                lineage, 
                colorBy,
                mutgene: (colorBy === 'genotype') ? mutgene : undefined,
                mutposition: (colorBy === 'genotype') ? mutposition : undefined
            });
        };
    };

    const colorByError = (antigenicModelStatus === 'nodata' && colorBy === 'antigenic');

    // const _gene = gene || '';
    // const _hla = hla || '';

    return (
        <FormControl className={classes.formControl} fullWidth>
            <CustomLabel id={'colorBy'} error={colorByError} label={'Color by'} />
            <CustomSelect
                value={colorBy}
                onChange={handleColorByChange}
                error={colorByError}
                inputProps={{
                    name: 'colorBy',
                    id: 'colorBy',
                }}
            >
                {colorOptions.map((cB) => (
                    <MenuItem 
                        data-testid={`colorby-option`} 
                        key={cB.key} 
                        value={cB.key}>{cB.label}
                    </MenuItem>
                ))}
            </CustomSelect>
            {colorByError && (
                <FormHelperText id="colorBy-error-text" error>No antigenic summary data for selected model</FormHelperText>
            )}
        </FormControl>
    )
}

ColorBySelector.propTypes = {
    colorBy: PropTypes.string,
    modelId: PropTypes.string,
    antigenicModelId: PropTypes.string,
    regionId: PropTypes.string,
    lineage: PropTypes.string,
    // antigenicCladesStatus: PropTypes.string,
    antigenicTiterType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]),
    antigenicModelStatus: PropTypes.string,
    classes: PropTypes.shape({ formControl: PropTypes.string }),
    colorOptions: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
    hla: PropTypes.string,
    gene: PropTypes.string,
    mutposition: PropTypes.number,
    mutgene: PropTypes.string,
    geneOptions: PropTypes.arrayOf(PropTypes.string),
    hlaOptions: PropTypes.arrayOf(PropTypes.string),
    customMeasures: PropTypes.objectOf(PropTypes.shape({
        key: PropTypes.string,
    })),
    modelStatus: PropTypes.string,
    setColorBy: PropTypes.func,
    setParameters: PropTypes.func,
    // fetchVisibleNodes: PropTypes.func,
    fetchTCellAntigenicityScores: PropTypes.func,
    fetchModel: PropTypes.func
};


const mapStateToProps = (state, ownProps) => {
    // console.log(getGeoMapColorsOptions(state));
    const { colorOptions, colorOptionsMap } = ownProps.mapSelector ? getGeoMapColorsOptions(state) : getColorOptions(state);
    const colorBy = ownProps.mapSelector ? state.parameters.geoMapColorBy : state.parameters.colorBy;

    
    const colorOptionsItems = ownProps.intro ?
        colorOptions.filter(el => appConfig.introColorByOptions.includes(el.key)) : colorOptions;
    // const scaleParams = getComplexDomainParameterValues(state)

    return ({
        lineage: state.parameters.lineage,
        modelId: state.parameters.modelId,
        antigenicModelId: state.parameters.antigenicModelId,
        regionId: state.parameters.regionId,
        zoomNodeId: state.parameters.zoomNodeId,
        strainSubset: state.parameters.strainSubset,
        antigenicModelStatus: state.antigenic.antigenicModelStatus,
        colorOptions: colorOptionsItems,
        colorOptionsMap,
        colorBy,
        customMeasures: getCustomMeasures(state),
        customTreeAttrs: state.customTreeData.customTreeAttrs,
        status: state.customTreeData.status,
        colorByOptionsFilter: state.metadata.colorByOptions || null,
        geneOptions: state.metadata.tcellAntigenicityOptions.geneOptions,
        hlaOptions: state.metadata.tcellAntigenicityOptions.hlaOptions,
        gene: state.parameters.gene,
        hla: state.parameters.hla,
        modelStatus: state.modelData.modelStatus[state.parameters.colorBy] || 'none',
        modelType: state.modelData.modelType,
        mutgene: state.parameters.mutgene,
        mutposition: +state.parameters.mutposition,
        genotypeFilterGenesList: state.genotype.genotypeFilterGenesList,
        refStrain: state.parameters.refStrain,
        modelRegionId: state.parameters.modelRegionId

    });
};

const mapDispatchToProps = dispatch => bindActionCreators({
    // fetchVisibleNodes,
    setColorBy,
    setParameters,
    setStrainSearchStatus,
    setReferenceStrain,
    fetchMeasureScalesDomains,
    resetHumanSerologyData,
    resetModelData,
    resetModelTypes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ColorBySelector));
