import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { select } from 'd3-selection';
import PropTypes from 'prop-types';
import { getChartDataStatus } from '../../../redux/selectors/statusSelector';
import { getColorBins, getSelectedBins } from '../../../redux/selectors/metadataSelector';
import { toggleFrequenciesOption } from '../../../redux/actions/frequenciesActions';
import { handleMouseEnter, handleMouseLeave } from '../Elements/functions';
import { useStyles } from './styles';
import LegendElement from '../Elements/LegendElement';

const missingDataColor = '#dee0e2';
const FrequenciesLegend = ({lineage, gene, hla,
    exportMode, visibleBins, chartLayout, colorBins,
    freqCategory, selectedBins, toggleFrequenciesOption,
}) => {
    const [legendData, setLegendData] = useState([]);
    const classes = useStyles();
    const _element = useRef();
    const freqCategoryFilter = (d) => (colorBins ? colorBins[d] : true);
    const freqCategoryKeyLabel = (d) => {
        const colorBin = colorBins ? colorBins[d] : null;
        const label = colorBin ? colorBin.label || colorBin.value : null;
        const color = colorBin ? colorBin.color : missingDataColor;
        return { key: d, label, color };
    };
    const getStyle = () => (name) => exportMode ? classes[`${name}Export`] : classes[name];
    const style = getStyle();

    useEffect(() => {
        return () => setLegendData([]);
    }, []);
    
    useEffect(() => {
        select('#frequencies_legend_svg').selectAll('g.frequenciesLegend').remove();
    }, [lineage, freqCategory, gene, hla]);

    useEffect(() => {
        const sigVal = (c) => (c.significant == c.key ? 1 : 0);
        const newData = (chartLayout === 'stacked' ? [...selectedBins, 0] : selectedBins)
            .filter(freqCategoryFilter)
            .filter((c) => !exportMode || visibleBins[c])
            .map(freqCategoryKeyLabel)
            .sort((a, b) => sigVal(b) - sigVal(a) || `${a.label}`.localeCompare(b.label));
        const finalData = newData.sort((a, b) => a.key - b.key);
        setLegendData(finalData);
    }, [selectedBins]);

    return (
        <div className={style('rootFrequenciesLegend')}>
            <div id="legendWrapper" ref={_element} className={style('legend')}>
                {legendData.map((color) => {
                    const visible = visibleBins[color.key] || (chartLayout === 'stacked');
                    const border = `3px solid ${color.color}`;
                    
                    return ( 
                        <LegendElement 
                            key={`elem-${color.key}`}
                            keyId={color.key}
                            visible={visible}
                            fontSize='12px'
                            handleClick={() => toggleFrequenciesOption(color.key)}
                            handleEnter={() => handleMouseEnter(color.key, chartLayout)}
                            handleLeave={() => handleMouseLeave(color.key, chartLayout)}
                            label={color.label}
                            color={color.color} 
                            border={border}
                            textDecoration='none'
                            size={exportMode ? 10 : 14}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { freqCategory } = state.parameters;
    const { clades } = state.cladeData;
    const { measures } = state.metadata;
    const { visibleBins } = state.parameters;
    const { chartLayout, lineage, exportMode, gene, hla } = state.parameters;
    const loading = getChartDataStatus(state);
    const colorBins = measures[freqCategory]?.discrete && getColorBins('freqCategory')(state);
    const selectedBins = measures[freqCategory]?.discrete ? getSelectedBins(state) : [];

    return {
        lineage,
        freqCategory,
        gene,
        hla,
        loading,
        selectedBins,
        colorBins,
        clades,
        visibleBins,
        chartLayout,
        exportMode,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ toggleFrequenciesOption }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FrequenciesLegend);

FrequenciesLegend.propTypes = {
    selectedBins: PropTypes.arrayOf(PropTypes.string),
    colorBins: PropTypes.objectOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
            color: PropTypes.string,
        }),
    ),
    visibleBins: PropTypes.objectOf(PropTypes.bool),
    loading: PropTypes.bool,
    toggleFrequenciesOption: PropTypes.func,
    exportMode: PropTypes.bool,
    chartLayout: PropTypes.string,
    lineage: PropTypes.string,
    freqCategory: PropTypes.string,
    gene: PropTypes.string,
    hla: PropTypes.string,
};
